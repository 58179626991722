import React, { useEffect, useState } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useRouteMatch,
    Redirect
} from "react-router-dom";

import { Navbar, Nav, Container, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import Ticker from 'react-ticker'
import { LinkContainer } from 'react-router-bootstrap'

import 'bootstrap/dist/css/bootstrap.min.css';
import './MainPage.css'

import { logout, userIdentity } from '../../redux/user/userSlice'
import { fetchProfileByToken } from '../../redux/user/userThunk'

import HomePage from '../HomePage/HomePage';
import MyBookingPage from '../MyBookingPage/MyBookingPage'
import SettingPage from '../SettingPage/SettingPage';
import NavItem from '@restart/ui/esm/NavItem';
import ConditionalContainer from '../../components/ConditionalContainer';
import UserManagePage from '../UserManagePage/UserManagePage';
import AdminHomePage from '../AdminHomePage/AdminHomePage';
import RepairmentManagePage from '../RepairmentManagePage/RepairmentManagePage';
import EventManagePage from '../EventManagePage/EventManagePage';
import QuestionPage from '../QuestionPage/QuestionPage';
import RepairmentFormPage from '../RepairmentFormPage/RepairmentFormPage';
import EventDetailPage from '../EventDetailPage/EventDetailPage';
import StudentSongPage from '../StudentSongPage/StudentSongPage';
import StudentSongFormPage from '../StudentSongFormPage/StudentSongFormPage';
import TeacherSongPage from '../TeacherSongPage/TeacherSongPage';
import { fetchSystemInfo } from '../../redux/system/systemThunk';
import ClassroomRegulationPage from '../ClassroomRegulationPage/ClassroomRegulationPage';
import SystemSettingPage from '../SystemSettingPage/SystemSettingPage';
import TeachingTypeManagePage from '../TeachingTypeManagePage/TeachingTypeManagePage';
import RoomTypeManagePage from '../RoomTypeManagePage/RoomTypeManagePage';
import ReservationTypeManagePage from '../ReservationTypeManagePage/ReservationTypeManagePage';
import ReservationTypeFormPage from '../ReservationTypeFormPage/ReservationTypeFormPage';
import RoomManagePage from '../RoomManagePage/RoomManagePage';
import RoomFormPage from '../RoomFormPage/RoomFormPage';
import AdminReservationFormPage from '../AdminReservationFormPage/AdminReservationFormPage';
import AddUserFormPage from '../AddUserFormPage/AddUserFormPage';
import UserBannedPage from '../UserBannedPage/UserBannedPage';
import AdminReservationRecordPage from '../AdminReservationRecord/AdminReservationRecordPage';
import ReservationFormPage from '../ReservationFormPage/ReservationFormPage';
import ReservationSearchPage from '../ReservationSearchPage/ReservationSearchPage';
import AdminSemesterManagePage from '../AdminSemesterManagePage/AdminSemesterManagePage';
import AdminSemesterDetailPage from '../AdminSemesterDetailPage/AdminSemesterDetailPage';
import AddWeekReservationPage from '../AddWeekReservationPage/AddWeekReservationPage';
import UserReservationPausePage from '../UserReservationPausePage/UserReservationPausePage';


export default function MainPage() {

    const { path, url } = useRouteMatch();
    const dispatch = useDispatch();

    const user = useSelector(state => state.user);
    const system = useSelector(state => state.system);

    const profile = user.profile;
    const isTeacher = profile.identity === userIdentity.teacher;
    const isStudent = profile.identity === userIdentity.student;
    const isAdminF = profile.identity === userIdentity.admin_f;
    const isAdminS = profile.identity === userIdentity.admin_s;



    useEffect(async () => {
        dispatch(fetchProfileByToken({ token: localStorage.getItem('token') }));
    }, []);


    const logoutEvent = (event) => {
        dispatch(logout())
    }


    return (
        <Container className="mainpage-container">
            <Row className="justify-content-md-center">
                <Col md='10'>
                    <Navbar variant="light" expand="lg" collapseOnSelect>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                        <Container className='navbar-container'>
                            <Navbar.Collapse id="responsive-navbar-nav" className="py-0">
                                <Nav className="me-auto">
                                    <ConditionalContainer condition={isStudent}>
                                        <LinkContainer to={`${path}/home`}><Nav.Link><p className="nav-color">首頁</p></Nav.Link></LinkContainer>
                                        <LinkContainer to={`${path}/myreservation`}><Nav.Link><p className="nav-color">我的預約</p></Nav.Link></LinkContainer>
                                        <LinkContainer to={`${path}/studentsongpage`}><Nav.Link href="#"><p className="nav-color">曲目上傳</p></Nav.Link></LinkContainer>
                                        <LinkContainer to={`${path}/question`}><Nav.Link href="#"><p className="nav-color">相關問題</p></Nav.Link></LinkContainer>
                                        <LinkContainer to={`${path}/setting`}><Nav.Link href="#"><p className="nav-color">帳戶設定</p></Nav.Link></LinkContainer>
                                        <LinkContainer to={`${path}`}><Nav.Link><p onClick={logoutEvent} className="nav-color">登出</p></Nav.Link></LinkContainer>
                                    </ConditionalContainer>
                                    <ConditionalContainer condition={isTeacher}>
                                        <LinkContainer to={`${path}/home`}><Nav.Link><p className="nav-color">首頁</p></Nav.Link></LinkContainer>
                                        <LinkContainer to={`${path}/myreservation`}><Nav.Link><p className="nav-color">我的預約</p></Nav.Link></LinkContainer>
                                        <LinkContainer to={`${path}/teachersongpage`}><Nav.Link href="#"><p className="nav-color">曲目審核</p></Nav.Link></LinkContainer>
                                        <LinkContainer to={`${path}/question`}><Nav.Link href="#"><p className="nav-color">相關問題</p></Nav.Link></LinkContainer>
                                        <LinkContainer to={`${path}/setting`}><Nav.Link href="#"><p className="nav-color">帳戶設定</p></Nav.Link></LinkContainer>
                                        <LinkContainer to={`${path}`}><Nav.Link><p onClick={logoutEvent} className="nav-color">登出</p></Nav.Link></LinkContainer>
                                    </ConditionalContainer>
                                    <ConditionalContainer condition={isAdminF}>
                                        <LinkContainer to={`${path}/adminhome`}><Nav.Link><p className="nav-color">首頁</p></Nav.Link></LinkContainer>
                                        <LinkContainer to={`${path}/eventmanage`}><Nav.Link><p className="nav-color">曲目管理</p></Nav.Link></LinkContainer>
                                        <LinkContainer to={`${path}/usermanage`}><Nav.Link href="#"><p className="nav-color">帳戶管理</p></Nav.Link></LinkContainer>
                                        <LinkContainer to={`${path}/systemsetting`}><Nav.Link><p className="nav-color">系統設定</p></Nav.Link></LinkContainer>
                                        <LinkContainer to={`${path}/setting`}><Nav.Link href="#"><p className="nav-color">帳戶設定</p></Nav.Link></LinkContainer>
                                        <LinkContainer to={`${path}`}><Nav.Link><p onClick={logoutEvent} className="nav-color">登出</p></Nav.Link></LinkContainer>
                                    </ConditionalContainer>
                                    <ConditionalContainer condition={isAdminS}>
                                        <LinkContainer to={`${path}/adminhome`}><Nav.Link><p className="nav-color">首頁</p></Nav.Link></LinkContainer>
                                        <LinkContainer to={`${path}/usermanage`}><Nav.Link href="#"><p className="nav-color">帳戶管理</p></Nav.Link></LinkContainer>
                                        <LinkContainer to={`${path}/systemsetting`}><Nav.Link><p className="nav-color">系統設定</p></Nav.Link></LinkContainer>
                                        <LinkContainer to={`${path}/setting`}><Nav.Link href="#"><p className="nav-color">帳戶設定</p></Nav.Link></LinkContainer>
                                        <LinkContainer to={`${path}`}><Nav.Link><p onClick={logoutEvent} className="nav-color">登出</p></Nav.Link></LinkContainer>
                                    </ConditionalContainer>
                                </Nav>
                                <Navbar.Brand><p className="nav-color">您好 {user.profile.name}!</p></Navbar.Brand>
                            </Navbar.Collapse>
                        </Container>
                    </Navbar>
                </Col>
            </Row>
            <Row className="justify-content-md-center">
                <Col className="text-ticker" md='10'>
                    <Ticker offset="run-in" speed={5} height={25}>
                        {() => (<p>{system.info.announcement}</p>)}
                    </Ticker>
                </Col>
            </Row>

            <ConditionalContainer condition={!(Object.keys(user.profile).length === 0)}>
                <Switch>
                    <Route exact path={`${path}/home`}>
                        <HomePage />
                    </Route>

                    <Route exact path={`${path}/banned`}>
                        <UserBannedPage />
                    </Route>

                    <Route exact path={`${path}/reservationpause`}>
                        <UserReservationPausePage />
                    </Route>

                    <Route exact path={`${path}/adminhome`}>
                        <AdminHomePage />
                    </Route>

                    <Route exact path={`${path}/myreservation`}>
                        <MyBookingPage />
                    </Route>

                    <Route exact path={`${path}/usermanage`}>
                        <UserManagePage />
                    </Route>

                    <Route exact path={`${path}/usermanage/adduser`}>
                        <AddUserFormPage />
                    </Route>

                    <Route exact path={`${path}/setting`}>
                        <SettingPage />
                    </Route>

                    <Route exact path={`${path}/repairmanage`}>
                        <RepairmentManagePage />
                    </Route>

                    <Route exact path={`${path}/eventmanage`}>
                        <EventManagePage />
                    </Route>

                    <Route exact path={`${path}/question`}>
                        <QuestionPage />
                    </Route>

                    <Route exact path={`${path}/repairform`}>
                        <RepairmentFormPage />
                    </Route>

                    <Route exact path={`${path}/eventmanage/eventpage/:event_id`}>
                        <EventDetailPage />
                    </Route>

                    <Route exact path={`${path}/studentsongpage`}>
                        <StudentSongPage />
                    </Route>

                    <Route exact path={`${path}/studentsongpage/studentsongformpage`}>
                        <StudentSongFormPage />
                    </Route>

                    <Route exact path={`${path}/teachersongpage`}>
                        <TeacherSongPage />
                    </Route>

                    <Route exact path={`${path}/classroomregulation`}>
                        <ClassroomRegulationPage />
                    </Route>

                    <Route exact path={`${path}/systemsetting`}>
                        <SystemSettingPage />
                    </Route>

                    <Route exact path={`${path}/teachingtype`}>
                        <TeachingTypeManagePage />
                    </Route>

                    <Route exact path={`${path}/roomtype`}>
                        <RoomTypeManagePage />
                    </Route>

                    <Route exact path={`${path}/roommanage`}>
                        <RoomManagePage />
                    </Route>

                    <Route exact path={`${path}/roommanage/roomform`}>
                        <RoomFormPage />
                    </Route>

                    <Route exact path={`${path}/reservationtype`}>
                        <ReservationTypeManagePage />
                    </Route>

                    <Route exact path={`${path}/reservationtype/reservationtypeformpage`}>
                        <ReservationTypeFormPage />
                    </Route>

                    <Route exact path={`${path}/adminreservationform`}>
                        <AdminReservationFormPage />
                    </Route>

                    <Route exact path={`${path}/home/reservationform`}>
                        <ReservationFormPage />
                    </Route>

                    <Route exact path={`${path}/home/reservationsearch`}>
                        <ReservationSearchPage />
                    </Route>

                    <Route exact path={`${path}/reservationrecord`}>
                        <AdminReservationRecordPage />
                    </Route>

                    <Route exact path={`${path}/reservationsemester`}>
                        <AdminSemesterManagePage />
                    </Route>


                    <Route exact path={`${path}/reservationsemester/semesterpage/:semester_id`}>
                        <AdminSemesterDetailPage />
                    </Route>

                    <Route exact path={`${path}/weekreserveform`}>
                        <AddWeekReservationPage />
                    </Route>



                </Switch>
            </ConditionalContainer>
        </Container >
    );
}

