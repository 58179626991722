import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { Modal, Button } from "react-bootstrap"
import { deleteSong } from '../../../redux/song/songThunk';
import { getTimeFormatString, getSongEventName } from '../../../utils/utils';


export default function StudentDeleteSongDialog({ show, song, event, onClose, onConfirm }) {
    const dispatch = useDispatch();

    const user = useSelector(state => state.user);

    const [showState, setShow] = useState(show);

    useEffect(() => {
        setShow(show);
    }, [show]);


    const handleClose = () => {
        setShow(false);
        onClose();
    }

    const handleDelete = () => {

        dispatch(deleteSong({ token: user.token, song_id: song.id }));
        onConfirm();
        handleClose();
    }

    return (
        <Modal show={showState} >
            <Modal.Header>
                <Modal.Title>是否刪除於 {getTimeFormatString(song.upload_time)}
                    所上傳的"{event ? event.name : ""}"曲目資料?</Modal.Title>
            </Modal.Header>
            <Modal.Footer>
                <Button variant="danger" onClick={handleDelete}>
                    確認刪除
                </Button>
                <Button variant="primary" onClick={handleClose}>
                    取消
                </Button>
            </Modal.Footer>
        </Modal>
    );
}