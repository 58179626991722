import React, { useEffect, useState } from 'react';
import {
    useRouteMatch,
    useHistory,
    useParams
} from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Container, Button, Card } from "react-bootstrap"
import { LinkContainer } from 'react-router-bootstrap'
import DatePicker from 'react-datepicker';
import { userIdentity } from '../../redux/user/userSlice';
import { fetchAllSemesters, fetchSemesterDetailById } from '../../redux/semester/semesterThunk';
import SemesterWeekTable from '../../components/SemesterWeekTable/SemesterWeekTable';
import AdminDeleteWeekReservationDialog from './dialogs/AdminDeleteWeekReservationDialog';
import { getDayFromSecond, getTimeFormatString, getTimeFormatStringDate } from '../../utils/utils';
import LoadingContainer from '../../components/LoadingContainer/LoadingContainer';


export default function AdminSemesterDetailPage() {

    const { path, url } = useRouteMatch();
    const dispatch = useDispatch();
    const history = useHistory();
    const { semester_id } = useParams();

    const user = useSelector(state => state.user);
    const semester = useSelector(state => state.semester);


    const [selectedSemester, setSelectedSemester] = useState(null);
    const [semesterName, setSemesterName] = useState('');
    const [beginDate, setBeginDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [selectedReservation, setSelectedReservation] = useState(null);
    const [showDeleteWeekReservationDialog, setShowDeleteWeekReservationDialog] = useState(false);
    const [isFetching, setIsFetching] = useState(false);

    useEffect(() => {
        if ((user.profile.identity !== userIdentity.admin_f) && (user.profile.identity !== userIdentity.admin_s)) {
            history.replace("home");
        }
    }, [user])


    useEffect(() => {
        dispatch(fetchAllSemesters({ token: user.token }));
        dispatch(fetchSemesterDetailById({ token: user.token, semester_id: semester_id }));
    }, []);


    useEffect(() => {
        if (semester.selectSemester === {}) {
            return;
        }
        setSelectedSemester(semester.selectSemester);
        setSemesterName(semester.selectSemester.name);
        setBeginDate(getTimeFormatStringDate(semester.selectSemester.start_date));
        setEndDate(getTimeFormatStringDate(semester.selectSemester.end_date));
    }, [semester]);


    const handleClickTable = (col) => {
        console.log(col);
        if (!col.invalid) {
            history.push(`/user/weekreserveform?semester_id=${selectedSemester.id}&weekday=${col.weekday}&lesson=${col.lesson}&room_name=${col.room_name}`);

        }
        else if (col.invalid) {
            setIsFetching(true);
            setSelectedReservation(col);
            setShowDeleteWeekReservationDialog(true);
            
        }

    }


    return (
        <div className="pageContainer">
            <Container>
                <Row>
                    <Col md={2}>
                        <LinkContainer to={`/user/reservationsemester`}>
                            <Button size='lg'>返回列表</Button>
                        </LinkContainer>

                    </Col>
                    <Col md={{ span: 4, offset: 2 }}>
                        <h2>學期課表</h2>
                    </Col>

                </Row>
                <br />
                
                <Row>
                    <Col>
                        <Card>
                            <Card.Body>
                                <Card.Title><h3>{semesterName}</h3></Card.Title>
                                <br />
                                <Row className="justify-content-center">
                                    <Col md={1}>
                                        <Card.Text>學期時間</Card.Text>
                                    </Col>
                                    <Col md={2}>
                                        <Card.Text className="align-right">開始日期</Card.Text>
                                    </Col>
                                    <Col md={2}>
                                        <Card.Text className="align-right">{beginDate}</Card.Text>
                                    </Col>
                                    <Col md={2}>
                                        <Card.Text className="align-right">結束日期</Card.Text>
                                    </Col>
                                    <Col md={2}>
                                        <Card.Text className="align-right">{endDate}</Card.Text>
                                    </Col>
                                </Row>
                                <br />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <br />
                <LoadingContainer isLoading={isFetching}>
                <Row>
                    <Col>
                        <Card>
                            <SemesterWeekTable
                                semester={semester.selectSemester}
                                semester_id={semester_id}
                                onClick={handleClickTable} />
                        </Card>

                    </Col>
                </Row>
                </LoadingContainer>
            </Container>
            <AdminDeleteWeekReservationDialog
                reservation={selectedReservation}
                show={showDeleteWeekReservationDialog}
                onClose={() => {
                    setIsFetching(false);
                    setShowDeleteWeekReservationDialog(false);
                    
                 }}
                onDelete={() => {}} />
        </div>
    );
}

