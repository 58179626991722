import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { Modal, Button } from "react-bootstrap"
import { deleteRoom } from '../../../redux/room/roomThunk';


export default function DeletePermissionDeniedDialog({ show, onClose}) {
    useEffect(() => {
        setShow(show);
    }, [show]);

    const [showState, setShow] = useState(show);

    const handleClose = () => {
        setShow(false);
        onClose();
    }

    return (
        <Modal show={showState} >
            <Modal.Header>
                <Modal.Title>權限不足</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>學生管理員權限不足，無法清除非學生用琴房</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={handleClose}>
                    關閉
                </Button>
            </Modal.Footer>
        </Modal>
    );
}