import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LinkContainer } from 'react-router-bootstrap';
import { Row, Col, Container, Button, Table, NavDropdown, Nav, Accordion, Badge } from "react-bootstrap"
import { deleteReservations, fetchMyReservations } from '../../redux/reservation/reservationThunk';
import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';
import ConditionalContainer from '../../components/ConditionalContainer';
import DeleteReservationDialog from './dialogs/DeleteReservationDialog';
import { determineReservationStatus, getChineseWeekday } from '../../utils/utils';
import { reservationStatus } from '../../redux/reservation/reservationSlice';
import LoadingContainer from '../../components/LoadingContainer/LoadingContainer';

export default function MyBookingPage() {
  const dispatch = useDispatch();

  const user = useSelector(state => state.user);
  const reservation = useSelector(state => state.reservation);


  const [filteredMyReservations, setFilteredMyReservations] = useState([]);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [selectedReservation, setSelectedReservation] = useState(null);



  useEffect(() => {
    dispatch(fetchMyReservations({ token: user.token }));
  }, []);


  useEffect(() => {
    setFilteredMyReservations(reservation.myReservations.filter((reservationItem) => {
      const today = moment().startOf("day");
      const reservationDate = moment(reservationItem.date * 1000).startOf("day");
      return reservationDate.unix() >= today.unix();
    }));
  }, [reservation.myReservations]);





  const determineReservationStatusString = (reservationItem) => {
    const status = determineReservationStatus(reservationItem);
    const now = moment().unix();
    if (status === reservationStatus.waiting) {
      const duration = moment.duration((reservationItem.start_time - now), "seconds");
      return duration.format("dd日hh時mm分後可使用");
    } else if (status === reservationStatus.using) {
      const duration = moment.duration((moment().unix() - reservationItem.start_time), "seconds");
      return duration.format("使用中 已使用h時mm分")
    } else if (status === reservationStatus.finished) {
      const duration = moment.duration((reservationItem.end_time - reservationItem.start_time), "seconds");
      return duration.format("已使用h時mm分")
    }
    return "";
  }


  return (
    <div className="pageContainer">
      <Container>
        <Row>
          <Col>
            <h2>我的預約</h2>
          </Col>
        </Row>
        <Row>
          <Col>
            <LoadingContainer isLoading={reservation.isFetching}>
              <Table striped bordered responsive>
                <thead>
                  <tr>
                    <th>琴房</th>
                    <th>日期</th>
                    <th>時間</th>
                    <th>使用狀態</th>
                    <th>修改</th>
                    <th>刪除</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredMyReservations.map(
                    (reservationItem, i) =>
                      <tr key={i}>
                        <td>{reservationItem.room_name}</td>
                        <td>{moment(reservationItem.date * 1000).format("YYYY/MM/DD")} {getChineseWeekday(moment(reservationItem.date * 1000))}</td>
                        <td>{moment(reservationItem.start_time * 1000).format("HH:mm")}~{moment(reservationItem.end_time * 1000).format("HH:mm")}</td>
                        <td>{determineReservationStatusString(reservationItem)}</td>
                        <td>
                          <ConditionalContainer condition={determineReservationStatus(reservationItem) === reservationStatus.waiting || determineReservationStatus(reservationItem) === reservationStatus.using}>
                            <LinkContainer to={`home/reservationform?room_name=${reservationItem.room_name}&date=${reservationItem.date}&reservation_id=${reservationItem.id}`}><Button>
                              修改
                            </Button>
                            </LinkContainer>
                          </ConditionalContainer>
                        </td>
                        <td>
                          <ConditionalContainer condition={determineReservationStatus(reservationItem) === reservationStatus.waiting || determineReservationStatus(reservationItem) === reservationStatus.using}>
                            <Button
                              onClick={() => {
                                setSelectedReservation(reservationItem);
                                setShowDeleteDialog(true);
                              }}
                              variant='danger'>
                              刪除
                            </Button>
                          </ConditionalContainer>
                        </td>
                      </tr>
                  )}

                </tbody>
              </Table>
            </LoadingContainer>
          </Col>
        </Row>
      </Container>
      <DeleteReservationDialog
        reservation={selectedReservation}
        show={showDeleteDialog}
        onClose={() => {
          setShowDeleteDialog(false);
        }}
        onConfirm={() => {
          dispatch(fetchMyReservations({ token: user.token }));
        }} />
    </div>
  );
}

