import { createSlice } from '@reduxjs/toolkit';

import { fetchAllRepairments, updateRepairmentStatus, deleteRepairment, addNewRepairment } from './repairmentThunk';


export const repairmentStatus = {
    handled: "已處理",
    pending: "未處理",
}


export const initialState = {
    repairments: [
    ],
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorStatus: 0,
    errorMessage: '',
}


const repairmentSlice = createSlice({
    name: 'repairment',
    initialState,
    reducers: {
    },
    extraReducers: {
        [fetchAllRepairments.fulfilled]: (state, { payload }) => {
            console.log('payload', payload)
            state.repairments = payload;
            state.isFetching = false;
            state.isSuccess = true;
            return state;
        },
        [fetchAllRepairments.rejected]: (state, { payload }) => {
            console.log('reject payload', payload)
            state.isError = true;
            state.isSuccess = false;
            state.isFetching = false;
            state.errorStatus = payload.status;
            state.errorMessage = payload.data.message;
        },
        [fetchAllRepairments.pending]: (state) => {
            state.isFetching = true;
        },
        [updateRepairmentStatus.fulfilled]: (state, { payload }) => {
            console.log('payload', payload)

            let index = state.repairments.findIndex((repair) => repair.id === payload.id);
            state.repairments[index] = payload;

            state.isFetching = false;
            state.isSuccess = true;
            return state;
        },
        [updateRepairmentStatus.rejected]: (state, { payload }) => {
            console.log('reject payload', payload)
            state.isError = true;
            state.isSuccess = false;
            state.isFetching = false;
            state.errorStatus = payload.status;
            state.errorMessage = payload.data.message;
        },
        [updateRepairmentStatus.pending]: (state) => {
            state.isFetching = true;
        },
        [deleteRepairment.fulfilled]: (state, { payload }) => {
            console.log('payload', payload)

            let index = state.repairments.findIndex((repair) => repair.id === payload.id);
            state.repairments.splice(index, 1);

            state.isFetching = false;
            state.isSuccess = true;
            return state;
        },
        [deleteRepairment.rejected]: (state, { payload }) => {
            console.log('reject payload', payload)
            state.isError = true;
            state.isSuccess = false;
            state.isFetching = false;
            state.errorStatus = payload.status;
            state.errorMessage = payload.data.message;
        },
        [deleteRepairment.pending]: (state) => {
            state.isFetching = true;
        },

        [addNewRepairment.fulfilled]: (state, { payload }) => {
            console.log('payload', payload)
            state.isFetching = false;
            state.isSuccess = true;
        },
        [addNewRepairment.rejected]: (state, { payload }) => {
            console.log('reject payload', payload)
            state.isError = true;
            state.isSuccess = false;
            state.isFetching = false;
            state.errorStatus = payload.status;
            state.errorMessage = payload.data.message;
        },
        [addNewRepairment.pending]: (state) => {
            state.isFetching = true;
        },

    }
});





export default repairmentSlice;