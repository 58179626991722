import { createSlice, current } from '@reduxjs/toolkit';

import { fetchAllSongsByStudentId, fetchAllSongsByTeacherId, updateSongStatus, addNewSong, deleteSong } from './songThunk';

export const songStatus = {
    passed: "已審核",
    pending: "未審核",
    rejected: "未通過",
}

export const initialState = {
    songs: [],
    teachers: [],
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorStatus: 0,
    errorMessage: '',
}

const songSlice = createSlice({
    name: 'song',
    initialState,
    reducers: {
    },
    extraReducers: {
        [fetchAllSongsByStudentId.fulfilled]: (state, { payload }) => {
            console.log('payload', payload)
            state.songs = payload;
            state.isFetching = false;
            state.isSuccess = true;
            return state;
        },
        [fetchAllSongsByStudentId.rejected]: (state, { payload }) => {
            console.log('reject payload', payload)
            state.isError = true;
            state.isSuccess = false;
            state.isFetching = false;
            state.errorStatus = payload.status;
            state.errorMessage = payload.data.message;
        },
        [fetchAllSongsByStudentId.pending]: (state) => {
            state.isFetching = true;
        },

        [fetchAllSongsByTeacherId.fulfilled]: (state, { payload }) => {
            console.log('payload', payload)
            state.songs = payload;
            state.isFetching = false;
            state.isSuccess = true;
            return state;
        },
        [fetchAllSongsByTeacherId.rejected]: (state, { payload }) => {
            console.log('reject payload', payload)
            state.isError = true;
            state.isSuccess = false;
            state.isFetching = false;
            state.errorStatus = payload.status;
            state.errorMessage = payload.data.message;
        },
        [fetchAllSongsByTeacherId.pending]: (state) => {
            state.isFetching = true;
        },

        [addNewSong.fulfilled]: (state, { payload }) => {
            console.log('payload', payload)
            state.songs.push(payload);
            state.isFetching = false;
            state.isSuccess = true;
            return state;
        },
        [addNewSong.rejected]: (state, { payload }) => {
            console.log('reject payload', payload)
            state.isError = true;
            state.isSuccess = false;
            state.isFetching = false;
            state.errorStatus = payload.status;
            state.errorMessage = payload.data.message;
        },
        [addNewSong.pending]: (state) => {
            state.isFetching = true;
        },

        [updateSongStatus.fulfilled]: (state, { payload }) => {
            console.log('payload', payload)

            let index = state.songs.findIndex((song) => song.id === payload.id);
            state.songs[index] = payload;

            state.isFetching = false;
            state.isSuccess = true;
            return state;
        },
        [updateSongStatus.rejected]: (state, { payload }) => {
            console.log('reject payload', payload)
            state.isError = true;
            state.isSuccess = false;
            state.isFetching = false;
            state.errorStatus = payload.status;
            state.errorMessage = payload.data.message;
        },
        [updateSongStatus.pending]: (state) => {
            state.isFetching = true;
        },

        [deleteSong.fulfilled]: (state, { payload }) => {
            console.log('payload', payload)
            const index = state.songs.findIndex(element => element.id === payload.id);
            state.songs.splice(index, 1);
            state.isFetching = false;
            state.isSuccess = true;
            return state;
        },
        [deleteSong.rejected]: (state, { payload }) => {
            console.log('reject payload', payload)
            state.isError = true;
            state.isSuccess = false;
            state.isFetching = false;
            state.errorStatus = payload.status;
            state.errorMessage = payload.data.message;
        },
        [deleteSong.pending]: (state) => {
            state.isFetching = true;
        },

    }
});








export default songSlice;