import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useRouteMatch,
    Redirect,
    useHistory
} from "react-router-dom";
import { Row, Col, Container, Button, Form } from "react-bootstrap"
import { LinkContainer } from 'react-router-bootstrap'
import SelectSearch from 'react-select-search';
import { fuzzySearch } from 'react-select-search';


import useQuery from '../../utils/useQuery'
import moment from 'moment';
import { fetchAllRooms } from '../../redux/room/roomThunk';
import { userIdentity } from '../../redux/user/userSlice';
import ConditionalContainer from '../../components/ConditionalContainer';
import userListSlice from '../../redux/userList/userListSlice';
import { fetchAllUsers } from '../../redux/userList/userListThunk';

import './AdminReservationFormPage.css'
import { adminAddReservations } from '../../redux/reservation/reservationThunk';


export default function AdminReservationFormPage() {
    const dispatch = useDispatch();
    const history = useHistory();

    const { path, url } = useRouteMatch();
    const query = useQuery();

    const user = useSelector(state => state.user);
    const room = useSelector(state => state.room);


    const roomId = query.get('room_id');
    const defaultDate = parseInt(query.get('date'));
    const defaultBeginTime = parseInt(query.get('begin_time'));
    const defaultEndTime = parseInt(query.get('end_time'));



    const [userDetail, setUserDetail] = useState("");
    const [userName, setUserName] = useState("");

    const [selectedRoom, setSeletedRoom] = useState({ name: '' });
    const [date, setDate] = useState(moment());

    const [backendErrorMessage, setBackendErrorMessage] = useState("");
    const [validated, setValidated] = useState(false);




    useEffect(() => {
        dispatch(fetchAllRooms({ token: user.token }));
        dispatch(fetchAllUsers({ token: user.token }));
    }, [])


    useEffect(() => {
        if (!roomId) {
            return;
        }
        if (room.rooms.length === 0) {
            return;
        }
        const findRoom = room.rooms.find(element => element.id === roomId);
        if (!findRoom) {
            return;
        }
        setSeletedRoom(findRoom);
    }, [roomId, room]);


    useEffect(() => {
        let filteredDate = moment(defaultDate * 1000).startOf("start");
        setDate(filteredDate);
    }, [defaultDate]);


    useEffect(() => {
        if (!defaultBeginTime || !defaultEndTime) {
            history.replace('/user/home');
        }
    }, [defaultBeginTime, defaultEndTime]);



    const isUserDetailValid = () => {
        return userDetail.length <= 10;
    }

    const isUserNameValid = () => {
        return userName.length <= 10;
    }

    const isFormValid = () => {
        return isUserDetailValid() && isUserNameValid();
    }


    const handleSubmit = async (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false || !isFormValid()) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            event.preventDefault();

            const param = {
                token: user.token,
                user_detail: userDetail,
                user_name: userName,
                date: moment(date).startOf('day').unix(),
                start_time: defaultBeginTime,
                end_time: defaultEndTime,
                room: selectedRoom,
            }

            let result = await dispatch(adminAddReservations(param));
            console.log(result)

            if (result.meta.requestStatus === "fulfilled") {
                history.replace('/user/home');
            } else {
                const errorMessage = result.payload.data.message;
                if (errorMessage.includes("Time interval can't reserve now.")) {
                    setBackendErrorMessage("預約時間未開放");
                } else if (errorMessage.includes("Sorry! time interval has been taken by others.")) {
                    setBackendErrorMessage("已經有其他使用者預約此時段");
                }
            }

        }

        setValidated(true);

    };


    return (
        <div className="pageContainer">
            <Container>
                <Row>
                    <Col>
                        <h4>{selectedRoom.name}</h4>
                    </Col>
                </Row>
                <Form onSubmit={handleSubmit}>
                    <Form.Group as={Row} className="justify-content-center">
                        <Form.Label column md={{ span: 3, offset: 0 }} className={"text-align-left"}>
                            日期：　{date.format('YYYY/MM/DD')}
                        </Form.Label>
                        <Form.Label column md={{ span: 3, offset: 0 }} className={"text-align-left"}>時間：　{moment(defaultBeginTime * 1000).format("HH:mm")}　至　{moment(defaultEndTime * 1000).format("HH:mm")}</Form.Label>
                    </Form.Group>
                    <br />
                    <Form.Group as={Row} className="justify-content-center">
                        <Form.Label column md={{ span: 3, offset: 0 }} className={"text-align-left"}>預約使用者</Form.Label>
                        <Col md={3}>
                            <Form.Control
                                required
                                column
                                as="input"
                                placeholder="請輸入預約者名稱"
                                value={userName}
                                onChange={e => setUserName(e.target.value)}
                                isInvalid={!isUserNameValid() && validated}
                            />
                            <Form.Control.Feedback type="invalid">
                                請選擇輸入0~10字的預約者名稱
                            </Form.Control.Feedback>
                        </Col>
                    </Form.Group>
                    <br />
                    <Form.Group as={Row} className="justify-content-center">
                        <Form.Label column md={{ span: 3, offset: 0 }} className={"text-align-left"}>學號、老師授課領域、其他</Form.Label>
                        <Col md={3}>
                            <Form.Control
                                required
                                column
                                as="input"
                                placeholder="請輸入預約者學號或身分"
                                value={userDetail}
                                onChange={e => setUserDetail(e.target.value)}
                                isInvalid={!isUserDetailValid() && validated}
                            />
                            <Form.Control.Feedback type="invalid">
                                請選擇輸入0~10字的預約者學號或身分
                            </Form.Control.Feedback>
                        </Col>
                    </Form.Group>
                    <ConditionalContainer condition={backendErrorMessage !== ""}>
                        <>
                            <br />
                            <Row className="justify-content-center">
                                <Col md={{ span: 2, offset: 0 }}>
                                    <p className="error-message">{backendErrorMessage}</p>
                                </Col>
                            </Row>
                        </>
                    </ConditionalContainer>
                    <br />
                    <Row>
                        <Col>
                            <Button size='md' type="submit">預約</Button>
                        </Col>
                    </Row>
                </Form>
            </Container>
        </div>
    );
}

