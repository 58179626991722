import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useRouteMatch,
    Redirect,
    useHistory
} from "react-router-dom";
import { Row, Col, Container, Button, Form, Badge } from "react-bootstrap"



import useQuery from '../../utils/useQuery'
import moment from 'moment';
import { fetchAllRooms, fetchAllRoomTypes } from '../../redux/room/roomThunk';
import { userIdentity } from '../../redux/user/userSlice';
import ConditionalContainer from '../../components/ConditionalContainer';
import userListSlice from '../../redux/userList/userListSlice';

import ReservationSuccessDialog from './dialogs/ReservationSuccessDialog';
import ReservationFailedDialog from './dialogs/ReservationFailedDialog';


import './ReservationFormPage.css'
import { addReservations, adminAddReservations, fetchMyReservations, fetchReservationAvaliable, updateReservation } from '../../redux/reservation/reservationThunk';
import UserReservationAvalibilityTable from '../../components/UserReservationAvalibilityTable/UserReservationAvalibilityTable';
import { reservationStatus } from '../../redux/reservation/reservationSlice';
import { determineReservationStatus, roundStartTime } from '../../utils/utils';
import LoadingContainer from '../../components/LoadingContainer/LoadingContainer';


export default function ReservationFormPage() {
    const dispatch = useDispatch();
    const history = useHistory();

    const { path, url } = useRouteMatch();
    const query = useQuery();

    const user = useSelector(state => state.user);
    const reservation = useSelector(state => state.reservation);


    const roomName = query.get('room_name');
    const defaultReservationId = query.get('reservation_id');
    const date = moment(parseInt(query.get('date')) * 1000).startOf("day");
    const defaultStartTime = moment(parseInt(query.get('start_time') * 1000));
    const defaultEndTime = moment(parseInt(query.get('end_time') * 1000));


    const [cycle, setCycle] = useState(1800);
    const [filteredAvailableTime, setFilteredAvailableTime] = useState([]);
    const [defaultReservation, setDefaultReservation] = useState(null);


    const [startTimeList, setStartTimeList] = useState([]);
    const [endTimeList, setEndTimeList] = useState([]);
    const [startTime, setStartTime] = useState(null);
    const [endTime, setEndTime] = useState(null);

    const [newReservation, setNewReservation] = useState(null);
    const [backendErrorResult, setBackendErrorResult] = useState({});
    const [validated, setValidated] = useState(false);



    const [showSuccessDialog, setShowSuccessDialog] = useState(false);
    const [showFailedDialog, setShowFailedDialog] = useState(false);



    useEffect(() => {
        dispatch(fetchReservationAvaliable({ token: user.token, options: { date: date.unix(), room_name: roomName } }));
        dispatch(fetchMyReservations({ token: user.token }));
    }, []);

    useEffect(() => {
        if (!defaultReservationId) {
            return;
        }
        const findedReservaion = reservation.myReservations.find(element => element.id === defaultReservationId);
        if (!findedReservaion) {
            return;
        }
        setDefaultReservation(findedReservaion);

        let newStartTime = moment(findedReservaion.start_time * 1000);
        if (cycle === 3600) {
            newStartTime = newStartTime.startOf("hour");
        } else if (cycle === 1800) {
            if (newStartTime.get("minutes") < 30) {
                newStartTime = newStartTime.startOf("hour");
            } else {
                newStartTime = newStartTime.startOf("hour").add("30", "minutes");
            }
        }
        setStartTime(newStartTime);
        setEndTime(moment(findedReservaion.end_time * 1000));

    }, [defaultReservationId, reservation]);



    useEffect(() => {
        if (reservation.roomsAvaliableTimes[roomName]) {
            let availableTimes = reservation.roomsAvaliableTimes[roomName].map(obj => ({ ...obj }));
            const newCycle = availableTimes.shift()[1];
            if (defaultReservation) {
                availableTimes.push([roundStartTime(defaultReservation.start_time, newCycle).unix(), defaultReservation.end_time]);
            }
            availableTimes.sort((a, b) => {
                return a[0] - b[0]
            })
            setCycle(newCycle);
            setFilteredAvailableTime(availableTimes);
        }
    }, [reservation, defaultReservation]);


    useEffect(() => {
        generateStartTimeList();
    }, [filteredAvailableTime]);


    useEffect(() => {
        generateEndTimeList();
    }, [startTime]);


    const generateStartTimeList = () => {
        const localStartTimeList = [];

        filteredAvailableTime.forEach((timeInterval) => {
            let intervalStartTime = moment(timeInterval[0] * 1000);
            let intervalEndTime = moment(timeInterval[1] * 1000);

            if (moment(intervalStartTime).startOf("day").unix() < moment(intervalEndTime).startOf("day").unix()) {
                intervalEndTime = moment(intervalStartTime).startOf("day").add(1, "day");
            }

            let firstStartTime;
            if (cycle === 1800) {
                if (intervalStartTime.minutes() === 0) {
                    firstStartTime = intervalStartTime.startOf("hour");
                } else if (0 < intervalStartTime.minutes() && intervalStartTime.minutes() <= 30) {
                    firstStartTime = intervalStartTime.startOf("hour").add(30, 'minutes');
                } else {
                    firstStartTime = intervalStartTime.startOf("hour").add(1, 'hours');
                }
            } else if (cycle === 3600) {
                if (intervalStartTime.minutes() === 0) {
                    firstStartTime = intervalStartTime.startOf("hour");
                } else {
                    firstStartTime = intervalStartTime.startOf("hour").add(1, 'hours');
                }
            } else {
                return;
            }
            for (let time = firstStartTime.unix(); time < intervalEndTime.unix(); time += cycle) {
                if (time + cycle < moment().unix()) {
                    continue;
                }
                console.log(time, moment().unix())
                localStartTimeList.push(time);
            }
        });

        setStartTimeList(localStartTimeList);
    }


    const generateEndTimeList = () => {
        console.log(startTime)
        if (!isStartTimeValid()) {
            setEndTime(null);
            setEndTimeList([]);
            return;
        }
        console.log(startTime.unix())
        console.log(filteredAvailableTime)

        let selectedTimeInterval;
        for (let i = 0; i < filteredAvailableTime.length; i++) {
            if (filteredAvailableTime[i][0] <= startTime.unix() && startTime.unix() <= filteredAvailableTime[i][1]) {
                selectedTimeInterval = [filteredAvailableTime[i][0], filteredAvailableTime[i][1]];
                if (filteredAvailableTime[i + 1] && filteredAvailableTime[i + 1][0] === filteredAvailableTime[i][1]) {
                    selectedTimeInterval[1] = filteredAvailableTime[i + 1][1];
                    if (filteredAvailableTime[i + 2] && filteredAvailableTime[i + 2][0] === filteredAvailableTime[i + 1][1]) {
                        selectedTimeInterval[1] = filteredAvailableTime[i + 2][1];
                    }
                }
            }
        }
        if (!selectedTimeInterval) {
            return;
        }
        let intervalStartTime = moment(selectedTimeInterval[0] * 1000);
        let intervalEndTime = moment(selectedTimeInterval[1] * 1000);

        if (moment(intervalStartTime).startOf("day").unix() < moment(intervalEndTime).startOf("day").unix()) {
            intervalEndTime = moment(intervalStartTime).startOf("day").add(1, "day");
        }

        const localEndTimeList = [];
        let firstEndTime;
        firstEndTime = moment(startTime).add(cycle / 60, 'minutes');

        for (let time = firstEndTime.unix(); time <= intervalEndTime.unix(); time += cycle) {
            localEndTimeList.push(time);
        }

        setEndTimeList(localEndTimeList);
    }

    const formatTime = (time) => {
        if (!time) {
            return null;
        }
        return time.unix();
    }


    const parseTime = (timeInt) => {
        if (!timeInt) {
            return null;
        }
        if (timeInt === "請選擇") {
            return null;
        }
        return moment(timeInt * 1000);
    }

    const isStartTimeValid = () => {
        return startTime !== null;
    }

    const isEndTimeValid = () => {
        return endTime !== null;
    }


    const isFormValid = () => {
        return isStartTimeValid() && isEndTimeValid() && (endTime.unix() > startTime.unix());
    }


    const handleSubmit = async (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false || !isFormValid()) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            event.preventDefault();



            let result;
            if (defaultReservation) {
                let param = {
                    token: user.token,
                    id: defaultReservation.id,
                    date: date.unix(),
                    start_time: startTime.unix(),
                    end_time: endTime.unix(),
                }
                if (determineReservationStatus(defaultReservation) === reservationStatus.using) {
                    param.start_time = defaultReservation.start_time;
                }
                result = await dispatch(updateReservation(param));
            } else {
                const param = {
                    token: user.token,
                    date: date.unix(),
                    start_time: startTime.unix(),
                    end_time: endTime.unix(),
                    room_name: roomName,
                }
                result = await dispatch(addReservations(param));
            }
            console.log(result)

            if (result.meta.requestStatus === "fulfilled") {
                console.log(result.payload.data);
                setNewReservation(result.payload);
                setShowSuccessDialog(true);
            } else {
                setBackendErrorResult(result);
                setShowFailedDialog(true);
            }

        }

        setValidated(true);

    };


    const handleTableClick = (cell) => {
        if (cell.invalid) {
            return;
        }
        const startHour = cell.span[0];
        const endHour = cell.span[1];
        if (!(defaultReservation && determineReservationStatus(defaultReservation) === reservationStatus.using)) {
            setStartTime(moment(date).set({ hour: Math.floor(startHour), minute: (startHour % 1) * 60 }));
        }
        setEndTime(moment(date).set({ hour: Math.floor(endHour), minute: (endHour % 1) * 60 }));
    }


    return (
        <div className="pageContainer">
            <Container>
                <Row>
                    <Col>
                        <h4>{roomName}</h4>
                    </Col>
                </Row>
                <Form onSubmit={handleSubmit}>
                    <Form.Group as={Row} className="justify-content-center">
                        <Form.Label column md={{ span: 2, offset: 0 }}>
                            日期：　{date.format('YYYY/MM/DD')}
                        </Form.Label>
                        <Form.Label column md={{ span: 1, offset: 0 }}>時間</Form.Label>
                        <Col md={2}>
                            <Form.Select
                                value={formatTime(startTime)}
                                onChange={e => setStartTime(parseTime(e.target.value))}
                                disabled={defaultReservation && determineReservationStatus(defaultReservation) === reservationStatus.using}
                                isInvalid={validated && !isStartTimeValid()}
                                isValid={validated && isStartTimeValid()}
                            >
                                <option key={-1} value={null}>請選擇</option>
                                {startTimeList.map((time, idx) =>
                                    <option key={idx} value={time}>{moment(time * 1000).format("HH:mm")}</option>)}
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                                請選擇開始時間
                            </Form.Control.Feedback>
                        </Col>
                        <Form.Label column md={{ span: 1, offset: 0 }}>至</Form.Label>
                        <Col md={2}>
                            <Form.Select
                                value={formatTime(endTime)}
                                onChange={e => setEndTime(parseTime(e.target.value))}
                                isInvalid={validated && !isEndTimeValid()}
                                isValid={validated && isEndTimeValid()}
                            >
                                <option key={-1} value={null}>請選擇</option>
                                {endTimeList.map((time, idx) =>
                                    <option key={idx} value={time}>{moment(time * 1000).format("HH:mm")}</option>)}
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                                請選擇結束時間
                            </Form.Control.Feedback>
                        </Col>
                    </Form.Group>
                    <br />
                    <Row className='justify-content-center'>
                        <Col md={1}>
                            <h5>
                                <Badge bg="light">可預約</Badge>
                            </h5>
                        </Col>
                        <Col md={1}>
                            <h5>
                                <Badge bg="success">目前預約</Badge>
                            </h5>
                        </Col>
                        <Col md={1}>
                            <h5>
                                <Badge bg="dark">無法預約</Badge>
                            </h5>
                        </Col>
                    </Row>
                    <Row className='justify-content-center'>
                        <Col md={6}>
                        <LoadingContainer isLoading={reservation.isFetching}>
                            <UserReservationAvalibilityTable cycle={cycle} filteredAvailableTimeList={filteredAvailableTime} reservation={defaultReservation} onClick={handleTableClick} />
                        </LoadingContainer>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Button size='md' type="submit">{defaultReservation ? "更改預約" : "預約"}</Button>
                        </Col>
                    </Row>
                </Form>
            </Container>
            <ReservationSuccessDialog
                reservation={newReservation}
                show={showSuccessDialog}
                onToHome={() => { history.replace('/user/home'); }}
                onToMyReservation={() => { history.replace('/user/myreservation'); }} />
            <ReservationFailedDialog
                backendErrorResult={backendErrorResult}
                show={showFailedDialog}
                onToHome={() => { history.replace('/user/home'); }}
                onCancel={() => { setShowFailedDialog(false); }}
            />
        </div>
    );
}

