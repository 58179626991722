import { createSlice } from '@reduxjs/toolkit';

import { fetchAllSemesters, fetchSemesterDetailById, addNewSemester, deleteSemester, addWeekReserve, deleteWeekReserve} from './semesterThunk';


export const initialState = {
    semesters: [
    ],
    selectSemester:{},
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorStatus: 0,
    errorMessage: '',
}


const semesterSlice = createSlice({
    name: 'semester',
    initialState,
    reducers: {
    },
    extraReducers: {
        [fetchAllSemesters.fulfilled]: (state, { payload }) => {
            console.log('payload', payload)
            state.semesters = payload;
            state.isFetching = false;
            state.isSuccess = true;
            return state;
        },
        [fetchAllSemesters.rejected]: (state, { payload }) => {
            console.log('reject payload', payload)
            state.isError = true;
            state.isSuccess = false;
            state.isFetching = false;
            state.errorStatus = payload.status;
            state.errorMessage = payload.data.message;
        },
        [fetchAllSemesters.pending]: (state) => {
            state.isFetching = true;
        },

        [fetchSemesterDetailById.fulfilled]: (state, { payload }) => {
            console.log('payload', payload)
            state.selectSemester = payload;
            state.isFetching = false;
            state.isSuccess = true;
            return state;
        },
        [fetchSemesterDetailById.rejected]: (state, { payload }) => {
            console.log('reject payload', payload)
            state.isError = true;
            state.isSuccess = false;
            state.isFetching = false;
            state.errorStatus = payload.status;
            state.errorMessage = payload.data.message;
        },
        [fetchSemesterDetailById.pending]: (state) => {
            state.isFetching = true;
        },

        [addNewSemester.fulfilled]: (state, { payload }) => {
            console.log('payload', payload)
            state.semesters.push(payload);
            state.isFetching = false;
            state.isSuccess = true;
           return state;
        },
        [addNewSemester.rejected]: (state, { payload }) => {
            console.log('reject payload', payload)
            state.isError = true;
            state.isSuccess = false;
            state.isFetching = false;
            state.errorStatus = payload.status;
            state.errorMessage = payload.data.message;
        },
        [addNewSemester.pending]: (state) => {
            state.isFetching = true;
        },

        [deleteSemester.fulfilled]: (state, { payload }) => {
            console.log('payload', payload)

            let index = state.semesters.findIndex((semester) => semester.id === payload.id);
            state.semesters.splice(index, 1);

            state.isFetching = false;
            state.isSuccess = true;
            return state;
        },
        [deleteSemester.rejected]: (state, { payload }) => {
            console.log('reject payload', payload)
            state.isError = true;
            state.isSuccess = false;
            state.isFetching = false;
            state.errorStatus = payload.status;
            state.errorMessage = payload.data.message;
        },
        [deleteSemester.pending]: (state) => {
            state.isFetching = true;
        },
        [addWeekReserve.fulfilled]: (state, { payload }) => {
            console.log('payload', payload);
            let index = state.semesters.findIndex((semester) => semester.id === payload.id);
            state.semesters[index] = payload;
            state.isFetching = false;
            state.isSuccess = true;
            return state;
        },
        [addWeekReserve.rejected]: (state, { payload }) => {
            console.log('reject payload', payload)
            state.isError = true;
            state.isSuccess = false;
            state.isFetching = false;
            state.errorStatus = payload.status;
            state.errorMessage = payload.data.message;
        },
        [addWeekReserve.pending]: (state) => {
            state.isFetching = true;
        },
        [deleteWeekReserve.fulfilled]: (state, { payload }) => {
            console.log('payload', payload)
            let index = state.semesters.findIndex((semester) => semester.id === payload.id);
            state.semesters[index] = payload;
            state.selectSemester = payload;
            state.isFetching = false;
            state.isSuccess = true;
            return state;
        },
        [deleteWeekReserve.rejected]: (state, { payload }) => {
            console.log('reject payload', payload)
            state.isError = true;
            state.isSuccess = false;
            state.isFetching = false;
            state.errorStatus = payload.status;
            state.errorMessage = payload.data.message;
        },
        [deleteWeekReserve.pending]: (state) => {
            state.isFetching = true;
        },
    }
});





export default semesterSlice;