import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Container, Button, Table, NavDropdown, Nav, Accordion, Badge, Tabs, Tab, Card, Form } from "react-bootstrap";
import { useHistory, useRouteMatch } from 'react-router';
import { LinkContainer } from 'react-router-bootstrap';


import './TeachingTypeManagePage.css';

import store from '../../redux/store';
import { fetchAllTeachers, fetchAllTeachingTypes } from '../../redux/teacher/teacherThunk';
import { fetchAllSongsByStudentId } from '../../redux/song/songThunk';
import { fetchAllEvents } from '../../redux/event/eventThunk';
import { getTimeFormatString, getSongEventName } from '../../utils/utils';
import ConditionalContainer from '../../components/ConditionalContainer';
import AddTeachingTypeDialog from './dialogs/AddTeachingTypeDialog';
import DeleteTeachingTypeDialog from './dialogs/DeleteTeachingTypeDialog';


export default function TeachingTypeManagePage() {

    const dispatch = useDispatch();
    const { path, url } = useRouteMatch();

    const user = useSelector(state => state.user);
    const song = useSelector(state => state.song);
    const teacher = useSelector(state => state.teacher);
    const event = useSelector(state => state.event);


    const [showAddTeachingTypeDialog, setShowAddTeachingTypeDialog] = useState(false);
    const [showDeleteTeachingTypeDialog, setShowDeleteTeachingTypeDialog] = useState(false);

    const [selectedTeachingType, setSelectedTeachingType] = useState(null);




    useEffect(() => {
        dispatch(fetchAllTeachers({ token: user.token }));
        dispatch(fetchAllTeachingTypes({ token: user.token }));
    }, [])







    return (
        <div className="pageContainer">
            <Container>
                <Row>
                    <Col md={{ span: 8, offset: 2 }}>
                        <h2>授課領域</h2>
                    </Col>
                    <Col md={2}>
                        <Button size='lg' onClick={() => setShowAddTeachingTypeDialog(true)}>新增授課項目</Button>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="teaching-type-table-container">
                            <Table striped bordered responsive>
                                <thead>
                                    <tr>
                                        <th>授課領域名稱</th>
                                        <th>屬於該領域的老師人數</th>
                                        <th>刪除</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {teacher.teachingTypes.map(
                                        (teachingType, i) =>
                                            <tr key={teachingType.id}>
                                                <td>{teachingType.name}</td>
                                                <td>{teachingType.number}</td>
                                                <td>
                                                    <ConditionalContainer condition={teachingType.number === 0}>
                                                        <Button variant="danger"
                                                            onClick={() => {
                                                                setSelectedTeachingType(teachingType);
                                                                setShowDeleteTeachingTypeDialog(true);
                                                            }}>
                                                            刪除
                                                        </Button>
                                                    </ConditionalContainer>
                                                </td>
                                            </tr>
                                    )}

                                </tbody>
                            </Table>
                        </div>
                    </Col>
                </Row>
            </Container>
            <AddTeachingTypeDialog
                show={showAddTeachingTypeDialog}
                onClose={() => { setShowAddTeachingTypeDialog(false) }}
                onConfirm={() => { }} />
            <DeleteTeachingTypeDialog
                teachingType={selectedTeachingType}
                show={showDeleteTeachingTypeDialog}
                onClose={() => { setShowDeleteTeachingTypeDialog(false) }}
                onConfirm={() => { }} />
        </div >
    );
}

