import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import jsonwebtoken from 'jsonwebtoken';


export const fetchAllSongsByStudentId = createAsyncThunk(
    'song/fetchAllSongsByStudentId',
    async ({ token, student_id }, thunkAPI) => {

        if (!token) {
            return thunkAPI.rejectWithValue({ status: 0, data: { 'message': 'invalid token!' } });
        }
        try {
            const tokenData = jsonwebtoken.decode(token);
            const response = await axios.get(`/song?student_id=${student_id}`,
                {
                    headers: { "x-access-token": `token ${token}` }
                }
            );
            return response.data;

        } catch (error) {
            console.log("error", error.response)
            return thunkAPI.rejectWithValue({ status: error.response.status, data: error.response.data });
        }

    }
);

export const fetchAllSongsByTeacherId = createAsyncThunk(
    'song/fetchAllSongsByTeacherId',
    async ({ token, teacher_id }, thunkAPI) => {

        if (!token) {
            return thunkAPI.rejectWithValue({ status: 0, data: { 'message': 'invalid token!' } });
        }
        try {
            const tokenData = jsonwebtoken.decode(token);
            const response = await axios.get(`/song?teacher_id=${teacher_id}`,
                {
                    headers: { "x-access-token": `token ${token}` }
                }
            );
            return response.data;

        } catch (error) {
            console.log("error", error.response)
            return thunkAPI.rejectWithValue({ status: error.response.status, data: error.response.data });
        }

    }
);


export const addNewSong = createAsyncThunk(
    'song/addNewSong',
    async ({ token, teacher_id, event_id, detail }, thunkAPI) => {

        if (!token) {
            return thunkAPI.rejectWithValue({ status: 0, data: { 'message': 'invalid token!' } });
        }
        const form = new FormData();
        form.append('teacher_id', teacher_id);
        form.append('event', event_id);
        form.append('detail', detail);

        try {
            const response = await axios.post('/song/', form,
                {
                    headers: { "x-access-token": `token ${token}` }
                }
            );
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue({ status: error.response.status, data: error.response.data });
        }

    }
);


export const updateSongStatus = createAsyncThunk(
    'song/updateSongStatus',
    async ({ token, song_id, status }, thunkAPI) => {

        if (!token) {
            return thunkAPI.rejectWithValue({ status: 0, data: { 'message': 'invalid token!' } });
        }

        const form = new FormData();
        form.append('id', song_id);
        form.append('status', status);
        try {
            const response = await axios.put(`/song/`,
                form,
                {
                    headers: { "x-access-token": `token ${token}` }
                }
            );
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue({ status: error.response.status, data: error.response.data });
        }

    }
);

export const deleteSong = createAsyncThunk(
    'song/deleteSong',
    async ({ token, song_id }, thunkAPI) => {

        if (!token) {
            return thunkAPI.rejectWithValue({ status: 0, data: { 'message': 'invalid token!' } });
        }

        try {
            const response = await axios.delete(`/song/`,
                {
                    headers: { "x-access-token": `token ${token}` },
                    data: { id: song_id },
                }
            );
            return { id: song_id };
        } catch (error) {
            return thunkAPI.rejectWithValue({ status: error.response.status, data: error.response.data });
        }

    }
);
