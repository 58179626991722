import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import jsonwebtoken from 'jsonwebtoken';
import { Col } from 'react-bootstrap';


export const fetchAllSemesters = createAsyncThunk(
    'semester/fetchAllSemesters',
    async ({ token }, thunkAPI) => {

        if (!token) {
            return thunkAPI.rejectWithValue({ status: 0, data: { 'message': 'invalid token!' } });
        }
        try {
            const response = await axios.get(`/semester/all`,
                {
                    headers: { "x-access-token": `token ${token}` }
                }
            );
            return response.data;

        } catch (error) {
            console.log("error", error.response)
            return thunkAPI.rejectWithValue({ status: error.response.status, data: error.response.data });
        }

    }
);



export const fetchSemesterDetailById = createAsyncThunk(
    'semester/fetchSemesterDetailById',
    async ({ token, semester_id }, thunkAPI) => {

        if (!token) {
            return thunkAPI.rejectWithValue({ status: 0, data: { 'message': 'invalid token!' } });
        }
        try {
            const response = await axios.get(`/semester?id=${semester_id}`,
                {
                    headers: { "x-access-token": `token ${token}` }
                }
            );
            return response.data;

        } catch (error) {
            console.log("error", error.response)
            return thunkAPI.rejectWithValue({ status: error.response.status, data: error.response.data });
        }

    }
);


export const addNewSemester = createAsyncThunk(
    'semester/addNewSemester',
    async ({ token, semesterName, startDate, endDate, selectedRoom }, thunkAPI) => {

        if (!token) {
            return thunkAPI.rejectWithValue({ status: 0, data: { 'message': 'invalid token!' } });
        }
        const form = new FormData();
        form.append('name', semesterName);
        form.append('start_date', startDate);
        form.append('end_date', endDate);
        form.append('selected_room', selectedRoom);

        try {
            const response = await axios.post('/semester/', form,
                {
                    headers: { "x-access-token": `token ${token}` }
                }
            );
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue({ status: error.response.status, data: error.response.data });
        }

    }
);


export const addWeekReserve = createAsyncThunk(
    'semester/addWeekReserve',
    async ({ token, semesterId, roomName, weekDay, lesson, teacherId, lessonName }, thunkAPI) => {

        if (!token) {
            return thunkAPI.rejectWithValue({ status: 0, data: { 'message': 'invalid token!' } });
        }
        const form = new FormData();
        form.append('semester_id', semesterId);
        form.append('room_name', roomName);
        form.append('week_day', weekDay);
        form.append('lesson', lesson);
        form.append('teacher_id', teacherId);
        form.append('lesson_name', lessonName)

        try {
            const response = await axios.post('/semester/week_routine/', form,
                {
                    headers: { "x-access-token": `token ${token}` }
                }
            );
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue({ status: error.response.status, data: error.response.data });
        }

    }
);

export const deleteWeekReserve = createAsyncThunk(
    'semester/deleteWeekReserve',
    async ({ token, semesterId, roomName, weekDay, lesson, teacherId, lessonName}, thunkAPI) => {
        
        if (!token) {
            return thunkAPI.rejectWithValue({ status: 0, data: { 'message': 'invalid token!' } });
        }

        try {
            const response = await axios.delete(`/semester/week_routine/`,
                {
                    headers: { "x-access-token": `token ${token}` },
                    data: {

                        semester_id: semesterId,
                        room_name: roomName,
                        week_day: weekDay,
                        lesson: lesson,
                        teacher_id: teacherId,
                        lesson_name: lessonName
                    },
                }
            );
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue({ status: error.response.status, data: error.response.data });
        }

    }
);



export const deleteSemester = createAsyncThunk(
    'semester/deleteSemester',
    async ({ token, semester_id }, thunkAPI) => {

        if (!token) {
            return thunkAPI.rejectWithValue({ status: 0, data: { 'message': 'invalid token!' } });
        }

        try {
            const response = await axios.delete(`/semester/`,
                {
                    headers: { "x-access-token": `token ${token}` },
                    data: { id: semester_id },
                }
            );
            return { id: semester_id };
        } catch (error) {
            return thunkAPI.rejectWithValue({ status: error.response.status, data: error.response.data });
        }

    }
);

