import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Container, Button, Table, NavDropdown, Nav, Accordion, Badge, Card, Form } from "react-bootstrap"
import { createThunkMiddleware } from '@reduxjs/toolkit'

import DeleteUserDialog from './dialogs/DeleteUserDialog';
import ResetUserPasswordDialog from './dialogs/ResetUserPasswordDialog';
import SetUserNoteDialog from './dialogs/SetUserNoteDialog';
import AddTypeDialog from './dialogs/AddTypeDialog';
import DeleteTypeDialog from './dialogs/DeleteTypeDialog';
import DeleteSubtypeDialog from './dialogs/DeleteSubtypeDialog';
import AddSubtypeDialog from './dialogs/AddSubtypeDialog';

import './UserManagePage.css'
import { fetchAllTypes, fetchSubtypes } from '../../redux/type/typeThunk';
import { addUserById, fetchAllUsers, fetchUserTimeLimits } from '../../redux/userList/userListThunk';

import store from '../../redux/store'
import SetUserStatusDialog from './dialogs/SetUserStatusDialog';
import moment from 'moment';
import MoveUserSubtypeDialog from './dialogs/MoveUserSubtypeDialog';
import { LinkContainer } from 'react-router-bootstrap';
import { useRouteMatch } from 'react-router';
import { getUserTypeString } from '../../utils/utils';
import LoadingContainer from '../../components/LoadingContainer/LoadingContainer';

export default function UserManagePage() {
  const dispatch = useDispatch();
  const { path, url } = useRouteMatch();



  const type = useSelector(state => state.type);
  const user = useSelector(state => state.user);
  const userList = useSelector(state => state.userList);


  const [displayUserList, setDisplayUserList] = useState(userList.userList);
  const [selectedUserList, setSelectedUserList] = useState({});
  const [selectedAll, setSelectedAll] = useState(false);

  const [selectedTypeName, setSelectedTypeName] = useState("");
  const [selectedSubtypeName, setSelectedSubtypeName] = useState("");
  const [selectedTypeObject, setSelectedTypeObject] = useState({});
  const [selectedSubtypeObject, setSelectedSubtypeObject] = useState({});



  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showResetDialog, setShowResetDialog] = useState(false);
  const [showSetNoteDialog, setShowSetNoteDialog] = useState(false);
  const [showAddTypeDialog, setShowAddTypeDialog] = useState(false);
  const [showAddSubtypeDialog, setShowAddSubtypeDialog] = useState(false);
  const [showDeleteTypeDialog, setShowDeleteTypeDialog] = useState(false);
  const [showDeleteSubtypeDialog, setShowDeleteSubtypeDialog] = useState(false);
  const [showSetUserStatusDialog, setShowSetUserStatusDialog] = useState(false);
  const [showMoveUserSubtypeDialog, setShowMoveUserSubtypeDialog] = useState(false);




  useEffect(async () => {
    const result = await dispatch(fetchAllTypes({ token: user.token }));
    if (result.meta.requestStatus !== "fulfilled") {
      return;
    }
    console.log(result.payload)
    result.payload.forEach((typeItem) => {
      dispatch(fetchSubtypes({ token: user.token, typeName: typeItem.name }));
    });
  }, []);


  useEffect(async () => {
    const result = await dispatch(fetchAllUsers({ token: user.token }));
    if (result.meta.requestStatus !== "fulfilled") {
      return;
    }
    dispatch(fetchUserTimeLimits({ token: user.token }));
  }, []);




  useEffect(() => {
    if (Object.keys(userList.userList).length === 0) {
      return;
    }
    setDisplayUserList(userList.userList);
    setAllUserSelection(false);
  }, [userList]);



  useEffect(() => {
    clearSelectType();
  }, [userList]);


  useEffect(async () => {
    if (selectedTypeName === "") {
      return;
    }

    const selectedType = type.types.find(element => element.name === selectedTypeName);
    if (!selectedType) {
      return;
    }
    setSelectedTypeObject(selectedType);

    const selectedSubtypeDict = selectedType.subtypeDict;
    if (!selectedSubtypeDict) {
      return;
    }

    if (selectedSubtypeName === "") {
      return;
    }
    const selectedSubtype = selectedSubtypeDict[selectedSubtypeName];
    if (!selectedSubtype) {
      return;
    }
    if (!selectedSubtype.user_list) {
      return;
    }
    setSelectedSubtypeObject(selectedSubtype);

    console.log('selectedType', selectedType);
    console.log('selectedSubtype', selectedSubtype);

    let newUserList = {};
    for (let i = 0; i < selectedSubtype.user_list.length; i++) {
      const id = selectedSubtype.user_list[i];
      const filteredUser = userList.userList[id];
      if (filteredUser) {
        newUserList[id] = filteredUser;
      }
    }
    setDisplayUserList(newUserList);
    setAllUserSelection(false);
    setSelectedAll(false);
  }, [selectedTypeName, selectedSubtypeName, type]);




  const clearSelectType = () => {
    setSelectedTypeName("");
    setSelectedSubtypeName("");
    setDisplayUserList(userList.userList);
    setAllUserSelection(false);
  }

  const setAllUserSelection = (selected) => {
    Object.keys(userList.userList).forEach((id) => setUserSelection(selected, id));
  }

  const setUserSelection = (selected, user_id) => {
    setSelectedUserList(prevState => ({
      ...prevState,
      [user_id]: selected
    }));
  }

  const typeLimitsString = (typeLimits) => {
    if (!typeLimits) {
      return "";
    }
    let str = "";
    Object.keys(typeLimits).forEach((typeName) => {
      str += `${typeName}(${typeLimits[typeName]})\n`;
    });
    return str;
  }


  return (
    <div className="pageContainer">
      <Container>
        <Row>
          <Col>
            <h2>帳戶管理</h2>
            <br />
          </Col>
        </Row>
        <Row className="justify-content-md-center">
          <Col md={3}>
            <h3><Badge className='type-title' bg="secondary">條目</Badge></h3>
            <Accordion>
              <Card>
                <Card.Header className="d-grid gap-2">
                  <Button variant="outline-secondary" onClick={clearSelectType}>全部用戶</Button>
                </Card.Header>
              </Card>
              {type.types.map(
                (typeItem, i) =>
                  <Accordion.Item eventKey={i} key={i} onClick={() => { setSelectedTypeName(typeItem.name); }}>
                    <Accordion.Header>{typeItem.name}</Accordion.Header>
                    <Accordion.Body>
                      <Nav className="flex-column">
                        {typeItem.subtype.map(
                          (subtype, j) =>
                            <Nav.Link key={j} onClick={() => { setSelectedSubtypeName(subtype); setSelectedTypeName(typeItem.name); }}>{subtype}</Nav.Link>
                        )}
                      </Nav>
                    </Accordion.Body>
                  </Accordion.Item>
              )}
            </Accordion>
            <br />
            <Button onClick={() => setShowAddTypeDialog(true)}>建立條目</Button>
            <Button onClick={() => setShowDeleteTypeDialog(selectedTypeName ? true : false)} variant="danger">刪除條目</Button>
            <br />
            <Button onClick={() => setShowAddSubtypeDialog(true)}>新增類組</Button>
            <Button onClick={() => setShowDeleteSubtypeDialog(selectedSubtypeName ? true : false)} variant="danger">刪除類組</Button>
          </Col>
          <Col md={9}>
            <div>
              <LinkContainer to={`${path}/adduser`}><Button>新增使用者</Button></LinkContainer>
              <Button onClick={() => setShowDeleteDialog(true)}>刪除使用者</Button>
              <Button onClick={() => setShowResetDialog(true)}>重置密碼</Button>
              <Button onClick={() => setShowMoveUserSubtypeDialog(true)}>移動類組</Button>
              <Button onClick={() => setShowSetUserStatusDialog(true)}>停權/復權</Button>
              <Button onClick={() => setShowSetNoteDialog(true)}>備註</Button>
            </div>
            <br />
            <LoadingContainer isLoading={userList.isFetching}>
              <Table striped bordered responsive>
                <thead>
                  <tr>
                    <th><Form.Check type="checkbox" label="全選"
                      onChange={(event) => {
                        setAllUserSelection(event.target.checked);
                        setSelectedAll(event.target.checked);
                      }}
                      checked={selectedAll} /></th>
                    <th>身分</th>
                    <th>帳號</th>
                    <th>名字</th>
                    <th>類組</th>
                    <th>狀態</th>
                    <th>預約時數上限</th>
                    <th>備註</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.values(displayUserList).map(
                    (userIter, i) =>
                      <tr key={i}>
                        <td><Form.Check type="checkbox"
                          onChange={(event) => setUserSelection(event.target.checked, userIter.id)}
                          checked={selectedUserList[userIter.id] ?? false} />
                        </td>
                        <td>{userIter.identity}</td>
                        <td>{userIter.account}</td>
                        <td>{userIter.name}</td>
                        <td className='type'>
                          {getUserTypeString(userIter, type.types)}
                        </td>
                        <td
                          className={`${userIter.banned ? "banned" : ""} ${userIter.banned_due !== 0 ? "with-date" : ""}`}>
                          {userIter.banned ? "停權" : "正常"} {userIter.banned_due !== 0 ? `\n至${moment(new Date(userIter.banned_due * 1000)).format('YYYY/MM/DD')}` : ""}
                        </td>
                        <td className='type-limits'>{typeLimitsString(userIter.type_limits)}</td>
                        <td>{userIter.notes}</td>
                      </tr>
                  )}

                </tbody>
              </Table>
            </LoadingContainer>
          </Col>
        </Row>
      </Container>
      <DeleteUserDialog deleteUserList={selectedUserList} show={showDeleteDialog} onClose={() => setShowDeleteDialog(false)} onConfirm={() => setSelectedAll(false)} />
      <ResetUserPasswordDialog resetUserList={selectedUserList} show={showResetDialog} onClose={() => setShowResetDialog(false)} onConfirm={() => setSelectedAll(false)} />
      <SetUserNoteDialog selectedUserList={selectedUserList} show={showSetNoteDialog} onClose={() => setShowSetNoteDialog(false)} onConfirm={() => setSelectedAll(false)} />
      <AddTypeDialog show={showAddTypeDialog} onClose={() => setShowAddTypeDialog(false)} onConfirm={() => { window.location.reload(); }} />
      <AddSubtypeDialog show={showAddSubtypeDialog} onClose={() => setShowAddSubtypeDialog(false)} onConfirm={() => { window.location.reload(); }} />
      <DeleteTypeDialog type={selectedTypeObject} show={showDeleteTypeDialog} onClose={() => setShowDeleteTypeDialog(false)} onConfirm={() => { window.location.reload(); }} />
      <DeleteSubtypeDialog subtype={selectedSubtypeObject} show={showDeleteSubtypeDialog} onClose={() => setShowDeleteSubtypeDialog(false)} onConfirm={() => { window.location.reload(); }} />
      <SetUserStatusDialog selectedUserList={selectedUserList} show={showSetUserStatusDialog} onClose={() => setShowSetUserStatusDialog(false)} onConfirm={() => setSelectedAll(false)} />
      <MoveUserSubtypeDialog selectedUserList={selectedUserList} show={showMoveUserSubtypeDialog} onClose={() => setShowMoveUserSubtypeDialog(false)} onConfirm={() => { setSelectedAll(false); }} />
    </div>
  );
}

