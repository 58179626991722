import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Container, Button, Table, NavDropdown, Nav, Accordion, Badge, Tabs, Tab, Card, Form } from "react-bootstrap";
import { useHistory, useRouteMatch } from 'react-router';
import { userIdentity } from '../../redux/user/userSlice';
import moment from 'moment';




export default function UserBannedPage() {

    const dispatch = useDispatch();
    const history = useHistory();
    const { path, url } = useRouteMatch();

    const user = useSelector(state => state.user);

    useEffect(() => {
        if (user.profile.identity === userIdentity.admin_f || user.profile.identity === userIdentity.admin_s) {
            history.replace("adminhome");
        }
        if (user.profile.banned === false) {
            history.replace("home");
        }
    }, [user])

    return (
        <div className="pageContainer">
            <Container>
                <Row>
                    <Col>
                        <h2>親愛的{user.profile.name}，您好!</h2>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p>您目前不能預約琴房，{user.profile.banned_due !== 0 ? `停權時間至${moment(user.profile.banned_due * 1000).format('YYYY/MM/DD')}止，` : ""}若有任何疑問，請聯絡管理員或洽詢系辦，謝謝!</p>
                    </Col>
                </Row>
            </Container>

        </div >
    );

}

