import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { Modal, Button, Form, Row, Col } from "react-bootstrap"
import { addNewSong, deleteSong } from '../../redux/song/songThunk';

import SongDisplay from '../../components/SongDisplay/SongDisplay';

export default function StudentSongFormCheckDialog({ show, teacher, event, songDetail, deleteSongId, onClose, onConfirm }) {
    const dispatch = useDispatch();

    const user = useSelector(state => state.user);


    useEffect(() => {
        setShow(show);
    }, [show]);

    const [showState, setShow] = useState(show);


    const handleClose = () => {
        setShow(false);
        onClose();
    }

    const submitSong = async () => {
        if (deleteSongId) {
            await dispatch(deleteSong({ token: user.token, song_id: deleteSongId }));
        }
        await dispatch(addNewSong({ token: user.token, teacher_id: teacher.id, event_id: event.id, detail: songDetail }));
        onConfirm();
        handleClose();
    }
    
    return (
        <Modal show={showState} >
            <Modal.Header>
                <Modal.Title>曲目上傳確認</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <SongDisplay
                    song={{
                        name: user.profile.name,
                        teacher: teacher.name,
                        item: teacher.teaching,
                        student_id: user.profile.student_id,
                        detail: songDetail
                    }}
                    eventName={event.name} />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    修改內容
                </Button>
                <Button variant="primary" onClick={submitSong}>
                    確定上傳
                </Button>
            </Modal.Footer>
        </Modal >);
}