import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useRouteMatch,
    Redirect,
    useHistory
} from "react-router-dom";
import { Row, Col, Container, Button, Form } from "react-bootstrap"
import { LinkContainer } from 'react-router-bootstrap'

import './StudentSongFormPage.css'

import useQuery from '../../utils/useQuery'
import { fetchAllTeachers, fetchAllTeachingTypes } from '../../redux/teacher/teacherThunk';
import { fetchAllEvents } from '../../redux/event/eventThunk';
import StudentSongFormCheckDialog from './StudentSongFormCheckDialog';


export default function StudentSongFormPage() {
    const dispatch = useDispatch();
    const history = useHistory();

    const { path, url } = useRouteMatch();
    const query = useQuery();

    const user = useSelector(state => state.user);
    const teacher = useSelector(state => state.teacher);
    const event = useSelector(state => state.event);


    const teacher_id = query.get('teacher_id');
    const defaultSongId = query.get('song_id');
    const defaultEvent = query.get('event');
    const defaultSongDetail = query.get('song_detail');


    const [selectedTeacher, setSeletedTeacher] = useState({ name: '', teaching: '' });
    const [validated, setValidated] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState({ name: '' });
    const [songDetail, setSongDetail] = useState(defaultSongDetail ?? "");

    const [showCheckDialog, setShowCheckDialog] = useState(false);



    const profile = user.profile;



    useEffect(() => {
        dispatch(fetchAllTeachers({ token: user.token }));
        dispatch(fetchAllTeachingTypes({ token: user.token }));
        dispatch(fetchAllEvents({ token: user.token }));
    }, [])


    useEffect(() => {
        const findedTeacher = teacher.teachers.find(teacher => teacher.id === teacher_id);
        if (findedTeacher) {
            setSeletedTeacher(findedTeacher);
        }
    }, [teacher])


    useEffect(() => {
        if (!defaultEvent) {
            if (event.events.length !== 0) {
                setSelectedEvent(event.events[0]);
            }
            return;
        }
        const findedEvent = event.events.find(event => event.id === defaultEvent);
        if (findedEvent) {
            setSelectedEvent(findedEvent);
        }
    }, [event])


    const handleSubmit = async (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false || !isSongDetailValid()) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            event.preventDefault();
            setShowCheckDialog(true);
        }

        setValidated(true);

    };

    const isSongDetailValid = () => {
        return songDetail.length <= 1000;
    }

    // const resetForm = () => {
    //     setValidated(false);
    //     setLocation("");
    //     setDetail("");
    //     setShowCompleteDialog(false);
    // }



    return (
        <div className="pageContainer">
            <Container>
                <Row>
                    <Col>
                        <h2>曲目上傳填寫</h2>
                    </Col>
                </Row>
                <Row className="justify-content-md-center">
                    <Col md='8'>
                        <Form noValidate validated={validated} onSubmit={handleSubmit}>
                            <Row>
                                <Col className='form-col form-data-col'>
                                    <p>學號: {profile.account}</p>
                                </Col>
                                <Col className='form-col form-data-col'>
                                    <p>姓名: {profile.name}</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col className='form-col form-data-col'>
                                    <p>項目: {selectedTeacher.teaching}</p>
                                </Col>
                                <Col className='form-col form-data-col'>
                                    <p>老師: {selectedTeacher.name}</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col className='form-col'>
                                    <Form.Label>活動:</Form.Label>
                                    <Form.Select value={selectedEvent.name} onChange={e => setSelectedEvent(event.events[e.target.options.selectedIndex])}>
                                        {event.events.map(
                                            (event, i) =>
                                                <option key={event.id}>{event.name}</option>
                                        )}
                                    </Form.Select>
                                </Col>
                            </Row>
                            <Row>
                                <Col className='form-col'>
                                    <Form.Label>填寫曲目</Form.Label>
                                    <Form.Control
                                        required
                                        column
                                        as="textarea"
                                        rows={5}
                                        placeholder="填寫格式請參考會考曲目格式規範"
                                        value={songDetail}
                                        onChange={e => setSongDetail(e.target.value)}
                                        isInvalid={!isSongDetailValid()}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        請輸入1~1000字的曲目資料
                                    </Form.Control.Feedback>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Button size='lg' type="submit">上傳</Button>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
            </Container>
            <StudentSongFormCheckDialog
                show={showCheckDialog}
                teacher={selectedTeacher}
                event={selectedEvent}
                songDetail={songDetail}
                deleteSongId={defaultSongId}
                onClose={() => setShowCheckDialog(false)}
                onConfirm={() => history.push('/user/studentsongpage')} />
        </div>
    );
}

