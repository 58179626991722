import React, { useEffect, useState } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useRouteMatch,
    Redirect,
    useHistory
} from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Container, Button, Alert } from "react-bootstrap"
import { LinkContainer } from 'react-router-bootstrap'
import { fetchAllRooms, fetchAllRoomTypes } from '../../redux/room/roomThunk';
import AdminMultiroomReservationTable from '../../components/AdminMultiroomReservationTable/AdminMultiroomReservationTable';
import AdminReservationSearchPanel from '../../components/AdminReservationSearchPanel/AdminReservationSearchPanel';
import ConditionalContainer from '../../components/ConditionalContainer';
import moment from 'moment';
import { fetchAdminReservations, fetchAllAdminReservations, fetchAllReservationTypes } from '../../redux/reservation/reservationThunk';
import { userIdentity } from '../../redux/user/userSlice';
import AdminDeleteReservationDialog from './dialogs/AdminDeleteReservationDialog';
import AdminMultidayReservationTable from '../../components/AdminMultidayReservationTable/AdminMultidayReservationTable';


export default function AdminHomePage() {

    const { path, url } = useRouteMatch();
    const dispatch = useDispatch();
    const history = useHistory();


    const user = useSelector(state => state.user);
    const room = useSelector(state => state.room);
    const reservation = useSelector(state => state.reservation);


    const [filteredRoom, setFilteredRoom] = useState(null);
    const [beginDate, setBeginDate] = useState(moment().startOf("day"));
    const [endDate, setEndDate] = useState(moment().startOf("day"));
    const [dateList, setDateList] = useState([]);
    const [selectedReservation, setSelectedReservation] = useState(null);
    const [showAlert, setShowAlert] = useState(false);


    const [showDeleteReservationDialog, setShowDeleteReservationDialog] = useState(false);





    useEffect(() => {
        dispatch(fetchAllRooms({ token: user.token }));
        dispatch(fetchAllReservationTypes({ token: user.token }));
        dispatch(fetchAllRoomTypes({ token: user.token }));
        dispatch(fetchAllAdminReservations({ token: user.token }));
    }, []);


    useEffect(() => {
        if ((user.profile.identity !== userIdentity.admin_f) && (user.profile.identity !== userIdentity.admin_s)) {
            history.replace("home");
        }
    }, [user])



    useEffect(() => {
        let newDateList = [];
        console.log(beginDate)
        console.log(endDate)
        for (let d = beginDate.unix(); d <= endDate.unix(); d += 86400) {
            newDateList.push(moment(d * 1000));
        }
        setDateList(newDateList);
    }, [beginDate, endDate]);

    useEffect(() => {
        console.log(dateList)
    }, [dateList]);


    const handleSearch = (params) => {
        console.log(params);
        setBeginDate(moment(params.beginDate));
        setEndDate(moment(params.endDate));
        setFilteredRoom(params.room ?? null);
        // dispatch(fetchAdminReservations({
        //     token: user.token,
        //     start: moment(params.beginDate).startOf('day').unix(),
        //     end: moment(params.endDate).startOf('day').unix(),
        //     room_id: params.room ? params.room.id : null,
        // }));
        dispatch(fetchAllAdminReservations({ token: user.token }));
    }

    const handleClearSearch = () => {
        setBeginDate(moment().startOf("day"));
        setEndDate(moment().startOf("day"));
        setFilteredRoom(null);
    }

    const handleClickTable = (col) => {
        console.log(col);
        if (col.invalid || col.passed) {
            return;
        }
        else if (user.profile.identity !== userIdentity.admin_f) {
            if ((col.room.restricts === 'identity') && (col.room.restricts_type !== 'everyone') && (col.room.restricts_type !== 'admin and student')) {
                setShowAlert(true);
                return;
            }
        }
        else if (col.reservation) {

            setSelectedReservation(col.reservation);
            setShowDeleteReservationDialog(true);
        }
        else {
            let date = moment(col.date).startOf("day");

            let beginTime = moment(date).set({ hour: Math.floor(col.span[0]), minute: col.span[0] % 1 * 60, second: 0, millisecond: 0 })
            let endTime = moment(date).set({ hour: Math.floor(col.span[1]), minute: col.span[1] % 1 * 60, second: 0, millisecond: 0 })

            history.push(`adminreservationform?room_id=${col.room.id}&date=${date.unix()}&begin_time=${beginTime.unix()}&end_time=${endTime.unix()}`);
        }
    }

    if (showAlert) {
        return (
            <Alert variant="warning" onClose={() => setShowAlert(false)} className='mt-5'>
                <Alert.Heading>權限不足！</Alert.Heading>
                <p>
                    抱歉，因為此琴房的預約身份限制，學生管理員權限不足，無法預約
                </p>
                <hr />
                <div className="d-flex justify-content-end">
                    <Button onClick={() => setShowAlert(false)} variant="outline-success">
                        回到琴房搜尋頁
                    </Button>
                </div>
            </Alert>
        );
    }

    return (
        <div className="pageContainer">
            <Container>
                <Row>
                    <Col>
                        <LinkContainer to={`roommanage`}><Button>琴房資料總覽</Button></LinkContainer>
                    </Col>
                    <Col>
                        <LinkContainer to={`repairmanage`}><Button>報修情形</Button></LinkContainer>
                    </Col>
                    <Col>
                        <LinkContainer to={`reservationrecord`}><Button>預約紀錄</Button></LinkContainer>
                    </Col>
                    {user.profile.identity === 'admin_f' ?
                        <Col>
                            <LinkContainer to={`reservationsemester`}><Button>學期課表</Button></LinkContainer>
                        </Col> :
                        <></>
                    }
                </Row>
                <br />
                <Row>
                    <Col>
                        <AdminReservationSearchPanel onSearch={handleSearch} onClear={handleClearSearch} />
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col>
                        <ConditionalContainer condition={filteredRoom === null}>
                            {dateList.map(
                                (date, idx) =>
                                    <Container key={idx}>
                                        <Row>
                                            <Col>
                                                <h4>{moment(date).format('YYYY/MM/DD')}</h4>
                                            </Col>
                                        </Row>
                                        {room.roomTypes.map(
                                            (roomType, jIdx) =>
                                                <>
                                                    <Row key={jIdx}>
                                                        <Col>
                                                            <AdminMultiroomReservationTable
                                                                roomType={roomType}
                                                                dateObject={date}
                                                                reservationList={reservation.adminReservations}
                                                                onClick={handleClickTable} />
                                                        </Col>
                                                    </Row>
                                                    <br />
                                                </>
                                        )}

                                    </Container>)}
                        </ConditionalContainer>
                        <ConditionalContainer condition={filteredRoom !== null}>
                            <Row>
                                <Col>
                                    <AdminMultidayReservationTable
                                        selectedRoom={filteredRoom}
                                        dateRange={[beginDate, endDate]}
                                        reservationList={reservation.adminReservations}
                                        onClick={handleClickTable} />
                                </Col>
                            </Row>
                        </ConditionalContainer>
                    </Col>
                </Row>
            </Container>
            <AdminDeleteReservationDialog
                reservation={selectedReservation}
                show={showDeleteReservationDialog}
                onClose={() => { setShowDeleteReservationDialog(false); }}
                onDelete={() => { dispatch(fetchAllAdminReservations({ token: user.token })); }} />
        </div>
    );
}

