import { createSlice, current } from '@reduxjs/toolkit';

import { fetchAllTypes, fetchSubtypes, addNewType, deleteType, addNewSubtype, deleteSubtype, moveUserSubtype, fetchUserSubtypes } from './typeThunk';


export const initialState = {
    types: [],
    mySubtypes: [],
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorStatus: 0,
    errorMessage: '',
}

const typeSlice = createSlice({
    name: 'type',
    initialState,
    reducers: {
    },
    extraReducers: {
        [fetchAllTypes.fulfilled]: (state, { payload }) => {
            console.log('payload', payload)
            state.types = payload;
            state.isFetching = false;
            state.isSuccess = true;
            return state;
        },
        [fetchAllTypes.rejected]: (state, { payload }) => {
            console.log('reject payload', payload)
            state.isError = true;
            state.isSuccess = false;
            state.isFetching = false;
            state.errorStatus = payload.status;
            state.errorMessage = payload.data.message;
        },
        [fetchAllTypes.pending]: (state) => {
            state.isFetching = true;
        },

        [fetchSubtypes.fulfilled]: (state, { payload }) => {
            console.log('payload', payload)
            const subtypeObject = payload.subtypes.reduce((obj, item) => {
                obj[item['name']] = item
                return obj
            }, {});
            state.types.find(element => element.name === payload.typeName).subtypeDict = subtypeObject;
            state.isFetching = false;
            state.isSuccess = true;
            return state;
        },
        [fetchSubtypes.rejected]: (state, { payload }) => {
            console.log('reject payload', payload)
            state.isError = true;
            state.isSuccess = false;
            state.isFetching = false;
            state.errorStatus = payload.status;
            state.errorMessage = payload.data.message;
        },
        [fetchSubtypes.pending]: (state) => {
            state.isFetching = true;
        },


        [fetchUserSubtypes.fulfilled]: (state, { payload }) => {
            console.log('payload', payload)
            state.mySubtypes = payload;
            state.isFetching = false;
            state.isSuccess = true;
            return state;
        },
        [fetchUserSubtypes.rejected]: (state, { payload }) => {
            console.log('reject payload', payload)
            state.isError = true;
            state.isSuccess = false;
            state.isFetching = false;
            state.errorStatus = payload.status;
            state.errorMessage = payload.data.message;
        },
        [fetchUserSubtypes.pending]: (state) => {
            state.isFetching = true;
        },


        [addNewType.fulfilled]: (state, { payload }) => {
            console.log('payload', payload)
            state.types.push(payload);
            state.isFetching = false;
            state.isSuccess = true;
            return state;
        },
        [addNewType.rejected]: (state, { payload }) => {
            console.log('reject payload', payload)
            state.isError = true;
            state.isSuccess = false;
            state.isFetching = false;
            state.errorStatus = payload.status;
            state.errorMessage = payload.data.message;
        },
        [addNewType.pending]: (state) => {
            state.isFetching = true;
        },

        [deleteType.fulfilled]: (state, { payload }) => {
            console.log('payload', payload)
            const index = state.types.findIndex(element => element.id === payload.id);
            state.types.splice(index, 1);
            state.isFetching = false;
            state.isSuccess = true;
            return state;
        },
        [deleteType.rejected]: (state, { payload }) => {
            console.log('reject payload', payload)
            state.isError = true;
            state.isSuccess = false;
            state.isFetching = false;
            state.errorStatus = payload.status;
            state.errorMessage = payload.data.message;
        },
        [deleteType.pending]: (state) => {
            state.isFetching = true;
        },

        [addNewSubtype.fulfilled]: (state, { payload }) => {
            console.log('payload', payload)
            let subtypeDict = state.types.find(element => element.name === payload.root).subtypeDict;
            subtypeDict = { ...subtypeDict, [payload.name]: payload };
            state.isFetching = false;
            state.isSuccess = true;
            return state;
        },
        [addNewSubtype.rejected]: (state, { payload }) => {
            console.log('reject payload', payload)
            state.isError = true;
            state.isSuccess = false;
            state.isFetching = false;
            state.errorStatus = payload.status;
            state.errorMessage = payload.data.message;
        },
        [addNewSubtype.pending]: (state) => {
            state.isFetching = true;
        },


        [deleteSubtype.fulfilled]: (state, { payload }) => {
            console.log('payload', payload)
            let typeItem = state.types.find(element => element.name === payload.root);
            delete typeItem.subtypeDict[payload.name];
            state.isFetching = false;
            state.isSuccess = true;
            return state;
        },
        [deleteSubtype.rejected]: (state, { payload }) => {
            console.log('reject payload', payload)
            state.isError = true;
            state.isSuccess = false;
            state.isFetching = false;
            state.errorStatus = payload.status;
            state.errorMessage = payload.data.message;
        },
        [deleteSubtype.pending]: (state) => {
            state.isFetching = true;
        },


        [moveUserSubtype.fulfilled]: (state, { payload }) => {
            console.log('payload', payload);
            const index = state.types.find(element => element.name === payload.typeName)
                .subtypeDict[payload.oldSubtypeName]
                .user_list
                .findIndex(element => element === payload.user_id);
            state.types.find(element => element.name === payload.typeName).subtypeDict[payload.oldSubtypeName].user_list.splice(index, 1);
            state.types.find(element => element.name === payload.typeName).subtypeDict[payload.newSubtype.name] = payload.newSubtype;
            state.isFetching = false;
            state.isSuccess = true;
            return state;
        },
        [moveUserSubtype.rejected]: (state, { payload }) => {
            console.log('reject payload', payload)
            state.isError = true;
            state.isSuccess = false;
            state.isFetching = false;
            state.errorStatus = payload.status;
            state.errorMessage = payload.data.message;
        },
        [moveUserSubtype.pending]: (state) => {
            state.isFetching = true;
        },

    }
});








export default typeSlice;