import { createSlice, current } from '@reduxjs/toolkit';
import { addNewRoom, addNewRoomType, deleteRoom, deleteRoomType, fetchAllRooms, fetchAllRoomTypes, updateRoom, updateRoomStatus } from './roomThunk';


export const roomTypeRestricts = {
    adminOnly: "admin",
    adminAndTeacher: "admin and teacher",
    adminAndStudent: "admin and student",
    everyone: "everyone"
}

export const roomStatus = {
    allow: "allow",
    notAllow: "not allow",
}

export const initialState = {
    rooms: [],
    roomTypes: [],
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorStatus: 0,
    errorMessage: '',
}

const roomSlice = createSlice({
    name: 'room',
    initialState,
    reducers: {
    },
    extraReducers: {

        [fetchAllRooms.fulfilled]: (state, { payload }) => {
            console.log('payload', payload)
            state.rooms = payload;
            state.isFetching = false;
            state.isSuccess = true;
            return state;
        },
        [fetchAllRooms.rejected]: (state, { payload }) => {
            console.log('reject payload', payload)
            state.isError = true;
            state.isSuccess = false;
            state.isFetching = false;
            state.errorStatus = payload.status;
            state.errorMessage = payload.data.message;
        },
        [fetchAllRooms.pending]: (state) => {
            state.isFetching = true;
        },

        [addNewRoom.fulfilled]: (state, { payload }) => {
            console.log('payload', payload)
            state.rooms.push(payload);
            state.isFetching = false;
            state.isSuccess = true;
            return state;
        },
        [addNewRoom.rejected]: (state, { payload }) => {
            console.log('reject payload', payload)
            state.isError = true;
            state.isSuccess = false;
            state.isFetching = false;
            state.errorStatus = payload.status;
            state.errorMessage = payload.data.message;
        },
        [addNewRoom.pending]: (state) => {
            state.isFetching = true;
        },

        [updateRoom.fulfilled]: (state, { payload }) => {
            console.log('payload', payload)
            let index = state.rooms.findIndex((room) => room.id === payload.id);
            state.rooms[index] = payload;
            state.isFetching = false;
            state.isSuccess = true;
            return state;
        },
        [updateRoom.rejected]: (state, { payload }) => {
            console.log('reject payload', payload)
            state.isError = true;
            state.isSuccess = false;
            state.isFetching = false;
            state.errorStatus = payload.status;
            state.errorMessage = payload.data.message;
        },
        [updateRoom.pending]: (state) => {
            state.isFetching = true;
        },

        [updateRoomStatus.fulfilled]: (state, { payload }) => {
            console.log('payload', payload)
            let index = state.rooms.findIndex((room) => room.id === payload.id);
            state.rooms[index] = payload;
            state.isFetching = false;
            state.isSuccess = true;
            return state;
        },
        [updateRoomStatus.rejected]: (state, { payload }) => {
            console.log('reject payload', payload)
            state.isError = true;
            state.isSuccess = false;
            state.isFetching = false;
            state.errorStatus = payload.status;
            state.errorMessage = payload.data.message;
        },
        [updateRoomStatus.pending]: (state) => {
            state.isFetching = true;
        },

        [deleteRoom.fulfilled]: (state, { payload }) => {
            console.log('payload', payload)
            let index = state.rooms.findIndex((room) => room.id === payload.id);
            state.rooms.splice(index, 1);
            state.isFetching = false;
            state.isSuccess = true;
            return state;
        },
        [deleteRoom.rejected]: (state, { payload }) => {
            console.log('reject payload', payload)
            state.isError = true;
            state.isSuccess = false;
            state.isFetching = false;
            state.errorStatus = payload.status;
            state.errorMessage = payload.data.message;
        },
        [deleteRoom.pending]: (state) => {
            state.isFetching = true;
        },

        [fetchAllRoomTypes.fulfilled]: (state, { payload }) => {
            console.log('payload', payload)
            state.roomTypes = payload;
            state.isFetching = false;
            state.isSuccess = true;
            return state;
        },
        [fetchAllRoomTypes.rejected]: (state, { payload }) => {
            console.log('reject payload', payload)
            state.isError = true;
            state.isSuccess = false;
            state.isFetching = false;
            state.errorStatus = payload.status;
            state.errorMessage = payload.data.message;
        },
        [fetchAllRoomTypes.pending]: (state) => {
            state.isFetching = true;
        },

        [addNewRoomType.fulfilled]: (state, { payload }) => {
            console.log('payload', payload)
            state.roomTypes.push(payload);
            state.isFetching = false;
            state.isSuccess = true;
            return state;
        },
        [addNewRoomType.rejected]: (state, { payload }) => {
            console.log('reject payload', payload)
            state.isError = true;
            state.isSuccess = false;
            state.isFetching = false;
            state.errorStatus = payload.status;
            state.errorMessage = payload.data.message;
        },
        [addNewRoomType.pending]: (state) => {
            state.isFetching = true;
        },

        [deleteRoomType.fulfilled]: (state, { payload }) => {
            console.log('payload', payload)
            let index = state.roomTypes.findIndex((roomType) => roomType.id === payload.id);
            state.roomTypes.splice(index, 1);
            state.isFetching = false;
            state.isSuccess = true;
            return state;
        },
        [deleteRoomType.rejected]: (state, { payload }) => {
            console.log('reject payload', payload)
            state.isError = true;
            state.isSuccess = false;
            state.isFetching = false;
            state.errorStatus = payload.status;
            state.errorMessage = payload.data.message;
        },
        [deleteRoomType.pending]: (state) => {
            state.isFetching = true;
        },




    }
});








export default roomSlice;