import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import jsonwebtoken from 'jsonwebtoken';

import { repairmentStatus } from './repairmentSlice';


export const fetchAllRepairments = createAsyncThunk(
    'repairment/fetchAllRepairments',
    async ({ token }, thunkAPI) => {

        if (!token) {
            return thunkAPI.rejectWithValue({ status: 0, data: { 'message': 'invalid token!' } });
        }
        try {
            const tokenData = jsonwebtoken.decode(token);
            const response = await axios.get(`/repair/all`,
                {
                    headers: { "x-access-token": `token ${token}` }
                }
            );
            return response.data;

        } catch (error) {
            console.log("error", error.response)
            return thunkAPI.rejectWithValue({ status: error.response.status, data: error.response.data });
        }

    }
);


export const updateRepairmentStatus = createAsyncThunk(
    'repairment/updateRepairmentStatus',
    async ({ token, repairment_id }, thunkAPI) => {

        if (!token) {
            return thunkAPI.rejectWithValue({ status: 0, data: { 'message': 'invalid token!' } });
        }

        const form = new FormData();
        form.append('id', repairment_id);
        form.append('status', repairmentStatus.handled);

        try {
            const response = await axios.put(`/repair/`,
                form,
                {
                    headers: { "x-access-token": `token ${token}` }
                }
            );
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue({ status: error.response.status, data: error.response.data });
        }

    }
);


export const deleteRepairment = createAsyncThunk(
    'repairment/deleteRepairment',
    async ({ token, repairment_id }, thunkAPI) => {

        if (!token) {
            return thunkAPI.rejectWithValue({ status: 0, data: { 'message': 'invalid token!' } });
        }


        try {
            const response = await axios.delete(`/repair/`,
                {
                    headers: { "x-access-token": `token ${token}` },
                    data: {id: repairment_id},
                }
            );
            return {id: repairment_id};
        } catch (error) {
            return thunkAPI.rejectWithValue({ status: error.response.status, data: error.response.data });
        }

    }
);


export const addNewRepairment = createAsyncThunk(
    'repairment/addNewRepairment',
    async ({ token, location, detail }, thunkAPI) => {

        if (!token) {
            return thunkAPI.rejectWithValue({ status: 0, data: { 'message': 'invalid token!' } });
        }
        const form = new FormData();
        form.append('location', location);
        form.append('detail', detail);

        try {
            const response = await axios.post('/repair/', form,
                {
                    headers: { "x-access-token": `token ${token}` }
                }
            );
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue({ status: error.response.status, data: error.response.data });
        }

    }
);
