import { createSlice, current } from '@reduxjs/toolkit';
import { fetchAllReservationTypes, fetchReservationAvaliable, addNewReserveType, deleteReserveType, updateReserveType, updateAllowAllReserve, fetchAdminReservationsByRoom, fetchAllAdminReservations, adminAddReservations, deleteAdminReservations, addReservations, fetchMyReservations, deleteReservations, updateReservation, fetchReservationRecords } from './reservationThunk';


export const recordStatus = {
    reserve: "reserve",
    update: "update",
    cancel: "cancel",
    adminCancel: "admin cancel",
}

export const reservationStatus = {
    using: "using",
    waiting: "waiting",
    finished: "finished",
  }


export const initialState = {
    myReservations: [],
    adminReservations: [],
    reservationTypes: [],
    roomsAvaliableTimes: {},
    records: [],
    allowAllReserve: true,
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorStatus: 0,
    errorMessage: '',
}

const reservationSlice = createSlice({
    name: 'reservation',
    initialState,
    reducers: {
    },
    extraReducers: {


        [fetchAllAdminReservations.fulfilled]: (state, { payload }) => {
            console.log('payload', payload)
            state.adminReservations = payload;
            state.isFetching = false;
            state.isSuccess = true;
            return state;
        },
        [fetchAllAdminReservations.rejected]: (state, { payload }) => {
            console.log('reject payload', payload)
            state.isError = true;
            state.isSuccess = false;
            state.isFetching = false;
            state.errorStatus = payload.status;
            state.errorMessage = payload.data.message;
        },
        [fetchAllAdminReservations.pending]: (state) => {
            state.isFetching = true;
        },

        [fetchAdminReservationsByRoom.fulfilled]: (state, { payload }) => {
            console.log('payload', payload)
            state.adminReservations = payload;
            state.isFetching = false;
            state.isSuccess = true;
            return state;
        },
        [fetchAdminReservationsByRoom.rejected]: (state, { payload }) => {
            console.log('reject payload', payload)
            state.isError = true;
            state.isSuccess = false;
            state.isFetching = false;
            state.errorStatus = payload.status;
            state.errorMessage = payload.data.message;
        },
        [fetchAdminReservationsByRoom.pending]: (state) => {
            state.isFetching = true;
        },

        [adminAddReservations.fulfilled]: (state, { payload }) => {
            console.log('payload', payload)
            state.isFetching = false;
            state.isSuccess = true;
            return state;
        },
        [adminAddReservations.rejected]: (state, { payload }) => {
            console.log('reject payload', payload)
            state.isError = true;
            state.isSuccess = false;
            state.isFetching = false;
            state.errorStatus = payload.status;
            state.errorMessage = payload.data.message;
        },
        [adminAddReservations.pending]: (state) => {
            state.isFetching = true;
        },

        [deleteAdminReservations.fulfilled]: (state, { payload }) => {
            console.log('payload', payload)
            let index = state.adminReservations.findIndex((reservation) => reservation.id === payload.id);
            state.adminReservations.splice(index, 1);
            state.isFetching = false;
            state.isSuccess = true;
            return state;
        },
        [deleteAdminReservations.rejected]: (state, { payload }) => {
            console.log('reject payload', payload)
            state.isError = true;
            state.isSuccess = false;
            state.isFetching = false;
            state.errorStatus = payload.status;
            state.errorMessage = payload.data.message;
        },
        [deleteAdminReservations.pending]: (state) => {
            state.isFetching = true;
        },



        [fetchAllReservationTypes.fulfilled]: (state, { payload }) => {
            console.log('payload', payload)
            state.reservationTypes = payload;
            state.isFetching = false;
            state.isSuccess = true;
            return state;
        },
        [fetchAllReservationTypes.rejected]: (state, { payload }) => {
            console.log('reject payload', payload)
            state.isError = true;
            state.isSuccess = false;
            state.isFetching = false;
            state.errorStatus = payload.status;
            state.errorMessage = payload.data.message;
        },
        [fetchAllReservationTypes.pending]: (state) => {
            state.isFetching = true;
        },

        [addNewReserveType.fulfilled]: (state, { payload }) => {
            console.log('payload', payload)
            state.reservationTypes.push(payload);
            state.isFetching = false;
            state.isSuccess = true;
            return state;
        },
        [addNewReserveType.rejected]: (state, { payload }) => {
            console.log('reject payload', payload)
            state.isError = true;
            state.isSuccess = false;
            state.isFetching = false;
            state.errorStatus = payload.status;
            state.errorMessage = payload.data.message;
        },
        [addNewReserveType.pending]: (state) => {
            state.isFetching = true;
        },


        [updateReserveType.fulfilled]: (state, { payload }) => {
            console.log('payload', payload)
            let index = state.reservationTypes.findIndex((reservationType) => reservationType.id === payload.id);
            state.reservationTypes[index] = payload;
            state.isFetching = false;
            state.isSuccess = true;
            return state;
        },
        [updateReserveType.rejected]: (state, { payload }) => {
            console.log('reject payload', payload)
            state.isError = true;
            state.isSuccess = false;
            state.isFetching = false;
            state.errorStatus = payload.status;
            state.errorMessage = payload.data.message;
        },
        [updateReserveType.pending]: (state) => {
            state.isFetching = true;
        },


        [deleteReserveType.fulfilled]: (state, { payload }) => {
            console.log('payload', payload)
            let index = state.reservationTypes.findIndex((reservationType) => reservationType.id === payload.id);
            state.reservationTypes.splice(index, 1);
            state.isFetching = false;
            state.isSuccess = true;
            return state;
        },
        [deleteReserveType.rejected]: (state, { payload }) => {
            console.log('reject payload', payload)
            state.isError = true;
            state.isSuccess = false;
            state.isFetching = false;
            state.errorStatus = payload.status;
            state.errorMessage = payload.data.message;
        },
        [deleteReserveType.pending]: (state) => {
            state.isFetching = true;
        },

        [updateAllowAllReserve.fulfilled]: (state, { payload }) => {
            console.log('payload', payload)
            state.allowAllReserve = payload;
            state.isFetching = false;
            state.isSuccess = true;
            return state;
        },
        [updateAllowAllReserve.rejected]: (state, { payload }) => {
            console.log('reject payload', payload)
            state.isError = true;
            state.isSuccess = false;
            state.isFetching = false;
            state.errorStatus = payload.status;
            state.errorMessage = payload.data.message;
        },
        [updateAllowAllReserve.pending]: (state) => {
            state.isFetching = true;
        },


        [fetchReservationRecords.fulfilled]: (state, { payload }) => {
            console.log('payload', payload)
            state.records = payload;
            state.isFetching = false;
            state.isSuccess = true;
            return state;
        },
        [fetchReservationRecords.rejected]: (state, { payload }) => {
            console.log('reject payload', payload)
            state.isError = true;
            state.isSuccess = false;
            state.isFetching = false;
            state.errorStatus = payload.status;
            state.errorMessage = payload.data.message;
        },
        [fetchReservationRecords.pending]: (state) => {
            state.isFetching = true;
        },



        [fetchReservationAvaliable.fulfilled]: (state, { payload }) => {
            console.log('payload', payload)
            state.roomsAvaliableTimes = payload;
            state.isFetching = false;
            state.isSuccess = true;
            return state;
        },
        [fetchReservationAvaliable.rejected]: (state, { payload }) => {
            console.log('reject payload', payload)
            state.isError = true;
            state.isSuccess = false;
            state.isFetching = false;
            state.errorStatus = payload.status;
            state.errorMessage = payload.data.message;
        },
        [fetchReservationAvaliable.pending]: (state) => {
            state.isFetching = true;
        },


        [fetchMyReservations.fulfilled]: (state, { payload }) => {
            console.log('payload', payload)
            state.myReservations = payload;
            state.isFetching = false;
            state.isSuccess = true;
            return state;
        },
        [fetchMyReservations.rejected]: (state, { payload }) => {
            console.log('reject payload', payload)
            state.isError = true;
            state.isSuccess = false;
            state.isFetching = false;
            state.errorStatus = payload.status;
            state.errorMessage = payload.data.message;
        },
        [fetchMyReservations.pending]: (state) => {
            state.isFetching = true;
        },


        [addReservations.fulfilled]: (state, { payload }) => {
            console.log('payload', payload);
            state.myReservations.push(payload);
            state.isFetching = false;
            state.isSuccess = true;
            return state;
        },
        [addReservations.rejected]: (state, { payload }) => {
            console.log('reject payload', payload)
            state.isError = true;
            state.isSuccess = false;
            state.isFetching = false;
            state.errorStatus = payload.status;
            state.errorMessage = payload.data.message;
        },
        [addReservations.pending]: (state) => {
            state.isFetching = true;
        },

        [updateReservation.fulfilled]: (state, { payload }) => {
            console.log('payload', payload)
            let index = state.myReservations.findIndex((reservation) => reservation.id === payload.id);
            state.myReservations[index] = payload;
            state.isFetching = false;
            state.isSuccess = true;
            return state;
        },
        [updateReservation.rejected]: (state, { payload }) => {
            console.log('reject payload', payload)
            state.isError = true;
            state.isSuccess = false;
            state.isFetching = false;
            state.errorStatus = payload.status;
            state.errorMessage = payload.data.message;
        },
        [updateReservation.pending]: (state) => {
            state.isFetching = true;
        },

        [deleteReservations.fulfilled]: (state, { payload }) => {
            console.log('payload', payload)
            let index = state.myReservations.findIndex((reservation) => reservation.id === payload.id);
            state.myReservations.splice(index, 1);
            state.isFetching = false;
            state.isSuccess = true;
            return state;
        },
        [deleteReservations.rejected]: (state, { payload }) => {
            console.log('reject payload', payload)
            state.isError = true;
            state.isSuccess = false;
            state.isFetching = false;
            state.errorStatus = payload.status;
            state.errorMessage = payload.data.message;
        },
        [deleteReservations.pending]: (state) => {
            state.isFetching = true;
        },


    }
});








export default reservationSlice;