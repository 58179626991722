import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useRouteMatch } from "react-router-dom";
import { Modal, Button, Form, Row, Col } from "react-bootstrap"

import SongDisplay from '../../../components/SongDisplay/SongDisplay';
import { updateSongStatus } from '../../../redux/song/songThunk';
import { songStatus } from '../../../redux/song/songSlice';


export default function TeacherSongReprocessDialog({ show, song, onClose }) {
    const dispatch = useDispatch();
    const history = useHistory();

    const { path, url } = useRouteMatch();

    const user = useSelector(state => state.user);


    useEffect(() => {
        setShow(show);
    }, [show]);

    const [showState, setShow] = useState(show);
    const [showDeleteSongDialog, setShowDeleteSongDialog] = useState(false);



    const handleClose = () => {
        setShow(false);
        onClose();
    }

    const reprocessSong = async () => {
        await dispatch(updateSongStatus({ token: user.token, song_id: song.id, status: songStatus.pending }));
        handleClose();
    }

    return (
        <Modal show={showState} >
            <Modal.Header>
                <Modal.Title>曲目上傳資料</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <SongDisplay song={song} />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    關閉
                </Button>
                <Button variant="primary" onClick={reprocessSong}>
                    重新審核
                </Button>
            </Modal.Footer>
        </Modal >
    );
}