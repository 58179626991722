import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useRouteMatch,
    Redirect,
    useHistory
} from "react-router-dom";
import { Row, Col, Container, Button, Form } from "react-bootstrap"
import { LinkContainer } from 'react-router-bootstrap'
import ConditionalContainer from '../../components/ConditionalContainer'

import './RoomFormPage.css'

import useQuery from '../../utils/useQuery'
import { addNewReserveType, fetchAllReservationTypes, updateReserveType } from '../../redux/reservation/reservationThunk';
import { getDayFromSecond, getHourFromSecond, getMinuteFromSecond, getMonthFromSecond, getWeekFromSecond } from '../../utils/utils';
import { fetchAllTypes, fetchSubtypes } from '../../redux/type/typeThunk';
import { addNewRoom, fetchAllRooms, fetchAllRoomTypes, updateRoom } from '../../redux/room/roomThunk';
import { roomTypeRestricts } from '../../redux/room/roomSlice';
import { userIdentity } from '../../redux/user/userSlice';

export default function RoomFormPage() {
    const dispatch = useDispatch();
    const history = useHistory();

    const { path, url } = useRouteMatch();
    const query = useQuery();

    const user = useSelector(state => state.user);
    const room = useSelector(state => state.room);
    const type = useSelector(state => state.type);
    const reservation = useSelector(state => state.reservation);


    const room_id = query.get('room_id');


    const restrictOptions = [
        { name: "依身分", value: "identity" },
        { name: "依類組", value: "type" },
    ]

    const identityRestrictOptionsAdminS = [
        { name: "管理員與使用者-學生", value: roomTypeRestricts.adminAndStudent },
        { name: "所有人", value: roomTypeRestricts.everyone }
    ]

    const identityRestrictOptions = [
        { name: "限管理員", value: roomTypeRestricts.adminOnly },
        { name: "管理員與使用者-老師", value: roomTypeRestricts.adminAndTeacher },
        { name: "管理員與使用者-學生", value: roomTypeRestricts.adminAndStudent },
        { name: "所有人", value: roomTypeRestricts.everyone }
    ]

    const distinguishWeekdayHolidayOptions = [
        { name: "區分平假日", value: true },
        { name: "不區分平假日", value: false },
    ]

    const timePeriodOptions = [
        { name: "一", value: 1 },
        { name: "二", value: 2 },
        { name: "三", value: 3 },
    ]


    const [roomName, setRoomName] = useState("");
    const [roomType, setRoomType] = useState({ name: "" });
    const [restrict, setRestrict] = useState(restrictOptions[0]);
    const [identityRestrict, setIdentityRestrict] = useState(identityRestrictOptions[2]);
    const [typeRestrict, setTypeRestrict] = useState({ name: "", subtype: [] });
    const [subtypeRestrict, setSubtypeRestrict] = useState("");
    const [distinguishWeekdayHoliday, setDistinguishWeekdayHoliday] = useState(distinguishWeekdayHolidayOptions[0]);
    const [weekdayReservationType, setWeekdayReservationType] = useState({ name: "" });
    const [holidayReservationType, setHolidayReservationType] = useState({ name: "" });
    const [weekdayAllowReserveList, setWeekdayAllowReserveList] = useState([[0, 0], [0, 0], [0, 0]]);
    const [holidayAllowReserveList, setHolidayAllowReserveList] = useState([[0, 0], [0, 0], [0, 0]]);


    const [roomNameErrorMessage, setRoomNameErrorMessage] = useState("");
    const [validated, setValidated] = useState(false);





    useEffect(async () => {
        dispatch(fetchAllRooms({ token: user.token }));
        dispatch(fetchAllRoomTypes({ token: user.token }));
        dispatch(fetchAllTypes({ token: user.token }));
        // dispatch(fetchSubtypes({ token: user.token }));
        dispatch(fetchAllReservationTypes({ token: user.token }));
    }, []);

    useEffect(() => {
        if (type.types[0]) {
            setTypeRestrict(type.types[0]);
            setSubtypeRestrict(type.types[0].subtype[0]);
        }
    }, [type]);

    useEffect(() => {
        if (reservation.reservationTypes[0]) {
            setWeekdayReservationType(reservation.reservationTypes[0]);
            setHolidayReservationType(reservation.reservationTypes[0]);
        }
    }, [reservation]);

    useEffect(() => {
        if (room.roomTypes[0] && !room_id) {
            setRoomType(room.roomTypes[0]);
        }
    }, [room]);

    useEffect(() => {
        for (let i = 0; i < weekdayAllowReserveList.length; i++) {
            for (let j = 0; j < weekdayAllowReserveList[i].length; j++) {
                if (weekdayAllowReserveList[i][j] < 0) {
                    let newArr = [...weekdayAllowReserveList];
                    newArr[i][j] = 0;
                    setWeekdayAllowReserveList(newArr);
                }
            }
        }
    }, [weekdayAllowReserveList]);

    useEffect(() => {
        for (let i = 0; i < holidayAllowReserveList.length; i++) {
            for (let j = 0; j < holidayAllowReserveList[i].length; j++) {
                if (holidayAllowReserveList[i][j] < 0) {
                    let newArr = [...holidayAllowReserveList];
                    newArr[i][j] = 0;
                    setHolidayAllowReserveList(newArr);
                }
            }
        }
    }, [holidayAllowReserveList]);


    useEffect(async () => {
        if (!room_id) {
            return;
        }

        const defaultRoom = room.rooms.find(element => element.id === room_id);
        if (!defaultRoom) {
            return;
        }

        const defaultRoomType = room.roomTypes.find(roomType => roomType.name === defaultRoom.type);
        if (!defaultRoomType) {
            return;
        }

        let defaultWeekdayAllowReserveList = defaultRoom.reserve_type.weekday.allow_reserve.map(function (row) {
            return row.map(function (col) {
                return parseInt(col);
            });
        })
        let defaultWeekdayAllowReserveListLength = defaultWeekdayAllowReserveList.length;
        for (let i = 0; i < 3 - defaultWeekdayAllowReserveListLength; i++) {
            defaultWeekdayAllowReserveList.push([0, 0]);
        }
        let defaultHolidayAllowReserveList = defaultRoom.reserve_type.holiday.allow_reserve.map(function (row) {
            return row.map(function (col) {
                return parseInt(col);
            });
        })
        let defaultHolidayAllowReserveListLength = defaultHolidayAllowReserveList.length;
        for (let i = 0; i < 3 - defaultHolidayAllowReserveListLength; i++) {
            defaultHolidayAllowReserveList.push([0, 0]);
        }

        if (reservation.reservationTypes.length === 0 || reservation.reservationTypes.length === 0) {
            return;
        }


        setRoomName(defaultRoom.name);
        setRoomType(defaultRoomType);
        setRestrict(restrictOptions.find(option => option.value === defaultRoom.restricts))
        if (defaultRoom.restricts === "identity") {
            setIdentityRestrict(identityRestrictOptions.find(option => option.value === defaultRoom.restricts_type));
        } else if (defaultRoom.restricts === "type") {
            const words = defaultRoom.restricts_type.split(':');
            const typeName = words[0];
            const subtypeName = words[1];
            setTypeRestrict(type.types.find(element => element.name === typeName));
            setSubtypeRestrict(subtypeName);
        }
        setWeekdayReservationType(reservation.reservationTypes.find(element => element.name === defaultRoom.reserve_type.weekday.type));
        setHolidayReservationType(reservation.reservationTypes.find(element => element.name === defaultRoom.reserve_type.holiday.type));
        setWeekdayAllowReserveList(defaultWeekdayAllowReserveList);
        setHolidayAllowReserveList(defaultHolidayAllowReserveList);

        if (defaultRoom.reserve_type.weekday.type === defaultRoom.reserve_type.holiday.type
            && JSON.stringify(defaultWeekdayAllowReserveList) === JSON.stringify(defaultHolidayAllowReserveList)) {
            setDistinguishWeekdayHoliday(distinguishWeekdayHolidayOptions[1]);
        } else {
            setDistinguishWeekdayHoliday(distinguishWeekdayHolidayOptions[0]);
        }

    }, [type, room, reservation]);








    const handleSubmit = async (event) => {
        const form = event.currentTarget;
        determineErrorMessage();
        if (form.checkValidity() === false || !isFormValid()) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            event.preventDefault();

            let reserveType;
            if (distinguishWeekdayHoliday.value) {
                reserveType = {
                    weekday: {
                        type: weekdayReservationType.name,
                        allow_reserve: weekdayAllowReserveList.slice(0, weekdayReservationType.time_period).map(function (row) {
                            return row.map(function (col) {
                                return col.toString();
                            });
                        }),
                    },
                    holiday: {
                        type: holidayReservationType.name,
                        allow_reserve: holidayAllowReserveList.slice(0, holidayReservationType.time_period).map(function (row) {
                            return row.map(function (col) {
                                return col.toString();
                            });
                        }),
                    }
                }
            } else {
                reserveType = {
                    weekday: {
                        type: weekdayReservationType.name,
                        allow_reserve: weekdayAllowReserveList.slice(0, weekdayReservationType.time_period).map(function (row) {
                            return row.map(function (col) {
                                return col.toString();
                            });
                        }),
                    },
                    holiday: {
                        type: weekdayReservationType.name,
                        allow_reserve: weekdayAllowReserveList.slice(0, weekdayReservationType.time_period).map(function (row) {
                            return row.map(function (col) {
                                return col.toString();
                            });
                        }),
                    },
                }
            }

            if (!room_id) {
                console.log({
                    token: user.token,
                    name: roomName,
                    type: roomType.name,
                    restricts: restrict.value,
                    restricts_type: restrict.value === "identity" ? identityRestrict.value : `${typeRestrict.name}:${subtypeRestrict}`,
                    reserve_type: JSON.stringify(reserveType).replaceAll("\"", "\'")
                })
                await dispatch(addNewRoom({
                    token: user.token,
                    name: roomName,
                    type: roomType.name,
                    restricts: restrict.value,
                    restricts_type: restrict.value === "identity" ? identityRestrict.value : `${typeRestrict.name}:${subtypeRestrict}`,
                    reserve_type: reserveType,
                }));
            } else {
                await dispatch(updateRoom({
                    token: user.token,
                    id: room_id,
                    name: roomName,
                    type: roomType.name,
                    restricts: restrict.value,
                    restricts_type: restrict.value === "identity" ? identityRestrict.value : `${typeRestrict.name}:${subtypeRestrict}`,
                    reserve_type: reserveType,
                }));
            }


            history.replace('/user/roommanage')
        }

        setValidated(true);

    };

    const isFormValid = () => {
        return isRoomNameValid();
    }

    const isRoomNameValid = () => {
        return isRoomNameLengthValid() && isRoomNameNotRepeatValid();
    }

    const isRoomNameLengthValid = () => {
        return roomName.length > 0 && roomName.length <= 10;
    }

    const isRoomNameNotRepeatValid = () => {
        return !room.rooms.find(element => (element.name === roomName && room_id !== element.id));
    }

    const determineErrorMessage = () => {
        if (!isRoomNameLengthValid()) {
            setRoomNameErrorMessage("請輸入1~10字的新增琴房名稱!");
        } else if (!isRoomNameNotRepeatValid()) {
            setRoomNameErrorMessage("預約琴房名稱已經存在!");
        }
    }


    return (
        <div className="pageContainer">
            <Container>
                <Row>
                    <Col>
                        <h2>新增琴房</h2>
                    </Col>
                </Row>
                <Row className="justify-content-md-center">
                    <Col md='12'>
                        <Form onSubmit={handleSubmit}>

                            {/* roomName form */}
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label className="text-align-left" column sm="2">琴房名稱:</Form.Label>
                                <Col sm="2">
                                    <Form.Control
                                        required
                                        column
                                        as="input"
                                        value={roomName}
                                        onChange={e => {
                                            setRoomName(e.target.value);
                                            determineErrorMessage();
                                        }}
                                        isValid={isRoomNameValid() && validated}
                                        isInvalid={!isRoomNameValid() && validated}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {roomNameErrorMessage}
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>


                            {/* roomType form */}
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label className="text-align-left" column md={{ span: 2 }}>琴房類別: </Form.Label>
                                <Col sm="2">
                                    <Form.Select
                                        value={roomType ? roomType.name : ""}
                                        onChange={e => setRoomType(room.roomTypes.find(element => element.name === e.target.value))}>
                                        {room.roomTypes.map(
                                            (roomType, i) =>
                                                <option key={i}>{roomType.name}</option>
                                        )}
                                    </Form.Select>
                                </Col>
                            </Form.Group>


                            {/* restrict form */}
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label className="text-align-left" column sm="2">
                                    限制:
                                </Form.Label>
                                <Col sm="2">
                                    <div key={"restrict-radio"} className="mb-3">
                                        {restrictOptions.map(
                                            (option, i) =>
                                                <Form.Check
                                                    inline
                                                    checked={restrict.value === option.value}
                                                    label={option.name}
                                                    name="restrict-radio"
                                                    type="radio"
                                                    id={`restrict-radio-${i}`}
                                                    onChange={() => setRestrict(option)}
                                                />
                                        )}
                                    </div>
                                </Col>
                                <ConditionalContainer condition={restrict.value === "identity"}>
                                    <Form.Label className="text-align-left" column md={{ span: 1, offset: 1 }}>可預約身分: </Form.Label>
                                    <Col sm="3">
                                        {(user.profile.identity === userIdentity.admin_s) ?
                                            <Form.Select
                                                value={identityRestrict ? identityRestrict.name : ""}
                                                onChange={e => setIdentityRestrict(identityRestrictOptionsAdminS.find(element => element.name === e.target.value))}>
                                                {identityRestrictOptionsAdminS.map((option, i) =>
                                                    <option key={i}>{option.name}</option>
                                                )}

                                            </Form.Select> :
                                            <Form.Select
                                                value={identityRestrict ? identityRestrict.name : ""}
                                                onChange={e => setIdentityRestrict(identityRestrictOptions.find(element => element.name === e.target.value))}>
                                                {identityRestrictOptions.map((option, i) =>
                                                    <option key={i}>{option.name}</option>
                                                )}

                                            </Form.Select>}
                                    </Col>
                                </ConditionalContainer>
                                <ConditionalContainer condition={restrict.value === "type"}>
                                    <Form.Label className="text-align-left" column md={{ span: 1, offset: 1 }}>條目: </Form.Label>
                                    <Col sm="2">
                                        <Form.Select
                                            value={typeRestrict.name}
                                            onChange={e => setTypeRestrict(type.types.find(element => element.name === e.target.value))}>
                                            {type.types.map(
                                                (option, i) =>
                                                    <option key={i}>{option.name}</option>
                                            )}
                                        </Form.Select>
                                    </Col>
                                    <Form.Label className="text-align-left" column md={{ span: 1 }}>類組: </Form.Label>
                                    <Col sm="2">
                                        <Form.Select
                                            value={subtypeRestrict}
                                            onChange={e => setSubtypeRestrict(e.target.value)}>
                                            {typeRestrict.subtype.map(
                                                (option, i) =>
                                                    <option key={i}>{option}</option>
                                            )}
                                        </Form.Select>
                                    </Col>
                                </ConditionalContainer>
                            </Form.Group>


                            {/* distinguishWeekdayHoliday rodio form */}
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label className="text-align-left" column sm="2">
                                    預約設定:
                                </Form.Label>
                                <Col sm="3">
                                    <div key={"distinguishWeekdayHoliday-radio"} className="mb-3">
                                        {distinguishWeekdayHolidayOptions.map(
                                            (option, i) =>
                                                <Form.Check
                                                    inline
                                                    checked={distinguishWeekdayHoliday.value === option.value}
                                                    label={option.name}
                                                    name="distinguishWeekdayHoliday-radio"
                                                    type="radio"
                                                    id={`distinguishWeekdayHoliday-radio-${i}`}
                                                    onChange={() => setDistinguishWeekdayHoliday(option)}
                                                />
                                        )}
                                    </div>
                                </Col>
                            </Form.Group>


                            <Form.Group as={Row} className="mb-3">
                                <Form.Label className="text-align-left" column md={{ span: 2, offset: 1 }}>{distinguishWeekdayHoliday.value ? "平日" : ""}預約種類: </Form.Label>
                                <Col sm="2">
                                    <Form.Select
                                        value={weekdayReservationType.name}
                                        onChange={e => setWeekdayReservationType(reservation.reservationTypes.find(element => element.name === e.target.value))}>
                                        {reservation.reservationTypes.map(
                                            (option, i) =>
                                                <option key={i}>{option.name}</option>
                                        )}
                                    </Form.Select>
                                </Col>
                            </Form.Group>
                            {[...Array(weekdayReservationType.time_period).keys()].map(
                                (i) =>
                                    <div><Form.Group as={Row} className="mb-3 justify-content-center">
                                        <Form.Label className="text-align-left" column md={{ span: 2, offset: 1 }}>　時段{timePeriodOptions[i].name}開始使用時間: </Form.Label>
                                        <Col>
                                            <Form.Control
                                                required
                                                column
                                                as="input"
                                                type="number"
                                                value={getMonthFromSecond(weekdayAllowReserveList[i][0])}
                                                onChange={e => {
                                                    let newArr = [...weekdayAllowReserveList];
                                                    newArr[i][0] = weekdayAllowReserveList[i][0] + (e.target.value - getMonthFromSecond(weekdayAllowReserveList[i][0])) * 86400 * 31;
                                                    setWeekdayAllowReserveList(newArr);
                                                }}
                                            />
                                        </Col>
                                        <Form.Label column>月</Form.Label>
                                        <Col>
                                            <Form.Control
                                                required
                                                column
                                                as="input"
                                                type="number"
                                                value={getWeekFromSecond(weekdayAllowReserveList[i][0])}
                                                onChange={e => {
                                                    let newArr = [...weekdayAllowReserveList];
                                                    newArr[i][0] = weekdayAllowReserveList[i][0] + (e.target.value - getWeekFromSecond(weekdayAllowReserveList[i][0])) * 86400 * 7;
                                                    setWeekdayAllowReserveList(newArr);
                                                }}
                                            />
                                        </Col>
                                        <Form.Label column>週</Form.Label>
                                        <Col>
                                            <Form.Control
                                                required
                                                column
                                                as="input"
                                                type="number"
                                                value={getDayFromSecond(weekdayAllowReserveList[i][0])}
                                                onChange={e => {
                                                    let newArr = [...weekdayAllowReserveList];
                                                    newArr[i][0] = weekdayAllowReserveList[i][0] + (e.target.value - getDayFromSecond(weekdayAllowReserveList[i][0])) * 86400;
                                                    setWeekdayAllowReserveList(newArr);
                                                }}
                                            />
                                        </Col>
                                        <Form.Label column>日</Form.Label>
                                        <Col>
                                            <Form.Control
                                                required
                                                column
                                                as="input"
                                                type="number"
                                                value={getHourFromSecond(weekdayAllowReserveList[i][0])}
                                                onChange={e => {
                                                    let newArr = [...weekdayAllowReserveList];
                                                    newArr[i][0] = weekdayAllowReserveList[i][0] + (e.target.value - getHourFromSecond(weekdayAllowReserveList[i][0])) * 3600;
                                                    setWeekdayAllowReserveList(newArr);
                                                }}
                                            />
                                        </Col>
                                        <Form.Label column>時</Form.Label>
                                        <Col>
                                            <Form.Control
                                                required
                                                column
                                                as="input"
                                                type="number"
                                                value={getMinuteFromSecond(weekdayAllowReserveList[i][0])}
                                                onChange={e => {
                                                    let newArr = [...weekdayAllowReserveList];
                                                    newArr[i][0] = weekdayAllowReserveList[i][0] + (e.target.value - getMinuteFromSecond(weekdayAllowReserveList[i][0])) * 60;
                                                    setWeekdayAllowReserveList(newArr);
                                                }}
                                            />
                                        </Col>
                                        <Form.Label column>分</Form.Label>

                                    </Form.Group>
                                        <Form.Group as={Row} className="mb-3 justify-content-center">
                                            <Form.Label className="text-align-left" column md={{ span: 2, offset: 1 }}>　時段{timePeriodOptions[i].name}結束使用時間: </Form.Label>
                                            <Col>
                                                <Form.Control
                                                    required
                                                    column
                                                    as="input"
                                                    type="number"
                                                    value={getMonthFromSecond(weekdayAllowReserveList[i][1])}
                                                    onChange={e => {
                                                        let newArr = [...weekdayAllowReserveList];
                                                        newArr[i][1] = weekdayAllowReserveList[i][1] + (e.target.value - getMonthFromSecond(weekdayAllowReserveList[i][1])) * 86400 * 31;
                                                        setWeekdayAllowReserveList(newArr);
                                                    }}
                                                />
                                            </Col>
                                            <Form.Label column>月</Form.Label>
                                            <Col>
                                                <Form.Control
                                                    required
                                                    column
                                                    as="input"
                                                    type="number"
                                                    value={getWeekFromSecond(weekdayAllowReserveList[i][1])}
                                                    onChange={e => {
                                                        let newArr = [...weekdayAllowReserveList];
                                                        newArr[i][1] = weekdayAllowReserveList[i][1] + (e.target.value - getWeekFromSecond(weekdayAllowReserveList[i][1])) * 86400 * 7;
                                                        setWeekdayAllowReserveList(newArr);
                                                    }}
                                                />
                                            </Col>
                                            <Form.Label column>週</Form.Label>
                                            <Col>
                                                <Form.Control
                                                    required
                                                    column
                                                    as="input"
                                                    type="number"
                                                    value={getDayFromSecond(weekdayAllowReserveList[i][1])}
                                                    onChange={e => {
                                                        let newArr = [...weekdayAllowReserveList];
                                                        newArr[i][1] = weekdayAllowReserveList[i][1] + (e.target.value - getDayFromSecond(weekdayAllowReserveList[i][1])) * 86400;
                                                        setWeekdayAllowReserveList(newArr);
                                                    }}
                                                />
                                            </Col>
                                            <Form.Label column>日</Form.Label>
                                            <Col>
                                                <Form.Control
                                                    required
                                                    column
                                                    as="input"
                                                    type="number"
                                                    value={getHourFromSecond(weekdayAllowReserveList[i][1])}
                                                    onChange={e => {
                                                        let newArr = [...weekdayAllowReserveList];
                                                        newArr[i][1] = weekdayAllowReserveList[i][1] + (e.target.value - getHourFromSecond(weekdayAllowReserveList[i][1])) * 3600;
                                                        setWeekdayAllowReserveList(newArr);
                                                    }}
                                                />
                                            </Col>
                                            <Form.Label column>時</Form.Label>
                                            <Col>
                                                <Form.Control
                                                    required
                                                    column
                                                    as="input"
                                                    type="number"
                                                    value={getMinuteFromSecond(weekdayAllowReserveList[i][1])}
                                                    onChange={e => {
                                                        let newArr = [...weekdayAllowReserveList];
                                                        newArr[i][1] = weekdayAllowReserveList[i][1] + (e.target.value - getMinuteFromSecond(weekdayAllowReserveList[i][1])) * 60;
                                                        setWeekdayAllowReserveList(newArr);
                                                    }}
                                                />
                                            </Col>
                                            <Form.Label column>分</Form.Label>
                                        </Form.Group>
                                    </div>
                            )}
                            <ConditionalContainer condition={distinguishWeekdayHoliday.value}>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="text-align-left" column md={{ span: 2, offset: 1 }}>假日預約種類: </Form.Label>
                                    <Col sm="2">
                                        <Form.Select
                                            value={holidayReservationType.name}
                                            onChange={e => setHolidayReservationType(reservation.reservationTypes.find(element => element.name === e.target.value))}>
                                            {reservation.reservationTypes.map(
                                                (option, i) =>
                                                    <option key={i}>{option.name}</option>
                                            )}
                                        </Form.Select>
                                    </Col>
                                </Form.Group>
                                {[...Array(holidayReservationType.time_period).keys()].map(
                                    (i) =>
                                        <div><Form.Group as={Row} className="mb-3 justify-content-center">
                                            <Form.Label className="text-align-left" column md={{ span: 2, offset: 1 }}>　時段{timePeriodOptions[i].name}開始使用時間: </Form.Label>
                                            <Col>
                                                <Form.Control
                                                    required
                                                    column
                                                    as="input"
                                                    type="number"
                                                    value={getMonthFromSecond(holidayAllowReserveList[i][0])}
                                                    onChange={e => {
                                                        let newArr = [...holidayAllowReserveList];
                                                        newArr[i][0] = holidayAllowReserveList[i][0] + (e.target.value - getMonthFromSecond(holidayAllowReserveList[i][0])) * 86400 * 31;
                                                        setHolidayAllowReserveList(newArr);
                                                    }}
                                                />
                                            </Col>
                                            <Form.Label column>月</Form.Label>
                                            <Col>
                                                <Form.Control
                                                    required
                                                    column
                                                    as="input"
                                                    type="number"
                                                    value={getWeekFromSecond(holidayAllowReserveList[i][0])}
                                                    onChange={e => {
                                                        let newArr = [...holidayAllowReserveList];
                                                        newArr[i][0] = holidayAllowReserveList[i][0] + (e.target.value - getWeekFromSecond(holidayAllowReserveList[i][0])) * 86400 * 7;
                                                        setHolidayAllowReserveList(newArr);
                                                    }}
                                                />
                                            </Col>
                                            <Form.Label column>週</Form.Label>
                                            <Col>
                                                <Form.Control
                                                    required
                                                    column
                                                    as="input"
                                                    type="number"
                                                    value={getDayFromSecond(holidayAllowReserveList[i][0])}
                                                    onChange={e => {
                                                        let newArr = [...holidayAllowReserveList];
                                                        newArr[i][0] = holidayAllowReserveList[i][0] + (e.target.value - getDayFromSecond(holidayAllowReserveList[i][0])) * 86400;
                                                        setHolidayAllowReserveList(newArr);
                                                    }}
                                                />
                                            </Col>
                                            <Form.Label column>日</Form.Label>
                                            <Col>
                                                <Form.Control
                                                    required
                                                    column
                                                    as="input"
                                                    type="number"
                                                    value={getHourFromSecond(holidayAllowReserveList[i][0])}
                                                    onChange={e => {
                                                        let newArr = [...holidayAllowReserveList];
                                                        newArr[i][0] = holidayAllowReserveList[i][0] + (e.target.value - getHourFromSecond(holidayAllowReserveList[i][0])) * 3600;
                                                        setHolidayAllowReserveList(newArr);
                                                    }}
                                                />
                                            </Col>
                                            <Form.Label column>時</Form.Label>
                                            <Col>
                                                <Form.Control
                                                    required
                                                    column
                                                    as="input"
                                                    type="number"
                                                    value={getMinuteFromSecond(holidayAllowReserveList[i][0])}
                                                    onChange={e => {
                                                        let newArr = [...holidayAllowReserveList];
                                                        newArr[i][0] = holidayAllowReserveList[i][0] + (e.target.value - getMinuteFromSecond(holidayAllowReserveList[i][0])) * 60;
                                                        setHolidayAllowReserveList(newArr);
                                                    }}
                                                />
                                            </Col>
                                            <Form.Label column>分</Form.Label>

                                        </Form.Group>
                                            <Form.Group as={Row} className="mb-3 justify-content-center">
                                                <Form.Label className="text-align-left" column md={{ span: 2, offset: 1 }}>　時段{timePeriodOptions[i].name}結束使用時間: </Form.Label>
                                                <Col>
                                                    <Form.Control
                                                        required
                                                        column
                                                        as="input"
                                                        type="number"
                                                        value={getMonthFromSecond(holidayAllowReserveList[i][1])}
                                                        onChange={e => {
                                                            let newArr = [...holidayAllowReserveList];
                                                            newArr[i][1] = holidayAllowReserveList[i][1] + (e.target.value - getMonthFromSecond(holidayAllowReserveList[i][1])) * 86400 * 31;
                                                            setHolidayAllowReserveList(newArr);
                                                        }}
                                                    />
                                                </Col>
                                                <Form.Label column>月</Form.Label>
                                                <Col>
                                                    <Form.Control
                                                        required
                                                        column
                                                        as="input"
                                                        type="number"
                                                        value={getWeekFromSecond(holidayAllowReserveList[i][1])}
                                                        onChange={e => {
                                                            let newArr = [...holidayAllowReserveList];
                                                            newArr[i][1] = holidayAllowReserveList[i][1] + (e.target.value - getWeekFromSecond(holidayAllowReserveList[i][1])) * 86400 * 7;
                                                            setHolidayAllowReserveList(newArr);
                                                        }}
                                                    />
                                                </Col>
                                                <Form.Label column>週</Form.Label>
                                                <Col>
                                                    <Form.Control
                                                        required
                                                        column
                                                        as="input"
                                                        type="number"
                                                        value={getDayFromSecond(holidayAllowReserveList[i][1])}
                                                        onChange={e => {
                                                            let newArr = [...holidayAllowReserveList];
                                                            newArr[i][1] = holidayAllowReserveList[i][1] + (e.target.value - getDayFromSecond(holidayAllowReserveList[i][1])) * 86400;
                                                            setHolidayAllowReserveList(newArr);
                                                        }}
                                                    />
                                                </Col>
                                                <Form.Label column>日</Form.Label>
                                                <Col>
                                                    <Form.Control
                                                        required
                                                        column
                                                        as="input"
                                                        type="number"
                                                        value={getHourFromSecond(holidayAllowReserveList[i][1])}
                                                        onChange={e => {
                                                            let newArr = [...holidayAllowReserveList];
                                                            newArr[i][1] = holidayAllowReserveList[i][1] + (e.target.value - getHourFromSecond(holidayAllowReserveList[i][1])) * 3600;
                                                            setHolidayAllowReserveList(newArr);
                                                        }}
                                                    />
                                                </Col>
                                                <Form.Label column>時</Form.Label>
                                                <Col>
                                                    <Form.Control
                                                        required
                                                        column
                                                        as="input"
                                                        type="number"
                                                        value={getMinuteFromSecond(holidayAllowReserveList[i][1])}
                                                        onChange={e => {
                                                            let newArr = [...holidayAllowReserveList];
                                                            newArr[i][1] = holidayAllowReserveList[i][1] + (e.target.value - getMinuteFromSecond(holidayAllowReserveList[i][1])) * 60;
                                                            setHolidayAllowReserveList(newArr);
                                                        }}
                                                    />
                                                </Col>
                                                <Form.Label column>分</Form.Label>
                                            </Form.Group>
                                        </div>
                                )}
                            </ConditionalContainer>
                            <Row>
                                <Col>
                                    <Button size='lg' type="submit">{room_id ? "編輯" : "新增"}</Button>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

