import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Container, Button, Table, NavDropdown, Nav, Accordion, Badge, Tabs, Tab, Card, Form } from "react-bootstrap";
import { useHistory, useRouteMatch } from 'react-router';
import { LinkContainer } from 'react-router-bootstrap';


import './StudentSongPage.css';

import store from '../../redux/store';
import { fetchAllTeachers, fetchAllTeachingTypes } from '../../redux/teacher/teacherThunk';
import { fetchAllSongsByStudentId } from '../../redux/song/songThunk';
import { fetchAllEvents } from '../../redux/event/eventThunk';
import StudentSongEditDialog from './dialogs/StudentSongEditDialog';
import { getTimeFormatString, getSongEventName } from '../../utils/utils';
import LoadingContainer from '../../components/LoadingContainer/LoadingContainer';


export default function StudentSongPage() {

    const dispatch = useDispatch();
    const { path, url } = useRouteMatch();

    const user = useSelector(state => state.user);
    const song = useSelector(state => state.song);
    const teacher = useSelector(state => state.teacher);
    const event = useSelector(state => state.event);



    const [teachingType, setTeachingType] = useState("全部");

    const [filteredTeacherList, setFilteredTeacherList] = useState([]);
    const [filteredSongList, setFilteredSongList] = useState([]);

    const [showEditDialog, setShowEditDialog] = useState(false);
    const [selectedSong, setSelectedSong] = useState({});





    useEffect(() => {
        dispatch(fetchAllTeachers({ token: user.token }));
        dispatch(fetchAllTeachingTypes({ token: user.token }));
        dispatch(fetchAllSongsByStudentId({ token: user.token, student_id: user.profile.student_id }));
    }, [])


    useEffect(() => {
        if (teacher.teachingTypes.length !== 0) {
            dispatch(fetchAllEvents({ token: user.token }));
        }
    }, [teacher])


    useEffect(() => {
        filterLists();
    }, [teacher, song])



    const filterLists = () => {
        setFilteredTeacherList(teacher.teachers.filter(teacher => teacher.teaching === teachingType || teachingType === "全部"))
        setFilteredSongList(
            song.songs.filter(
                song => {
                    const findedTeacher = teacher.teachers.find(teacher => teacher.id === song.teacher_id);
                    if (!findedTeacher) {
                        return false;
                    }
                    return (findedTeacher.teaching === teachingType || teachingType === "全部");
                }
            )
        );
    }




    return (
        <div className="pageContainer">
            <Container>
                <Row>
                    <Col>
                        <h2>曲目上傳</h2>
                    </Col>
                </Row>
                <Row>
                    <Col md="4">
                        <Card>
                            <Card.Body>
                                <Card.Title><h3>搜尋</h3></Card.Title>
                                <Card.Text>選擇授課領域</Card.Text>
                                <Form.Select value={teachingType} onChange={e => setTeachingType(e.target.value)}>
                                    <option key={-1}>全部</option>
                                    {teacher.teachingTypes.map(
                                        (type, i) =>
                                            <option key={i}>{type.name}</option>
                                    )}
                                </Form.Select>
                                <Button variant="primary" onClick={filterLists}>搜尋</Button>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md="8">
                        <Tabs defaultActiveKey="uploadSong" id="uncontrolled-tab-example" fill>
                            <Tab eventKey="uploadSong" title="上傳曲目">
                                <LoadingContainer isLoading={teacher.isFetching}>
                                    <div className="teacher-table-container">
                                        <Table striped bordered hover responsive>
                                            <thead>
                                                <tr>
                                                    <th>教師</th>
                                                    <th>授課領域</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {filteredTeacherList.map(
                                                    (teacher, i) =>
                                                        <LinkContainer to={`${path}/studentsongformpage?teacher_id=${teacher.id}`}>
                                                            <tr key={teacher.id} className='cursor-pointer'>
                                                                <td>{teacher.name}</td>
                                                                <td>{teacher.teaching}</td>
                                                            </tr>
                                                        </LinkContainer>
                                                )}

                                            </tbody>
                                        </Table>
                                    </div>
                                </LoadingContainer>
                            </Tab>
                            <Tab eventKey="manageSong" title="已上傳曲目">
                                <LoadingContainer isLoading={event.isFetching}>
                                    <div className="song-table-container">
                                        <Table striped bordered hover responsive>
                                            <thead>
                                                <tr>
                                                    <th>活動名稱</th>
                                                    <th>上傳時間</th>
                                                    <th>狀態</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {filteredSongList.map(
                                                    (song, i) =>
                                                        <tr key={song.id} className='cursor-pointer' onClick={() => { setSelectedSong(song); setShowEditDialog(true); }}>
                                                            <td>{getSongEventName(song, event.events)}</td>
                                                            <td>{getTimeFormatString(song.upload_time)}</td>
                                                            <td>{song.status}</td>
                                                        </tr>
                                                )}

                                            </tbody>
                                        </Table>
                                    </div>
                                </LoadingContainer>
                            </Tab>
                        </Tabs>
                    </Col>
                </Row>
            </Container>
            <StudentSongEditDialog
                show={showEditDialog}
                song={selectedSong}
                teacher={teacher.teachers.find(teacher => teacher.id === selectedSong.teacher_id)}
                event={event.events.find(event => event.id === selectedSong.event)}
                onClose={() => setShowEditDialog(false)}
                onDelete={() => { }} />
        </div >
    );
}

