import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { Modal, Button } from "react-bootstrap"
import { deleteReservations } from '../../../redux/reservation/reservationThunk';
import moment from 'moment';
import { getChineseWeekday } from '../../../utils/utils';


export default function DeleteReservationDialog({ reservation, show, onClose, onConfirm }) {
    const dispatch = useDispatch();

    const user = useSelector(state => state.user);

    const [showState, setShow] = useState(show);

    const [roomName, setRoomName] = useState("");
    const [date, setDate] = useState(moment().startOf("day"));
    const [startTime, setStartTime] = useState(moment());
    const [endTime, setEndTime] = useState(moment());



    useEffect(() => {
        setShow(show);
    }, [show]);


    useEffect(() => {
        if (!reservation) {
            return;
        }
        setRoomName(reservation.room_name);
        setDate(moment(reservation.date * 1000).startOf("day"));
        setStartTime(moment(reservation.start_time * 1000));
        setEndTime(moment(reservation.end_time * 1000));
    }, [reservation]);


    const handleClose = () => {
        setShow(false);
        onClose();
    }

    const handleDelete = async () => {

        console.log(reservation.id)
        const result = await dispatch(deleteReservations({ token: user.token, reservation_id: reservation.id }));
        if (result.meta.requestStatus === "fulfilled") {
            onConfirm();
        }
        handleClose();
    }

    return (
        <Modal show={showState} >
            <Modal.Header>
                <Modal.Title>是否取消"{roomName}"於{date.format("YYYY/MM/DD")} {getChineseWeekday(date)} {startTime.format("HH:mm")}~{endTime.format("HH:mm")} 的預約?</Modal.Title>
            </Modal.Header>
            <Modal.Footer>
                <Button variant="danger" onClick={handleDelete}>
                    確認刪除
                </Button>
                <Button variant="primary" onClick={handleClose}>
                    取消
                </Button>
            </Modal.Footer>
        </Modal>
    );
}