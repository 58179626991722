import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { Modal, Button, Row, Col } from "react-bootstrap"
import { deleteRoom } from '../../../redux/room/roomThunk';
import moment from 'moment';


export default function DeleteRoomDialog({ reservation, show, onToHome, onToMyReservation }) {
    const dispatch = useDispatch();

    const user = useSelector(state => state.user);


    useEffect(() => {
        setShow(show);
    }, [show]);

    const [showState, setShow] = useState(show);

    const handleToHome = () => {
        setShow(false);
        onToHome();
    }

    const handleToMyReservation = () => {
        setShow(false);
        onToMyReservation();
    }

    return (
        <Modal show={showState} >
            <Modal.Header>
                <Modal.Title>預約成功</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        預約者：{reservation ? reservation.name : ""}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        預約琴房：{reservation ? reservation.room_name : ""}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        日期：{reservation ? moment(reservation.date * 1000).format("YYYY/MM/DD") : ""}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        預約時段：{reservation ? `${moment(reservation.start_time * 1000).format("HH:mm")}至${moment(reservation.end_time * 1000).format("HH:mm")}` : ""}
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={handleToMyReservation}>
                    前往我的預約
                </Button>
                <Button variant="primary" onClick={handleToHome}>
                    回首頁
                </Button>
            </Modal.Footer>
        </Modal>
    );
}