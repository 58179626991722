import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Container, Button, Table, NavDropdown, Nav, Accordion, Badge, Tabs, Tab, Card, Form } from "react-bootstrap";
import { useHistory, useRouteMatch } from 'react-router';
import { LinkContainer } from 'react-router-bootstrap';


import './ReservationTypeManagePage.css';

import store from '../../redux/store';
import { fetchAllReservationTypes, updateAllowAllReserve } from '../../redux/reservation/reservationThunk';
import { getReservationTypeCycleString, getReservationTypeEndTimeString, getReservationTypeRestrictsString, getReservationTypeStartTimeString } from '../../utils/utils';
import DeleteRerservationTypeDialog from './dialogs/DeleteRerservationTypeDialog';
import { roomTypeRestricts } from '../../redux/room/roomSlice';
import { fetchAllRooms } from '../../redux/room/roomThunk';
import ConditionalContainer from '../../components/ConditionalContainer';
import { fetchSystemInfo } from '../../redux/system/systemThunk';


export default function ReservationTypeManagePage() {

    const dispatch = useDispatch();
    const { path, url } = useRouteMatch();

    const user = useSelector(state => state.user);
    const room = useSelector(state => state.room);
    const reservation = useSelector(state => state.reservation);
    const system = useSelector(state => state.system);


    const [reservationSwitch, setReservationSwitch] = useState(false);
    const [selectedRervationType, setSelectedRervationType] = useState(null);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);



    useEffect(async () => {
        dispatch(fetchAllReservationTypes({ token: user.token }));
        dispatch(fetchAllRooms({ token: user.token }));
        dispatch(fetchSystemInfo());
    }, []);


    useEffect(() => {
        if (!system.info) {
            return;
        }
        if (system.info.allow_all_reserve !== false && system.info.allow_all_reserve !== true) {
            return;
        }
        setReservationSwitch(system.info.allow_all_reserve);
    }, [system.info]);



    const handleReservationSwitch = (event) => {
        setReservationSwitch(event.target.checked);
        dispatch(updateAllowAllReserve({ token: user.token, status: event.target.checked }));
    }

    const countRoom = (reservationType) => {
        let count = 0;
        room.rooms.forEach((room) => {
            if (room.reserve_type.weekday.type === reservationType.name || room.reserve_type.holiday.type === reservationType.name) {
                count++;
            }
        });
        return count;
    }



    return (
        <div className="pageContainer">
            <Container>
                <Row>
                    <Col md={2}>
                        <Form>
                            {user.profile.identity === 'admin_s' ?
                                <Form.Check
                                    disabled
                                    type="switch"
                                    id="reservation-switch"
                                    label="開/關所有使用者預約"
                                    checked={reservationSwitch}
                                    onChange={handleReservationSwitch}
                                /> :
                                <Form.Check
                                    type="switch"
                                    id="reservation-switch"
                                    label="開/關所有使用者預約"
                                    checked={reservationSwitch}
                                    onChange={handleReservationSwitch}
                                />
                            }
                        </Form>
                    </Col>
                    <Col md={{ span: 8 }}>
                        <h2>預約設置</h2>
                    </Col>
                    <Col md={2}>
                        {user.profile.identity === 'admin_s' ?
                            <LinkContainer disabled to={`${path}/reservationtypeformpage`}>
                                <Button size='lg'>新增預約種類</Button>
                            </LinkContainer> :
                            <LinkContainer  to={`${path}/reservationtypeformpage`}>
                                <Button size='lg'>新增預約種類</Button>
                            </LinkContainer>
                        }
                    </Col>

                </Row>
                <Row>
                    <Col>
                        <div className="song-table-container">
                            <Table striped bordered responsive>
                                <thead>
                                    <tr>
                                        <th>預約種類名稱</th>
                                        <th>週期</th>
                                        <th>時段</th>
                                        <th>開始預約</th>
                                        <th>結束預約</th>
                                        <th>預約時數上限</th>
                                        <th>屬於該種類的琴房數量</th>
                                        <th>修改</th>
                                        <th>刪除</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {reservation.reservationTypes.map(
                                        (reservationType, i) =>
                                            <tr key={reservationType.id}>
                                                <td>{reservationType.name}</td>
                                                <td>{getReservationTypeCycleString(reservationType)}</td>
                                                <td>{reservationType.time_period}</td>
                                                <td style={{ 'whiteSpace': 'pre-wrap', 'fontSize': 9 }}>{getReservationTypeStartTimeString(reservationType)}</td>
                                                <td style={{ 'whiteSpace': 'pre-wrap', 'fontSize': 9 }}>{getReservationTypeEndTimeString(reservationType)}</td>
                                                <td>{getReservationTypeRestrictsString(reservationType)}</td>
                                                <td>{countRoom(reservationType)}</td>
                                                <td>
                                                    {user.profile.identity === 'admin_s' ?
                                                        <LinkContainer disabled to={`${path}/reservationtypeformpage?reservationType_id=${reservationType.id}`}>
                                                            <Button>修改</Button>
                                                        </LinkContainer> :
                                                        <LinkContainer to={`${path}/reservationtypeformpage?reservationType_id=${reservationType.id}`}>
                                                            <Button>修改</Button>
                                                        </LinkContainer>
                                                    }
                                                </td>

                                                <td>
                                                    <ConditionalContainer condition={countRoom(reservationType) === 0}>
                                                        {user.profile.identity === 'admin_s' ?
                                                            <Button
                                                                disabled
                                                                variant="danger"
                                                                onClick={() => {
                                                                    setShowDeleteDialog(true);
                                                                    setSelectedRervationType(reservationType
                                                                    )
                                                                }}>
                                                                刪除
                                                            </Button> :
                                                            <Button
                                                                variant="danger"
                                                                onClick={() => {
                                                                    setShowDeleteDialog(true);
                                                                    setSelectedRervationType(reservationType
                                                                    )
                                                                }}>
                                                                刪除
                                                            </Button>
                                                        }
                                                    </ConditionalContainer>
                                                </td>
                                            </tr>
                                    )}

                                </tbody>
                            </Table>
                        </div>
                    </Col>
                </Row>
            </Container>
            {/* <StudentSongEditDialog
                show={showEditDialog}
                song={selectedSong}
                teacher={teacher.teachers.find(teacher => teacher.id === selectedSong.teacher_id)}
                event={event.events.find(event => event.id === selectedSong.event)}
                onClose={() => setShowEditDialog(false)}
                onDelete={() => { }} /> */}
            <DeleteRerservationTypeDialog
                show={showDeleteDialog}
                reservationType={selectedRervationType}
                onClose={() => setShowDeleteDialog(false)}
                onDelete={() => { }}
            />
        </div>
    );
}

