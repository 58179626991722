import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Container, Button, Table, NavDropdown, Nav, Accordion, Badge, Tabs, Tab, Card, Form } from "react-bootstrap";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { get24hrToInt } from '../../utils/utils';
import { fetchReservationAvaliable } from '../../redux/reservation/reservationThunk';
import { fetchAllRooms } from '../../redux/room/roomThunk';


import './AdminReservationSearchPanel.css'

export default function AdminReservationSearchPanel({ onSearch, onClear }) {

    const dispatch = useDispatch();

    const user = useSelector(state => state.user);
    const room = useSelector(state => state.room);

    const [beginDate, setBeginDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [roomName, setRoomName] = useState("全部");


    useEffect(() => {
        dispatch(fetchAllRooms({ token: user.token }));
    }, [])


    const handleSearch = (event) => {

        let params = {
            beginDate: beginDate,
            endDate: endDate,
        };
        params.beginDate.setHours(0, 0, 0);
        params.endDate.setHours(0, 0, 1);

        if (roomName !== '全部') {
            params.room = room.rooms.find(element => element.name === roomName);
        }

        if (onSearch) {
            onSearch(params);
        }
    }

    const handleCancel = (event) => {
        if (onClear) {
            onClear();
        }
    }

    return (
        <Card>
            <Card.Body>
                <Card.Title><h3>搜尋</h3></Card.Title>
                <Row className="justify-content-center">
                    <Col lg={1}>
                        <Card.Text>選擇時間</Card.Text>
                    </Col>
                    <Col lg={2}>
                        <Card.Text className="">開始日期</Card.Text>
                    </Col>
                    <Col lg={3}>
                        <DatePicker selected={beginDate} onChange={(date) => setBeginDate(date)} />
                    </Col>
                    <Col lg={2}>
                        <Card.Text className="">結束日期</Card.Text>
                    </Col>
                    <Col lg={3}>
                        <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} />
                    </Col>
                </Row>
                <br />
                <Row className="justify-content-center">
                    <Col lg={1}>
                        <Card.Text>選擇琴房</Card.Text>
                    </Col>
                    <Col lg={{ offset: 1, span: 2 }}>
                        <Form.Select value={roomName} onChange={e => setRoomName(e.target.value)}>
                            <option key={-1}>全部</option>
                            {room.rooms.map(
                                (room, i) =>
                                    <option key={i}>{room.name}</option>
                            )}
                        </Form.Select>
                    </Col>
                    <Col md={7}>
                    </Col>
                </Row>
                <br />
                <Button variant="primary" onClick={handleSearch}>搜尋</Button>
                <Button variant="secondary" onClick={handleCancel}>取消搜尋</Button>
            </Card.Body>
        </Card>
    )

}