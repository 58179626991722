import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Container, Button, Table, NavDropdown, Nav, Accordion, Badge } from "react-bootstrap"
import { createThunkMiddleware } from '@reduxjs/toolkit'

import './RepairmentManagePage.css'
import { deleteRepairment, fetchAllRepairments, updateRepairmentStatus } from '../../redux/repairment/repairmentThunk'
import { repairmentStatus } from '../../redux/repairment/repairmentSlice'

import store from '../../redux/store'


export default function RepairmentManagePage() {

    const dispatch = useDispatch();

    const user = useSelector(state => state.user);
    const repairment = useSelector(state => state.repairment);

    console.log(repairment.repairments);

    useEffect(() => {
        dispatch(fetchAllRepairments({ token: user.token }));
    }, []);



    const buttonClickHandler = (repair) => {
        if(repair.status === repairmentStatus.pending){
            dispatch(updateRepairmentStatus({token: user.token, repairment_id: repair.id}))
        }else if(repair.status === repairmentStatus.handled){
            dispatch(deleteRepairment({token: user.token, repairment_id: repair.id}))
        }
    }




    return (
        <div className="pageContainer">
            <Container>
                <Row>
                    <Col>
                        <h2>報修情形</h2>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Table striped bordered responsive>
                            <thead>
                                <tr>
                                    <th>學號/授課領域</th>
                                    <th>報修者</th>
                                    <th>地點</th>
                                    <th>報修內容</th>
                                    <th>狀態</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {repairment.repairments.map(
                                    (repair, i) =>
                                        <tr key={i}>
                                            <td>{repair.student_id ? repair.student_id : repair.teaching}</td>
                                            <td>{repair.name}</td>
                                            <td>{repair.location}</td>
                                            <td>{repair.detail}</td>
                                            <td>{repair.status}</td>
                                            <td>
                                                <Button onClick={() => buttonClickHandler(repair)}>
                                                    {repair.status === repairmentStatus.pending ? "已處理" : "刪除紀錄"}
                                                </Button>
                                            </td>
                                        </tr>
                                )}

                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Container>
        </div >
    );
}

