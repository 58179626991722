import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import jsonwebtoken from 'jsonwebtoken';
import { userIdentity } from '../user/userSlice';

export const fetchAllUsers = createAsyncThunk(
    'userList/fetchAllUsers',
    async ({ token }, thunkAPI) => {

        if (!token) {
            return thunkAPI.rejectWithValue({ status: 0, data: { 'message': 'invalid token!' } });
        }
        try {
            const tokenData = jsonwebtoken.decode(token);
            const response = await axios.get(`/member/all`,
                {
                    headers: { "x-access-token": `token ${token}` }
                }
            );
            return response.data;

        } catch (error) {
            console.log("error", error.response)
            return thunkAPI.rejectWithValue({ status: error.response.status, data: error.response.data });
        }

    }
);

export const fetchAllTeachers = createAsyncThunk(
    'userList/fetchAllTeachers',
    async ({ token }, thunkAPI) => {

        if (!token) {
            return thunkAPI.rejectWithValue({ status: 0, data: { 'message': 'invalid token!' } });
        }
        try {
            const tokenData = jsonwebtoken.decode(token);
            const response = await axios.get(`/member?identity=teacher`,
                {
                    headers: { "x-access-token": `token ${token}` }
                }
            );
            return response.data;

        } catch (error) {
            console.log("error", error.response)
            return thunkAPI.rejectWithValue({ status: error.response.status, data: error.response.data });
        }

    }
);



export const fetchUserTimeLimits = createAsyncThunk(
    'userList/fetchUserTimeLimits',
    async ({ token }, thunkAPI) => {

        if (!token) {
            return thunkAPI.rejectWithValue({ status: 0, data: { 'message': 'invalid token!' } });
        }
        try {
            const tokenData = jsonwebtoken.decode(token);
            const response = await axios.get(`/reserveType/getUserHourLimit/all`,
                {
                    headers: { "x-access-token": `token ${token}` }
                }
            );
            return response.data;

        } catch (error) {
            console.log("error", error.response)
            return thunkAPI.rejectWithValue({ status: error.response.status, data: error.response.data });
        }

    }
);


export const addUserById = createAsyncThunk(
    'userList/addUserById',
    async ({ token, user_id }, thunkAPI) => {

        if (!token) {
            return thunkAPI.rejectWithValue({ status: 0, data: { 'message': 'invalid token!' } });
        }
        try {
            const response = await axios.get(`/member?user_id=${user_id}`,
                {
                    headers: { "x-access-token": `token ${token}` }
                }
            );
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue({ status: error.response.status, data: error.response.data });
        }

    }
);


export const registerUser = createAsyncThunk(
    'userList/registerUser',
    async ({ token, account, password, name, identity, student_id, teaching, types }, thunkAPI) => {

        if (!token) {
            return thunkAPI.rejectWithValue({ status: 0, data: { 'message': 'invalid token!' } });
        }

        const form = new FormData();
        form.append('account', account);
        form.append('name', name);
        form.append('password', password);
        form.append('identity', identity);
        if(identity === userIdentity.teacher){
            form.append('teaching', teaching);
        }else if(identity === userIdentity.student){
            form.append('student_id', student_id);
        }
        form.append('types', JSON.stringify(types).replaceAll("\"", "\'"));
        try {
            const response = await axios.post(`/member/register`,
                form,
                {
                    headers: { "x-access-token": `token ${token}` }
                }
            );
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue({ status: error.response.status, data: error.response.data });
        }

    }
);


export const deleteUserById = createAsyncThunk(
    'userList/deleteUserById',
    async ({ token, user_id }, thunkAPI) => {

        if (!token) {
            return thunkAPI.rejectWithValue({ status: 0, data: { 'message': 'invalid token!' } });
        }

        try {
            const response = await axios.delete(`/member/`,
                {
                    headers: { "x-access-token": `token ${token}` },
                    data: { user_id: user_id },
                }
            );
            return { id: user_id };
        } catch (error) {
            return thunkAPI.rejectWithValue({ status: error.response.status, data: error.response.data });
        }

    }
);



export const resetUserPasswordById = createAsyncThunk(
    'userList/resetUserPasswordById',
    async ({ token, user_id }, thunkAPI) => {

        if (!token) {
            return thunkAPI.rejectWithValue({ status: 0, data: { 'message': 'invalid token!' } });
        }

        const form = new FormData();
        form.append('user_id', user_id);
        try {
            const response = await axios.put(`/member/resetPassword`,
                form,
                {
                    headers: { "x-access-token": `token ${token}` }
                }
            );
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue({ status: error.response.status, data: error.response.data });
        }

    }
);


export const setUserNote = createAsyncThunk(
    'userList/setUserNote',
    async ({ token, user_id, notes }, thunkAPI) => {

        if (!token) {
            return thunkAPI.rejectWithValue({ status: 0, data: { 'message': 'invalid token!' } });
        }

        const form = new FormData();
        form.append('user_id', user_id);
        form.append('notes', notes);
        try {
            const response = await axios.put(`/member/notes`,
                form,
                {
                    headers: { "x-access-token": `token ${token}` }
                }
            );
            return {
                id: user_id,
                response: response.data,
                notes: notes
            };
        } catch (error) {
            return thunkAPI.rejectWithValue({ status: error.response.status, data: error.response.data });
        }

    }
);


export const setUserStatus = createAsyncThunk(
    'userList/setUserStatus',
    async ({ token, user_id, banned, banned_due }, thunkAPI) => {

        if (!token) {
            return thunkAPI.rejectWithValue({ status: 0, data: { 'message': 'invalid token!' } });
        }

        const form = new FormData();
        form.append('user_id', user_id);
        form.append('banned', banned ? 'True' : 'False');
        form.append('banned_due', banned ? banned_due : 0);
        try {
            const response = await axios.put(`/member/banned`,
                form,
                {
                    headers: { "x-access-token": `token ${token}` }
                }
            );
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue({ status: error.response.status, data: error.response.data });
        }

    }
);