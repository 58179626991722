import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { Modal, Button } from "react-bootstrap"
import { deleteUserById, resetUserPasswordById } from '../../../redux/userList/userListThunk';


export default function ResetUserPasswordDialog({ resetUserList, show, onClose, onConfirm }) {
    const dispatch = useDispatch();

    const user = useSelector(state => state.user);

    const [showState, setShow] = useState(show);


    useEffect(() => {
        setShow(show);
    }, [show]);

    useEffect(() => {
        const resetUserListCount = Object.keys(resetUserList).filter(element => resetUserList[element]).length;
        if (resetUserListCount === 0) {
            handleClose();
        }
    }, [resetUserList, show]);


    const handleClose = () => {
        setShow(false);
        onClose();
    }

    const handleResetPassword = () => {
        Object.keys(resetUserList).forEach((id) => {
            if (resetUserList[id]) {
                dispatch(resetUserPasswordById({ token: user.token, user_id: id }));
            }

        });
        onConfirm();
        handleClose();
    }

    const countUser = () => {
        let count = 0;
        Object.keys(resetUserList).forEach((id) => {
            if (resetUserList[id]) {
                count++;
            }
        })
        return count;
    }

    return (
        <Modal show={showState} >
            <Modal.Header>
                <Modal.Title>是否重設選取的{countUser()}位使用者密碼?</Modal.Title>
            </Modal.Header>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleResetPassword}>
                    確認重設
                </Button>
                <Button variant="primary" onClick={handleClose}>
                    取消
                </Button>
            </Modal.Footer>
        </Modal>);
}