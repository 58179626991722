import React, { useEffect, useState } from 'react';
import { Row, Col, Container, Image, Button, ListGroup } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import ConditionalContainer from '../../components/ConditionalContainer';

import { fetchSystemInfo } from '../../redux/system/systemThunk'
import { userIdentity } from '../../redux/user/userSlice';
import EditClassroomRegulationDialog from './dialogs/EditClassroomRegulationDialog';


export default function ClassroomRegulationPage() {

  const dispatch = useDispatch();

  const user = useSelector(state => state.user);
  const system = useSelector(state => state.system);


  const [showEditDialog, setShowEditDialog] = useState(false);



  return (
    <div className="pageContainer">
      <Container>
        <Row>
          <Col md={{ span: 8, offset: 2 }}>
            <h2>琴房管理辦法</h2>
          </Col>
          <ConditionalContainer condition={user.profile.identity === userIdentity.admin}>
            <Col md={2}><Button size='lg' onClick={() => setShowEditDialog(true)}>編輯</Button></Col>
          </ConditionalContainer>
        </Row>
        <Row className="justify-content-md-center">
          <Col md={10}>
            <p style={{ 'whiteSpace': 'pre-wrap', textAlign: 'start' }}>{system.info.room_rules}</p>
          </Col>
        </Row>
        <Row className="justify-content-md-center">
          <Col md={6}>
            {system.images.map((image, index) =>
              <Image src={image.url} fluid />
            )}
          </Col>
        </Row>
        <br />
        <Row className="justify-content-md-center">
          <Col md={10}>
            <ListGroup horizontal>
              {/* <Row> */}
              {system.files.map((file, index) =>
                // <Col md={4}>
                <ListGroup.Item key={index}>
                  <a href={file.url}>{file.url.split('/').pop()}</a>
                </ListGroup.Item>
                // </Col>
              )}
              {/* </Row> */}
            </ListGroup>
          </Col>
        </Row>
      </Container>
      <EditClassroomRegulationDialog show={showEditDialog} onConfirm={() => { }} onClose={() => setShowEditDialog(false)} />
    </div>
  );
}

