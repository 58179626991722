import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useRouteMatch,
    Redirect
} from "react-router-dom";
import { Row, Col, Container, Button, Form } from "react-bootstrap"
import { LinkContainer } from 'react-router-bootstrap'

import './RepairmentFormPage.css'
import { addNewRepairment } from '../../redux/repairment/repairmentThunk';
import RepairmentFormCompleteDialog from './RepairmentFormCompleteDialog';


export default function RepairmentFormPage() {
    const dispatch = useDispatch();

    const { path, url } = useRouteMatch();
    const user = useSelector(state => state.user);

    const [validated, setValidated] = useState(false);
    const [location, setLocation] = useState("");
    const [detail, setDetail] = useState("");

    const [showCompleteDialog, setShowCompleteDialog] = useState(false);



    const profile = user.profile;



    const handleSubmit = async (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false || !isLocationValid() || !isDetailValid()) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            event.preventDefault();
            await dispatch(addNewRepairment({ token: user.token, location: location, detail: detail }));
            setShowCompleteDialog(true);
        }

        setValidated(true);

    };

    const isLocationValid = () => {
        return location.length <= 50;
    }
    const isDetailValid = () => {
        return detail.length <= 100;
    }

    const resetForm = () => {
        setValidated(false);
        setLocation("");
        setDetail("");
        setShowCompleteDialog(false);
    }



    return (
        <div className="pageContainer">
            <Container>
                <Row>
                    <Col>
                        <h2>報修表單</h2>
                    </Col>
                </Row>
                <Row className="justify-content-md-center">
                    <Col md='8'>
                        <Form noValidate validated={validated} onSubmit={handleSubmit}>
                            <Row>
                                <Col className='form-col'>
                                    <p>學號: {profile.account}</p>
                                </Col>
                                <Col className='form-col'>
                                    <p>姓名: {profile.name}</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col className='form-col'>
                                    <Form.Label>地點:</Form.Label>
                                    <Form.Control
                                        required
                                        column
                                        as="input"
                                        placeholder="請輸入地點"
                                        value={location}
                                        onChange={e => setLocation(e.target.value)}
                                        isInvalid={!isLocationValid()}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        請輸入1~50字的地點名稱
                                    </Form.Control.Feedback>
                                </Col>
                            </Row>
                            <Row>
                                <Col className='form-col'>
                                    <Form.Label>報修內容</Form.Label>
                                    <Form.Control
                                        required
                                        column
                                        as="textarea"
                                        rows={3}
                                        placeholder="請填寫報修內容，例如: 鋼琴斷絃、冷氣故障、天花板滲水..."
                                        value={detail}
                                        onChange={e => setDetail(e.target.value)}
                                        isInvalid={!isDetailValid()}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        請輸入1~100字的敘述，如果100字不夠描述請直接連絡系辦
                                    </Form.Control.Feedback>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Button size='lg' type="submit">報修</Button>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
            </Container>
            <RepairmentFormCompleteDialog show={showCompleteDialog} location={location} detail={detail} onClose={resetForm}/>
        </div>
    );
}

