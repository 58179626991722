import React, { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect
} from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';


import 'bootstrap/dist/css/bootstrap.min.css';
import { Row, Col, Container, Image } from "react-bootstrap"

import { PrivateRoute } from "./components/PrivateRoute";

import LoginPage from './pages/LoginPage/LoginPage'
import MainPage from "./pages/MainPage/MainPage";
import HomePage from "./pages/HomePage/HomePage";
import ForgotPasswordPage from "./pages/ForgotPasswordPage/ForgotPasswordPage";


import { fetchSystemInfo } from './redux/system/systemThunk'

import logo from './assets/logo.png';
import './App.css';

function App() {

  const dispatch = useDispatch();
  const system = useSelector(state => state.system);

  useEffect(() => {
    dispatch(fetchSystemInfo());
  }, []);


  return (
    <div className="App">
      <header className="NTHU-header">
        <Container>
          <Row className='justify-content-center'>
            <Col md={5}>
              <h2 className="title">音樂系琴房預約系統</h2>
            </Col>
            <Col md={5} className="logo-container">
              <Image className="logo" src={logo} />
            </Col>
          </Row>
        </Container>
      </header>
      <div className="wrapper">
        <Router>
          <Switch>
            <PrivateRoute path="/user" component={MainPage} />
            <Route exact path="/login">
              <LoginPage />
            </Route>
            <Route exact path="/forgotpassword">
              <ForgotPasswordPage />
            </Route>
            <Redirect from="*" to="/user/home" />
          </Switch>
        </Router>
        <br />
      </div>
      <footer className="footer">
        <p style={{ 'whiteSpace': 'pre-wrap' }}>{system.info.information}</p>
      </footer>
    </div>
  );
}

export default App;
