import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { Modal, Button, Form } from "react-bootstrap"
import { addNewEvent } from '../../redux/event/eventThunk';


export default function AddEventDialog({ show, onClose, onConfirm }) {
    const dispatch = useDispatch();

    const user = useSelector(state => state.user);


    useEffect(() => {
        setShow(show);
    }, [show]);

    const [showState, setShow] = useState(show);
    const [eventName, setEventName] = useState("");


    const handleClose = () => {
        setShow(false);
        onClose();
    }

    const handleAddEvent = () => {

        dispatch(addNewEvent({ token: user.token, eventName: eventName }));

        onConfirm();
        handleClose();
    }

    return (
        <Modal show={showState} >
            <Modal.Header>
                <Modal.Title>新增活動</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Control column as="input" placeholder="請輸入活動名稱" onChange={e => setEventName(e.target.value)} />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleAddEvent}>
                    新增
                </Button>
                <Button variant="primary" onClick={handleClose}>
                    取消
                </Button>
            </Modal.Footer>
        </Modal>);
}