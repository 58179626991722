import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Container, Button, Table, NavDropdown, Nav, Accordion, Badge, Tabs, Tab, Card, Form } from "react-bootstrap";
import { useHistory, useRouteMatch } from 'react-router';
import { LinkContainer } from 'react-router-bootstrap';

import { fetchReservationAvaliable } from '../../redux/reservation/reservationThunk';
import { fetchAllRooms } from '../../redux/room/roomThunk';
import AvailableReservationSearchPanel from '../../components/AvailableReservationSearchPanel/AvailableReservationSearchPanel';
import { userIdentity } from '../../redux/user/userSlice';
import moment from 'moment';
import useQuery from '../../utils/useQuery';
import { getChineseWeekday } from '../../utils/utils';
import LoadingContainer from '../../components/LoadingContainer/LoadingContainer';



export default function ReservationSearchPage() {

    const dispatch = useDispatch();
    const history = useHistory();
    const { path, url } = useRouteMatch();
    const query = useQuery();


    const defaultDate = query.get('date');
    const defaultStartTime = query.get('start_time');
    const defaultEndTime = query.get('end_time');
    const defaultRoomTypeId = query.get('room_type_id');




    const user = useSelector(state => state.user);
    const reservation = useSelector(state => state.reservation);


    const [date, setDate] = useState(moment().startOf('day'));
    const [startTime, setStartTime] = useState(null);
    const [endTime, setEndTime] = useState(null);
    const [roomTypeId, setRoomTypeId] = useState("");


    const [roomTimeList, setRoomTimeList] = useState([]);







    useEffect(() => {
        if (defaultDate) {
            setDate(moment(defaultDate * 1000));
        }
        if (defaultStartTime) {
            setStartTime(moment(defaultStartTime * 1000));
        }
        if (defaultEndTime) {
            setEndTime(moment(defaultEndTime * 1000));
        }
        if (defaultRoomTypeId) {
            setRoomTypeId(defaultRoomTypeId);
        }
    }, [defaultDate, defaultStartTime, defaultEndTime, defaultRoomTypeId]);


    useEffect(() => {
        if (user.profile.identity === userIdentity.admin) {
            history.replace("adminhome");
        }
        if (user.profile.banned) {
            history.replace("banned");
        }
    }, [user]);


    useEffect(() => {
        let params = {};
        if (date) {
            params.date = date.unix();
        }
        if (startTime) {
            params.start_time = startTime.unix();
        }
        if (endTime) {
            params.end_time = endTime.unix();
        }
        if (roomTypeId) {
            params.room_type_id = roomTypeId;
        }
        dispatch(fetchReservationAvaliable({ token: user.token, options: params }));
    }, [date, startTime, endTime, roomTypeId])



    useEffect(() => {
        let rooms = Object.keys(reservation.roomsAvaliableTimes);
        let newRoomTimeList = [];

        console.log(reservation.roomsAvaliableTimes)

        rooms.forEach(function (room) {
            reservation.roomsAvaliableTimes[room].forEach(function (timeInterval, index) {
                if (index === 0) {
                    return;
                }
                let intervalStartTime = moment(timeInterval[0] * 1000);
                let intervalEndTime = moment(timeInterval[1] * 1000);
                if (moment(intervalStartTime).startOf("day").unix() < moment(intervalEndTime).startOf("day").unix()) {
                    intervalEndTime = moment(intervalStartTime).startOf("day").add(1, "day");
                }

                const roomDict = {
                    room_name: room,
                    date: moment(intervalStartTime).startOf("day"),
                    start_time: intervalStartTime,
                    end_time: intervalEndTime,
                }

                newRoomTimeList.push(roomDict);

            })
        });


        newRoomTimeList.sort(function (a, b) {
            return a.start_time.unix() - b.start_time.unix();
        });

        console.log("roomTimeList", newRoomTimeList)

        setRoomTimeList(newRoomTimeList);

    }, [reservation]);


    const onSearch = (params) => {
        console.log(params);

        setDate(moment().startOf("day"));
        setStartTime(null);
        setEndTime(null);
        setRoomTypeId("");


        let paramStr = "";
        if (params.date) {
            paramStr += `date=${params.date}&`
            setDate(moment(params.date * 1000));
        }
        if (params.start_time) {
            paramStr += `start_time=${params.start_time}&`
            setStartTime(moment(params.start_time * 1000));
        }
        if (params.end_time) {
            paramStr += `end_time=${params.end_time}&`
            setEndTime(moment(params.end_time * 1000));
        }
        if (params.room_type_id) {
            paramStr += `room_type_id=${params.room_type_id}&`
            setRoomTypeId(params.room_type_id);
        }
        console.log(paramStr)

        history.push(`reservationsearch?${paramStr}`);
    }


    return (
        <div className="pageContainer">
            <Container>
                <Row>
                    <Col>
                        <h2>搜尋結果</h2>
                    </Col>
                </Row>
                <Row>
                    <Col lg="4">
                        <AvailableReservationSearchPanel onSearch={onSearch} />
                        <br />
                    </Col>
                    <Col lg="8">
                        <LoadingContainer isLoading={reservation.isFetching}>
                            <div className="teacher-table-container">
                                {(roomTimeList.length !== 0) ?
                                    <Table striped bordered hover responsive>
                                        <thead>
                                            <tr>
                                                <th>琴房</th>
                                                <th>日期</th>
                                                <th>開始時間</th>
                                                <th>結束時間</th>
                                                <th>等待時間</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {roomTimeList.map(
                                                (room, i) =>
                                                    <LinkContainer to={`reservationform?room_name=${room.room_name}&date=${room.date.unix()}`}>
                                                        <tr key={i} className='cursor-pointer'>
                                                            <td>{room.room_name}</td>
                                                            <td>{room.date.format("YYYY/MM/DD")} {getChineseWeekday(room.date)}</td>
                                                            <td>{room.start_time.format("HH:mm")}</td>
                                                            <td>{room.end_time.format("HH:mm")}</td>
                                                            <td>{room.start_time.unix() - moment().unix() >= 0 ? moment.duration((room.start_time.unix() - moment().unix()), "seconds").format("dd日hh時mm分後可使用") : "已可預約"}</td>
                                                        </tr>
                                                    </LinkContainer>

                                            )}
                                        </tbody>
                                    </Table>
                                    : <h5>目前無可預約琴房</h5>}
                            </div>
                        </LoadingContainer>
                    </Col>
                </Row>
            </Container>

        </div >
    );

}
