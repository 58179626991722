import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Container, Button, Table, NavDropdown, Nav, Accordion, Badge, Tabs, Tab, Card, Form } from "react-bootstrap";
import DatePicker from 'sassy-datepicker';
import { get24hrToInt } from '../../utils/utils';
import { fetchReservationAvaliable } from '../../redux/reservation/reservationThunk';
import { fetchAllRooms, fetchAllRoomTypes } from '../../redux/room/roomThunk';


import './AvailableReservationSearchPanel.css'

export default function AvailableReservationSearchPanel({ onSearch }) {

    const dispatch = useDispatch();

    const user = useSelector(state => state.user);
    const room = useSelector(state => state.room);

    const [date, setDate] = useState(new Date().toDateString());
    const [beginTime, setBeginTime] = useState("請選擇");
    const [endTime, setEndTime] = useState("請選擇");
    const [roomTypeName, setRoomTypeName] = useState("全部");
    const [beginTimeList, setBeginTimeList] = useState([]);
    const [endTimeList, setEndTimeList] = useState([]);


    useEffect(() => {
        dispatch(fetchAllRoomTypes({ token: user.token }));
        generateTimeOptions();
    }, [])

    const generateTimeOptions = () => {
        const beginTimeList = [];
        for (let i = 0; i <= 47; i++) {
            beginTimeList.push(<option>{((i % 2 === 0) ? i / 2 : (i - 1) / 2).toString().padStart(2, 0)}:{(i % 2 === 0) ? '00' : '30'}</option>)
        }
        setBeginTimeList(beginTimeList);
        const endTimeList = [];
        for (let i = 1; i <= 48; i++) {
            endTimeList.push(<option>{((i % 2 === 0) ? i / 2 : (i - 1) / 2).toString().padStart(2, 0)}:{(i % 2 === 0) ? '00' : '30'}</option>)
        }
        setEndTimeList(endTimeList)
    }


    const handleSearch = (event) => {
        let params = {};
        params['date'] = Date.parse(date) / 1000;
        if (beginTime !== '請選擇') { params['start_time'] = get24hrToInt(beginTime) }
        if (endTime !== '請選擇') { params['end_time'] = get24hrToInt(endTime) }
        if (roomTypeName !== '全部') { params['room_type_id'] = room.roomTypes.find(element => element.name === roomTypeName).id }

        onSearch(params);
    }

    return (
        <Card>
            <Card.Body>
                <Card.Title><h3>搜尋</h3></Card.Title>
                <Card.Text>選擇時間</Card.Text>
                <Form.Control type="text" placeholder={date} />
                <DatePicker className="date-picker" onChange={e => setDate(e.toDateString())} />
                <br />

                <Card.Text>開始時間</Card.Text>
                <Form.Select value={beginTime} onChange={e => setBeginTime(e.target.value)}>
                    <option key={-1}>請選擇</option>
                    {beginTimeList}
                </Form.Select>
                <br />

                <Card.Text>結束時間</Card.Text>
                <Form.Select value={endTime} onChange={e => setEndTime(e.target.value)}>
                    <option key={-1}>請選擇</option>
                    {endTimeList}
                </Form.Select>
                <br />

                <Card.Text>選擇琴房種類</Card.Text>
                <Form.Select value={roomTypeName} onChange={e => setRoomTypeName(e.target.value)}>
                    <option key={-1}>全部</option>
                    {room.roomTypes.map(
                        (roomType, i) =>
                            <option key={i}>{roomType.name}</option>
                    )}
                </Form.Select>
                <br />

                <Button variant="primary" onClick={handleSearch}>搜尋</Button>
            </Card.Body>
        </Card>
    )

}