import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Container, Button, Table, NavDropdown, Nav, Accordion, Badge, Form } from "react-bootstrap"
import { useHistory, useRouteMatch } from 'react-router';
import { useParams } from "react-router-dom";
import { LinkContainer } from 'react-router-bootstrap'

import { deleteEvent, fetchAllEvents, fetchEventDetailById } from '../../redux/event/eventThunk'
import { songStatus } from '../../redux/song/songSlice';
import store from '../../redux/store'
import DeleteEventDialog from './dialogs/DeleteEventDialog';
import DeleteSongDialog from './dialogs/DeleteSongDialog';

import XLSX from 'xlsx';


export default function EventDetailPage(props) {

    const dispatch = useDispatch();
    const { path, url } = useRouteMatch();
    const { event_id } = useParams();
    const history = useHistory();



    const user = useSelector(state => state.user);
    const event = useSelector(state => state.event);


    const [selectedEvent, setSelectedEvent] = useState({ name: '', song_list: [] });
    const [filteredSongList, setFilteredSongList] = useState([]);
    const [selectedSongList, setSelectedSongList] = useState([]);

    const [showDeleteEventDialog, setShowDeleteEventDialog] = useState(false);
    const [showDeleteSongDialog, setShowDeleteSongDialog] = useState(false);




    useEffect(async () => {
        if (event.events.length === 0) {
            await dispatch(fetchAllEvents({ token: user.token }));
        }
        store.getState().event.events.forEach((eventIter) => {
            if (!eventIter.song_list || eventIter.song_list.length === 0) {
                dispatch(fetchEventDetailById({ token: user.token, event_id: eventIter.id }));
            }
        })
    }, []);

    useEffect(() => {
        const findedEvent = event.events.find(element => element.id === event_id);

        if (!findedEvent) {
            return;
        }

        const newSongList = findedEvent.song_list.filter((song) => {
            return song.status === songStatus.passed;
        });

        // Set selected Event
        setSelectedEvent(findedEvent);
        setFilteredSongList(newSongList);
        setSelectedSongList(Array(newSongList.length).fill(false));

    }, [event]);



    const exportExcel = () => {
        const data =
            [
                [selectedEvent.name],
                ["學號姓名", "曲目", "老師評語", "評分"],
                ...filteredSongList.map(
                    (song, i) =>
                        [`${song.student_id}\n${song.name}`, song.detail]
                )
            ];
        const ws = XLSX.utils.aoa_to_sheet(data);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "selectedEvent.name");
        XLSX.writeFile(wb, `${selectedEvent.name}.xlsx`)
    }



    return (
        <div className="pageContainer">
            <Container>
                <Row>
                    <Col md={{ span: 8, offset: 2 }}>
                        <h2 onClick={() => { console.log(selectedSongList) }}>{selectedEvent.name}</h2>
                    </Col>
                    <Col md={2}>
                        <Button size='lg' variant="danger" onClick={() => setShowDeleteEventDialog(true)}>刪除活動</Button>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Button variant="danger" onClick={() => { setShowDeleteSongDialog(true) }}>刪除資料</Button>
                        <Button onClick={exportExcel}>匯出</Button>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Table striped bordered responsive>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>學號</th>
                                    <th>上傳者</th>
                                    <th>曲目資料</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredSongList.map(
                                    (song, i) =>
                                        <tr key={i}>
                                            <td>
                                                <Form.Check type="checkbox"
                                                    checked={selectedSongList[i]}
                                                    onChange={(e) => setSelectedSongList(
                                                        songs => {
                                                            let newSongs = [...songs];
                                                            newSongs[i] = e.target.checked;
                                                            return newSongs;
                                                        }
                                                    )}
                                                />
                                            </td>
                                            <td>{song.student_id}</td>
                                            <td>{song.name}</td>
                                            <td>{song.detail}</td>
                                        </tr>
                                )}

                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Container>
            <DeleteEventDialog
                event={selectedEvent}
                show={showDeleteEventDialog}
                onClose={() => setShowDeleteEventDialog(false)}
                onConfirm={() => { history.replace('/user/eventmanage') }}
            />
            <DeleteSongDialog
                deleteSongList={filteredSongList.filter((song, index) => selectedSongList[index])}
                show={showDeleteSongDialog}
                onClose={() => setShowDeleteSongDialog(false)}
                onConfirm={() => setSelectedSongList(Array(filteredSongList.length).fill(false))}
            />
        </div>
    );
}

