import React, { useEffect, useState } from 'react';
import { Row, Col, Container, Form, Button, Nav } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap'


import { login } from '../../redux/user/userThunk'
import { clearState } from '../../redux/user/userSlice'


import 'bootstrap/dist/css/bootstrap.min.css';


import './LoginPage.css';
import ConditionalContainer from '../../components/ConditionalContainer';


export default function LoginPage() {

  const dispatch = useDispatch();
  const history = useHistory();
  const { register, errors, handleSubmit } = useForm();

  const { isFetching, isSuccess, isError, errorMessage } = useSelector(
    state => state.user
  );


  const [backendErrorMessage, setBackendErrorMessage] = useState("");


  if (localStorage.getItem('token')) {
    console.log(localStorage.getItem('token'))
    history.push('/');
  }

  useEffect(() => {
    if (isError) {
      dispatch(clearState());
    }

    if (isSuccess) {
      dispatch(clearState());
      history.push('/');
    }
  }, [isError, isSuccess]);



  const onSubmit = async (data) => {
    const result = await dispatch(login(data));
    if (result.meta.requestStatus === "fulfilled") {
      return;
    }

    const errorMessage = result.payload.data.message;
    console.log(errorMessage);
    if (errorMessage.includes("Username or password is wrong.")) {
      setBackendErrorMessage("使用者名稱或密碼錯誤");
    } else {
      setBackendErrorMessage(errorMessage);
    }
  }



  return (
    <div className="login-container">
      <Container>
        <Row className="justify-content-md-center">
          <Col md={6}>
            <Form onSubmit={handleSubmit(onSubmit)}>

              <Form.Group className="mb-3" as={Row} md={4} controlId="formBasicEmail">
                <Form.Label column>帳號</Form.Label>
                <Col md={8}>
                  <Form.Control column type="text" placeholder="請輸入帳號" {...register('username')} />
                </Col>
              </Form.Group>

              <Form.Group className="mb-3" as={Row} md={4} controlId="formBasicPassword">
                <Form.Label column >密碼</Form.Label>
                <Col md={8}>
                  <Form.Control type="password" placeholder="請輸入密碼" {...register('password')} />
                </Col>
              </Form.Group>

              <Row>
                <Col md={{ span: 4, offset: 4 }} className={"login-error-message-col"}>
                  <p className="login-error-message">{backendErrorMessage}</p>
                </Col>
                <Col md={{ span: 4, offset: 0 }}>
                  <LinkContainer to={`forgotpassword`}><Nav.Link href={"#"}>無法登入?</Nav.Link></LinkContainer>
                </Col>
              </Row>


              <Button variant="primary" type="submit">
                登入
              </Button>

            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
}


