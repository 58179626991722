import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { Modal, Button, Form } from "react-bootstrap"
import { deleteUserById, resetUserPasswordById, setUserNote } from '../../../redux/userList/userListThunk';


export default function SetUserNoteDialog({ selectedUserList, show, onClose, onConfirm }) {
    const dispatch = useDispatch();

    const user = useSelector(state => state.user);
    const userList = useSelector(state => state.userList);


    const [showState, setShow] = useState(show);
    const [notes, setNotes] = useState("");



    useEffect(() => {
        setShow(show);
    }, [show]);

    useEffect(() => {
        const selectedUserCount = Object.keys(selectedUserList).filter(element => selectedUserList[element]).length;
        if (selectedUserCount === 0) {
            handleClose();
        } else if (selectedUserCount === 1) {
            const findedUserId = Object.keys(selectedUserList).find(element => selectedUserList[element] === true);
            setNotes(userList.userList[findedUserId].notes);
        } else {
            setNotes("");
        }
    }, [selectedUserList, show]);


    const handleClose = () => {
        setShow(false);
        onClose();
    }

    const handleSetNote = () => {
        Object.keys(selectedUserList).forEach((id) => {
            if (selectedUserList[id]) {
                dispatch(setUserNote({ token: user.token, user_id: id, notes: notes }));
            }

        });
        onConfirm();
        handleClose();
    }

    return (
        <Modal show={showState} >
            <Modal.Header>
                <Modal.Title>備註</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Control value={notes} column as="textarea" placeholder="請輸入備註" onChange={e => setNotes(e.target.value)} />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleSetNote}>
                    確認
                </Button>
                <Button variant="primary" onClick={handleClose}>
                    取消
                </Button>
            </Modal.Footer>
        </Modal>);
}