import { createSlice } from '@reduxjs/toolkit';

import { fetchAllEvents, fetchEventDetailById, addNewEvent, deleteEvent, deleteSongFromEvent } from './eventThunk';


export const initialState = {
    events: [
    ],
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorStatus: 0,
    errorMessage: '',
}


const eventSlice = createSlice({
    name: 'event',
    initialState,
    reducers: {
    },
    extraReducers: {
        [fetchAllEvents.fulfilled]: (state, { payload }) => {
            console.log('payload', payload)
            state.events = payload;
            state.isFetching = false;
            state.isSuccess = true;
            return state;
        },
        [fetchAllEvents.rejected]: (state, { payload }) => {
            console.log('reject payload', payload)
            state.isError = true;
            state.isSuccess = false;
            state.isFetching = false;
            state.errorStatus = payload.status;
            state.errorMessage = payload.data.message;
        },
        [fetchAllEvents.pending]: (state) => {
            state.isFetching = true;
        },

        [fetchEventDetailById.fulfilled]: (state, { payload }) => {
            console.log('payload', payload)
            state.events.find(element => element.id === payload.event.id).song_list = payload.song_list;
            state.isFetching = false;
            state.isSuccess = true;
            return state;
        },
        [fetchEventDetailById.rejected]: (state, { payload }) => {
            console.log('reject payload', payload)
            state.isError = true;
            state.isSuccess = false;
            state.isFetching = false;
            state.errorStatus = payload.status;
            state.errorMessage = payload.data.message;
        },
        [fetchEventDetailById.pending]: (state) => {
            state.isFetching = true;
        },

        [addNewEvent.fulfilled]: (state, { payload }) => {
            console.log('payload', payload)
            state.events.push(payload);
            state.isFetching = false;
            state.isSuccess = true;
            return state;
        },
        [addNewEvent.rejected]: (state, { payload }) => {
            console.log('reject payload', payload)
            state.isError = true;
            state.isSuccess = false;
            state.isFetching = false;
            state.errorStatus = payload.status;
            state.errorMessage = payload.data.message;
        },
        [addNewEvent.pending]: (state) => {
            state.isFetching = true;
        },

        [deleteEvent.fulfilled]: (state, { payload }) => {
            console.log('payload', payload)

            let index = state.events.findIndex((event) => event.id === payload.id);
            state.events.splice(index, 1);

            state.isFetching = false;
            state.isSuccess = true;
            return state;
        },
        [deleteEvent.rejected]: (state, { payload }) => {
            console.log('reject payload', payload)
            state.isError = true;
            state.isSuccess = false;
            state.isFetching = false;
            state.errorStatus = payload.status;
            state.errorMessage = payload.data.message;
        },
        [deleteEvent.pending]: (state) => {
            state.isFetching = true;
        },

        [deleteSongFromEvent.fulfilled]: (state, { payload }) => {
            console.log('payload', payload)

            let eventIdx = state.events.findIndex((event) => event.id === payload.event_id);
            let songIdx = state.events[eventIdx].song_list.findIndex((song) => song.id === payload.song_id);
            state.events[eventIdx].song_list.splice(songIdx, 1);

            state.isFetching = false;
            state.isSuccess = true;
            return state;
        },
        [deleteSongFromEvent.rejected]: (state, { payload }) => {
            console.log('reject payload', payload)
            state.isError = true;
            state.isSuccess = false;
            state.isFetching = false;
            state.errorStatus = payload.status;
            state.errorMessage = payload.data.message;
        },
        [deleteSongFromEvent.pending]: (state) => {
            state.isFetching = true;
        },
    }
});





export default eventSlice;