import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { Modal, Button } from "react-bootstrap"
import { deleteUserById } from '../../../redux/userList/userListThunk';


export default function DeleteUserDialog({ deleteUserList, show, onClose, onConfirm }) {
    const dispatch = useDispatch();

    const user = useSelector(state => state.user);


    useEffect(() => {
        setShow(show);
    }, [show]);

    useEffect(() => {
        if (countUser() === 0) {
            handleClose();
        }
    }, [deleteUserList, show]);

    const [showState, setShow] = useState(show);

    const handleClose = () => {
        setShow(false);
        onClose();
    }

    const handleDelete = () => {
        Object.keys(deleteUserList).forEach((id) => {
            if (deleteUserList[id]) {
                dispatch(deleteUserById({ token: user.token, user_id: id }));
            }

        });
        onConfirm();
        handleClose();
    }

    const countUser = () => {
        let count = 0;
        Object.keys(deleteUserList).forEach((id) => {
            if (deleteUserList[id]) {
                count++;
            }
        })
        return count;
    }

    return (
        <Modal show={showState} >
            <Modal.Header>
                <Modal.Title>是否刪除選取的{countUser()}位使用者?</Modal.Title>
            </Modal.Header>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleDelete}>
                    確認刪除
                </Button>
                <Button variant="primary" onClick={handleClose}>
                    取消
                </Button>
            </Modal.Footer>
        </Modal>);
}