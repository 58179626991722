import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useRouteMatch,
    Redirect,
    useHistory
} from "react-router-dom";
import { Row, Col, Container, Button, Form } from "react-bootstrap"
import { LinkContainer } from 'react-router-bootstrap'
import ConditionalContainer from '../../components/ConditionalContainer'

import './ReservationTypeFormPage.css'

import useQuery from '../../utils/useQuery'
import { addNewReserveType, fetchAllReservationTypes, updateReserveType } from '../../redux/reservation/reservationThunk';
import { getDayFromSecond, getHourFromSecond, getMinuteFromSecond, getMonthFromSecond, getWeekFromSecond } from '../../utils/utils';
import { fetchAllTypes, fetchSubtypes } from '../../redux/type/typeThunk';

export default function ReservationTypeFormPage() {
    const dispatch = useDispatch();
    const history = useHistory();

    const { path, url } = useRouteMatch();
    const query = useQuery();

    const user = useSelector(state => state.user);
    const type = useSelector(state => state.type);
    const reservation = useSelector(state => state.reservation);




    const reservationType_id = query.get('reservationType_id');


    const cycleOptions = [
        { name: "每日", value: 86400 },
        { name: "每週一到日", value: 86400 * 7 },
        { name: "每月", value: 86400 * 31 },
        { name: "自訂", value: -1 },
    ]

    const timePeriodOptions = [
        { name: "一", value: 1 },
        { name: "二", value: 2 },
        { name: "三", value: 3 },
    ]



    const [typeName, setTypeName] = useState("");
    const [cycle, setCycle] = useState(cycleOptions[0]);
    const [selfDefinedCycleDuration, setSelfDefinedCycleDuration] = useState(0);
    const [timePeriod, setTimePeriod] = useState(timePeriodOptions[0]);
    const [allowReserveList, setAllowReserveList] = useState([[0, 0], [0, 0], [0, 0]]);
    const [selectedType, setSelectedType] = useState({ name: "", subtype: [] });
    const [subtypeLimits, setSubtypeLimits] = useState([]);


    const [typeNameErrorMessage, setTypeNameErrorMessage] = useState("");
    const [validated, setValidated] = useState(false);





    useEffect(async () => {
        dispatch(fetchAllReservationTypes({ token: user.token }));
        dispatch(fetchAllTypes({ token: user.token }));
    }, []);


    useEffect(async () => {
        if (!reservationType_id) {
            return;
        }
        const defaultReservationType = reservation.reservationTypes.find(reservationType => reservationType.id === reservationType_id);
        if (!defaultReservationType) {
            return;
        }
        const defaultType = type.types.find(element => element.name === defaultReservationType.limits_type)
        if (!defaultType) {
            return;
        }
        let defaultAllowReserveList = defaultReservationType.allow_reserve.map(function (row) {
            return row.map(function (col) {
                return parseFloat(col);
            });
        })
        let defaultAllowReserveListLength = defaultAllowReserveList.length;
        for (let i = 0; i < 3 - defaultAllowReserveListLength; i++) {
            defaultAllowReserveList.push([0, 0]);
        }
        // console.log(defaultAllowReserveList)
        setTypeName(defaultReservationType.name);
        setCycle(cycleOptions.find(option => option.value === defaultReservationType.cycle) ?? cycleOptions[cycleOptions.length - 1]);
        setTimePeriod(timePeriodOptions.find(option => option.value === defaultReservationType.time_period));
        setSelfDefinedCycleDuration(defaultReservationType.cycle ?? 0);
        setAllowReserveList(defaultAllowReserveList);
        setSelectedType(defaultType);
        setSubtypeLimits(defaultType.subtype.map((element) => defaultReservationType.limits_detail[element]));
        console.log(defaultReservationType);
    }, [type]);


    useEffect(() => {
        if (type.types[0] && !reservationType_id) {
            setSelectedType(type.types[0]);
        }
    }, [type]);

    useEffect(() => {
        if (selfDefinedCycleDuration < 0) {
            setSelfDefinedCycleDuration(0);
        }
    }, [selfDefinedCycleDuration]);

    useEffect(() => {
        for (let i = 0; i < allowReserveList.length; i++) {
            for (let j = 0; j < allowReserveList[i].length; j++) {
                if (allowReserveList[i][j] < 0) {
                    let newArr = [...allowReserveList];
                    newArr[i][j] = 0;
                    setAllowReserveList(newArr);
                }
            }
        }
    }, [allowReserveList]);

    useEffect(() => {
        if (!reservationType_id) {
            setSubtypeLimits(Array(selectedType.subtype.length).fill(0));
        }
    }, [selectedType]);

    useEffect(() => {
        for (let i = 0; i < subtypeLimits.length; i++) {
            if (subtypeLimits[i] < 0) {
                let newArr = [...subtypeLimits];
                newArr[i] = 0;
                setSubtypeLimits(newArr);
            }
        }
    }, [subtypeLimits]);


    const handleSubmit = async (event) => {
        const form = event.currentTarget;
        determineErrorMessage();
        if (form.checkValidity() === false || !isFormValid()) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            event.preventDefault();

            if (!reservationType_id) {
                await dispatch(addNewReserveType({
                    token: user.token,
                    name: typeName,
                    cycle: cycle.value,
                    time_period: timePeriod.value,
                    allow_reserve: allowReserveList.slice(0, timePeriod.value).map(function (row) {
                        return row.map(function (col) {
                            return col.toString();
                        });
                    }),
                    limits_type: selectedType.name,
                    limits_detail: selectedType.subtype.reduce((obj, k, i) => ({ ...obj, [k]: subtypeLimits[i] }), {})
                }));
            } else {
                await dispatch(updateReserveType({
                    token: user.token,
                    id: reservationType_id,
                    name: typeName,
                    cycle: cycle.value,
                    time_period: timePeriod.value,
                    allow_reserve: allowReserveList.slice(0, timePeriod.value).map(function (row) {
                        return row.map(function (col) {
                            return col.toString();
                        });
                    }),
                    limits_type: selectedType.name,
                    limits_detail: selectedType.subtype.reduce((obj, k, i) => ({ ...obj, [k]: subtypeLimits[i] }), {})
                }));

            }


            history.replace('/user/reservationtype')
        }

        setValidated(true);

    };

    const isFormValid = () => {
        return isTypeNameValid();
    }

    const isTypeNameValid = () => {
        return isTypeNameLengthValid() && isTypeNameNotRepeatValid();
    }

    const isTypeNameLengthValid = () => {
        return typeName.length > 0 && typeName.length <= 10;
    }

    const isTypeNameNotRepeatValid = () => {
        return !reservation.reservationTypes.find(element => (element.name === typeName && reservationType_id !== element.id));
    }

    const determineErrorMessage = () => {
        if (!isTypeNameLengthValid()) {
            setTypeNameErrorMessage("請輸入1~10字的新增預約種類!");
        } else if (!isTypeNameNotRepeatValid()) {
            setTypeNameErrorMessage("預約種類名稱已經存在!");
        }
    }



    return (
        <div className="pageContainer">
            <Container>
                <Row>
                    <Col>
                        <h2>新增預約種類</h2>
                    </Col>
                </Row>
                <Row className="justify-content-md-center">
                    <Col md='12'>
                        <Form onSubmit={handleSubmit}>

                            {/* typeName form */}
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label className="text-align-left" column sm="2">預約種類名稱:</Form.Label>
                                <Col sm="8">
                                    <Form.Control
                                        required
                                        column
                                        as="input"
                                        value={typeName}
                                        onChange={e => {
                                            setTypeName(e.target.value);
                                            determineErrorMessage();
                                        }}
                                        isValid={isTypeNameValid() && validated}
                                        isInvalid={!isTypeNameValid() && validated}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {typeNameErrorMessage}
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>

                            {/* cycle form */}
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label className="text-align-left" column sm="2">
                                    週期:
                                </Form.Label>
                                <Col sm="8">
                                    <div key={"cycle-radio"} className="mb-3">
                                        {cycleOptions.map(
                                            (option, i) =>
                                                <Form.Check
                                                    inline
                                                    checked={cycle.value === option.value}
                                                    label={option.name}
                                                    name="cycle-radio"
                                                    type="radio"
                                                    id={`cycle-radio-${i}`}
                                                    onChange={() => setCycle(option)}
                                                />
                                        )}
                                    </div>
                                </Col>
                            </Form.Group>


                            {/* if cycle is self defined form */}
                            <ConditionalContainer condition={cycle.name === "自訂"}>
                                <Form.Group as={Row} className="mb-3 justify-content-center">
                                    <Form.Label className="text-align-left" column sm="2">自訂週期: </Form.Label>
                                    <Form.Label column>每</Form.Label>
                                    <Col>
                                        <Form.Control
                                            required
                                            column
                                            as="input"
                                            type="number"
                                            value={getMonthFromSecond(selfDefinedCycleDuration)}
                                            onChange={e => setSelfDefinedCycleDuration(
                                                selfDefinedCycleDuration + (e.target.value - getMonthFromSecond(selfDefinedCycleDuration)) * 86400 * 31
                                            )}
                                        />
                                    </Col>
                                    <Form.Label column>月</Form.Label>
                                    <Col>
                                        <Form.Control
                                            required
                                            column
                                            as="input"
                                            type="number"
                                            value={getWeekFromSecond(selfDefinedCycleDuration)}
                                            onChange={e => setSelfDefinedCycleDuration(
                                                selfDefinedCycleDuration + (e.target.value - getWeekFromSecond(selfDefinedCycleDuration)) * 86400 * 7
                                            )}
                                        />
                                    </Col>
                                    <Form.Label column>週</Form.Label>
                                    <Col>
                                        <Form.Control
                                            required
                                            column
                                            as="input"
                                            type="number"
                                            value={getDayFromSecond(selfDefinedCycleDuration)}
                                            onChange={e => setSelfDefinedCycleDuration(
                                                selfDefinedCycleDuration + (e.target.value - getDayFromSecond(selfDefinedCycleDuration)) * 86400
                                            )}
                                        />
                                    </Col>
                                    <Form.Label column>日</Form.Label>
                                </Form.Group>
                            </ConditionalContainer>

                            {/* if cycle is everyday form */}
                            <ConditionalContainer condition={cycle.name === "每日"}>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="text-align-left" column sm="2">
                                        時段:
                                    </Form.Label>
                                    <Col sm="8">
                                        <div key={"time-period-radio"} className="mb-3">
                                            {timePeriodOptions.map(
                                                (option, i) =>
                                                    <Form.Check
                                                        inline
                                                        checked={timePeriod.value === option.value}
                                                        label={option.name}
                                                        name="time-period-radio"
                                                        type="radio"
                                                        id={`time-period-radio-${i}`}
                                                        onChange={() => setTimePeriod(option)}
                                                    />
                                            )}
                                        </div>
                                    </Col>
                                    {[...Array(timePeriod.value).keys()].map(
                                        (i) =>
                                            <div><Form.Group as={Row} className="mb-3 justify-content-center">
                                                <Form.Label className="text-align-left" column md={{ span: 2, offset: 1 }}>時段{timePeriodOptions[i].name}開始時間前: </Form.Label>
                                                <Col>
                                                    <Form.Control
                                                        required
                                                        column
                                                        as="input"
                                                        type="number"
                                                        value={getMonthFromSecond(allowReserveList[i][0])}
                                                        onChange={e => {
                                                            let newArr = [...allowReserveList];
                                                            newArr[i][0] = allowReserveList[i][0] + (e.target.value - getMonthFromSecond(allowReserveList[i][0])) * 86400 * 31;
                                                            setAllowReserveList(newArr);
                                                        }}
                                                    />
                                                </Col>
                                                <Form.Label column>月</Form.Label>
                                                <Col>
                                                    <Form.Control
                                                        required
                                                        column
                                                        as="input"
                                                        type="number"
                                                        value={getWeekFromSecond(allowReserveList[i][0])}
                                                        onChange={e => {
                                                            let newArr = [...allowReserveList];
                                                            newArr[i][0] = allowReserveList[i][0] + (e.target.value - getWeekFromSecond(allowReserveList[i][0])) * 86400 * 7;
                                                            setAllowReserveList(newArr);
                                                        }}
                                                    />
                                                </Col>
                                                <Form.Label column>週</Form.Label>
                                                <Col>
                                                    <Form.Control
                                                        required
                                                        column
                                                        as="input"
                                                        type="number"
                                                        value={getDayFromSecond(allowReserveList[i][0])}
                                                        onChange={e => {
                                                            let newArr = [...allowReserveList];
                                                            newArr[i][0] = allowReserveList[i][0] + (e.target.value - getDayFromSecond(allowReserveList[i][0])) * 86400;
                                                            setAllowReserveList(newArr);
                                                        }}
                                                    />
                                                </Col>
                                                <Form.Label column>日</Form.Label>
                                                <Col>
                                                    <Form.Control
                                                        required
                                                        column
                                                        as="input"
                                                        type="number"
                                                        value={getHourFromSecond(allowReserveList[i][0])}
                                                        onChange={e => {
                                                            let newArr = [...allowReserveList];
                                                            newArr[i][0] = allowReserveList[i][0] + (e.target.value - getHourFromSecond(allowReserveList[i][0])) * 3600;
                                                            setAllowReserveList(newArr);
                                                        }}
                                                    />
                                                </Col>
                                                <Form.Label column>時</Form.Label>
                                                <Col>
                                                    <Form.Control
                                                        required
                                                        column
                                                        as="input"
                                                        type="number"
                                                        value={getMinuteFromSecond(allowReserveList[i][0])}
                                                        onChange={e => {
                                                            let newArr = [...allowReserveList];
                                                            newArr[i][0] = allowReserveList[i][0] + (e.target.value - getMinuteFromSecond(allowReserveList[i][0])) * 60;
                                                            setAllowReserveList(newArr);
                                                        }}
                                                    />
                                                </Col>
                                                <Form.Label column>分</Form.Label>
                                                <Form.Label column sm="1">開始預約</Form.Label>

                                            </Form.Group>
                                                <Form.Group as={Row} className="mb-3 justify-content-center">
                                                    <Form.Label className="text-align-left" column md={{ span: 2, offset: 1 }}>時段{timePeriodOptions[i].name}結束時間前: </Form.Label>
                                                    <Col>
                                                        <Form.Control
                                                            required
                                                            column
                                                            as="input"
                                                            type="number"
                                                            value={getMonthFromSecond(allowReserveList[i][1])}
                                                            onChange={e => {
                                                                let newArr = [...allowReserveList];
                                                                newArr[i][1] = allowReserveList[i][1] + (e.target.value - getMonthFromSecond(allowReserveList[i][1])) * 86400 * 31;
                                                                setAllowReserveList(newArr);
                                                            }}
                                                        />
                                                    </Col>
                                                    <Form.Label column>月</Form.Label>
                                                    <Col>
                                                        <Form.Control
                                                            required
                                                            column
                                                            as="input"
                                                            type="number"
                                                            value={getWeekFromSecond(allowReserveList[i][1])}
                                                            onChange={e => {
                                                                let newArr = [...allowReserveList];
                                                                newArr[i][1] = allowReserveList[i][1] + (e.target.value - getWeekFromSecond(allowReserveList[i][1])) * 86400 * 7;
                                                                setAllowReserveList(newArr);
                                                            }}
                                                        />
                                                    </Col>
                                                    <Form.Label column>週</Form.Label>
                                                    <Col>
                                                        <Form.Control
                                                            required
                                                            column
                                                            as="input"
                                                            type="number"
                                                            value={getDayFromSecond(allowReserveList[i][1])}
                                                            onChange={e => {
                                                                let newArr = [...allowReserveList];
                                                                newArr[i][1] = allowReserveList[i][1] + (e.target.value - getDayFromSecond(allowReserveList[i][1])) * 86400;
                                                                setAllowReserveList(newArr);
                                                            }}
                                                        />
                                                    </Col>
                                                    <Form.Label column>日</Form.Label>
                                                    <Col>
                                                        <Form.Control
                                                            required
                                                            column
                                                            as="input"
                                                            type="number"
                                                            value={getHourFromSecond(allowReserveList[i][1])}
                                                            onChange={e => {
                                                                let newArr = [...allowReserveList];
                                                                newArr[i][1] = allowReserveList[i][1] + (e.target.value - getHourFromSecond(allowReserveList[i][1])) * 3600;
                                                                setAllowReserveList(newArr);
                                                            }}
                                                        />
                                                    </Col>
                                                    <Form.Label column>時</Form.Label>
                                                    <Col>
                                                        <Form.Control
                                                            required
                                                            column
                                                            as="input"
                                                            type="number"
                                                            value={getMinuteFromSecond(allowReserveList[i][1])}
                                                            onChange={e => {
                                                                let newArr = [...allowReserveList];
                                                                newArr[i][1] = allowReserveList[i][1] + (e.target.value - getMinuteFromSecond(allowReserveList[i][1])) * 60;
                                                                setAllowReserveList(newArr);
                                                            }}
                                                        />
                                                    </Col>
                                                    <Form.Label column>分</Form.Label>
                                                    <Form.Label column sm="1">結束預約</Form.Label>

                                                </Form.Group>
                                            </div>
                                    )}
                                </Form.Group>
                            </ConditionalContainer>

                            {/* if cycle is not everyday form */}
                            <ConditionalContainer condition={cycle.name !== "每日"}>
                                <Form.Group as={Row} className="mb-3 justify-content-center">
                                    <Form.Label className="text-align-left" column md={{ span: 2, offset: 1 }}>開始時間前: </Form.Label>
                                    <Col>
                                        <Form.Control
                                            required
                                            column
                                            as="input"
                                            type="number"
                                            value={getMonthFromSecond(allowReserveList[0][0])}
                                            onChange={e => {
                                                let newArr = [...allowReserveList];
                                                newArr[0][0] = allowReserveList[0][0] + (e.target.value - getMonthFromSecond(allowReserveList[0][0])) * 86400 * 31;
                                                setAllowReserveList(newArr);
                                            }}
                                        />
                                    </Col>
                                    <Form.Label column>月</Form.Label>
                                    <Col>
                                        <Form.Control
                                            required
                                            column
                                            as="input"
                                            type="number"
                                            value={getWeekFromSecond(allowReserveList[0][0])}
                                            onChange={e => {
                                                let newArr = [...allowReserveList];
                                                newArr[0][0] = allowReserveList[0][0] + (e.target.value - getWeekFromSecond(allowReserveList[0][0])) * 86400 * 7;
                                                setAllowReserveList(newArr);
                                            }}
                                        />
                                    </Col>
                                    <Form.Label column>週</Form.Label>
                                    <Col>
                                        <Form.Control
                                            required
                                            column
                                            as="input"
                                            type="number"
                                            value={getDayFromSecond(allowReserveList[0][0])}
                                            onChange={e => {
                                                let newArr = [...allowReserveList];
                                                newArr[0][0] = allowReserveList[0][0] + (e.target.value - getDayFromSecond(allowReserveList[0][0])) * 86400;
                                                setAllowReserveList(newArr);
                                            }}
                                        />
                                    </Col>
                                    <Form.Label column>日</Form.Label>
                                    <Col>
                                        <Form.Control
                                            required
                                            column
                                            as="input"
                                            type="number"
                                            value={getHourFromSecond(allowReserveList[0][0])}
                                            onChange={e => {
                                                let newArr = [...allowReserveList];
                                                newArr[0][0] = allowReserveList[0][0] + (e.target.value - getHourFromSecond(allowReserveList[0][0])) * 3600;
                                                setAllowReserveList(newArr);
                                            }}
                                        />
                                    </Col>
                                    <Form.Label column>時</Form.Label>
                                    <Col>
                                        <Form.Control
                                            required
                                            column
                                            as="input"
                                            type="number"
                                            value={getMinuteFromSecond(allowReserveList[0][0])}
                                            onChange={e => {
                                                let newArr = [...allowReserveList];
                                                newArr[0][0] = allowReserveList[0][0] + (e.target.value - getMinuteFromSecond(allowReserveList[0][0])) * 60;
                                                setAllowReserveList(newArr);
                                            }}
                                        />
                                    </Col>
                                    <Form.Label column>分</Form.Label>
                                    <Form.Label column sm="1">開始預約</Form.Label>

                                </Form.Group>
                                <Form.Group as={Row} className="mb-3 justify-content-center">
                                    <Form.Label className="text-align-left" column md={{ span: 2, offset: 1 }}>結束時間前: </Form.Label>
                                    <Col>
                                        <Form.Control
                                            required
                                            column
                                            as="input"
                                            type="number"
                                            value={getMonthFromSecond(allowReserveList[0][1])}
                                            onChange={e => {
                                                let newArr = [...allowReserveList];
                                                newArr[0][1] = allowReserveList[0][1] + (e.target.value - getMonthFromSecond(allowReserveList[0][1])) * 86400 * 31;
                                                setAllowReserveList(newArr);
                                            }}
                                        />
                                    </Col>
                                    <Form.Label column>月</Form.Label>
                                    <Col>
                                        <Form.Control
                                            required
                                            column
                                            as="input"
                                            type="number"
                                            value={getWeekFromSecond(allowReserveList[0][1])}
                                            onChange={e => {
                                                let newArr = [...allowReserveList];
                                                newArr[0][1] = allowReserveList[0][1] + (e.target.value - getWeekFromSecond(allowReserveList[0][1])) * 86400 * 7;
                                                setAllowReserveList(newArr);
                                            }}
                                        />
                                    </Col>
                                    <Form.Label column>週</Form.Label>
                                    <Col>
                                        <Form.Control
                                            required
                                            column
                                            as="input"
                                            type="number"
                                            value={getDayFromSecond(allowReserveList[0][1])}
                                            onChange={e => {
                                                let newArr = [...allowReserveList];
                                                newArr[0][1] = allowReserveList[0][1] + (e.target.value - getDayFromSecond(allowReserveList[0][1])) * 86400;
                                                setAllowReserveList(newArr);
                                            }}
                                        />
                                    </Col>
                                    <Form.Label column>日</Form.Label>
                                    <Col>
                                        <Form.Control
                                            required
                                            column
                                            as="input"
                                            type="number"
                                            value={getHourFromSecond(allowReserveList[0][1])}
                                            onChange={e => {
                                                let newArr = [...allowReserveList];
                                                newArr[0][1] = allowReserveList[0][1] + (e.target.value - getHourFromSecond(allowReserveList[0][1])) * 3600;
                                                setAllowReserveList(newArr);
                                            }}
                                        />
                                    </Col>
                                    <Form.Label column>時</Form.Label>
                                    <Col>
                                        <Form.Control
                                            required
                                            column
                                            as="input"
                                            type="number"
                                            value={getMinuteFromSecond(allowReserveList[0][1])}
                                            onChange={e => {
                                                let newArr = [...allowReserveList];
                                                newArr[0][1] = allowReserveList[0][1] + (e.target.value - getMinuteFromSecond(allowReserveList[0][1])) * 60;
                                                setAllowReserveList(newArr);
                                            }}
                                        />
                                    </Col>
                                    <Form.Label column>分</Form.Label>
                                    <Form.Label column sm="1">結束預約</Form.Label>
                                </Form.Group>
                            </ConditionalContainer>

                            <Form.Group as={Row} className="mb-3">
                                <Form.Label className="text-align-left" column sm="2">
                                    預約時間上限:
                                </Form.Label>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label className="text-align-left" column md={{ span: 1, offset: 1 }}>條目: </Form.Label>
                                <Col sm="2">
                                    <Form.Select
                                        value={selectedType ? selectedType.name : ""}
                                        onChange={e => setSelectedType(type.types.find(element => element.name === e.target.value))}>
                                        {type.types.map(
                                            (type, i) =>
                                                <option key={i}>{type.name}</option>
                                        )}
                                    </Form.Select>
                                </Col>
                                <Col md={{ offset: 1 }}>
                                    {selectedType.subtype.map((subtype, i) =>
                                        <Form.Group as={Row} className="mb-3">
                                            <Form.Label className="text-align-left" column sm="2">{subtype}:</Form.Label>
                                            <Col sm="4">
                                                <Form.Control
                                                    required
                                                    column
                                                    as="input"
                                                    type="number"
                                                    value={subtypeLimits[i]}
                                                    onChange={e => {
                                                        let newArr = [...subtypeLimits];
                                                        newArr[i] = parseFloat(e.target.value);
                                                        setSubtypeLimits(newArr);
                                                    }}
                                                />
                                            </Col>
                                        </Form.Group>
                                    )}
                                </Col>
                            </Form.Group>
                            <Row>
                                <Col>
                                    <Button size='lg' type="submit">{reservationType_id ? "編輯" : "新增"}</Button>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

