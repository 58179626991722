import React, { useEffect } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useRouteMatch,
    Redirect
} from "react-router-dom";
import { Row, Col, Container, Button } from "react-bootstrap"
import { LinkContainer } from 'react-router-bootstrap'


export default function QuestionPage() {

    const { path, url } = useRouteMatch();

    return (
        <div className="pageContainer">
            <Container>
                <Row>
                    <Col>
                        <h2>相關問題</h2>
                    </Col>
                </Row>
                <Row className="justify-content-md-center">
                    <Col md='6'>
                        <Row>
                            <Col>
                                <LinkContainer to={`classroomregulation`}><Button size='lg'>琴房管理辦法</Button></LinkContainer>
                            </Col>
                        </Row>
                        <Row className="justify-content-md-center">
                            <Col>
                                <LinkContainer to={`repairform`}><Button size='lg'>琴房報修表單</Button></LinkContainer>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

