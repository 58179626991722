import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Container, Button, Table, NavDropdown, Nav, Accordion, Badge } from "react-bootstrap"
import { fetchAllAdminReservations, fetchReservationRecords } from '../../redux/reservation/reservationThunk';
import { fetchAllUsers } from '../../redux/userList/userListThunk';
import moment from 'moment';
import { getChineseWeekday } from '../../utils/utils';
import { recordStatus } from '../../redux/reservation/reservationSlice';
import LoadingContainer from '../../components/LoadingContainer/LoadingContainer';


export default function AdminReservationRecordPage() {

    const dispatch = useDispatch();

    const user = useSelector(state => state.user);
    const reservation = useSelector(state => state.reservation);



    useEffect(() => {
        dispatch(fetchReservationRecords({ token: user.token }));
    }, []);



    const actionToString = (record) => {
        const action = record.action;
        if (action === recordStatus.reserve) {
            return "預約";
        } else if (action === recordStatus.update) {
            return "修改";
        } else if (action === recordStatus.cancel) {
            return "取消預約";
        } else if (action === recordStatus.adminCancel) {
            return "管理員刪除";
        }
        return "";
    }


    return (
        <div className="pageContainer">
            <Container>
                <Row>
                    <Col>
                        <h2>預約紀錄</h2>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <LoadingContainer isLoading={reservation.isFetching}>
                            <Table striped bordered responsive>
                                <thead>
                                    <tr>
                                        <th>學號/授課領域</th>
                                        <th>預約者</th>
                                        <th>帳號</th>
                                        <th>琴房</th>
                                        <th>預約時間</th>
                                        <th>動作</th>
                                        <th>時間</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {reservation.records.map(
                                        (record, i) =>
                                            <tr key={i}>
                                                <td>{record.id_teaching}</td>
                                                <td>{record.name}</td>
                                                <td>{record.account}</td>
                                                <td>{record.room_name}</td>
                                                <td>{`${moment(record.start_time * 1000).startOf("day").format("YYYY/MM/DD")} ${getChineseWeekday(moment(record.start_time * 1000).startOf("day"))} ${moment(record.start_time * 1000).format("HH:mm")}~${moment(record.end_time * 1000).format("HH:mm")}`}</td>
                                                <td>{actionToString(record)}</td>
                                                <td>{`${moment(record.created * 1000).format("YYYY/MM/DD")} ${getChineseWeekday(moment(record.created * 1000))} ${moment(record.created * 1000).format("HH:mm")}`}</td>
                                            </tr>
                                    )}
                                </tbody>
                            </Table>
                        </LoadingContainer>
                    </Col>
                </Row>
            </Container>
        </div >
    );
}

