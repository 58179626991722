import { useState } from "react"
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { Table } from "react-bootstrap"
import PropTypes from 'prop-types';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ConditionalContainer from '../ConditionalContainer'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import './SemesterWeekTable.css'
import moment, { invalid, max } from "moment";

const weekNameCh = ['星期一', '星期二', '星期三', '星期四', '星期五', '星期六', '星期日'];
const weekNameEg = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
const lessonStrList = ['1', '2', '3', '4', 'n', '5', '6', '7', '8', '9', 'a', 'b', 'c'];



function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}


TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};


function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


function initTableData(weekday, room_list, semester_id) {
  let newSemesterTable = {};
  for (let lesson = 0; lesson < 13; lesson++) {
    let tableRow = [];
    for (let room = 0; room < room_list.length; room++) {
      let reserveCell = {
        invalid: false,
        room_name: room_list[room],
        lesson: lessonStrList[lesson],
        semester_id: semester_id,
        weekday: weekday,
      }
      tableRow.push(reserveCell);
    };

    let lesson_str = lessonStrList[lesson];
    newSemesterTable[lesson_str] = tableRow;
  }
  return newSemesterTable;
}

function createTableData(weekday, semester_id, room_list, reserve_data) {


  reserve_data = Object.keys(reserve_data).map((key) => reserve_data[key]);


  let newSemesterTable = initTableData(weekday, room_list, semester_id);
  if (reserve_data !== []) {
    reserve_data.forEach((reservation, i) => {
      let idx = room_list.findIndex(e => e === reservation.room_name);
      let findLessonIndex = lessonStrList.findIndex(e => e === reservation.lesson);

      let cell = {
        invalid: true,
        semester_id: semester_id,
        room_name: reservation.room_name,
        weekday: reservation.week_day,
        lesson_str: reservation.lesson,
        lesson_name: reservation.lesson_name,
        teacher_name: reservation.teacher_name,
        teacher_id: reservation.teacher_id,
      };
      newSemesterTable[lessonStrList[findLessonIndex]].splice(idx, 1, cell);
    })
  }
  return newSemesterTable;
}



export default function SemesterWeekTable({ semester, semester_id, onClick }) {

  const [value, setValue] = useState(0);
  const [roomList, setRoomList] = useState([]);

  const [mondayData, setMondayData] = useState({});
  const [tuesdayData, setTuesdayData] = useState({});
  const [wednesdayData, setWednesdayData] = useState({});
  const [thursdayData, setThursdayData] = useState({});
  const [fridayData, setFridayData] = useState({});
  const [saturdayData, setSaturdayData] = useState({});
  const [sundayData, setSundayData] = useState({});

  const Semester = useSelector(state => state.semester);


  // Fill semester table
  useEffect(() => {
    if (!semester) {
      return;
    }

    if (semester.selectSemester === {}) {
      return;
    }

    const findedSemester = Semester.semesters.find(element => {
      return element.id === semester_id;
    })

    if (!findedSemester) {
      return;
    }

    const semester_list = [semester['monday'], semester['tuesday'], semester['wednesday'], semester['thursday'], semester['friday'], semester['saturday'], semester['sunday']]

    if (!semester_list[0]) {
      return;
    }

    const room_list = semester['selected_room'];
    setRoomList(semester['selected_room']);
    setMondayData(createTableData('monday',semester_id, room_list, semester['monday']));
    setTuesdayData(createTableData('tuesday', semester_id, room_list, semester['tuesday']));
    setWednesdayData(createTableData('wednesday', semester_id, room_list, semester['wednesday']));
    setThursdayData(createTableData('thursday', semester_id, room_list, semester['thursday']));
    setFridayData(createTableData('friday', semester_id, room_list, semester['friday']));
    setSaturdayData(createTableData('saturday', semester_id, room_list, semester['saturday']));
    setSundayData(createTableData('sunday', semester_id, room_list, semester['sunday']));


  }, [Semester]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClick = (col) => {
    if (onClick) {
      onClick(col);
    }
  }
  function renderTable(data) {
    return (
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
            <TableCell></TableCell>
            {roomList.map((room, index) => (
              <TableCell align="center"key={index}>{room}</TableCell>
            ))}
              
            </TableRow>
          </TableHead>
          <TableBody>
          {lessonStrList.map(
            (lesson_str, i) =>
              <TableRow 
              key={i} 
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row" style={{ width:'5%' }}>{lesson_str}</TableCell>
                {data[lesson_str] ? data[lesson_str].map(
                  (col, j) =>
                    <TableCell
                    align="center"
                      className={
                        `cell ${col.invalid ? "reserved-cell" : "empty-cell"}`}
                      onClick={() => handleClick(col)}
                      style={{ width: '10%' }}
                      key={j}>
                        {col.lesson_name ?
                        <h6 className="cell-p">{col.lesson_name}</h6>
                        : <></>
                      }
                      {col.teacher_name ?
                        <h6 className="cell-p">{col.teacher_name}</h6>
                        : <></>
                      }
                  
                    </TableCell>
                ) : <></>}
              </TableRow>
          )}
            
          </TableBody>
        </Table>
      </TableContainer>
    );

  }


  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} 
        onChange={handleChange} 
        aria-label="basic tabs" 
        centered 
        allowScrollButtonsMobile 
        scrollButtons
        >
          <Tab label="星期一" {...a11yProps(0)} />
          <Tab label="星期二" {...a11yProps(1)} />
          <Tab label="星期三" {...a11yProps(2)} />
          <Tab label="星期四" {...a11yProps(3)} />
          <Tab label="星期五" {...a11yProps(4)} />
          <Tab label="星期六" {...a11yProps(5)} />
          <Tab label="星期日" {...a11yProps(6)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        {weekNameCh[0]}
        <br/>
        {renderTable(mondayData)};
      </TabPanel>
      <TabPanel value={value} index={1}>
        {weekNameCh[1]}
        <br/>
        {renderTable(tuesdayData)}
      </TabPanel>
      <TabPanel value={value} index={2}>
        {weekNameCh[2]}
        <br/>
        {renderTable(wednesdayData)}
      </TabPanel>
      <TabPanel value={value} index={3}>
        {weekNameCh[3]}
        <br/>
        {renderTable(thursdayData)}
      </TabPanel>
      <TabPanel value={value} index={4}>
        {weekNameCh[4]}
        <br/>
        {renderTable(fridayData)}
      </TabPanel>
      <TabPanel value={value} index={5}>
        {weekNameCh[5]}
        <br/>
        {renderTable(saturdayData)}
      </TabPanel>
      <TabPanel value={value} index={6}>
        {weekNameCh[6]}
        <br/>
        {renderTable(sundayData)}
      </TabPanel>
    </Box>
  )
}