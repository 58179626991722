import { createSlice } from '@reduxjs/toolkit';

import { fetchSystemInfo, updateAnnouncement, updateInformation, updateClassroomRule, uploadClassroomRuleImage, uploadClassroomRuleFile, deleteClassroomRuleFile, deleteClassroomRuleImage } from './systemThunk';



export const initialState = {
    info: {
        "announcement": "　",
        "information": "",
        "room_rules": ""
    },
    images: [],
    files: [],
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorStatus: 0,
    errorMessage: '',
}


const systemSlice = createSlice({
    name: 'system',
    initialState,
    reducers: {
    },
    extraReducers: {
        [fetchSystemInfo.fulfilled]: (state, { payload }) => {
            console.log('payload', payload)
            state.info = payload["system data"];
            state.images = payload["image"];
            state.files = payload["file"];
            state.isFetching = false;
            state.isSuccess = true;
            return state;
        },
        [fetchSystemInfo.rejected]: (state, { payload }) => {
            console.log('reject payload', payload)
            state.isError = true;
            state.isSuccess = false;
            state.isFetching = false;
            state.errorStatus = payload.status;
            state.errorMessage = payload.data.message;
        },
        [fetchSystemInfo.pending]: (state) => {
            state.isFetching = true;
        },

        [updateAnnouncement.fulfilled]: (state, { payload }) => {
            console.log('payload', payload)
            state.info.announcement = payload["announcement"];
            state.isFetching = false;
            state.isSuccess = true;
            return state;
        },
        [updateAnnouncement.rejected]: (state, { payload }) => {
            console.log('reject payload', payload)
            state.isError = true;
            state.isSuccess = false;
            state.isFetching = false;
            state.errorStatus = payload.status;
            state.errorMessage = payload.data.message;
        },
        [updateAnnouncement.pending]: (state) => {
            state.isFetching = true;
        },

        [updateInformation.fulfilled]: (state, { payload }) => {
            console.log('payload', payload)
            state.info.information = payload["information"];
            state.isFetching = false;
            state.isSuccess = true;
            return state;
        },
        [updateInformation.rejected]: (state, { payload }) => {
            console.log('reject payload', payload)
            state.isError = true;
            state.isSuccess = false;
            state.isFetching = false;
            state.errorStatus = payload.status;
            state.errorMessage = payload.data.message;
        },
        [updateInformation.pending]: (state) => {
            state.isFetching = true;
        },

        [updateClassroomRule.fulfilled]: (state, { payload }) => {
            console.log('payload', payload)
            state.info.room_rules = payload["room_rules"];
            state.isFetching = false;
            state.isSuccess = true;
            return state;
        },
        [updateClassroomRule.rejected]: (state, { payload }) => {
            console.log('reject payload', payload)
            state.isError = true;
            state.isSuccess = false;
            state.isFetching = false;
            state.errorStatus = payload.status;
            state.errorMessage = payload.data.message;
        },
        [updateClassroomRule.pending]: (state) => {
            state.isFetching = true;
        },

        [uploadClassroomRuleImage.fulfilled]: (state, { payload }) => {
            console.log('payload', payload)
            state.images.push(payload);
            state.isFetching = false;
            state.isSuccess = true;
            return state;
        },
        [uploadClassroomRuleImage.rejected]: (state, { payload }) => {
            console.log('reject payload', payload)
            state.isError = true;
            state.isSuccess = false;
            state.isFetching = false;
            state.errorStatus = payload.status;
            state.errorMessage = payload.data.message;
        },
        [uploadClassroomRuleImage.pending]: (state) => {
            state.isFetching = true;
        },

        [uploadClassroomRuleFile.fulfilled]: (state, { payload }) => {
            console.log('payload', payload)
            state.files.push(payload);
            state.isFetching = false;
            state.isSuccess = true;
            return state;
        },
        [uploadClassroomRuleFile.rejected]: (state, { payload }) => {
            console.log('reject payload', payload)
            state.isError = true;
            state.isSuccess = false;
            state.isFetching = false;
            state.errorStatus = payload.status;
            state.errorMessage = payload.data.message;
        },
        [uploadClassroomRuleFile.pending]: (state) => {
            state.isFetching = true;
        },

        [deleteClassroomRuleImage.fulfilled]: (state, { payload }) => {
            console.log('payload', payload)
            let index = state.images.findIndex((image) => image.id === payload.id);
            state.images.splice(index, 1);

            state.isFetching = false;
            state.isSuccess = true;
            return state;
        },
        [deleteClassroomRuleImage.rejected]: (state, { payload }) => {
            console.log('reject payload', payload)
            state.isError = true;
            state.isSuccess = false;
            state.isFetching = false;
            state.errorStatus = payload.status;
            state.errorMessage = payload.data.message;
        },
        [deleteClassroomRuleImage.pending]: (state) => {
            state.isFetching = true;
        },


        [deleteClassroomRuleFile.fulfilled]: (state, { payload }) => {
            console.log('payload', payload)
            let index = state.files.findIndex((file) => file.id === payload.id);
            state.files.splice(index, 1);

            state.isFetching = false;
            state.isSuccess = true;
            return state;
        },
        [deleteClassroomRuleFile.rejected]: (state, { payload }) => {
            console.log('reject payload', payload)
            state.isError = true;
            state.isSuccess = false;
            state.isFetching = false;
            state.errorStatus = payload.status;
            state.errorMessage = payload.data.message;
        },
        [deleteClassroomRuleFile.pending]: (state) => {
            state.isFetching = true;
        },

    }
});





export default systemSlice;