import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Row, Col, Container, Button } from "react-bootstrap"
import { userIdentity } from '../../redux/user/userSlice';
import ConditionalContainer from '../../components/ConditionalContainer';

import './SettingPage.css'
import UpdatePasswordDialog from './dialogs/UpdatePasswordDialog';
import UpdateEmailDialog from './dialogs/UpdateEmailDialog'
import { updateUserPassword } from '../../redux/user/userThunk';
import moment from 'moment';
import { getUserTypeString, getUserTypeStringByMyTypes } from '../../utils/utils';
import { fetchAllTypes, fetchSubtypes, fetchUserSubtypes } from '../../redux/type/typeThunk';


export default function SettingPage() {
    const dispatch = useDispatch();

    const user = useSelector(state => state.user);
    const type = useSelector(state => state.type);

    const [showUpdatePasswordDialog, setShowUpdatePasswordDialog] = useState(false);
    const [showUpdateEmailDialog, setShowUpdateEmailDialog] = useState(false);


    const profile = user.profile;


    const isTeacher = profile.identity === userIdentity.teacher;
    const isStudent = profile.identity === userIdentity.student;
    const isAdmin = ((profile.identity === userIdentity.admin_s) ||
    (profile.identity === userIdentity.admin_f) 
    );



    useEffect(() => {
        dispatch(fetchUserSubtypes({ token: user.token }));
    }, []);


    useEffect(() => {
        console.log(type.types)
    }, [type]);

    return (
        <div className="pageContainer">
            <Container>
                <Row>
                    <Col>
                        <h2>帳戶設定</h2>
                    </Col>
                </Row>
                <Row className="justify-content-md-center">
                    <Col md={4}>
                        <Container>
                            <Row>身分: {isAdmin ? "管理員" : (isTeacher ? "使用者-老師" : "使用者-學生")}</Row>
                            <Row>姓名: {profile.name}</Row>

                            <ConditionalContainer condition={isStudent}>
                                <Row>學號: {profile.student_id}</Row>
                            </ConditionalContainer>

                            <ConditionalContainer condition={isTeacher}>
                                <Row>授課類別: {profile.teaching}</Row>
                            </ConditionalContainer>

                            <ConditionalContainer condition={!isAdmin}>
                                <Row>組別: {getUserTypeStringByMyTypes(profile, type.mySubtypes)}</Row>
                            </ConditionalContainer>

                            <Row>帳號: {profile.account}</Row>

                            <ConditionalContainer condition={!isAdmin}>
                                <Row>狀態: {profile.banned ? "停權" : "正常"}{profile.banned_due !== 0 ? `至${moment(new Date(profile.banned_due * 1000)).format('YYYY/MM/DD')}` : ""}</Row>
                            </ConditionalContainer>

                            <ConditionalContainer condition={isAdmin}>
                                <Row>救援電子信箱: {profile.email}</Row>
                            </ConditionalContainer>

                            <ConditionalContainer condition={!isAdmin}>
                                <Row>備註: {profile.notes}</Row>
                            </ConditionalContainer>
                        </Container>
                    </Col>
                    <Col md={4}>
                        <Container>
                            <Row><Col><Button className="button" onClick={() => setShowUpdatePasswordDialog(true)}>　　修改密碼　　</Button></Col></Row>
                            <ConditionalContainer condition={isAdmin}>
                                <Row><Col><Button className="button" onClick={() => setShowUpdateEmailDialog(true)}>修改救援電子信箱</Button></Col></Row>
                            </ConditionalContainer>
                        </Container>

                    </Col>
                </Row>
            </Container>
            <UpdatePasswordDialog show={showUpdatePasswordDialog} onClose={() => setShowUpdatePasswordDialog(false)} onConfirm={() => { }} />
            <UpdateEmailDialog show={showUpdateEmailDialog} onClose={() => setShowUpdateEmailDialog(false)} onConfirm={() => { }} />

        </div>
    );
}

