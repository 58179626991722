import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { Modal, Button, Form } from "react-bootstrap"
import { addNewSubtype, fetchAllTypes } from '../../../redux/type/typeThunk';


export default function AddSubtypeDialog({ show, onClose, onConfirm }) {
    const dispatch = useDispatch();

    const user = useSelector(state => state.user);
    const type = useSelector(state => state.type);


    const [showState, setShow] = useState(show);
    const [validated, setValidated] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const [typeName, setTypeName] = useState("");
    const [subtypeName, setSubtypeName] = useState("");


    useEffect(() => {
        if (type.types.length !== 0) {
            setTypeName(type.types[0].name);
        }
    }, [type.types])

    useEffect(() => {
        setShow(show);
    }, [show]);




    const handleClose = () => {
        setShow(false);
        setValidated(false);
        onClose();
    }

    const handleSubmit = async (event) => {

        const form = event.currentTarget;
        event.preventDefault();

        determineErrorMessage();


        if (form.checkValidity() === false || !isInputValid()) {
        } else {
            await dispatch(addNewSubtype({ token: user.token, subtypeName: subtypeName, rootName: typeName, }));
            await dispatch(fetchAllTypes({ token: user.token }));

            onConfirm();
            handleClose();
        }

        setValidated(true);

    }


    const isInputValid = () => {
        return isSubTypeLengthValid() && isSubTypeLengthValid();
    }

    const determineErrorMessage = () => {
        console.log(isTypeValid())
        if (!isSubTypeLengthValid()) {
            setErrorMessage("請輸入1~10字的內容!");
        } else if (!isTypeValid()) {
            setErrorMessage("請選擇條目!");
        }

    }

    const isSubTypeLengthValid = () => {
        return subtypeName.length <= 10 && subtypeName.length > 0;
    }

    const isTypeValid = () => {
        return type.types.find(type => type.name === typeName);
    }

    return (
        <Modal show={showState} >
            <Modal.Header>
                <Modal.Title>新增類組</Modal.Title>
            </Modal.Header>
            <Form noValidate onSubmit={handleSubmit}>

                <Modal.Body>
                    <Form.Label>條目</Form.Label>
                    <Form.Select
                        value={typeName}
                        isValid={isTypeValid() && validated}
                        isInvalid={!isTypeValid() && validated}
                        onChange={e => setTypeName(e.target.value)}
                    >
                        {type.types.map(
                            (typeItem, i) =>
                                <option key={i}>{typeItem.name}</option>
                        )}
                    </Form.Select>
                    <br />
                    <Form.Control
                        column
                        as="input"
                        placeholder="請輸入類組名稱"
                        isValid={isSubTypeLengthValid() && validated}
                        isInvalid={!isSubTypeLengthValid() && validated}
                        onChange={e => setSubtypeName(e.target.value)} />
                    <Form.Control.Feedback type="invalid">{errorMessage}</Form.Control.Feedback>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" type="submit">
                        新增
                    </Button>
                    <Button variant="primary" onClick={handleClose}>
                        取消
                    </Button>
                </Modal.Footer>
            </Form>

        </Modal>);
}