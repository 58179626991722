import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useRouteMatch } from "react-router-dom";
import { Modal, Button, Form, Row, Col } from "react-bootstrap"

import SongDisplay from '../../../components/SongDisplay/SongDisplay';
import StudentDeleteSongDialog from './StudentDeleteSongDialog';


export default function StudentSongEditDialog({ show, song, teacher, event, onClose, onDelete }) {
    const dispatch = useDispatch();
    const history = useHistory();

    const { path, url } = useRouteMatch();

    const user = useSelector(state => state.user);


    useEffect(() => {
        setShow(show);
    }, [show]);

    const [showState, setShow] = useState(show);
    const [showDeleteSongDialog, setShowDeleteSongDialog] = useState(false);



    const handleClose = () => {
        setShow(false);
        onClose();
    }

    const deleteSong = async () => {
        setShow(false);
        setShowDeleteSongDialog(true);
    }

    const updateSong = async () => {
        history.push(`${path}/studentsongformpage?teacher_id=${teacher.id}&event=${event.id}&song_detail=${song.detail}&song_id=${song.id}`);
    }

    return (
        <div>
            <Modal show={showState} >
                <Modal.Header>
                    <Modal.Title>曲目上傳資料</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <SongDisplay song={song} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        取消
                    </Button>
                    <Button variant="primary" onClick={updateSong}>
                        修改
                    </Button>
                    <Button variant="danger" onClick={deleteSong}>
                        刪除
                    </Button>
                </Modal.Footer>
            </Modal >
            <StudentDeleteSongDialog
                show={showDeleteSongDialog}
                song={song}
                event={event}
                onClose={() => {
                    setShowDeleteSongDialog(false);
                    setShow(true);
                }}
                onConfirm={() => {
                    onDelete();
                    handleClose();
                }} />
        </div>);
}