import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { Modal, Button, Form, Col, Row } from "react-bootstrap"
import { deleteUserById, resetUserPasswordById, setUserNote } from '../../../redux/userList/userListThunk';
import { moveUserSubtype } from '../../../redux/type/typeThunk';


export default function MoveUserSubtypeDialog({ selectedUserList, show, onClose, onConfirm }) {
    const dispatch = useDispatch();

    const user = useSelector(state => state.user);
    const userList = useSelector(state => state.userList);
    const type = useSelector(state => state.type);



    const [showState, setShow] = useState(show);
    const [selectedType, setSelectedType] = useState({ name: "", subtype: [] });
    const [selectedSubType, setSelectedSubType] = useState("");



    useEffect(() => {
        setShow(show);
    }, [show]);

    useEffect(() => {
        const selectedUserCount = Object.keys(selectedUserList).filter(element => selectedUserList[element]).length;
        if (selectedUserCount === 0) {
            handleClose();
        }
    }, [selectedUserList, show]);


    useEffect(() => {
        if (type.types.length === 0) {
            return;
        }
        setSelectedType(type.types[0]);
    }, [type]);


    useEffect(() => {
        if (selectedType.subtype.length === 0) {
            return;
        }
        setSelectedSubType(selectedType.subtype[0]);
    }, [selectedType]);


    const handleClose = () => {
        setShow(false);
        onClose();
    }

    const handleMoveSubtype = async () => {
        // TODO: wait for backend to fix race condition
        
        // Object.keys(selectedUserList).forEach(async (id) => {
        //     if (selectedUserList[id]) {
        //         const findedUser = userList.userList[id];
        //         const fromSubtypeName = Object.keys(selectedType.subtypeDict).find(subtypeId => {
        //             const subType = selectedType.subtypeDict[subtypeId];
        //             const result = subType.user_list.includes(id);
        //             return result;
        //         });
        //         console.log({
        //             token: user.token,
        //             user_id: id,
        //             typeName: selectedType.name,
        //             fromSubtypeName: fromSubtypeName,
        //             toSubtypeName: selectedSubType,
        //         })
        //         dispatch(moveUserSubtype(
        //             {
        //                 token: user.token,
        //                 user_id: id,
        //                 typeName: selectedType.name,
        //                 fromSubtypeName: fromSubtypeName,
        //                 toSubtypeName: selectedSubType,
        //             }));
        //     }

        // });
        for (const id of Object.keys(selectedUserList)) {
            if (selectedUserList[id]) {
                const findedUser = userList.userList[id];
                const fromSubtypeName = Object.keys(selectedType.subtypeDict).find(subtypeId => {
                    const subType = selectedType.subtypeDict[subtypeId];
                    const result = subType.user_list.includes(id);
                    return result;
                });
                console.log({
                    token: user.token,
                    user_id: id,
                    typeName: selectedType.name,
                    fromSubtypeName: fromSubtypeName,
                    toSubtypeName: selectedSubType,
                })
                await dispatch(moveUserSubtype(
                    {
                        token: user.token,
                        user_id: id,
                        typeName: selectedType.name,
                        fromSubtypeName: fromSubtypeName,
                        toSubtypeName: selectedSubType,
                    }));
            }
        }
        onConfirm();
        handleClose();
    }

    return (
        <Modal show={showState} >
            <Modal.Header>
                <Modal.Title>移動類組</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group as={Row} className="justify-content-center">
                    <Form.Label>條目:</Form.Label>
                    <Form.Select value={selectedType} onChange={e => setSelectedType(type.types.find(element => element.name === e.target.value))}>
                        {type.types.map(
                            (type, i) =>
                                <option key={i}>{type.name}</option>
                        )}
                    </Form.Select>
                </Form.Group>
                <br />
                <Form.Group as={Row} className="justify-content-center">
                    <Form.Label>類組:</Form.Label>
                    <Form.Select value={selectedSubType} onChange={e => setSelectedSubType(e.target.value)}>
                        {selectedType.subtype.map(
                            (subtype, i) =>
                                <option key={i}>{subtype}</option>
                        )}
                    </Form.Select>
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleMoveSubtype}>
                    確認
                </Button>
                <Button variant="primary" onClick={handleClose}>
                    取消
                </Button>
            </Modal.Footer>
        </Modal>);
}