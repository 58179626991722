import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios'
import jsonwebtoken from 'jsonwebtoken'


export const login = createAsyncThunk(
    "user/login",
    async ({ username, password }, thunkAPI) => {
        const form = new FormData();
        form.append('account', username);
        form.append('password', password);

        try {
            const response = await axios.post('/member/login', form);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue({ status: error.response.status, data: error.response.data });
        }
    }
)


export const checkUser = createAsyncThunk(
    "user/checkUser",
    async ({ username }, thunkAPI) => {
        const form = new FormData();
        form.append('account', username);

        try {
            const response = await axios.post('/auth/check_identity', form);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue({ status: error.response.status, data: error.response.data });
        }
    }
)


export const sendCaptcha = createAsyncThunk(
    "user/sendCaptcha",
    async ({ email }, thunkAPI) => {
        const form = new FormData();
        form.append('email', email);

        try {
            const response = await axios.post('/auth/send_captcha', form);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue({ status: error.response.status, data: error.response.data });
        }
    }
)


export const verifyCaptcha = createAsyncThunk(
    "user/verifyCaptcha",
    async ({ captcha, ran_str_encode }, thunkAPI) => {
        const form = new FormData();
        form.append('captcha', captcha);
        form.append('ran_str_encode', ran_str_encode);
        try {
            const response = await axios.post('/auth/verify_captcha', form);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue({ status: error.response.status, data: error.response.data });
        }
    }
)


export const fetchProfileByToken = createAsyncThunk(
    'user/fetchProfileByToken',
    async ({ token }, thunkAPI) => {

        if (!token) {
            return thunkAPI.rejectWithValue({ status: 0, data: { 'message': 'invalid token!' } });
        }
        try {
            const tokenData = jsonwebtoken.decode(token);
            const response = await axios.get(`/member/?user_id=${tokenData.data.id}`,
                {
                    headers: { "x-access-token": `token ${token}` }
                }
            );
            response.data.token = token;
            return response.data;

        } catch (error) {
            return thunkAPI.rejectWithValue({ status: error.response.status, data: error.response.data });
        }

    }
);


export const updateUserPassword = createAsyncThunk(
    'user/updateUserPassword',
    async ({ token, password }, thunkAPI) => {

        if (!token) {
            return thunkAPI.rejectWithValue({ status: 0, data: { 'message': 'invalid token!' } });
        }
        const form = new FormData();
        form.append('password', password);
        try {
            const response = await axios.put('/member/updatePassword', form,
                {
                    headers: { "x-access-token": `token ${token}` }
                }
            );
            response.data.token = token;
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue({ status: error.response.status, data: error.response.data });
        }

    }
);


export const updateUserEmail = createAsyncThunk(
    'user/updateUserEmail',
    async ({ token, email }, thunkAPI) => {

        if (!token) {
            return thunkAPI.rejectWithValue({ status: 0, data: { 'message': 'invalid token!' } });
        }
        const form = new FormData();
        form.append('email', email);
        try {
            const response = await axios.put('/member/updateEmail', form,
                {
                    headers: { "x-access-token": `token ${token}` }
                }
            );
            response.data.token = token;
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue({ status: error.response.status, data: error.response.data });
        }

    }
);