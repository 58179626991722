import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Container, Button, Table, NavDropdown, Nav, Accordion, Badge } from "react-bootstrap"
import { useHistory, useRouteMatch } from 'react-router';
import { LinkContainer } from 'react-router-bootstrap'


import './EventManagePage.css'
import { deleteEvent, fetchAllEvents, fetchEventDetailById } from '../../redux/event/eventThunk'
import { songStatus } from '../../redux/song/songSlice';

import store from '../../redux/store'
import AddEventDialog from './AddEventDialog';


export default function EventManagePage() {

    const dispatch = useDispatch();
    const { path, url } = useRouteMatch();

    const user = useSelector(state => state.user);
    const event = useSelector(state => state.event);
    const song = useSelector(state => state.song);

    const [showAddEventDialog, setShowAddEventDialog] = useState(false);



    useEffect(async () => {
        await dispatch(fetchAllEvents({ token: user.token }));
        
        store.getState().event.events.forEach((eventIter) => {
            if (!eventIter.song_list || eventIter.song_list.length === 0) {
                dispatch(fetchEventDetailById({ token: user.token, event_id: eventIter.id }));
            }
        })
    }, [song]);



    const countFilteredSong = (event, status) => {
        let count = 0;
        event.song_list.forEach((song) => {
            if (song.status === status) {
                count++;
            }
        });
        return count;
    }


    return (
        <div className="pageContainer">
            <Container>
                <Row>
                    <Col md={{ span: 8, offset: 2 }}>
                        <h2>曲目管理</h2>
                    </Col>
                    <Col md={2}><Button size='lg' onClick={() => setShowAddEventDialog(true)}>新增活動</Button></Col>
                </Row>
                <Row>
                    <Col>
                        <Table striped bordered hover responsive>
                            <thead>
                                <tr>
                                    <th>活動名稱</th>
                                    <th>已上傳筆數</th>
                                    <th>未審核筆數</th>
                                    <th>未通過筆數</th>
                                    <th>完成審核筆數</th>
                                </tr>
                            </thead>
                            <tbody>
                                {event.events.map(
                                    (event, i) =>
                                        <LinkContainer to={`${path}/eventpage/${event.id}`}>
                                            <tr key={event.id} className='event-row'>
                                                <td>{event.name}</td>
                                                <td>{event.song_list.length}</td>
                                                <td>{countFilteredSong(event, songStatus.pending)}</td>
                                                <td>{countFilteredSong(event, songStatus.rejected)}</td>
                                                <td>{countFilteredSong(event, songStatus.passed)}</td>
                                            </tr>
                                        </LinkContainer>
                                )}

                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Container>
            <AddEventDialog show={showAddEventDialog} onClose={() => setShowAddEventDialog(false)} onConfirm={() => { }} />
        </div >
    );
}

