import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Container, Button, Table, NavDropdown, Nav, Accordion, Badge, Tabs, Tab, Card, Form } from "react-bootstrap";
import { useHistory, useRouteMatch } from 'react-router';
import { LinkContainer } from 'react-router-bootstrap';


import './RoomManagePage.css';

import store from '../../redux/store';
import { fetchAllReservationTypes, updateAllowAllReserve } from '../../redux/reservation/reservationThunk';
import { getReservationTypeCycleString, getReservationTypeEndTimeString, getReservationTypeRestrictsString, getReservationTypeStartTimeString, getRoomHolidayEndTimeString, getRoomHolidayStartTimeString, getRoomTypeRestrictsString, getRoomWeekdayEndTimeString, getRoomWeekdayStartTimeString } from '../../utils/utils';
import DeleteRerservationTypeDialog from '../RoomManagePage/dialogs/DeleteRoomDialog';
import { fetchAllRooms, updateRoomStatus } from '../../redux/room/roomThunk';
import DeleteRoomDialog from '../RoomManagePage/dialogs/DeleteRoomDialog';
import DeletePermissionDeniedDialog from './dialogs/DeletePermissionDeniedDialog';
import { roomStatus } from '../../redux/room/roomSlice';


export default function RoomManagePage() {

    const dispatch = useDispatch();
    const { path, url } = useRouteMatch();

    const user = useSelector(state => state.user);
    const room = useSelector(state => state.room);


    const [selectedRoom, setSelectedRoom] = useState(null);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [showPermissionDialog, setShowPermissionDialog] = useState(false)


    useEffect(() => {
        dispatch(fetchAllRooms({ token: user.token }));
    }, [])


    const roomStatusSwitchHandler = (event, room) => {
        let status;
        if (event.target.checked) {
            status = roomStatus.allow;
        } else {
            status = roomStatus.notAllow;
        }
        dispatch(updateRoomStatus({ token: user.token, id: room.id, status: status }));
    }

    const isAdminSNotAvaliable = (user, room) => {
        if (user.profile.identity === 'admin_s') {
            if ((room.restricts === 'identity') && (
                room.restricts_type !== 'admin and student') && (
                    room.restricts_type !== 'everyone')) {
                        return true
            }
        }
        return false
    }

    return (
        <div className="pageContainer">
            <Container>
                <Row>
                    <Col md={{ span: 8, offset: 2 }}>
                        <h2>琴房資料總覽</h2>
                    </Col>
                    <Col md={2}>
                        <LinkContainer to={`${path}/roomform`}>
                            <Button size='lg'>新增琴房</Button>
                        </LinkContainer>
                    </Col>

                </Row>
                <Row>
                    <Col>
                        <div className="song-table-container">
                            <Table striped bordered responsive>
                                <thead>
                                    <tr>
                                        <th>開/關預約</th>
                                        <th>名稱</th>
                                        <th>種類</th>
                                        <th>限制</th>
                                        <th>(平)預約種類</th>
                                        <th>開始時間</th>
                                        <th>結束時間</th>
                                        <th>(假)預約種類</th>
                                        <th>開始時間</th>
                                        <th>結束時間</th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {room.rooms.map(
                                        (room, i) =>
                                            <tr key={room.id}>
                                                <td>
                                                {isAdminSNotAvaliable(user, room)?
                                                <Form.Check 
                                                    disabled
                                                    checked={room.status === roomStatus.allow}
                                                    type="switch"
                                                    id={`room-switch-${i}`}
                                                    onChange={e => roomStatusSwitchHandler(e, room)}
                                                />:
                                                <Form.Check 
                                                checked={room.status === roomStatus.allow}
                                                type="switch"
                                                id={`room-switch-${i}`}
                                                onChange={e => roomStatusSwitchHandler(e, room)}
                                                />}
                                                </td>
                                                <td>{room.name}</td>
                                                <td>{room.type}</td>
                                                <td>{room.restricts === "identity" ? getRoomTypeRestrictsString({ restricts: room.restricts_type }) : room.restricts_type}</td>
                                                <td>{room.reserve_type.weekday.type}</td>
                                                <td style={{ 'whiteSpace': 'pre-wrap', 'fontSize': 9 }}>{getRoomWeekdayStartTimeString(room)}</td>
                                                <td style={{ 'whiteSpace': 'pre-wrap', 'fontSize': 9 }}>{getRoomWeekdayEndTimeString(room)}</td>
                                                <td>{room.reserve_type.holiday.type}</td>
                                                <td style={{ 'whiteSpace': 'pre-wrap', 'fontSize': 9 }}>{getRoomHolidayStartTimeString(room)}</td>
                                                <td style={{ 'whiteSpace': 'pre-wrap', 'fontSize': 9 }}>{getRoomHolidayEndTimeString(room)}</td>
                                                <td>
                                                {isAdminSNotAvaliable(user, room)?
                                                    <LinkContainer disabled to={`${path}/roomform?room_id=${room.id}`}>
                                                        <Button>修改</Button>
                                                    </LinkContainer> :
                                                    <LinkContainer to={`${path}/roomform?room_id=${room.id}`}>
                                                    <Button>修改</Button>
                                                    </LinkContainer>
                                                }
                                                </td>
                                                <td>
                                                {isAdminSNotAvaliable(user, room)?
                                                    <Button
                                                        variant="danger"
                                                        disabled="true">
                                                        刪除
                                                    </Button> :
                                                    <Button
                                                        variant="danger"
                                                        onClick={() => {
                                                            setSelectedRoom(room);
                                                            setShowDeleteDialog(true);
                                                        }}>
                                                        刪除
                                                    </Button> 
                                                }
                                                </td>
                                            </tr>
                                    )}

                                </tbody>
                            </Table>
                        </div>
                    </Col>
                </Row>
            </Container>
            <DeleteRoomDialog
                show={showDeleteDialog}
                room={selectedRoom}
                onClose={() => setShowDeleteDialog(false)}
                onDelete={() => { }}
            />
            <DeletePermissionDeniedDialog
                show={showPermissionDialog}
                onClose={() => setShowPermissionDialog(false)}
            />
        </div>
    );
}

