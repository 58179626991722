import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { addNewSemester } from '../../redux/semester/semesterThunk';
import DatePicker from 'react-datepicker';
import { Row, Col, Button, Card, Form, Modal } from "react-bootstrap";

import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';

const ITEM_HEIGHT = 48;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: 100,
        },
    },
};


function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}


export default function AddSemesterDialog({ show, onClose, onConfirm }) {
    const dispatch = useDispatch();

    const user = useSelector(state => state.user);
    const room = useSelector(state => state.room);
    const theme = useTheme();


    useEffect(() => {
        setShow(show);
    }, [show]);

    const [showState, setShow] = useState(show);
    const [semesterName, setSemesterName] = useState("");
    const [semesterStartDate, setSemesterStartDate] = useState(new Date());
    const [semesterEndDate, setSemesterEndDate] = useState(new Date());
    const [selectedRoom, setSelectedRoom] = useState([]);
    const [sel, setSel] = useState([]);
    const [backendErrorMessage, setBackendErrorMessage] = useState("");

    const initSatets = () => {
        setSelectedRoom([]);
        setBackendErrorMessage('');
        setSemesterName("");
        setSemesterEndDate(new Date());
        setSemesterStartDate(new Date());

    }
    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setSelectedRoom(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
        setSel(selectedRoom);

    };

    const handleClose = () => {
        setShow(false);
        initSatets();
        onClose();
    }

    const handleAddSemester = async () => {

        console.log('rooms', selectedRoom);
        if (semesterName === "") {
            setBackendErrorMessage("請輸入學期名稱");

        } else if (semesterStartDate.valueOf() > semesterEndDate.valueOf()) {
            setBackendErrorMessage("結束日期必須大於開始日期");

        } else if (selectedRoom.length === 0) {
            setBackendErrorMessage("請選擇一間以上的琴房");
        } else {
            const result = await dispatch(addNewSemester({
                token: user.token,
                semesterName: semesterName,
                startDate: Date.parse(semesterStartDate.toDateString()) / 1000,
                endDate: Date.parse(semesterEndDate.toDateString()) / 1000,
                selectedRoom: selectedRoom
            }));

            if (result.meta.requestStatus === "fulfilled") {
                onConfirm();
                handleClose();
            } else {
                console.log(result);
                if (result.payload.status === 500) {
                    setBackendErrorMessage("伺服器錯誤，請聯繫主機人員");
                } else {
                    const errorMessage = result.payload.data.message;
                    console.log(errorMessage);
                    if (errorMessage.includes("Semester already exist.")) {
                        setBackendErrorMessage("學期名稱已存在");
                    } else if (errorMessage.includes("Permission denied")) {
                        setBackendErrorMessage("權限不足");
                    } else {
                        setBackendErrorMessage(errorMessage);
                    }
                }
            }



        }
    }



    return (
        <Modal show={showState} size="lg" aria-labelledby="example-modal-sizes-title-lg" >
            <Modal.Header>
                <Modal.Title id="example-modal-sizes-title-lg">新增課表</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row xs={1} md={5} style={{ padding: 5, justifyContent: 'center' }}>
                    <Col md={5} >
                        <Card.Text><h5>學期名稱</h5></Card.Text>
                    </Col>
                    <Col md={5}>
                        <Form.Control column as="input" placeholder="請輸入學期名稱" onChange={e => setSemesterName(e.target.value)} />
                    </Col>
                </Row>
                <Row xs={1} md={5} style={{ padding: 5, justifyContent: 'center' }}>
                    <Col md={5}>
                        <Card.Text><h5>選擇時間</h5></Card.Text>
                    </Col>
                    <Col md={5}>
                        <Row style={{ padding: 5, justifyContent: 'left' }}>
                            <Col md={5}>
                                <Card.Text>開始日期</Card.Text>
                            </Col>
                            <Col md={3}>
                                <DatePicker selected={semesterStartDate} onChange={(date) => setSemesterStartDate(date)} />
                            </Col>
                        </Row>
                        <Row style={{ padding: 5, justifyContent: 'left' }}>
                            <Col md={5}>
                                <Card.Text>結束日期</Card.Text>
                            </Col>
                            <Col md={3}>
                                <DatePicker selected={semesterEndDate} onChange={(date) => setSemesterEndDate(date)} />
                            </Col>
                        </Row>
                        <Row style={{ padding: 5, justifyContent: 'left' }}>
                            <Col md={5}>
                                <Card.Text>加入琴房</Card.Text>
                            </Col>
                        </Row>
                        <Row>
                            <div>
                                <FormControl sx={{ m: 1, width: 300 }}>
                                    <InputLabel>琴房</InputLabel>
                                    <Select
                                        labelId="demo-multiple-chip-label"
                                        id="demo-multiple-chip"
                                        multiple
                                        value={selectedRoom}
                                        onChange={handleChange}
                                        input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                                        renderValue={(selected) => (
                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                {selected.map((value) => (
                                                    <Chip key={value} label={value} />
                                                ))}
                                            </Box>
                                        )}
                                        MenuProps={MenuProps}
                                    >
                                        {room.rooms.map((room) => (
                                            <MenuItem
                                                key={room.name}
                                                value={room.name}
                                                style={getStyles(room.name, selectedRoom, theme)}
                                            >
                                                {room.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                        </Row>
                        <br />
                        <Row>
                            <h4 className="error-message">{backendErrorMessage}</h4>
                        </Row>
                    </Col>
                </Row>

            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleAddSemester}>
                    新增
                </Button>
                <Button variant="primary" onClick={handleClose}>
                    取消
                </Button>
            </Modal.Footer>
        </Modal>

    );
}