import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { Modal, Button } from "react-bootstrap"
import moment from 'moment';
import { deleteSemester } from '../../../redux/semester/semesterThunk';


export default function AdminDeleteSemesterDialog({ semester, show, onClose, onDelete }) {
    const dispatch = useDispatch();

    const user = useSelector(state => state.user);

    const [showState, setShow] = useState(show);

    const [name, setName] = useState();
    const [id, setId] = useState('');
    const [startDate, setStartDate] = useState(moment());
    const [endDate, setEndDate] = useState(moment());


    useEffect(() => {
        setShow(show);
    }, [show]);

    useEffect(() => {
        if (semester) {
            setName(semester.name);
            setId(semester.id)
            setStartDate(moment(semester.start_date * 1000))
            setEndDate(moment(semester.end_date * 1000))
        }
    }, [semester]);


    const handleClose = () => {
        setShow(false);
        onClose();
    }

    const handleDelete = () => {

        dispatch(deleteSemester({ token: user.token, semester_id: id }));

        onDelete();
        handleClose();
    }

    return (
        <Modal show={showState} >
            <Modal.Header>
                <Modal.Title>確認清除</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h5>是否刪除[ {name} ]於{startDate.format("YYYY/MM/DD")}~{endDate.format("YYYY/MM/DD")}的學期資料?</h5>
                <h6>註：清除學期資料不會取消已預約的琴房時段</h6>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={handleDelete}>
                    確認刪除
                </Button>
                <Button variant="primary" onClick={handleClose}>
                    取消
                </Button>
            </Modal.Footer>
        </Modal>
    );
}