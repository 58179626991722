import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import jsonwebtoken from 'jsonwebtoken';


export const fetchAllRooms = createAsyncThunk(
    'room/fetchAllRooms',
    async ({ token }, thunkAPI) => {

        if (!token) {
            return thunkAPI.rejectWithValue({ status: 0, data: { 'message': 'invalid token!' } });
        }
        try {
            const tokenData = jsonwebtoken.decode(token);
            const response = await axios.get(`/room/all`,
                {
                    headers: { "x-access-token": `token ${token}` }
                }
            );
            return response.data;

        } catch (error) {
            console.log("error", error.response)
            return thunkAPI.rejectWithValue({ status: error.response.status, data: error.response.data });
        }

    }
);

export const addNewRoom = createAsyncThunk(
    'room/addNewRoom',
    async ({ token, name, type, restricts, restricts_type, reserve_type }, thunkAPI) => {

        if (!token) {
            return thunkAPI.rejectWithValue({ status: 0, data: { 'message': 'invalid token!' } });
        }
        const form = new FormData();
        form.append('name', name);
        form.append('type', type);
        form.append('restricts', restricts);
        form.append('restricts_type', restricts_type);
        form.append('reserve_type', JSON.stringify(reserve_type).replaceAll("\"", "\'"));
        try {
            const response = await axios.post('/room/', form,
                {
                    headers: { "x-access-token": `token ${token}` }
                }
            );
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue({ status: error.response.status, data: error.response.data });
        }

    }
);

export const updateRoom = createAsyncThunk(
    'room/updateRoom',
    async ({ token, id, name, type, restricts, restricts_type, reserve_type }, thunkAPI) => {

        if (!token) {
            return thunkAPI.rejectWithValue({ status: 0, data: { 'message': 'invalid token!' } });
        }
        const form = new FormData();
        form.append('id', id);
        form.append('name', name);
        form.append('type', type);
        form.append('restricts', restricts);
        form.append('restricts_type', restricts_type);
        form.append('reserve_type', JSON.stringify(reserve_type).replaceAll("\"", "\'"));
        try {
            const response = await axios.put('/room/', form,
                {
                    headers: { "x-access-token": `token ${token}` }
                }
            );
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue({ status: error.response.status, data: error.response.data });
        }

    }
);


export const updateRoomStatus = createAsyncThunk(
    'room/updateRoom',
    async ({ token, id, status }, thunkAPI) => {

        if (!token) {
            return thunkAPI.rejectWithValue({ status: 0, data: { 'message': 'invalid token!' } });
        }
        const form = new FormData();
        form.append('id', id);
        form.append('status', status);
        try {
            const response = await axios.put('/room/', form,
                {
                    headers: { "x-access-token": `token ${token}` }
                }
            );
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue({ status: error.response.status, data: error.response.data });
        }

    }
);

export const deleteRoom = createAsyncThunk(
    'room/deleteRoom',
    async ({ token, room_id }, thunkAPI) => {

        if (!token) {
            return thunkAPI.rejectWithValue({ status: 0, data: { 'message': 'invalid token!' } });
        }

        try {
            const response = await axios.delete(`/room/`,
                {
                    headers: { "x-access-token": `token ${token}` },
                    data: { id: room_id },
                }
            );
            return { id: room_id };
        } catch (error) {
            return thunkAPI.rejectWithValue({ status: error.response.status, data: error.response.data });
        }

    }
);


export const fetchAllRoomTypes = createAsyncThunk(
    'room/fetchAllRoomTypes',
    async ({ token }, thunkAPI) => {

        if (!token) {
            return thunkAPI.rejectWithValue({ status: 0, data: { 'message': 'invalid token!' } });
        }
        try {
            const tokenData = jsonwebtoken.decode(token);
            const response = await axios.get(`/roomType/all`,
                {
                    headers: { "x-access-token": `token ${token}` }
                }
            );
            return response.data;

        } catch (error) {
            console.log("error", error.response)
            return thunkAPI.rejectWithValue({ status: error.response.status, data: error.response.data });
        }

    }
);


export const addNewRoomType = createAsyncThunk(
    'room/addNewRoomType',
    async ({ token, name, restricts, units }, thunkAPI) => {

        if (!token) {
            return thunkAPI.rejectWithValue({ status: 0, data: { 'message': 'invalid token!' } });
        }
        const form = new FormData();
        form.append('name', name);
        form.append('restricts', restricts);
        form.append('units', units);
        try {
            const response = await axios.post('/roomType/', form,
                {
                    headers: { "x-access-token": `token ${token}` }
                }
            );
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue({ status: error.response.status, data: error.response.data });
        }

    }
);

export const deleteRoomType = createAsyncThunk(
    'room/deleteRoomType',
    async ({ token, roomType_id }, thunkAPI) => {

        if (!token) {
            return thunkAPI.rejectWithValue({ status: 0, data: { 'message': 'invalid token!' } });
        }


        try {
            const response = await axios.delete(`/roomType/`,
                {
                    headers: { "x-access-token": `token ${token}` },
                    data: { id: roomType_id },
                }
            );
            return { id: roomType_id };
        } catch (error) {
            return thunkAPI.rejectWithValue({ status: error.response.status, data: error.response.data });
        }

    }
);