import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import jsonwebtoken from 'jsonwebtoken';
import { userIdentity } from '../user/userSlice';


// Admin Reservation APIs
export const fetchAllAdminReservations = createAsyncThunk(
    'reservation/fetchAllAdminReservations',
    async ({ token }, thunkAPI) => {

        if (!token) {
            return thunkAPI.rejectWithValue({ status: 0, data: { 'message': 'invalid token!' } });
        }
        try {
            const tokenData = jsonwebtoken.decode(token);
            const response = await axios.get(`/reservation/all`,
                {
                    headers: { "x-access-token": `token ${token}` }
                }
            );
            return response.data;

        } catch (error) {
            return thunkAPI.rejectWithValue({ status: error.response.status, data: error.response.data });
        }

    }
);

export const fetchAdminReservationsByRoom = createAsyncThunk(
    'reservation/fetchAdminReservationsByRoom',
    async ({ token, start, end, room_id }, thunkAPI) => {

        if (!token) {
            return thunkAPI.rejectWithValue({ status: 0, data: { 'message': 'invalid token!' } });
        }
        try {
            const tokenData = jsonwebtoken.decode(token);
            let queryParams = "";
            if (start) queryParams += `start=${start}&`;
            if (end) queryParams += `end=${end}&`
            if (room_id) queryParams += `room_id=${room_id}`
            const response = await axios.get(`/reservation?${queryParams}`,
                {
                    headers: { "x-access-token": `token ${token}` }
                }
            );
            return response.data;

        } catch (error) {
            return thunkAPI.rejectWithValue({ status: error.response.status, data: error.response.data });
        }

    }
);

export const adminAddReservations = createAsyncThunk(
    'reservation/adminAddReservations',
    async ({ token, user_name, user_detail, date, start_time, end_time, room }, thunkAPI) => {
        if (!token) {
            return thunkAPI.rejectWithValue({ status: 0, data: { 'message': 'invalid token!' } });
        }
        const form = new FormData();
        form.append('name', user_name);
        form.append('id_teaching', user_detail);
        form.append('date', date);
        form.append('start_time', start_time);
        form.append('end_time', end_time);
        form.append('room_name', room.name);
        try {
            const response = await axios.post('/reservation/', form,
                {
                    headers: { "x-access-token": `token ${token}` }
                }
            );
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue({ status: error.response.status, data: error.response.data });
        }
    }
);

export const deleteAdminReservations = createAsyncThunk(
    'reservation/deleteAdminReservations',
    async ({ token, reservation_id }, thunkAPI) => {

        if (!token) {
            return thunkAPI.rejectWithValue({ status: 0, data: { 'message': 'invalid token!' } });
        }


        try {
            const tokenData = jsonwebtoken.decode(token);
            const response = await axios.delete(`/reservation/`,
                {
                    headers: { "x-access-token": `token ${token}` },
                    data: {id: reservation_id},
                }
            );
            return { id: reservation_id };

        } catch (error) {
            return thunkAPI.rejectWithValue({ status: error.response.status, data: error.response.data });
        }

    }
);




// Admin Reservation Type APIs
export const fetchAllReservationTypes = createAsyncThunk(
    'reservationTypes/fetchAllReservationTypes',
    async ({ token }, thunkAPI) => {

        if (!token) {
            return thunkAPI.rejectWithValue({ status: 0, data: { 'message': 'invalid token!' } });
        }
        try {
            const tokenData = jsonwebtoken.decode(token);
            const response = await axios.get(`/reserveType/all`,
                {
                    headers: { "x-access-token": `token ${token}` }
                }
            );
            return response.data;

        } catch (error) {
            console.log("error", error.response)
            return thunkAPI.rejectWithValue({ status: error.response.status, data: error.response.data });
        }

    }
);

export const addNewReserveType = createAsyncThunk(
    'reservation/addNewReserveType',
    async ({ token, name, cycle, time_period, allow_reserve, limits_type, limits_detail }, thunkAPI) => {

        if (!token) {
            return thunkAPI.rejectWithValue({ status: 0, data: { 'message': 'invalid token!' } });
        }
        const form = new FormData();
        form.append('name', name);
        form.append('cycle', cycle);
        form.append('time_period', time_period);
        form.append('allow_reserve', JSON.stringify(allow_reserve).replaceAll("\"", "\'"));
        form.append('limits_type', limits_type);
        form.append('limits_detail', JSON.stringify(limits_detail));
        try {
            const response = await axios.post('/reserveType/', form,
                {
                    headers: { "x-access-token": `token ${token}` }
                }
            );
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue({ status: error.response.status, data: error.response.data });
        }

    }
);

export const updateReserveType = createAsyncThunk(
    'reservation/updateReserveType',
    async ({ token, id, name, cycle, time_period, allow_reserve, limits_type, limits_detail }, thunkAPI) => {

        if (!token) {
            return thunkAPI.rejectWithValue({ status: 0, data: { 'message': 'invalid token!' } });
        }
        const form = new FormData();
        form.append('id', id);
        form.append('name', name);
        form.append('cycle', cycle);
        form.append('time_period', time_period);
        form.append('allow_reserve', JSON.stringify(allow_reserve).replaceAll("\"", "\'"));
        form.append('limits_type', limits_type);
        form.append('limits_detail', JSON.stringify(limits_detail));
        try {
            const response = await axios.put('/reserveType/', form,
                {
                    headers: { "x-access-token": `token ${token}` }
                }
            );
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue({ status: error.response.status, data: error.response.data });
        }

    }
);

export const deleteReserveType = createAsyncThunk(
    'reservation/deleteReserveType',
    async ({ token, reservationType_id }, thunkAPI) => {

        if (!token) {
            return thunkAPI.rejectWithValue({ status: 0, data: { 'message': 'invalid token!' } });
        }

        try {
            const response = await axios.delete(`/reserveType/`,
                {
                    headers: { "x-access-token": `token ${token}` },
                    data: { id: reservationType_id },
                }
            );
            return { id: reservationType_id };
        } catch (error) {
            return thunkAPI.rejectWithValue({ status: error.response.status, data: error.response.data });
        }

    }
);


export const updateAllowAllReserve = createAsyncThunk(
    'reservation/updateAllowAllReserve',
    async ({ token, status }, thunkAPI) => {

        if (!token) {
            return thunkAPI.rejectWithValue({ status: 0, data: { 'message': 'invalid token!' } });
        }
        const form = new FormData();
        form.append('status', status);
        try {
            const response = await axios.put('/reserveType/allowAllReserve', form,
                {
                    headers: { "x-access-token": `token ${token}` }
                }
            );
            return status;
        } catch (error) {
            return thunkAPI.rejectWithValue({ status: error.response.status, data: error.response.data });
        }

    }
);



// Admin Record APIs
export const fetchReservationRecords = createAsyncThunk(
    'reservation/fetchReservationRecords',
    async ({ token }, thunkAPI) => {

        if (!token) {
            return thunkAPI.rejectWithValue({ status: 0, data: { 'message': 'invalid token!' } });
        }
        try {
            const tokenData = jsonwebtoken.decode(token);
            const response = await axios.get(`/records/all`,
                {
                    headers: { "x-access-token": `token ${token}` }
                }
            );
            return response.data;

        } catch (error) {
            return thunkAPI.rejectWithValue({ status: error.response.status, data: error.response.data });
        }

    }
);



// User Reservation APIs
export const fetchReservationAvaliable = createAsyncThunk(
    'reservation/fetchReservationAvaliable',
    async ({ token, options }, thunkAPI) => {

        if (!token) {
            return thunkAPI.rejectWithValue({ status: 0, data: { 'message': 'invalid token!' } });
        }
        try {
            const tokenData = jsonwebtoken.decode(token);
            let queryParams = "";
            if (options.date) queryParams += `date=${options.date}&`;
            if (options.start_time) queryParams += `start_time=${options.start_time}&`;
            if (options.end_time) queryParams += `end_time=${options.end_time}&`;
            if (options.room_type_id) queryParams += `room_type_id=${options.room_type_id}&`;
            if (options.room_name) queryParams += `room_name=${options.room_name}&`;
            const response = await axios.get(`/reservation/avaliable?${queryParams}`,
                {
                    headers: { "x-access-token": `token ${token}` }
                }
            );
            return response.data;

        } catch (error) {
            return thunkAPI.rejectWithValue({ status: error.response.status, data: error.response.data });
        }

    }
);

export const fetchMyReservations = createAsyncThunk(
    'reservationTypes/fetchMyReservations',
    async ({ token }, thunkAPI) => {

        if (!token) {
            return thunkAPI.rejectWithValue({ status: 0, data: { 'message': 'invalid token!' } });
        }
        try {
            const tokenData = jsonwebtoken.decode(token);
            const response = await axios.get(`/reservation?user_id=${tokenData.data.id}`,
                {
                    headers: { "x-access-token": `token ${token}` }
                }
            );
            return response.data;

        } catch (error) {
            console.log("error", error.response)
            return thunkAPI.rejectWithValue({ status: error.response.status, data: error.response.data });
        }

    }
);

export const addReservations = createAsyncThunk(
    'reservation/addReservations',
    async ({ token, date, start_time, end_time, room_name }, thunkAPI) => {
        if (!token) {
            return thunkAPI.rejectWithValue({ status: 0, data: { 'message': 'invalid token!' } });
        }
        const form = new FormData();
        form.append('date', date);
        form.append('start_time', start_time);
        form.append('end_time', end_time);
        form.append('room_name', room_name);
        try {
            const response = await axios.post('/reservation/', form,
                {
                    headers: { "x-access-token": `token ${token}` }
                }
            );
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue({ status: error.response.status, data: error.response.data });
        }
    }
);


export const updateReservation = createAsyncThunk(
    'reservation/updateReservation',
    async ({ token, id, date, start_time, end_time }, thunkAPI) => {

        if (!token) {
            return thunkAPI.rejectWithValue({ status: 0, data: { 'message': 'invalid token!' } });
        }
        const form = new FormData();
        form.append('id', id);
        form.append('date', date);
        form.append('start_time', start_time);
        form.append('end_time', end_time);

        try {
            const response = await axios.put('/reservation/', form,
                {
                    headers: { "x-access-token": `token ${token}` }
                }
            );
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue({ status: error.response.status, data: error.response.data });
        }

    }
);



export const deleteReservations = createAsyncThunk(
    'reservation/deleteReservations',
    async ({ token, reservation_id }, thunkAPI) => {

        if (!token) {
            return thunkAPI.rejectWithValue({ status: 0, data: { 'message': 'invalid token!' } });
        }

        try {
            const tokenData = jsonwebtoken.decode(token);
            const response = await axios.delete(`/reservation/`,
                {
                    headers: { "x-access-token": `token ${token}` },
                    data: { id: reservation_id },
                }
            );
            return { id: reservation_id };

        } catch (error) {
            return thunkAPI.rejectWithValue({ status: error.response.status, data: error.response.data });
        }

    }
);
