import { createSlice } from '@reduxjs/toolkit';

import { fetchAllUsers, addUserById, registerUser, deleteUserById, resetUserPasswordById, setUserNote, setUserStatus, fetchUserTimeLimits, fetchAllTeachers } from './userListThunk';

export const initialState = {
    userList: {},
    teacherList: [],
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorStatus: 0,
    errorMessage: '',
};

const userListSlice = createSlice({
    name: 'userList',
    initialState,
    reducers: {
    },
    extraReducers: {
        [fetchAllUsers.fulfilled]: (state, { payload }) => {
            state.userList = payload.reduce((obj, item) => {
                obj[item['id']] = item;
                return obj;
            }, {});
            state.isFetching = false;
            state.isSuccess = true;
        },
        [fetchAllUsers.rejected]: (state, { payload }) => {
            state.isFetching = false;
            state.isError = true;
            state.errorStatus = payload.status;
            state.errorMessage = payload.data.message;
        },
        [fetchAllUsers.pending]: (state) => {
            state.isFetching = true;
        },
        
        [fetchAllTeachers.fulfilled]: (state, { payload }) => {
            state.teacherList = payload
            state.isFetching = false;
            state.isSuccess = true;
        },
        [fetchAllTeachers.rejected]: (state, { payload }) => {
            state.isFetching = false;
            state.isError = true;
            state.errorStatus = payload.status;
            state.errorMessage = payload.data.message;
        },
        [fetchAllTeachers.pending]: (state) => {
            state.isFetching = true;
        },

        [fetchUserTimeLimits.fulfilled]: (state, { payload }) => {
            console.log('payload', payload)
            payload.all_user_hour_limit.forEach(element => {
                state.userList[element.user_id].type_limits = element.type_limits;
            });
            state.isFetching = false;
            state.isSuccess = true;
        },
        [fetchUserTimeLimits.rejected]: (state, { payload }) => {
            state.isFetching = false;
            state.isError = true;
            state.errorStatus = payload.status;
            state.errorMessage = payload.data.message;
        },
        [fetchUserTimeLimits.pending]: (state) => {
            state.isFetching = true;
        },


        [addUserById.fulfilled]: (state, { payload }) => {
            state.userList[payload.id] = payload;
            state.isFetching = false;
            state.isSuccess = true;
        },
        [addUserById.rejected]: (state, { payload }) => {
            state.isFetching = false;
            state.isError = true;
            state.errorStatus = payload.status;
            state.errorMessage = payload.data.message;
        },
        [addUserById.pending]: (state) => {
            state.isFetching = true;
        },


        [registerUser.fulfilled]: (state, { payload }) => {
            state.userList[payload.id] = payload;
            state.isFetching = false;
            state.isSuccess = true;
        },
        [registerUser.rejected]: (state, { payload }) => {
            state.isFetching = false;
            state.isError = true;
            state.errorStatus = payload.status;
            state.errorMessage = payload.data.message;
        },
        [registerUser.pending]: (state) => {
            state.isFetching = true;
        },



        [deleteUserById.fulfilled]: (state, { payload }) => {
            delete state.userList[payload.id];
            state.isFetching = false;
            state.isSuccess = true;
        },
        [deleteUserById.rejected]: (state, { payload }) => {
            state.isFetching = false;
            state.isError = true;
            state.errorStatus = payload.status;
            state.errorMessage = payload.data.message;
        },
        [deleteUserById.pending]: (state) => {
            state.isFetching = true;
        },


        [resetUserPasswordById.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.isSuccess = true;
        },
        [resetUserPasswordById.rejected]: (state, { payload }) => {
            state.isFetching = false;
            state.isError = true;
            state.errorStatus = payload.status;
            state.errorMessage = payload.data.message;
        },
        [resetUserPasswordById.pending]: (state) => {
            state.isFetching = true;
        },


        [setUserNote.fulfilled]: (state, { payload }) => {
            state.userList[payload.id].notes = payload.notes;
            state.isFetching = false;
            state.isSuccess = true;
        },
        [setUserNote.rejected]: (state, { payload }) => {
            state.isFetching = false;
            state.isError = true;
            state.errorStatus = payload.status;
            state.errorMessage = payload.data.message;
        },
        [setUserNote.pending]: (state) => {
            state.isFetching = true;
        },


        [setUserStatus.fulfilled]: (state, { payload }) => {
            state.userList[payload.id] = payload;
            state.isFetching = false;
            state.isSuccess = true;
        },
        [setUserStatus.rejected]: (state, { payload }) => {
            state.isFetching = false;
            state.isError = true;
            state.errorStatus = payload.status;
            state.errorMessage = payload.data.message;
        },
        [setUserStatus.pending]: (state) => {
            state.isFetching = true;
        },

    }
});


export default userListSlice;