import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios'

import { login, fetchProfileByToken, updateUserPassword, updateUserEmail, checkUser, sendCaptcha, verifyCaptcha } from './userThunk'

export const userIdentity = {
    student: "student",
    teacher: "teacher",
    admin_s: "admin_s",
    admin_f: "admin_f",
}

export const initialState = {
    profile: {},
    token: '',
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorStatus: 0,
    errorMessage: '',
};
const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        clearState: (state) => {
            state.isError = false;
            state.isSuccess = false;
            state.isFetching = false;
            return state;
        },
        logout: (state) => {
            console.log('logout', state.token);
            axios.post(`/member/logout`, {},
                {
                    headers: { Authorization: `token ${state.token}` }
                }
            );
            state.isError = false;
            state.isSuccess = false;
            state.isFetching = false;
            localStorage.setItem("token", "");
            return state;
        }
    },
    extraReducers: {
        [login.fulfilled]: (state, { payload }) => {
            console.log('payload', payload)
            if (payload.user) {
                state.profile = payload.user;
            } else if (payload.admin) {
                state.profile = payload.admin;
            } 
            state.token = payload.token;
            console.log('payload.token', payload.token)
            state.isFetching = false;
            state.isSuccess = true;

            localStorage.setItem("token", payload.token);
            return state;
        },
        [login.rejected]: (state, { payload }) => {
            state.isFetching = false;
            state.isError = true;
            state.errorStatus = payload.status;
            state.errorMessage = payload.data.message;
        },
        [login.pending]: (state) => {
            state.isFetching = true;
        },

        [checkUser.fulfilled]: (state, { payload }) => {
            console.log('payload', payload)
            state.isFetching = false;
            state.isSuccess = true;
            return state;
        },
        [checkUser.rejected]: (state, { payload }) => {
            state.isFetching = false;
            state.isError = true;
            state.errorStatus = payload.status;
            state.errorMessage = payload.data.message;
        },
        [checkUser.pending]: (state) => {
            state.isFetching = true;
        },


        [sendCaptcha.fulfilled]: (state, { payload }) => {
            console.log('payload', payload)
            state.isFetching = false;
            state.isSuccess = true;
            return state;
        },
        [sendCaptcha.rejected]: (state, { payload }) => {
            state.isFetching = false;
            state.isError = true;
            state.errorStatus = payload.status;
            state.errorMessage = payload.data.message;
        },
        [sendCaptcha.pending]: (state) => {
            state.isFetching = true;
        },



        [verifyCaptcha.fulfilled]: (state, { payload }) => {
            console.log('payload', payload)
            state.isFetching = false;
            state.isSuccess = true;
            return state;
        },
        [verifyCaptcha.rejected]: (state, { payload }) => {
            state.isFetching = false;
            state.isError = true;
            state.errorStatus = payload.status;
            state.errorMessage = payload.data.message;
        },
        [verifyCaptcha.pending]: (state) => {
            state.isFetching = true;
        },



        [fetchProfileByToken.fulfilled]: (state, { payload }) => {
            console.log('payload', payload)
            state.profile = payload;
            state.isFetching = false;
            state.isSuccess = true;
            state.token = payload.token;
            console.log(payload.token);

            return state;
        },
        [fetchProfileByToken.rejected]: (state, { payload }) => {
            console.log('reject payload', payload)
            axios.post(`/member/logout`, {},
                {
                    headers: { Authorization: `token ${state.token}` }
                }
            );
            state.isError = false;
            state.isSuccess = false;
            state.isFetching = false;
            localStorage.setItem("token", "");
        },
        [fetchProfileByToken.pending]: (state) => {
            state.isFetching = true;
        },

        [updateUserPassword.fulfilled]: (state, { payload }) => {
            console.log('payload', payload)
            state.profile = payload;
            state.isFetching = false;
            state.isSuccess = true;
            return state;
        },
        [updateUserPassword.rejected]: (state, { payload }) => {
            console.log('reject payload', payload)
            state.isError = false;
            state.isSuccess = false;
            state.isFetching = false;
            state.errorStatus = payload.status;
            state.errorMessage = payload.data.message;
        },
        [updateUserPassword.pending]: (state) => {
            state.isFetching = true;
        },

        [updateUserEmail.fulfilled]: (state, { payload }) => {
            console.log('payload', payload)
            state.profile = payload;
            state.isFetching = false;
            state.isSuccess = true;
            return state;
        },
        [updateUserEmail.rejected]: (state, { payload }) => {
            console.log('reject payload', payload)
            state.isError = false;
            state.isSuccess = false;
            state.isFetching = false;
            state.errorStatus = payload.status;
            state.errorMessage = payload.data.message;
        },
        [updateUserEmail.pending]: (state) => {
            state.isFetching = true;
        },
    }
});



export const { clearState, logout } = userSlice.actions;
export default userSlice;