import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { Modal, Button, Form } from "react-bootstrap"
import { updateUserEmail } from '../../../redux/user/userThunk';


export default function UpdateEmailDialog({ show, onClose, onConfirm }) {
    const dispatch = useDispatch();

    const user = useSelector(state => state.user);


    useEffect(() => {
        setShow(show);
    }, [show]);

    const [showState, setShow] = useState(show);
    const [validated, setValidated] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [email, setEmail] = useState("");
    const [backendErrorMessage, setbackendErrorMessage] = useState("");
    



    const handleClose = () => {
        setShow(false);
        setValidated(false);
        setEmail("");
        setErrorMessage("");
        setbackendErrorMessage("");
        onClose();
    }
    

    const handleSubmit = async (event) => {
        const form = event.currentTarget;
        event.preventDefault();

        determineErrorMessage();


        if (form.checkValidity() === false || !isEmailValid()) {
            event.stopPropagation();
        } else {
            const result = await dispatch(updateUserEmail({ token: user.token, email: email }));
            if (result.meta.requestStatus !== "fulfilled") {
                const resultMessage = result.payload.data.message;
                console.log('message', resultMessage);
                if (resultMessage.includes("More than one admin account has the same email address.")) {
                    setbackendErrorMessage("多於兩個管理員使用相同救援帳戶");
                }
                else{
                    setErrorMessage("伺服器錯誤");
                }
                return;
            } else {
                onConfirm();
                handleClose();
            }
            
        }

        setValidated(true);

    };

    const isEmailFormatValid = () => {
        let regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        return regex.test(email.toLowerCase());
    }

    const isEmailValid = () => {
        return isEmailFormatValid();
    }

    const determineErrorMessage = () => {
        if (!isEmailFormatValid()) {
            setErrorMessage("電子郵件格式不符!");
        }

    }


    return (
        <Modal show={showState} >
            <Modal.Header>
                <Modal.Title>修改救援電子郵件</Modal.Title>
            </Modal.Header>
            <Form noValidate onSubmit={handleSubmit}>
                <Modal.Body>
                    <Form.Control
                        column
                        as="input"
                        type="email"
                        placeholder="輸入救援電子郵件"
                        isValid={isEmailValid() && validated}
                        isInvalid={!isEmailValid() && validated}
                        onChange={e => { setEmail(e.target.value); determineErrorMessage(); }} />
                    <Form.Control.Feedback type="invalid">{errorMessage}</Form.Control.Feedback>
                    <p className="error-message">{backendErrorMessage}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" type="submit">
                        更新救援電子郵件
                    </Button>
                    <Button variant="secondary" onClick={handleClose}>
                        取消
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>);
}