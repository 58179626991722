import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { Modal, Button, Form } from "react-bootstrap"
import { updateUserEmail } from '../../../redux/user/userThunk';
import { addNewTeachingType } from '../../../redux/teacher/teacherThunk';


export default function AddTeachingTypeDialog({ show, onClose, onConfirm }) {
    const dispatch = useDispatch();

    const user = useSelector(state => state.user);


    useEffect(() => {
        setShow(show);
    }, [show]);

    const [showState, setShow] = useState(show);
    const [validated, setValidated] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [teachingType, setTeachingType] = useState("");



    const handleClose = () => {
        setShow(false);
        setValidated(false);
        setTeachingType("");
        setErrorMessage("");
        onClose();
    }

    const handleSubmit = async (event) => {
        const form = event.currentTarget;
        event.preventDefault();

        determineErrorMessage();


        if (form.checkValidity() === false || !isTeachingTypeValid()) {
            event.stopPropagation();
        } else {
            await dispatch(addNewTeachingType({ token: user.token, teachingTypeName: teachingType }));
            onConfirm();
            handleClose();
        }

        setValidated(true);

    };

    const isTeachingTypeLengthValid = () => {
        return teachingType.length <= 10 && teachingType.length > 0;
    }

    const isTeachingTypeValid = () => {
        return isTeachingTypeLengthValid();
    }

    const determineErrorMessage = () => {
        if(!isTeachingTypeLengthValid()){
            setErrorMessage("授課領域必須在1~10字之間!");
        }

    }


    return (
        <Modal show={showState} >
            <Modal.Header>
                <Modal.Title>新增授課領域</Modal.Title>
            </Modal.Header>
            <Form noValidate onSubmit={handleSubmit}>
                <Modal.Body>
                    <Form.Control
                        column
                        as="input"
                        type="text"
                        placeholder="輸入新增授課領域(十字以內)"
                        isValid={isTeachingTypeValid() && validated}
                        isInvalid={!isTeachingTypeValid() && validated}
                        onChange={e => { setTeachingType(e.target.value); determineErrorMessage(); }} />
                    <Form.Control.Feedback type="invalid">{errorMessage}</Form.Control.Feedback>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" type="submit">
                        新增
                    </Button>
                    <Button variant="secondary" onClick={handleClose}>
                        取消
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>);
}