import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Container, Button, Table, NavDropdown, Nav, Accordion, Badge, Tabs, Tab, Card, Form } from "react-bootstrap";
import { useHistory, useRouteMatch } from 'react-router';
import { LinkContainer } from 'react-router-bootstrap';


import './TeacherSongPage.css';

import store from '../../redux/store';
import { fetchAllSongsByTeacherId } from '../../redux/song/songThunk';
import { fetchAllEvents } from '../../redux/event/eventThunk';
import { songStatus } from '../../redux/song/songSlice';
import { getTimeFormatString, getSongEventName } from '../../utils/utils';
import TeacherSongProcessDialog from './dialogs/TeacherSongProcessDialog';
import TeacherSongReprocessDialog from './dialogs/TeacherSongReprocessDialog';
import LoadingContainer from '../../components/LoadingContainer/LoadingContainer';



export default function TeacherSongPage() {

    const dispatch = useDispatch();
    const { path, url } = useRouteMatch();

    const user = useSelector(state => state.user);
    const song = useSelector(state => state.song);
    const event = useSelector(state => state.event);


    const [showProcessDialog, setShowProcessDialog] = useState(false);
    const [showReprocessDialog, setShowReprocessDialog] = useState(false);

    const [pendingSongList, setPendingSongList] = useState([]);
    const [processedSongList, setProcessedSongList] = useState([]);

    const [selectedSong, setSelectedSong] = useState({});





    useEffect(() => {
        dispatch(fetchAllSongsByTeacherId({ token: user.token, teacher_id: user.profile.id }));
        dispatch(fetchAllEvents({ token: user.token }));
    }, [])


    useEffect(() => {
        setPendingSongList(song.songs.filter(song => song.status === songStatus.pending));
        setProcessedSongList(song.songs.filter(song => song.status !== songStatus.pending));
    }, [song])



    const songStatusString = (song) => {
        if (song.status === songStatus.rejected) {
            return songStatus.rejected;
        } else if (song.status === songStatus.passed) {
            return `完成審核時間 ${getTimeFormatString(song.verify_time)}`;
        }
        return "";
    }




    return (
        <div className="pageContainer">
            <Container>
                <Row>
                    <Col>
                        <h2>曲目審核</h2>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Tabs defaultActiveKey="pendingSong" id="uncontrolled-tab-example" fill>
                            <Tab eventKey="pendingSong" title="尚未審核曲目">
                                <LoadingContainer isLoading={event.isFetching || song.isFetching}>
                                    <div className="teacher-table-container">
                                        {(pendingSongList.length !== 0) ?
                                            <Table striped bordered hover responsive>
                                                <thead>
                                                    <tr>
                                                        <th>活動名稱</th>
                                                        <th>上傳時間</th>
                                                        <th>上傳者</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {pendingSongList.map(
                                                        (song, i) =>
                                                            <tr key={song.id} className='cursor-pointer' onClick={() => { setSelectedSong(song); setShowProcessDialog(true) }}>
                                                                <td>{getSongEventName(song, event.events)}</td>
                                                                <td>{getTimeFormatString(song.upload_time)}</td>
                                                                <td>{song.name}</td>
                                                            </tr>
                                                    )}
                                                </tbody>
                                            </Table>
                                            : <h5>目前無尚未審核曲目</h5>}
                                    </div>
                                </LoadingContainer>
                            </Tab>
                            <Tab eventKey="processedSong" title="已審核曲目">
                                <LoadingContainer isLoading={event.isFetching || song.isFetching}>
                                    <div className="song-table-container">
                                        {(processedSongList.length !== 0) ?
                                            <Table striped bordered hover responsive>
                                                <thead>
                                                    <tr>
                                                        <th>活動名稱</th>
                                                        <th>上傳狀態</th>
                                                        <th>上傳者</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {processedSongList.map(
                                                        (song, i) =>
                                                            <tr key={song.id} className='cursor-pointer' onClick={() => { setSelectedSong(song); setShowReprocessDialog(true) }}>
                                                                <td>{getSongEventName(song, event.events)}</td>
                                                                <td>{songStatusString(song)}</td>
                                                                <td>{song.name}</td>
                                                            </tr>
                                                    )}

                                                </tbody>
                                            </Table>
                                            : <h5>目前無已審核曲目</h5>}
                                    </div>
                                </LoadingContainer>
                            </Tab>
                        </Tabs>
                    </Col>
                </Row>
            </Container>
            <TeacherSongProcessDialog show={showProcessDialog} song={selectedSong} onClose={() => setShowProcessDialog(false)} />
            <TeacherSongReprocessDialog show={showReprocessDialog} song={selectedSong} onClose={() => setShowReprocessDialog(false)} />
        </div >
    );
}

