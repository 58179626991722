import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Container, Button, Table, NavDropdown, Nav, Accordion, Badge, Tabs, Tab, Card, Form } from "react-bootstrap";
import { useHistory, useRouteMatch } from 'react-router';
import { LinkContainer } from 'react-router-bootstrap';


import './RoomTypeManagePage.css';

import store from '../../redux/store';
import { getTimeFormatString, getSongEventName, getRoomTypeRestrictsString, getRoomTypeUnitsString } from '../../utils/utils';
import ConditionalContainer from '../../components/ConditionalContainer';
import { fetchAllRooms, fetchAllRoomTypes } from '../../redux/room/roomThunk';
import DeleteRoomTypeDialog from './dialogs/DeleteRoomTypeDialog';
import AddRoomTypeDialog from './dialogs/AddRoomTypeDialog';


export default function RoomTypeManagePage() {

    const dispatch = useDispatch();
    const { path, url } = useRouteMatch();

    const user = useSelector(state => state.user);
    const room = useSelector(state => state.room);


    const [showAddRoomTypeDialog, setShowAddRoomTypeDialog] = useState(false);
    const [showDeleteRoomTypeDialog, setShowDeleteRoomTypeDialog] = useState(false);

    const [selectedRoomType, setSelectedRoomType] = useState(null);




    useEffect(() => {
        dispatch(fetchAllRooms({ token: user.token }));
        dispatch(fetchAllRoomTypes({ token: user.token }));
    }, [])


    const isAdminSNotAvaliable = (user, roomType) => {
        if (user.profile.identity === 'admin_s') {
            if ((roomType.restricts !== 'admin and student') && (roomType.restricts !== 'everyone')) {
                return true
            }
        }
        return false
    }




    return (
        <div className="pageContainer">
            <Container>
                <Row>
                    <Col md={{ span: 8, offset: 2 }}>
                        <h2>琴房種類</h2>
                    </Col>
                    <Col md={2}>
                        <Button size='lg' onClick={() => setShowAddRoomTypeDialog(true)}>新增琴房種類</Button>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="room-type-table-container">
                            <Table striped bordered responsive>
                                <thead>
                                    <tr>
                                        <th>琴房種類名稱</th>
                                        <th>可預約身分</th>
                                        <th>預約單位</th>
                                        <th>琴房數量</th>
                                        <th>刪除</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {room.roomTypes.map(
                                        (roomType, i) =>
                                            <tr key={roomType.id}>
                                                <td>{roomType.name}</td>
                                                <td>{getRoomTypeRestrictsString(roomType)}</td>
                                                <td>{getRoomTypeUnitsString(roomType)}</td>
                                                <td>{roomType.room_num}</td>
                                                <td>
                                                    <ConditionalContainer condition={roomType.room_num === 0}>
                                                        {isAdminSNotAvaliable(user, roomType) ?
                                                            <Button disabled variant="danger"
                                                                onClick={() => {
                                                                    setSelectedRoomType(roomType);
                                                                    setShowDeleteRoomTypeDialog(true);
                                                                }}>
                                                                刪除
                                                            </Button> :
                                                            <Button variant="danger"
                                                                onClick={() => {
                                                                    setSelectedRoomType(roomType);
                                                                    setShowDeleteRoomTypeDialog(true);
                                                                }}>
                                                                刪除
                                                            </Button>
                                                        }
                                                    </ConditionalContainer>
                                                </td>
                                            </tr>
                                    )}

                                </tbody>
                            </Table>
                        </div>
                    </Col>
                </Row>
            </Container>
            <AddRoomTypeDialog
                show={showAddRoomTypeDialog}
                onClose={() => { setShowAddRoomTypeDialog(false) }}
                onConfirm={() => { }} />
            <DeleteRoomTypeDialog
                roomType={selectedRoomType}
                show={showDeleteRoomTypeDialog}
                onClose={() => { setShowDeleteRoomTypeDialog(false) }}
                onConfirm={() => { }} />
        </div >
    );
}

