import { Container, Row, Col } from "react-bootstrap"
import { useSelector } from 'react-redux'
import { songStatus } from "../../redux/song/songSlice";
import { getSongEventName, getTimeFormatString } from "../../utils/utils";
import ConditionalContainer from "../ConditionalContainer"


import './SongDisplay.css'

export default function SongDisplay({ song, eventName }) {

    const event = useSelector(state => state.event);

    return (
        <Container>
            <ConditionalContainer condition={song.status === songStatus.pending}>
                <Row>
                    <Col className='form-col'>
                        <p>上傳時間: {getTimeFormatString(song.upload_time)}</p>
                    </Col>
                </Row>
            </ConditionalContainer>
            <ConditionalContainer condition={song.status === songStatus.passed || song.status === songStatus.rejected}>
                <Row>
                    <Col className='form-col' md='6'>
                        <p>審核時間: {getTimeFormatString(song.verify_time)}</p>
                    </Col>
                    <Col className='form-col' md='4'>
                        <p>狀態: {song.status}</p>
                    </Col>
                </Row>
            </ConditionalContainer>
            <Row>
                <Col className='form-col form-data-col' md='6'>
                    <p>學號: {song.student_id}</p>
                </Col>
                <Col className='form-col form-data-col' md='4'>
                    <p>姓名: {song.name}</p>
                </Col>
            </Row>
            <Row>
                <Col className='form-col form-data-col' md='6'>
                    <p>項目: {song.item}</p>
                </Col>
                <Col className='form-col form-data-col' md='4'>
                    <p>老師: {song.teacher}</p>
                </Col>
            </Row>
            <Row>
                <Col className='form-col form-data-col'><p>活動: {eventName ?? getSongEventName(song, event.events)}</p></Col>
            </Row>
            <Row>
                <Col className='form-col form-data-col'><p>曲目: </p></Col>
            </Row>
            <Row>
                <Col className='form-col'><p style={{ 'whiteSpace': 'pre-wrap' }}>{song.detail}</p></Col>
            </Row>
        </Container>
    )
}