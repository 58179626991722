import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { Modal, Button } from "react-bootstrap"
import { deleteWeekReserve } from '../../../redux/semester/semesterThunk';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function AdminDeleteWeekReservationDialog({ reservation, show, onClose, onDelete }) {
    const dispatch = useDispatch();

    const user = useSelector(state => state.user);
    const semester = useSelector(state => state.semester);

    const [showState, setShow] = useState(show);

    const [roomName, setRoomName] = useState("");
    const [lesson, setlesson] = useState('');
    const [isFetching, setIsFetching] = useState(false);

    const [open, setOpen] = React.useState(false);




    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    useEffect(() => {
        setShow(show);
    }, [show]);

    useEffect(() => {
        if (reservation) {
            console.log('selectSemester', semester.selectSemester);
            setRoomName(reservation['room_name']);
            setlesson(reservation['lesson_str'])
        }
    }, [reservation]);


    const handleClose = () => {
        setShow(false);
        onClose();
    }

    const handleDelete = async () => {
        console.log('ffff', reservation)
        onDelete(setIsFetching(true));
        const result = await dispatch(deleteWeekReserve({
            token: user.token,
            semesterId: reservation['semester_id'],
            roomName: reservation['room_name'],
            weekDay: reservation['weekday'],
            lesson: reservation['lesson_str'],
            teacherId: reservation['teacher_id'],
            lessonName: reservation['lesson_name'],
        }));
        if (result.meta.requestStatus !== "fulfilled") {
            const errorMessage = result.payload.data.message;
            console.log(errorMessage);

            setOpen(true);
        }
        onDelete(setIsFetching(false));
        handleClose();


    }

    return (
        <div>
        <Modal show={showState} >
            <Modal.Header>
                <Modal.Title>是否刪除[ {roomName} ]第 {lesson} 節課的預約?</Modal.Title>
            </Modal.Header>
            <Modal.Footer>
                <Button variant="danger" onClick={handleDelete}>
                    確認刪除
                </Button>
                <Button variant="primary" onClick={handleClose}>
                    取消
                </Button>
            </Modal.Footer>
            
        </Modal>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleAlertClose} severity="error" sx={{ width: '100%' }}>
            在清除時發生系統錯誤，請聯絡維護人員
        </Alert>
    </Snackbar>
    </div>
    );
}