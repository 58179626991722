import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { Modal, Button, Row, Col } from "react-bootstrap"
import { deleteRoom } from '../../../redux/room/roomThunk';
import moment from 'moment';


export default function DeleteRoomDialog({ backendErrorResult, show, onToHome, onCancel }) {
    const dispatch = useDispatch();

    const user = useSelector(state => state.user);

    const [showState, setShow] = useState(show);
    const [errorMessage, setErrorMessage] = useState("");


    useEffect(() => {
        setShow(show);
    }, [show]);

    useEffect(() => {
        console.log(backendErrorResult)
        if (!backendErrorResult) {
            return;
        }
        if (!backendErrorResult.payload) {
            return;
        }
        if (backendErrorResult.payload.status === 500) {
            setErrorMessage("伺服器錯誤");
            return
        }
        const backendErrorMessage = backendErrorResult.payload.data.message;
        console.log(backendErrorMessage)
        if (backendErrorMessage.includes("Time interval can't reserve now.")) {
            setErrorMessage("預約時間未開放");
        } else if (backendErrorMessage.includes("Sorry! time interval has been taken by others.")) {
            setErrorMessage("您選擇的時段已被預約，或者您在同一時間已預約其他琴房，請調整為有效時段，謝謝。");
        } else if (backendErrorMessage.includes("Permission denied by room identity restricts.")) {
            setErrorMessage("很抱歉您無法預約此琴房，請確認是否被停權、系統暫停預約或此琴房種類是否能夠預約，如:碩班琴房大學部不能預約。");
        } else if (backendErrorMessage.includes("New begin time and end time must cover the original time interval.")) {
            setErrorMessage("更改的預約時間需要包含原本預約時間。");
        } else if (backendErrorMessage.includes("Sorry! The reservation has exceed the hour limit.")) {
            setErrorMessage("很抱歉，您在本時段的預約已超過時間時間上限，請確認您的預約情形，謝謝。");
        } else if (backendErrorMessage.includes("Sorry! User has other reservation in the time interval.")) {
            setErrorMessage("您選擇的時段已被預約，或者您在同一時間已預約其他琴房，請調整為有效時段，謝謝。");
        } else if (backendErrorMessage.includes("Can\'t change the begin time of reservation that already started.")) {
            setErrorMessage("很抱歉，無法更改進行中預約的開始時間。");
        } else if (backendErrorMessage.includes("Sorry! Admin closed all reserve service.")) {
            setErrorMessage("很抱歉，管理員已經關閉所有預約。");
        } else if (backendErrorMessage.includes("Sorry! New time should intersects original time")) {
            setErrorMessage("很抱歉，更改的預約時間需要包含原本預約時間。");
        } else {
            setErrorMessage(backendErrorMessage);
        }
    }, [backendErrorResult]);



    const handleToHome = () => {
        setShow(false);
        onToHome();
    }

    const handleCancel = () => {
        setShow(false);
        onCancel();
    }

    return (
        <Modal show={showState} >
            <Modal.Header>
                <Modal.Title>預約失敗</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        {errorMessage}
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={handleToHome}>
                    回首頁
                </Button>
                <Button variant="primary" onClick={handleCancel}>
                    重新預約
                </Button>
            </Modal.Footer>
        </Modal>
    );
}