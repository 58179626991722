import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from "react-router-dom";
import { Modal, Button, Form } from "react-bootstrap"


export default function RepairmentFormCompleteDialog({ show, location, detail, onClose, onConfirm }) {
    const dispatch = useDispatch();
    const history = useHistory();

    const user = useSelector(state => state.user);


    useEffect(() => {
        setShow(show);
    }, [show]);

    const [showState, setShow] = useState(show);
    const [notes, setNotes] = useState("");


    const handleClose = () => {
        setShow(false);
        onClose();
    }

    return (
        <Modal show={showState} >
            <Modal.Header>
                <Modal.Title>報修完成</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>帳號: {user.profile.account}</p>
                <p>姓名: {user.profile.name}</p>
                <p>地點: {location}</p>
                <p>報修內容: {detail}</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    填寫新報修表單
                </Button>
                <Button variant="primary" onClick={() => history.push('/user/home')}>
                    回首頁
                </Button>
            </Modal.Footer>
        </Modal>);
}