import { combineReducers } from 'redux';

import typeSlice from './type/typeSlice';
import userSlice from './user/userSlice';
import repairmentSlice from './repairment/repairmentSlice';
import userListSlice from './userList/userListSlice';
import eventSlice from './event/eventSlice';
import songSlice from './song/songSlice';
import teacherSlice from './teacher/teacherSlice';
import systemSlice from './system/systemSlice';
import roomSlice from './room/roomSlice';
import reservationSlice from './reservation/reservationSlice';
import semesterSlice from './semester/semesterSlice';



const rootReducer = combineReducers({
  user: userSlice.reducer,
  type: typeSlice.reducer,
  repairment: repairmentSlice.reducer,
  userList: userListSlice.reducer,
  event: eventSlice.reducer,
  song: songSlice.reducer,
  teacher: teacherSlice.reducer,
  system: systemSlice.reducer,
  room: roomSlice.reducer,
  reservation: reservationSlice.reducer,
  semester: semesterSlice.reducer,
});

export default rootReducer;