import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { Modal, Button } from "react-bootstrap"
import moment from 'moment';
import { deleteAdminReservations } from '../../../redux/reservation/reservationThunk';


export default function AdminDeleteReservationDialog({ reservation, show, onClose, onDelete }) {
    const dispatch = useDispatch();

    const user = useSelector(state => state.user);

    const [showState, setShow] = useState(show);

    const [name, setName] = useState("");
    const [date, setDate] = useState(moment());
    const [times, setTimes] = useState([moment(), moment()]);


    useEffect(() => {
        setShow(show);
    }, [show]);

    useEffect(() => {
        if (reservation) {
            setName(reservation.name);
            setDate(moment(reservation.date * 1000))
            setTimes([moment(reservation.start_time * 1000), moment(reservation.end_time * 1000)])
        }
    }, [reservation]);


    const handleClose = () => {
        setShow(false);
        onClose();
    }

    const handleDelete = async () => {

        const result = await dispatch(deleteAdminReservations({ token: user.token, reservation_id: reservation.id }));
        if (result.meta.requestStatus === "fulfilled") {
            onDelete();
        }
        handleClose();
    }

    return (
        <Modal show={showState} >
            <Modal.Header>
                <Modal.Title>是否刪除"{name}"於{date.format("YYYY/MM/DD")} {times[0].format("HH:mm")}~{times[1].format("HH:mm")}的預約?</Modal.Title>
            </Modal.Header>
            <Modal.Footer>
                <Button variant="danger" onClick={handleDelete}>
                    確認刪除
                </Button>
                <Button variant="primary" onClick={handleClose}>
                    取消
                </Button>
            </Modal.Footer>
        </Modal>
    );
}