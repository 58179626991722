import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Container, Button, Table, NavDropdown, Nav, Accordion, Badge, Tabs, Tab, Card, Form } from "react-bootstrap";
import { useHistory, useRouteMatch } from 'react-router';
import { LinkContainer } from 'react-router-bootstrap';
import DatePicker from 'sassy-datepicker';
import { get24hrToInt } from '../../utils/utils';

import './HomePage.css';

import store from '../../redux/store';
import { fetchAllTeachers, fetchAllTeachingTypes } from '../../redux/teacher/teacherThunk';
import { fetchReservationAvaliable } from '../../redux/reservation/reservationThunk';
import { fetchAllRooms } from '../../redux/room/roomThunk';
import { fetchAllSongsByStudentId } from '../../redux/song/songThunk';
import { fetchAllEvents } from '../../redux/event/eventThunk';
import { getTimeFormatString24hr, getSongEventName } from '../../utils/utils';
import AvailableReservationSearchPanel from '../../components/AvailableReservationSearchPanel/AvailableReservationSearchPanel';
import { userIdentity } from '../../redux/user/userSlice';
import moment from 'moment';
import LoadingContainer from '../../components/LoadingContainer/LoadingContainer';



export default function HomePage() {

  const dispatch = useDispatch();
  const history = useHistory();
  const { path, url } = useRouteMatch();

  const user = useSelector(state => state.user);
  const system = useSelector(state => state.system);
  const reservation = useSelector(state => state.reservation);

  const [nowRoomTimeList, setNowRoomTimeList] = useState([]);
  const [nearRoomTimeList, setNearRoomTimeList] = useState([]);




  useEffect(() => {
    dispatch(fetchReservationAvaliable({ token: user.token, options: { date: moment().startOf('day') / 1000 } }));
  }, [])


  useEffect(() => {
    if ((user.profile.identity === userIdentity.admin_f) || (user.profile.identity === userIdentity.admin_s)) {
      history.replace("adminhome");
    }

    if (user.profile.banned) {
      history.replace("banned");
    }

    if (system.info.allow_all_reserve === false) {
      history.replace("reservationpause");
    }
    console.log(system.info.allow_all_reserve)
  }, [user, system])


  useEffect(() => {
    let rooms = Object.keys(reservation.roomsAvaliableTimes);
    let newNowRoomTimeList = [];
    let newNearRoomTimeList = [];

    console.log(reservation.roomsAvaliableTimes)

    const now = moment();
    const after30Mins = moment().add(30, "minutes");

    rooms.forEach(function (room) {
      reservation.roomsAvaliableTimes[room].forEach(function (timeInterval, index) {
        if (index === 0) {
          return;
        }
        let intervalStartTime = moment(timeInterval[0] * 1000);
        let intervalEndTime = moment(timeInterval[1] * 1000);
        if (moment(intervalStartTime).startOf("day").unix() < moment(intervalEndTime).startOf("day").unix()) {
          intervalEndTime = moment(intervalStartTime).startOf("day").add(1, "day");
        }

        const roomDict = {
          room_name: room,
          date: moment(intervalStartTime).startOf("day"),
          start_time: intervalStartTime,
          end_time: intervalEndTime,
        }

        if (intervalStartTime.unix() <= now.unix() && now.unix() <= intervalEndTime.unix()) {
          newNowRoomTimeList.push(roomDict);
        }
        if (intervalStartTime.unix() <= after30Mins.unix() && after30Mins.unix() <= intervalEndTime.unix()) {
          newNearRoomTimeList.push(roomDict);
        }
      })
    });


    newNowRoomTimeList.sort(function (a, b) {
      return a.start_time.unix() - b.start_time.unix();
    });
    newNearRoomTimeList.sort(function (a, b) {
      return a.start_time.unix() - b.start_time.unix();
    });

    console.log("nowRoomTimeList", newNowRoomTimeList)
    console.log("nearRoomTimeList", newNearRoomTimeList)

    setNowRoomTimeList(newNowRoomTimeList);
    setNearRoomTimeList(newNearRoomTimeList);
  }, [reservation]);


  const onSearch = (params) => {
    console.log(params);

    let paramStr = "";
    if (params.date) {
      paramStr += `date=${params.date}&`
    }
    if (params.start_time) {
      paramStr += `start_time=${params.start_time}&`
    }
    if (params.end_time) {
      paramStr += `end_time=${params.end_time}&`
    }
    if (params.room_type_id) {
      paramStr += `room_type_id=${params.room_type_id}&`
    }

    history.push(`home/reservationsearch?${paramStr}`);
  }


  return (
    <div className="pageContainer">
      <Container>
        <Row>
          <Col>
            <h2>建立預約</h2>
          </Col>
        </Row>
        <Row>
          <Col lg="4">
            <AvailableReservationSearchPanel onSearch={onSearch} />
            <br />
          </Col>
          <Col lg="8">
            <Tabs defaultActiveKey="uploadSong" id="uncontrolled-tab-example" fill>
              <Tab eventKey="uploadSong" title="當前可預約琴房">
                <LoadingContainer isLoading={reservation.isFetching}>
                  <div className="teacher-table-container">
                    {(nowRoomTimeList.length !== 0) ?
                      <Table striped bordered hover responsive>
                        <thead>
                          <tr>
                            <th>琴房</th>
                            <th>開始時間</th>
                            <th>結束時間</th>
                          </tr>
                        </thead>
                        <tbody>
                          {nowRoomTimeList.map(
                            (room, i) =>
                              <LinkContainer to={`${path}/reservationform?room_name=${room.room_name}&date=${room.date.unix()}`}>
                                <tr key={i} className='cursor-pointer'>
                                  <td>{room.room_name}</td>
                                  <td>{room.start_time.format("HH:mm")}</td>
                                  <td>{room.end_time.format("HH:mm")}</td>
                                </tr>
                              </LinkContainer>

                          )}
                        </tbody>
                      </Table>
                      : <h5>目前無可預約琴房</h5>}
                  </div>
                </LoadingContainer>
              </Tab>
              <Tab eventKey="manageSong" title="即將可使用琴房">
                <LoadingContainer isLoading={reservation.isFetching}>
                  <div className="song-table-container">
                    {(nearRoomTimeList.length !== 0) ?
                      <Table striped bordered hover responsive>
                        <thead>
                          <tr>
                            <th>琴房</th>
                            <th>開始時間</th>
                            <th>結束時間</th>
                          </tr>
                        </thead>
                        <tbody>
                          {nearRoomTimeList.map(
                            (room, i) =>
                              <LinkContainer to={`${path}/reservationform?room_name=${room.room_name}&date=${room.date.unix()}`}>
                                <tr key={i} className='cursor-pointer'>
                                  <td>{room.room_name}</td>
                                  <td>{room.start_time.format("HH:mm")}</td>
                                  <td>{room.end_time.format("HH:mm")}</td>
                                </tr>
                              </LinkContainer>
                          )}
                        </tbody>
                      </Table>
                      : <h5>目前無即將可預約琴房</h5>}
                  </div>
                </LoadingContainer>
              </Tab>
            </Tabs>
          </Col>
        </Row>
      </Container>

    </div >
  );

}

