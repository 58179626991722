import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { Modal, Button } from "react-bootstrap"
import { deleteSongFromEvent } from '../../../redux/event/eventThunk';


export default function DeleteSongDialog({ deleteSongList, show, onClose, onConfirm }) {
    const dispatch = useDispatch();

    const user = useSelector(state => state.user);


    useEffect(() => {
        setShow(show);
    }, [show]);

    useEffect(() => {
        if (deleteSongList.length === 0) {
            handleClose();
        }
    }, [deleteSongList])

    const [showState, setShow] = useState(show);

    const handleClose = () => {
        setShow(false);
        onClose();
    }

    const handleDelete = () => {
        deleteSongList.forEach((song) => {
            dispatch(deleteSongFromEvent({ token: user.token, song_id: song.id, event_id: song.event }));
        });
        onConfirm();
        handleClose();
    }

    return (
        <Modal show={showState} >
            <Modal.Header>
                <Modal.Title>是否刪除選取的{deleteSongList.length}筆曲目資料?</Modal.Title>
            </Modal.Header>
            <Modal.Footer>
                <Button variant="danger" onClick={handleDelete}>
                    確認刪除
                </Button>
                <Button variant="primary" onClick={handleClose}>
                    取消
                </Button>
            </Modal.Footer>
        </Modal>);
}