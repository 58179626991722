import React, { useEffect, useState } from 'react';
import {
    useRouteMatch,
    useHistory
} from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Container, Button, Table } from "react-bootstrap"
import { LinkContainer } from 'react-router-bootstrap'
import { userIdentity } from '../../redux/user/userSlice';
import { fetchAllSemesters, fetchSemesterDetailById } from '../../redux/semester/semesterThunk';
import { getTimeFormatStringDate } from '../../utils/utils';
import AddSemesterDialog from '../AddAdminSemesterPage/AddSemesterDialog';
import AdminDeleteSemesterDialog from './dialogs/AdminDeleteSemesterDialog';
import LoadingContainer from '../../components/LoadingContainer/LoadingContainer';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function AdminSemesterManagePage() {

    const { path, url } = useRouteMatch();
    const dispatch = useDispatch();
    const history = useHistory();


    const user = useSelector(state => state.user);
    const semester = useSelector(state => state.semester);

    const [showAddSemesterDialog, setShowAddSemesterDialog] = useState(false);
    const [selectedSemester, setSelectedSemester] = useState(null);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [isFetching, setIsFetching] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    console.log(semester.semesters);

    const [open, setOpen] = React.useState(false);


    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };


    useEffect(async() => {
        setIsFetching(true);
        const result =await dispatch(fetchAllSemesters({ token: user.token }));

        console.log(result);

        if (result.meta.requestStatus !== "fulfilled") {
            const errorMessage = result.payload.data.message;
            setOpen(true);
        }
        setIsFetching(false);
    }, [])

    useEffect(() => {
        if (semester.semesters.length === 0) {
            return;
        }
    }, [semester])

    useEffect(() => {
        if ((user.profile.identity !== userIdentity.admin_f) && (user.profile.identity !== userIdentity.admin_s)) {
            history.replace("home");
        }
    }, [user])






    return (
        <div className="pageContainer">
            <Container>
                <Row style={{ margin: 10 }}>
                    <Col md={{ span: 8, offset: 2 }}>
                        <h2>學期課表</h2>
                    </Col>
                    <Col md={2}><Button size='lg' onClick={() => setShowAddSemesterDialog(true)}>新增課表</Button></Col>
                </Row>
                <LoadingContainer isLoading={isFetching}>
                <Row >
                    <Col>
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>標號</th>
                                    <th>學期</th>
                                    <th>開始日期</th>
                                    <th>結束日期</th>
                                    <th>更新</th>
                                    <th>清除</th>
                                </tr>
                            </thead>
                            <tbody>
                                {semester.semesters.map(
                                    (semester, i) =>

                                        <tr key={semester.id} className='semester-row'>
                                            <td>{i + 1}</td>
                                            <td>{semester.name}</td>
                                            <td>{getTimeFormatStringDate(semester.start_date)}</td>
                                            <td>{getTimeFormatStringDate(semester.end_date)}</td>
                                            <td>
                                                <LinkContainer to={`${path}/semesterpage/${semester.id}`}>
                                                    <Button>修改</Button>
                                                </LinkContainer>
                                            </td>
                                            <td>
                                                <Button
                                                    variant="danger"
                                                    onClick={() => {
                                                        setSelectedSemester(semester);
                                                        setShowDeleteDialog(true);
                                                    }}>
                                                    刪除
                                                </Button>
                                            </td>
                                        </tr>

                                )}

                            </tbody>
                        </Table>
                    </Col>
                </Row>
                </LoadingContainer>
            </Container>
            <AddSemesterDialog show={showAddSemesterDialog} onClose={() => setShowAddSemesterDialog(false)} onConfirm={() => { }} />
            <AdminDeleteSemesterDialog
                show={showDeleteDialog}
                semester={selectedSemester}
                onClose={() => setShowDeleteDialog(false)}
                onDelete={() => {}}
            />
            <Snackbar open={open} autoHideDuration={6000} onClose={handleAlertClose}>
                <Alert onClose={handleAlertClose} severity="error" sx={{ width: '100%' }}>
                    讀取學期資料時發生系統錯誤，請聯絡維護人員
                </Alert>
            </Snackbar>
            
        </div>
    );
}

