import { createSlice, current } from '@reduxjs/toolkit';

import { fetchAllTeachers, fetchAllTeachingTypes, addNewTeachingType, deleteTeachingType } from './teacherThunk';


export const initialState = {
    teachers: [],
    teachingTypes: [],
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorStatus: 0,
    errorMessage: '',
}

const teacherSlice = createSlice({
    name: 'teacher',
    initialState,
    reducers: {
    },
    extraReducers: {

        [fetchAllTeachers.fulfilled]: (state, { payload }) => {
            console.log('payload', payload)
            state.teachers = payload;
            state.isFetching = false;
            state.isSuccess = true;
            return state;
        },
        [fetchAllTeachers.rejected]: (state, { payload }) => {
            console.log('reject payload', payload)
            state.isError = true;
            state.isSuccess = false;
            state.isFetching = false;
            state.errorStatus = payload.status;
            state.errorMessage = payload.data.message;
        },
        [fetchAllTeachers.pending]: (state) => {
            state.isFetching = true;
        },

        [fetchAllTeachingTypes.fulfilled]: (state, { payload }) => {
            console.log('payload', payload)
            state.teachingTypes = payload;
            state.isFetching = false;
            state.isSuccess = true;
            return state;
        },
        [fetchAllTeachingTypes.rejected]: (state, { payload }) => {
            console.log('reject payload', payload)
            state.isError = true;
            state.isSuccess = false;
            state.isFetching = false;
            state.errorStatus = payload.status;
            state.errorMessage = payload.data.message;
        },
        [fetchAllTeachingTypes.pending]: (state) => {
            state.isFetching = true;
        },

        [addNewTeachingType.fulfilled]: (state, { payload }) => {
            console.log('payload', payload)
            state.teachingTypes.push(payload);
            state.isFetching = false;
            state.isSuccess = true;
            return state;
        },
        [addNewTeachingType.rejected]: (state, { payload }) => {
            console.log('reject payload', payload)
            state.isError = true;
            state.isSuccess = false;
            state.isFetching = false;
            state.errorStatus = payload.status;
            state.errorMessage = payload.data.message;
        },
        [addNewTeachingType.pending]: (state) => {
            state.isFetching = true;
        },

        [deleteTeachingType.fulfilled]: (state, { payload }) => {
            console.log('payload', payload)
            let index = state.teachingTypes.findIndex((teachingType) => teachingType.id === payload.id);
            state.teachingTypes.splice(index, 1);
            state.isFetching = false;
            state.isSuccess = true;
            return state;
        },
        [deleteTeachingType.rejected]: (state, { payload }) => {
            console.log('reject payload', payload)
            state.isError = true;
            state.isSuccess = false;
            state.isFetching = false;
            state.errorStatus = payload.status;
            state.errorMessage = payload.data.message;
        },
        [deleteTeachingType.pending]: (state) => {
            state.isFetching = true;
        },




    }
});








export default teacherSlice;