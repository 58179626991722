import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    useRouteMatch,
    useHistory
} from "react-router-dom";
import { Row, Col, Container, Button, Form, Card } from "react-bootstrap"
import { LinkContainer } from 'react-router-bootstrap'
import DatePicker from 'react-datepicker';

import useQuery from '../../utils/useQuery'
import moment from 'moment';
import { fetchAllRooms } from '../../redux/room/roomThunk';
import { userIdentity } from '../../redux/user/userSlice';
import { fetchAllTeachers, fetchAllUsers } from '../../redux/userList/userListThunk';

import './AdminReservationFormPage.css'
import { addWeekReserve, fetchAllSemesters } from '../../redux/semester/semesterThunk';
import LoadingContainer from '../../components/LoadingContainer/LoadingContainer';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function AddWeekReservationPage() {
    const dispatch = useDispatch();
    const history = useHistory();
    const query = useQuery();

    const user = useSelector(state => state.user);
    const userList = useSelector(state => state.userList);
    const room = useSelector(state => state.room);
    const semester = useSelector(state => state.semester);

    const semester_id = query.get('semester_id');
    const weekday = query.get('weekday');
    const lesson = query.get('lesson');
    const roomName = query.get('room_name');

    const [selectedSemester, setSelectedSemester] = useState(null);
    const [semesterName, setSemesterName] = useState('');
    const [beginDate, setBeginDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');
    const [lessonName, setLessonName] = useState('請輸入課程名稱');
    const [teacher, setTeacher] = useState('請選擇');
    const [teacherList, setTeacherList] = useState([]);
    const [isFetching, setIsFetching] = useState(false);

    const [errorMessage, setErrorMessage] = useState("");

    const lessonTimeInt = {
        '1': [8 * 3600, 8 * 3600 + 50 * 60],
        '2': [9 * 3600, 9 * 3600 + 50 * 60],
        '3': [10 * 3600 + 10 * 60, 11 * 3600],
        '4': [11 * 3600 + 10 * 60, 12 * 3600],
        'n': [12 * 3600 + 10 * 60, 13 * 3600],
        '5': [13 * 3600 + 20 * 60, 14 * 3600 + 10 * 60],
        '6': [14 * 3600 + 20 * 60, 15 * 3600 + 10 * 60],
        '7': [15 * 3600 + 30 * 60, 16 * 3600 + 20 * 60],
        '8': [16 * 3600 + 30 * 60, 17 * 3600 + 20 * 60],
        '9': [17 * 3600 + 30 * 60, 18 * 3600 + 20 * 60],
        'a': [18 * 3600 + 30 * 60, 19 * 3600 + 20 * 60],
        'b': [19 * 3600 + 30 * 60, 20 * 3600 + 20 * 60],
        'c': [20 * 3600 + 30 * 60, 21 * 3600 + 20 * 60],

    };
    const lessonTimeStr = {
        '1': ['8:00', '8:50'],
        '2': ['9:00', '9:50'],
        '3': ['10:10', '11:00'],
        '4': ['11:10', '12:00'],
        'n': ['12:10', '13:00'],
        '5': ['13:20', '14:10'],
        '6': ['14:20', '15:10'],
        '7': ['15:30', '16:20'],
        '8': ['16:30', '17:20'],
        '9': ['17:30', '18:20'],
        'a': ['18:30', '19:20'],
        'b': ['19:30', '20:20'],
        'c': ['20:30', '21:20']
    }


    useEffect(() => {
        dispatch(fetchAllRooms({ token: user.token }));
        dispatch(fetchAllUsers({ token: user.token }));
        dispatch(fetchAllTeachers({ token: user.token }));
    }, [])

    useEffect(async () => {
        if (semester.semesters.length === 0) {
            await dispatch(fetchAllSemesters({ token: user.token }));
        }
        if (userList.teacherList.length === 0) {
            await dispatch(fetchAllTeachers({ token: user.token }));
        }
        setTeacherList(userList.teacherList);
    }, []);

    useEffect(() => {
        if ((user.profile.identity !== userIdentity.admin_f) && (user.profile.identity !== userIdentity.admin_s)) {
            history.replace("home");
        }
    }, [user])


    useEffect(() => {
        const findedSemester = semester.semesters.find(element => element.id === semester_id);

        if (!findedSemester) {
            return;
        }
        console.log(findedSemester);
        setSelectedSemester(findedSemester);
        setSemesterName(findedSemester.name);
        setBeginDate(new Date(findedSemester.start_date * 1000));
        setEndDate(new Date(findedSemester.end_date * 1000));
        setStartTime(lessonTimeStr[lesson][0]);
        setEndTime(lessonTimeStr[lesson][1]);
    }, [semester]);


    const handleSearch = async (event) => {
        if (lessonName === '請輸入課程名稱') {
            setErrorMessage("請輸入課程名稱");
            return;
        }
        if (teacher === '請選擇') {
            setErrorMessage("請選擇老師");
            return;
        }
        
        const findTeacher = userList.teacherList.find(e => e.name === teacher);
        console.log(findTeacher);
        if (!findTeacher) {
            setErrorMessage("老師使用者不存在");
            return;
        }

        const param = {
            token: user.token,
            semesterId: semester_id,
            roomName: roomName,
            weekDay: weekday,
            lesson: lesson,
            lessonName: lessonName,
            teacherId: findTeacher['id'],
        }
        setIsFetching(true);
        let result = await dispatch(addWeekReserve(param));
        console.log(result);

        if (result.meta.requestStatus === "fulfilled") {
            history.goBack();
        } else {
            const errorMessage = result.payload.data.message;
            if (errorMessage.includes("Sorry! Room has other reservation in the time interval.")) {
                setErrorMessage("抱歉！此琴房在此時段已有預約");
            } else if (errorMessage.includes("Sorry! The reservation has exceed the hour limit.")) {
                setErrorMessage("抱歉！已超過該使用者時數限制");
            } else if (errorMessage.includes("Sorry! Teacher has other reservation in the time interval.")) {
                setErrorMessage("抱歉！使用者在此期間已有預約");
            } else {
                setErrorMessage("系統錯誤，請聯絡維護人員");
            }
        }
        setIsFetching(false);

        return;
    };


    return (
        <div className="pageContainer">
            <Container>
                <Row>
                    <Col md={{ span: 8, offset: 2 }}>
                        <h2>學期課表</h2>
                    </Col>
                </Row>
                <br />
                <LoadingContainer isLoading={isFetching}>
                    <Row>
                        <Col>
                            <Card>
                                <Card.Body>
                                    <Card.Title><h3>{semesterName}</h3></Card.Title>
                                    <br />
                                    <Row className="justify-content-center">
                                        <Col md={1}>
                                            <Card.Text>學期時間</Card.Text>
                                        </Col>
                                        <Col md={2}>
                                            <Card.Text className="align-right">開始日期</Card.Text>
                                        </Col>
                                        <Col md={2}>
                                            <DatePicker disabled selected={beginDate} onChange={(date) => setBeginDate(date)} />
                                        </Col>
                                        <Col md={2}>
                                            <Card.Text className="align-right">結束日期</Card.Text>
                                        </Col>
                                        <Col md={2}>
                                            <DatePicker disabled selected={endDate} onChange={(date) => setEndDate(date)} />
                                        </Col>
                                    </Row>
                                    <br />
                                    <Row className="justify-content-center">
                                        <Col md={1}>
                                            <Card.Text>預約時間</Card.Text>
                                        </Col>
                                        <Col md={2}>
                                            <Card.Text className="align-right">開始時間</Card.Text>
                                        </Col>
                                        <Col md={2}>
                                            <Card.Text className="align-left">{startTime}</Card.Text>
                                        </Col>
                                        <Col md={2}>
                                            <Card.Text className="align-right">結束時間</Card.Text>
                                        </Col>
                                        <Col md={2}>
                                            <Card.Text className="align-left">{endTime}</Card.Text>
                                        </Col>
                                    </Row>
                                    <br />
                                    <Row className="justify-content-center">
                                        <Col md={1}>
                                            <Card.Text >琴房名稱</Card.Text>
                                        </Col>
                                        <Col md={{ offset: 1, span: 2 }}>
                                            <Card.Text>{roomName}</Card.Text>
                                        </Col>
                                        <Col md={5}>
                                        </Col>
                                    </Row>
                                    <br />
                                    <Row className="justify-content-center">
                                        <Col md={1}>
                                            <Card.Text>課程名稱</Card.Text>
                                        </Col>
                                        <Col md={{ offset: 1, span: 2 }}>
                                            <Form.Control column as="input" placeholder="請輸入課程名稱" onChange={e => setLessonName(e.target.value)} />
                                        </Col>
                                        <Col md={5}>
                                        </Col>
                                    </Row>
                                    < br />
                                    <Row className="justify-content-center">
                                        <Col md={1}>
                                            <Card.Text>老師名稱</Card.Text>
                                        </Col>
                                        <Col md={{ offset: 1, span: 2 }}>
                                            <Form.Select value={teacher} onChange={e => setTeacher(e.target.value)}>
                                                <option key={-1}>請選擇</option>
                                                {userList.teacherList.map(
                                                    (teacher, i) =>
                                                        <option key={i}>{teacher.name}</option>
                                                )}
                                            </Form.Select>
                                        </Col>
                                        <Col md={5}>
                                        </Col>
                                    </Row>
                                    < br />
                                    <Row>
                                        <Col md={{ span: 4, offset: 4 }} className={"login-error-message-col"}>
                                            <p className="login-error-message">{errorMessage}</p>
                                        </Col>
                                    </Row>
                                    <br />
                                    <LinkContainer to={`reservationsemester/semesterpage/${semester_id}`}><Button variant="secondary">返回</Button></LinkContainer>

                                    <Button variant="primary" onClick={handleSearch}>預約</Button>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </LoadingContainer>
                <br />

            </Container>
        </div>
    );
}

