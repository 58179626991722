import { useState } from "react"
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table } from "react-bootstrap"

import ConditionalContainer from '../ConditionalContainer'

import './UserReservationAvalibilityTable.css'
import moment, { invalid, max } from "moment";
import { roundStartTime } from "../../utils/utils";



export default function UserReservationAvalibilityTable({ cycle, filteredAvailableTimeList, reservation, onClick }) {


    const colSpan = 3600 / cycle;


    const [availabilityTable, setAvailabiilityTable] = useState([]);
    const [hourRange, setHourRange] = useState([0, 24]);



    // Calculate hourspan
    useEffect(() => {

        let minHour = 24;
        let maxHour = 0;

        filteredAvailableTimeList.forEach((timeInterval) => {
            const timeIntervalStartMoment = moment(timeInterval[0] * 1000);
            const timeIntervalEndMoment = moment(timeInterval[1] * 1000);
            const timeIntervalStartHour = timeIntervalStartMoment.hours();
            let timeIntervalEndHour = timeIntervalEndMoment.hours();
            timeIntervalEndHour = timeIntervalEndHour === 0 ? 24 : timeIntervalEndHour;
            if (timeIntervalStartHour < minHour) {
                minHour = timeIntervalStartHour;
            }
            if (timeIntervalEndHour > maxHour) {
                maxHour = timeIntervalEndHour;
            }
        });
        minHour = Math.max(minHour - 1, 0);
        maxHour = Math.min(maxHour + 1, 24);


        if (minHour < maxHour) {
            setHourRange([minHour, maxHour]);
        }

    }, [filteredAvailableTimeList]);


    useEffect(() => {

        // Create empty span
        let newAvailabilityTable =
            [...Array((hourRange[1] - hourRange[0]) * colSpan).keys()].map(
                i => (i / colSpan) + hourRange[0]).map(
                    (hour, i) => {
                        const startHour = hour;
                        const endHour = hour + 1 / colSpan;
                        let invalid = true;
                        let isReservation = false;

                        filteredAvailableTimeList.forEach((timeInterval) => {
                            const timeIntervalStartMoment = moment(timeInterval[0] * 1000);
                            const timeIntervalEndMoment = moment(timeInterval[1] * 1000);
                            const timeIntervalStartHour = timeIntervalStartMoment.get("hour") + timeIntervalStartMoment.get("minute") / 60;
                            let timeIntervalEndHour = timeIntervalEndMoment.get("hour") + timeIntervalEndMoment.get("minute") / 60;
                            timeIntervalEndHour = timeIntervalEndHour === 0 ? 24 : timeIntervalEndHour;
                            if (timeIntervalStartHour <= startHour && endHour <= timeIntervalEndHour) {
                                invalid = false;
                            }
                        });
                        if (reservation) {
                            const reservationStartTime = roundStartTime(reservation.start_time, cycle);
                            const reservationEndTime = moment(reservation.end_time * 1000);
                            if (reservationStartTime.get("hour") + reservationStartTime.get("minute") / 60 <= startHour
                                && endHour <= reservationEndTime.get("hour") + reservationEndTime.get("minute") / 60) {
                                invalid = false;
                                isReservation = true;
                            }
                        }
                        return {
                            invalid: invalid,
                            isReservation: isReservation,
                            span: [startHour, endHour],
                        }
                    }
                )

        console.log(newAvailabilityTable);
        setAvailabiilityTable(newAvailabilityTable);

    }, [filteredAvailableTimeList, hourRange]);


    const handleClick = (cell) => {
        if (onClick) {
            onClick(cell);
        }
    }

    return (
        <Table bordered responsive>
            <thead>
                <tr>
                    <th colSpan={1} key={0}></th>
                    <th colSpan={colSpan} key={1}>
                        {cycle / 60}分鐘
                    </th>
                </tr>
            </thead>
            <tbody>
                {[...Array((hourRange[1] - hourRange[0])).keys()].map(i => i + hourRange[0]).map(
                    (hour, iIdx) =>
                        <tr colSpan={1} key={iIdx}>
                            <td>{`${Math.floor(hour).toString().padStart(2, "0")}:${(hour % 1 * 60).toString().padStart(2, "0")}-${(Math.floor(hour + 1)).toString().padStart(2, "0")}:${((hour + 1) % 1 * 60).toString().padStart(2, "0")}`}</td>
                            {[...Array(colSpan).keys()].map(
                                (jIdx) => {
                                    let cell = availabilityTable[iIdx * colSpan + jIdx];
                                    cell = cell ?? { invalid: true };
                                    return (
                                        <td
                                            className={`${cell.invalid ? "user-invalid-cell" : "user-valid-cell"} ${cell.isReservation ? "user-reservation-cell" : ""}`}
                                            colSpan={1}
                                            key={iIdx * colSpan + jIdx}
                                            onClick={() => handleClick(cell)}
                                        >
                                        </td>
                                    );
                                }
                            )}
                        </tr>
                )}
            </tbody>
        </Table>
    )
}