import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { Modal, Button, Form, Row, Col } from "react-bootstrap"
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import ConditionalContainer from "../../../components/ConditionalContainer"
import { setUserStatus } from '../../../redux/userList/userListThunk';


export default function SetUserStatusDialog({ selectedUserList, show, onClose, onConfirm }) {
    const dispatch = useDispatch();

    const user = useSelector(state => state.user);
    const userList = useSelector(state => state.userList);


    const userBannedOptions = [
        { name: "停權", value: true },
        { name: "復權", value: false },
    ]


    const [showState, setShow] = useState(show);
    const [banned, setBanned] = useState(userBannedOptions[1]);
    const [date, setDate] = useState(null);




    useEffect(() => {
        setShow(show);
    }, [show]);

    useEffect(() => {
        const selectedUserCount = Object.keys(selectedUserList).filter(element => selectedUserList[element]).length;
        if (selectedUserCount === 0) {
            handleClose();
        } else if (selectedUserCount === 1) {
            const findedUserId = Object.keys(selectedUserList).find(element => selectedUserList[element] === true);
            const selectedUser = userList.userList[findedUserId];
            setBanned(selectedUser.banned ? userBannedOptions[0] : userBannedOptions[1]);
            if (selectedUser.banned_due !== 0) {
                setDate(new Date(selectedUser.banned_due * 1000));
            }
        } else {
            setBanned(userBannedOptions[1]);
            setDate(null);
        }
    }, [selectedUserList, show]);


    const handleClose = () => {
        setDate(null);
        setShow(false);
        onClose();
    }

    const handleSetNote = () => {
        Object.keys(selectedUserList).forEach((id) => {
            if (selectedUserList[id]) {
                const banned_due = date ? Math.floor(date.getTime() / 1000) : 0
                dispatch(setUserStatus({ token: user.token, user_id: id, banned: banned.value, banned_due: banned_due }));
            }
        });
        onConfirm();
        handleClose();
    }

    return (
        <Modal show={showState} >
            <Modal.Header>
                <Modal.Title>狀態設置</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row className="justify-content-center">
                    <Col md={4}>
                        <Form.Label>狀態:</Form.Label>
                    </Col>
                    <Col md={6}>
                        <div key={"banned-radio"} className="mb-3">
                            {userBannedOptions.map(
                                (option, i) =>
                                    <Form.Check
                                        key={i}
                                        inline
                                        checked={banned.value === option.value}
                                        label={option.name}
                                        name="banned-radio"
                                        type="radio"
                                        id={`banned-radio-${i}`}
                                        onChange={() => setBanned(option)}
                                    />
                            )}
                        </div>
                    </Col>
                </Row>
                <ConditionalContainer condition={banned.value}>
                    <Row className="justify-content-center">
                        <Col md={4}>
                            <Form.Label>停權時間:</Form.Label>
                        </Col>
                        <Col md={6}>
                            <DatePicker selected={date} onChange={(date) => setDate(date)} />
                        </Col>
                    </Row>
                </ConditionalContainer>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleSetNote}>
                    確認
                </Button>
                <Button variant="primary" onClick={handleClose}>
                    取消
                </Button>
            </Modal.Footer>
        </Modal>);
}