import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Container, Button, Table, NavDropdown, Nav, Accordion, Badge, Tabs, Tab, Card, Form } from "react-bootstrap";
import { useHistory, useRouteMatch } from 'react-router';
import { userIdentity } from '../../redux/user/userSlice';




export default function UserReservationPausePage() {

    const dispatch = useDispatch();
    const history = useHistory();
    const { path, url } = useRouteMatch();

    const user = useSelector(state => state.user);
    const system = useSelector(state => state.system);

    useEffect(() => {
        if (user.profile.identity === userIdentity.admin_f || user.profile.identity === userIdentity.admin_s) {
            history.replace("adminhome");
        }
        if (system.info.allow_all_reserve === true) {
            history.replace("home");
        }
    }, [user, system])

    return (
        <div className="pageContainer">
            <Container>
                <Row>
                    <Col>
                        <h2>親愛的{user.profile.name}，您好!</h2>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p>目前琴房暫時全面停止預約，需要等管理員重新開啟預約功能，有任何問題請聯絡管理員或洽詢系辦，謝謝!</p>
                    </Col>
                </Row>
            </Container>

        </div >
    );

}

