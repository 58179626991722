import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { Modal, Button, Form, ListGroup, CloseButton, Row, Col } from "react-bootstrap"
import { addNewType } from '../../../redux/type/typeThunk';

import './EditClassroomRegulationDialog.css'
import { uploadClassroomRuleImage, deleteClassroomRuleFile, deleteClassroomRuleImage, updateClassroomRule, uploadClassroomRuleFile } from '../../../redux/system/systemThunk';


export default function EditClassroomRegulationDialog({ show, onClose, onConfirm }) {
    const dispatch = useDispatch();

    const user = useSelector(state => state.user);
    const system = useSelector(state => state.system);


    const [showState, setShow] = useState(show);
    const [validated, setValidated] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const [regulation, setRegulation] = useState(system.info.room_rules);

    const hiddenImageInput = React.useRef(null);
    const hiddenFileInput = React.useRef(null);


    useEffect(() => {
        setShow(show);
    }, [show]);

    useEffect(() => {
        setRegulation(system.info.room_rules);
    }, [system.info.room_rules]);


    const handleClose = () => {
        setShow(false);
        setValidated(false);
        onClose();
    }

    const handleSubmit = async (event) => {

        const form = event.currentTarget;
        event.preventDefault();

        determineErrorMessage();

        if (form.checkValidity() === false || !isInputValid()) {
            event.stopPropagation();
        } else {
            dispatch(updateClassroomRule({ token: user.token, rules: regulation }));
            onConfirm();
            handleClose();
        }

        setValidated(true);

    }


    const isInputValid = () => {
        return isLengthValid();
    }

    const determineErrorMessage = () => {
        if (!isLengthValid()) {
            setErrorMessage("請輸入字元在10000字以內的內容!");
        }

    }


    const isLengthValid = () => {
        return regulation.length <= 10000;
    }

    const deleteImage = (image_id) => {
        console.log("delete image")
        dispatch(deleteClassroomRuleImage({ token: user.token, image_id: image_id }));
    }

    const deleteFile = (file_id) => {
        dispatch(deleteClassroomRuleFile({ token: user.token, file_id: file_id }));
    }


    const uploadImage = (event) => {
        const image = event.target.files[0];
        dispatch(uploadClassroomRuleImage({ token: user.token, image: image }));
    }

    const uploadFile = (event) => {
        const file = event.target.files[0];
        dispatch(uploadClassroomRuleFile({ token: user.token, file: file }));
    }

    return (
        <Modal show={showState} dialogClassName="modal-80w">
            <Modal.Header>
                <Modal.Title>琴房管理辦法編輯</Modal.Title>
            </Modal.Header>
            <Form noValidate onSubmit={handleSubmit}>
                <Modal.Body>
                    <Row>
                        <Col>
                            <Form.Control
                                column
                                value={regulation}
                                as="textarea"
                                rows={10}
                                placeholder=""
                                isValid={isInputValid() && validated}
                                isInvalid={!isInputValid() && validated}
                                onChange={e => setRegulation(e.target.value)} />
                            <Form.Control.Feedback type="invalid">{errorMessage}</Form.Control.Feedback>
                        </Col>
                    </Row>
                    <Row className="align-items-center justify-content-md-center images-row">
                        <Col md={2} className="text-center">
                            <h6>公告圖片:</h6>
                        </Col>
                        <Col>
                            <ListGroup horizontal>
                                {/* <Row> */}
                                {system.images.map((image, index) =>
                                    // <Col md={4}>
                                    <ListGroup.Item key={index}>
                                        <a href={image.url}>{image.url.split('/').pop()}</a>
                                        <CloseButton onClick={() => { deleteImage(image.id) }} />
                                    </ListGroup.Item>
                                    // </Col>
                                )}
                                {/* </Row> */}
                            </ListGroup>
                        </Col>
                        <Col md={2} className="text-center">
                            <Button onClick={() => hiddenImageInput.current.click()}>上傳圖片</Button>
                            <input
                                type="file"
                                ref={hiddenImageInput}
                                onChange={uploadImage}
                                style={{ display: 'none' }}
                                accept="image/png, image/jpeg"
                            />
                        </Col>
                    </Row>
                    <Row className="align-items-center justify-content-md-center files-row">
                        <Col md={2} className="text-center">
                            <h6>附加檔案:</h6>
                        </Col>
                        <Col>
                            <ListGroup horizontal>
                                {/* <Row> */}
                                {system.files.map((file, index) =>
                                    // <Col md={4}>
                                    <ListGroup.Item key={index}>
                                        <a href={file.url}>{file.url.split('/').pop()}</a>
                                        <CloseButton onClick={() => deleteFile(file.id)} />
                                    </ListGroup.Item>
                                    // </Col>
                                )}
                                {/* </Row> */}
                            </ListGroup>
                        </Col>
                        <Col md={2} className="text-center">
                            <Button onClick={() => hiddenFileInput.current.click()}>附加檔案</Button>
                            <input
                                type="file"
                                ref={hiddenFileInput}
                                onChange={uploadFile}
                                style={{ display: 'none' }}
                                accept=".pdf, .doc, .docs"
                            />
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" type="submit">
                        確認更改
                    </Button>
                    <Button variant="secondary" onClick={handleClose}>
                        取消
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal >);
}