import { useState } from "react"
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table } from "react-bootstrap"

import ConditionalContainer from '../ConditionalContainer'
import LoadingContainer from '../LoadingContainer/LoadingContainer'


import './AdminMultiroomReservationTable.css'
import moment, { invalid, max } from "moment";
import { fetchReservationAvaliable } from "../../redux/reservation/reservationThunk";
import { roundStartTime, roundEndTime } from "../../utils/utils";



export default function AdminMultiroomReservationTable({ roomType, dateObject, reservationList, onClick }) {
    const dispatch = useDispatch();

    const user = useSelector(state => state.user);
    const room = useSelector(state => state.room);
    const reservation = useSelector(state => state.reservation);


    const date = moment(dateObject).startOf('day');

    const [isLoading, setIsLoading] = useState(true);

    const [roomsAvaliableTimes, setRoomsAvaliableTimes] = useState({});
    const [filteredReservationList, setFilteredReservationList] = useState([]);
    const [colSpan, setColSpan] = useState(1);
    const [roomList, setRoomList] = useState([]);
    const [reservationTable, setReservationTable] = useState({});

    const [hourRange, setHourRange] = useState([0, 24]);





    useEffect(async () => {
        if (!roomType) {
            return;
        }
        // setIsLoading(true);
        const result = await dispatch(fetchReservationAvaliable({ token: user.token, options: { date: date.unix(), room_type_id: roomType.id } }));
        if (result.meta.requestStatus === "fulfilled") {
            const newRoomsAvailableTimes = result.payload;
            setRoomsAvaliableTimes(newRoomsAvailableTimes);

            if (Object.keys(newRoomsAvailableTimes).length === 0) {
                setIsLoading(false);
            }
        }
    }, [roomType, reservationList]);


    // Filter ReservationList
    useEffect(() => {
        if (!roomType) {
            return;
        }
        if (room.rooms.length === 0) {
            return;
        }
        const filteredList = reservationList.filter(element => {
            const findedRoom = room.rooms.find(element_in => element.room_name === element_in.name);
            if (!findedRoom) {
                return false;
            }
            return findedRoom.type === roomType.name && moment(date).startOf('day').unix() === element.date;
        });

        // console.log("date",moment(date).startOf('day').unix())
        // console.log("reservtionList", reservationList)
        // console.log("filteredList",filteredList);

        setFilteredReservationList(filteredList);
        setColSpan(3600 / roomType.units);

    }, [reservationList, roomType, room]);


    // Filter roomList
    useEffect(() => {
        if (!roomType) {
            return;
        }
        if (room.rooms.length === 0) {
            return;
        }
        const newRoomList = room.rooms.filter(element => element.type === roomType.name);
        setRoomList(newRoomList);

        if (newRoomList.length === 0) {
            setIsLoading(false);
        }

    }, [roomType, room]);


    // Calculate hourspan
    useEffect(() => {
        // let minHour = 24;
        // let maxHour = 0;

        // Object.keys(roomsAvaliableTimes).forEach((roomName) => {
        //     roomsAvaliableTimes[roomName].forEach((timeInterval, idx) => {
        //         if (idx === 0) {
        //             return;
        //         }
        //         let startHour = moment(timeInterval[0] * 1000).startOf("hour").get("hour");
        //         let endHour = moment(timeInterval[1] * 1000).startOf("hour").get("hour");
        //         endHour = (endHour === 0) ? 24 : endHour;
        //         endHour += 1;
        //         endHour = (endHour > 24) ? 24 : endHour;


        //         if (startHour < minHour) {
        //             minHour = startHour;
        //         }
        //         if (endHour > maxHour) {
        //             maxHour = endHour;
        //         }

        //     });
        // });

        // filteredReservationList.forEach((reservationItem, idx) => {
        //     const reservationStartTime = roundStartTime(reservationItem.start_time, roomType.units);
        //     const reservationEndTime = roundEndTime(reservationItem.end_time, roomType.units);
        //     const reservationStartHour = reservationStartTime.get("hours") + reservationStartTime.get("minutes") / 60;
        //     let reservationEndHour = reservationEndTime.get("hours") + reservationEndTime.get("minutes") / 60;

        //     if (reservationEndHour === 0) {
        //         reservationEndHour = 24;
        //     }

        //     if (reservationStartHour < minHour) {
        //         minHour = reservationStartHour;
        //     }
        //     if (reservationEndHour > maxHour) {
        //         maxHour = reservationEndHour;
        //     }


        // });

        // minHour -= 1;
        // maxHour += 1;

        // minHour = (minHour < 0) ? 0 : minHour;
        // maxHour = (maxHour > 24) ? 24 : maxHour;

        // if (minHour >= maxHour) {
        //     minHour = 8;
        //     maxHour = 20;
        // }

        // setHourRange([minHour, maxHour]);


        const dayType = moment(date).isoWeekday() < 6 ? "weekday" : "holiday";

        let allDailyCycleFlag = true;
        let minHour = 24;
        let maxHour = 0;
        roomList.forEach(roomItem => {
            const roomReserveTypeName = roomItem.reserve_type[dayType].type;
            const allowReserve = roomItem.reserve_type[dayType].allow_reserve;
            const reserveType = reservation.reservationTypes.find(reservationType => reservationType.name === roomReserveTypeName);
            if (!reserveType) {
                return;
            }
            if (reserveType.cycle === 86400) {
                const reservationTimeLimits = reserveType.allow_reserve;
                for (let i = 0; i < allowReserve.length; i++) {
                    const roomTimeLimit = Array.from(allowReserve[i], x => parseInt(x));
                    const reservationTimeLimit = Array.from(reservationTimeLimits[i], x => parseInt(x));
                    if (roomTimeLimit[0] / 3600 < minHour) {
                        minHour = Math.floor(roomTimeLimit[0] / 3600);
                    }
                    if (roomTimeLimit[1] / 3600 > maxHour) {
                        maxHour = Math.ceil(roomTimeLimit[1] / 3600);
                    }
                }
            } else {
                allDailyCycleFlag = false;
            }

        });
        // if (date.unix() === moment().startOf("day").unix()) {
        //     minHour = Math.max(minHour, moment().get("hours") - 1, 0);
        //     setHourRange([minHour, hourRange[1]]);
        // }
        if (allDailyCycleFlag && minHour < maxHour) {
            minHour = Math.max(minHour, 0);
            maxHour = Math.min(24, maxHour);
            setHourRange([minHour, maxHour]);
        } else {
            setHourRange([0, 24]);
        }

    }, [roomsAvaliableTimes, filteredReservationList, roomList]);


    useEffect(() => {
        if (!roomType) {
            return;
        }
        if (roomList.length === 0) {
            return;
        }


        let newReservationTable = {};

        // Create empty span
        roomList.forEach((roomItem, idx) => {
            newReservationTable[roomItem.id] =
                [...Array((hourRange[1] - hourRange[0]) * colSpan).keys()].map(
                    i => (i / colSpan) + hourRange[0]).map(
                        (hour, i) => {
                            const startHour = hour;
                            const endHour = hour + 1 / colSpan;
                            let invalid = true;
                            const availableIntervals = roomsAvaliableTimes[roomItem.name];

                            if (availableIntervals) {
                                availableIntervals.forEach((timeInterval) => {
                                    const timeIntervalStartMoment = moment(timeInterval[0] * 1000);
                                    const timeIntervalEndMoment = moment(timeInterval[1] * 1000);
                                    const timeIntervalStartHour = timeIntervalStartMoment.get("hour") + timeIntervalStartMoment.get("minute") / 60;
                                    let timeIntervalEndHour = timeIntervalEndMoment.get("hour") + timeIntervalEndMoment.get("minute") / 60;
                                    timeIntervalEndHour = timeIntervalEndHour === 0 ? 24 : timeIntervalEndHour;
                                    if (timeIntervalStartHour <= startHour && endHour <= timeIntervalEndHour) {
                                        invalid = false;
                                    }
                                });
                            } else {
                                invalid = true;
                            }

                            const passed = moment(date).startOf("day").add(endHour * 60, "minute").unix() < moment().unix();

                            return {
                                invalid: invalid,
                                passed: passed,
                                room: roomItem,
                                date: date,
                                reservation: null,
                                span: [startHour, endHour],
                            }
                        }
                    );
        });


        // Put reservation into span
        filteredReservationList.reverse().forEach((reservationItem, idx) => {
            const reservationRoom = room.rooms.find(element => element.name === reservationItem.room_name);
            let roomRow = newReservationTable[reservationRoom.id];
            const reservationStartTime = roundStartTime(reservationItem.start_time, roomType.units);
            const reservationEndTime = roundEndTime(reservationItem.end_time, roomType.units);
            const reservationStartHour = reservationStartTime.get("hours") + reservationStartTime.get("minutes") / 60;
            let reservationEndHour = reservationEndTime.get("hours") + reservationEndTime.get("minutes") / 60;

            if (reservationEndHour === 0) {
                reservationEndHour = 24;
            }
            if (moment(reservationItem.end_time * 1000).unix() < reservationEndTime.unix() && moment().unix() < reservationEndTime.unix()) {
                return;
            }
            
            const passed = reservationEndTime.unix() < moment().unix();

            let reservationCol = {
                invalid: false,
                passed: passed,
                room: reservationRoom,
                date: date,
                reservation: reservationItem,
                span: [reservationStartHour, reservationEndHour],
                is_semester_reserve: false,
            }

            if (reservationItem.is_semester_reserve) {
                reservationCol.is_semester_reserve = true
            }


            let startColIdx = roomRow.findIndex(element => element.span[0] === reservationStartHour);
            let endColIdx = roomRow.findIndex(element => element.span[1] === reservationEndHour);

            for (let i = startColIdx; i <= endColIdx; i++) {
                const roomCol = roomRow[i];
                if (roomCol && roomCol.reservation != null) {
                    return;
                }
            }

            roomRow.splice(startColIdx, endColIdx - startColIdx + 1, reservationCol);


            newReservationTable[reservationRoom.id] = roomRow;
        });

        setReservationTable(newReservationTable);

        if (Object.keys(roomsAvaliableTimes).length !== 0) {
            setIsLoading(false);
        }

        // if (roomType.name === "大琴房") {
        //     console.log(roomsAvaliableTimes)
        //     console.log(newReservationTable);
        //     console.log("setIsLoading false");
        // }

    }, [hourRange]);


    const handleClick = (col) => {
        if (onClick) {
            onClick(col);
        }
    }

    return (
        <LoadingContainer isLoading={isLoading}>
            <Table bordered responsive>
                <thead>
                    <tr>
                        <th colSpan={1} key={-1}><div style={{ width: "60px" }}>{roomType ? roomType.name : ""}</div></th>
                        {[...Array((hourRange[1] - hourRange[0]) * colSpan).keys()].map(i => (i / colSpan) + hourRange[0]).map(
                            (hour, i) =>
                                <th colSpan={1} key={i}>
                                    <div className="reservation-table-cell-header" style={{ width: `${2 / colSpan * 25}px` }}>
                                        {`${Math.floor(hour).toString().padStart(2, "0")}:${(hour % 1 * 60).toString().padStart(2, "0")}-
                                ${(Math.floor(hour + 1 / colSpan)).toString().padStart(2, "0")}:${((hour + 1 / colSpan) % 1 * 60).toString().padStart(2, "0")}`}
                                    </div>
                                </th>
                        )}
                    </tr>
                </thead>
                <tbody>
                    {roomList.map(
                        (room, i) =>
                            <tr key={i}>
                                <td colSpan={1} key={-1} style={{ width: "60px" }}>{room.name}</td>
                                {reservationTable[room.id] ? reservationTable[room.id].map(
                                    (col, j) =>
                                        <td
                                            className={
                                                `cell 
                                            ${(col.invalid && !col.passed) ? "invalid-not-passed-cell" : " "} 
                                            ${(col.invalid && col.passed) ? "invalid-passed-cell" : " "} 
                                            ${(col.is_semester_reserve && col.reservation && !col.passed)? "semester-not-passed-cell" : " "}
                                            ${(col.is_semester_reserve && col.reservation && col.passed)? "semester-passed-cell" : " "}
                                            ${(!col.is_semester_reserve &&col.reservation && !col.passed) ? "reservation-not-passed-cell" : " "} 
                                            ${(!col.is_semester_reserve && col.reservation && col.passed) ? "reservation-passed-cell" : " "} 
                                            ${(!col.is_semester_reserve && !col.invalid && !col.reservation && !col.passed) ? "empty-not-passed-cell" : " "}
                                            ${(!col.is_semester_reserve && !col.invalid && !col.reservation && col.passed) ? "empty-passed-cell" : " "}
                                            
                                            `}
                                            onClick={() => handleClick(col)}
                                            colSpan={colSpan * (col.span[1] - col.span[0])}
                                            key={j}>
                                            {col.reservation && !col.is_semester_reserve?
                                                <p className="reservation-table-cell-p">{`${col.reservation.name}\n${col.reservation.id_teaching}`}</p>
                                                : <></>
                                            }

                                        </td>

                                ) : <></>}
                            </tr>

                    )}
                </tbody>
            </Table>
        </LoadingContainer>
    )
}