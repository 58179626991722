import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import jsonwebtoken from 'jsonwebtoken';


export const fetchSystemInfo = createAsyncThunk(
    'system/fetchSystemInfo',
    async (thunkAPI) => {
        try {
            const response = await axios.get(`/system/data`,
                {}
            );
            return response.data;

        } catch (error) {
            console.log("error", error.response)
            return thunkAPI.rejectWithValue({ status: error.response.status, data: error.response.data });
        }

    }
);

export const updateAnnouncement = createAsyncThunk(
    'system/updateAnnouncement',
    async ({ token, announcement }, thunkAPI) => {

        if (!token) {
            return thunkAPI.rejectWithValue({ status: 0, data: { 'message': 'invalid token!' } });
        }

        const form = new FormData();
        form.append('announcement', announcement);

        try {
            const response = await axios.put(`/system/`,
                form,
                {
                    headers: { "x-access-token": `token ${token}` }
                }
            );
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue({ status: error.response.status, data: error.response.data });
        }

    }
);

export const updateInformation = createAsyncThunk(
    'system/updateInformation',
    async ({ token, information }, thunkAPI) => {

        if (!token) {
            return thunkAPI.rejectWithValue({ status: 0, data: { 'message': 'invalid token!' } });
        }

        const form = new FormData();
        form.append('information', information);

        try {
            const response = await axios.put(`/system/`,
                form,
                {
                    headers: { "x-access-token": `token ${token}` }
                }
            );
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue({ status: error.response.status, data: error.response.data });
        }

    }
);

export const updateClassroomRule = createAsyncThunk(
    'system/updateClassroomRule',
    async ({ token, rules }, thunkAPI) => {

        if (!token) {
            return thunkAPI.rejectWithValue({ status: 0, data: { 'message': 'invalid token!' } });
        }

        const form = new FormData();
        form.append('room_rules', rules);

        try {
            const response = await axios.put(`/system/`,
                form,
                {
                    headers: { "x-access-token": `token ${token}` }
                }
            );
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue({ status: error.response.status, data: error.response.data });
        }

    }
);

export const uploadClassroomRuleImage = createAsyncThunk(
    'system/uploadClassroomRuleImage',
    async ({ token, image }, thunkAPI) => {

        if (!token) {
            return thunkAPI.rejectWithValue({ status: 0, data: { 'message': 'invalid token!' } });
        }

        const form = new FormData();
        form.append('image', image);
        form.append('ext', image.name.split('.').pop());

        try {
            const response = await axios.post(`/image/`,
                form,
                {
                    headers: {
                        "x-access-token": `token ${token}`,
                        'Content-Type': 'multipart/form-data'
                    }
                }
            );
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue({ status: error.response.status, data: error.response.data });
        }

    }
);


export const uploadClassroomRuleFile = createAsyncThunk(
    'system/uploadClassroomRuleFile',
    async ({ token, file }, thunkAPI) => {

        if (!token) {
            return thunkAPI.rejectWithValue({ status: 0, data: { 'message': 'invalid token!' } });
        }

        const form = new FormData();
        form.append('file', file);
        form.append('ext', file.name.split('.').pop());

        try {
            const response = await axios.post(`/file/`,
                form,
                {
                    headers: {
                        "x-access-token": `token ${token}`,
                        'Content-Type': 'multipart/form-data'
                    }
                }
            );
            return response.data;
        } catch (error) {
            console.log(error)
            return thunkAPI.rejectWithValue({ status: error.response.status, data: error.response.data });
        }

    }
);


export const deleteClassroomRuleImage = createAsyncThunk(
    'system/deleteClassroomRuleImage',
    async ({ token, image_id }, thunkAPI) => {

        if (!token) {
            return thunkAPI.rejectWithValue({ status: 0, data: { 'message': 'invalid token!' } });
        }


        try {
            const response = await axios.delete(`/image/`,
                {
                    headers: { "x-access-token": `token ${token}` },
                    data: { id: image_id },
                }
            );
            return { id: image_id };
        } catch (error) {
            return thunkAPI.rejectWithValue({ status: error.response.status, data: error.response.data });
        }

    }
);


export const deleteClassroomRuleFile = createAsyncThunk(
    'system/deleteClassroomRuleFile',
    async ({ token, file_id }, thunkAPI) => {

        if (!token) {
            return thunkAPI.rejectWithValue({ status: 0, data: { 'message': 'invalid token!' } });
        }

        try {
            const response = await axios.delete(`/file/`,
                {
                    headers: { "x-access-token": `token ${token}` },
                    data: { id: file_id },
                }
            );
            return { id: file_id };
        } catch (error) {
            return thunkAPI.rejectWithValue({ status: error.response.status, data: error.response.data });
        }

    }
);