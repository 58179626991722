import moment from 'moment';
import { reservationStatus } from '../redux/reservation/reservationSlice';
import { roomTypeRestricts } from '../redux/room/roomSlice';


export const getSongEvent = (song, eventList) => {
    return eventList.find(event => event.id === song.event);
}

export const getSongEventName = (song, eventList) => {
    const eventItem = getSongEvent(song, eventList);
    if (!eventItem) {
        return "";
    }
    return eventItem.name;
}


export const getTimeFormatString = (time) => {
    return moment(new Date(time * 1000)).format('YYYY/MM/DD hh:mm')
}

export const getTimeFormatStringDate = (time) => {
    return moment(new Date(time * 1000)).format('YYYY/MM/DD')
}


export const getTimeFormatString24hr = (time) => {
    return moment(new Date(time * 1000)).format('HH:mm')
}

export const get24hrToInt = (time) => {
    let hour = parseInt(time.split(':')[0], 10);
    let minute = parseInt(time.split(':')[1], 10);
    return hour * 3600 + minute * 60
}


export const getRoomTypeRestrictsString = (roomType) => {
    if (roomType.restricts === roomTypeRestricts.adminOnly) {
        return "管理員";
    } else if (roomType.restricts === roomTypeRestricts.adminAndStudent) {
        return "管、生";
    } else if (roomType.restricts === roomTypeRestricts.adminAndTeacher) {
        return "管、師";
    } else if (roomType.restricts === roomTypeRestricts.everyone) {
        return "所有人";
    }
    return "error";
}

export const getRoomTypeUnitsString = (roomType) => {
    return `${roomType.units / 3600}小時`;
}


export const getReservationTypeCycleString = (reservationType) => {
    if (reservationType.cycle === 86400) {
        return "每日";
    } else if (reservationType.cycle === 86400 * 7) {
        return "每週";
    } else if (reservationType.cycle === 86400 * 31) {
        return "每月";
    }

    const month = Math.floor(reservationType.cycle / (86400 * 31));
    const week = Math.floor(reservationType.cycle % (86400 * 31) / (86400 * 7));
    const day = Math.floor(reservationType.cycle % (86400 * 31) % (86400 * 7) / 86400);
    if (month !== 0) {
        return `${month}月${week}週${day}天`;
    } else if (week !== 0) {
        return `${week}週${day}天`;
    }
    return `${day}天`;

}


export const getReservationTypeRestrictsString = (reservationType) => {
    let returnStr = ""
    let commaFlag = false;
    for (const [key, value] of Object.entries(reservationType.limits_detail)) {
        returnStr += `${commaFlag ? ", " : ""}${key}(${value})`;
        commaFlag = true;
    }
    return returnStr;
}

export const getReservationTypeStartTimeString = (reservationType) => {
    let returnStr = ""
    let newlineFlag = false;
    reservationType.allow_reserve.forEach(item => {
        const month = getMonthFromSecond(item[0]);
        const week = getWeekFromSecond(item[0]);
        const day = getDayFromSecond(item[0]);
        const hour = getHourFromSecond(item[0]);
        const minute = getMinuteFromSecond(item[0]);

        returnStr += `${newlineFlag ? "\n" : ""}${month}月${week}週${day}天${hour}時${minute}分前`
        newlineFlag = true;
    });

    return returnStr;
}

export const getReservationTypeEndTimeString = (reservationType) => {
    let returnStr = ""
    let newlineFlag = false;
    reservationType.allow_reserve.forEach(item => {
        const month = getMonthFromSecond(item[1]);
        const week = getWeekFromSecond(item[1]);
        const day = getDayFromSecond(item[1]);
        const hour = getHourFromSecond(item[1]);
        const minute = getMinuteFromSecond(item[1]);

        returnStr += `${newlineFlag ? "\n" : ""}${month}月${week}週${day}天${hour}時${minute}分前`
        newlineFlag = true;
    });

    return returnStr;
}

export const getRoomWeekdayStartTimeString = (room) => {
    let returnStr = ""
    let newlineFlag = false;
    room.reserve_type.weekday.allow_reserve.forEach(item => {
        const month = getMonthFromSecond(item[0]);
        const week = getWeekFromSecond(item[0]);
        const day = getDayFromSecond(item[0]);
        const hour = getHourFromSecond(item[0]);
        const minute = getMinuteFromSecond(item[0]);

        returnStr += `${newlineFlag ? "\n" : ""}${month}月${week}週${day}天${hour}時${minute}分前`
        newlineFlag = true;
    });

    return returnStr;
}

export const getRoomWeekdayEndTimeString = (room) => {
    let returnStr = ""
    let newlineFlag = false;
    room.reserve_type.weekday.allow_reserve.forEach(item => {
        const month = getMonthFromSecond(item[1]);
        const week = getWeekFromSecond(item[1]);
        const day = getDayFromSecond(item[1]);
        const hour = getHourFromSecond(item[1]);
        const minute = getMinuteFromSecond(item[1]);

        returnStr += `${newlineFlag ? "\n" : ""}${month}月${week}週${day}天${hour}時${minute}分前`
        newlineFlag = true;
    });

    return returnStr;
}

export const getRoomHolidayStartTimeString = (room) => {
    let returnStr = ""
    let newlineFlag = false;
    room.reserve_type.holiday.allow_reserve.forEach(item => {
        const month = getMonthFromSecond(item[0]);
        const week = getWeekFromSecond(item[0]);
        const day = getDayFromSecond(item[0]);
        const hour = getHourFromSecond(item[0]);
        const minute = getMinuteFromSecond(item[0]);

        returnStr += `${newlineFlag ? "\n" : ""}${month}月${week}週${day}天${hour}時${minute}分前`
        newlineFlag = true;
    });

    return returnStr;
}

export const getRoomHolidayEndTimeString = (room) => {
    let returnStr = ""
    let newlineFlag = false;
    room.reserve_type.holiday.allow_reserve.forEach(item => {
        const month = getMonthFromSecond(item[1]);
        const week = getWeekFromSecond(item[1]);
        const day = getDayFromSecond(item[1]);
        const hour = getHourFromSecond(item[1]);
        const minute = getMinuteFromSecond(item[1]);

        returnStr += `${newlineFlag ? "\n" : ""}${month}月${week}週${day}天${hour}時${minute}分前`
        newlineFlag = true;
    });

    return returnStr;
}

export const getChineseWeekday = (date) => {
    const weekdayMap = {
        Monday: "一",
        Tuesday: "二",
        Wednesday: "三",
        Thursday: "四",
        Friday: "五",
        Saturday: "六",
        Sunday: "日",
    }
    return `(${weekdayMap[date.format("dddd")]})`;
}

export const getMonthFromSecond = (second) => {
    return Math.floor(second / (86400 * 31));
}

export const getWeekFromSecond = (second) => {
    return Math.floor(second % (86400 * 31) / (86400 * 7));
}

export const getDayFromSecond = (second) => {
    return Math.floor(second % (86400 * 31) % (86400 * 7) / 86400);
}

export const getHourFromSecond = (second) => {
    return Math.floor(second % (86400 * 31) % (86400 * 7) % 86400 / 3600);
}

export const getMinuteFromSecond = (second) => {
    return Math.floor(second % (86400 * 31) % (86400 * 7) % 86400 % 3600 / 60);
}

export const getUserTypeString = (user, types) => {
    let str = "";
    types.forEach(type => {
        const subtypeDict = type.subtypeDict;
        if (!subtypeDict) {
            return;
        }
        const subtype = Object.values(subtypeDict).find(subtype => {
            return subtype.user_list.includes(user.id);
        });
        str += `${type.name}-${subtype ? subtype.name : ""}\n`;
    })
    return str;
}


export const getUserTypeStringByMyTypes = (user, mySubtypes) => {
    let str = "";
    if (!mySubtypes) {
        return str;
    }
    console.log(mySubtypes)
    mySubtypes.forEach((mySubtype, idx) => {
        str += `${idx === 0 ? "" : "、"}${mySubtype.root}-${mySubtype.name}`;
    })
    return str;
}


export const determineReservationStatus = (reservationItem) => {
    const now = moment().unix();
    if (now < reservationItem.start_time) {
        return reservationStatus.waiting;
    } else if (reservationItem.start_time <= now && now < reservationItem.end_time) {
        return reservationStatus.using;
    } else if (reservationItem.end_time < now) {
        return reservationStatus.finished;
    }
    return reservationStatus.finished;
}

export const roundStartTime = (originalStartTime, cycle) => {
    let newStartTime = moment(originalStartTime * 1000);
    if (cycle === 3600) {
        newStartTime = newStartTime.startOf("hour");
    } else if (cycle === 1800) {
        if (newStartTime.get("minutes") < 30) {
            newStartTime = newStartTime.startOf("hour");
        } else {
            newStartTime = newStartTime.startOf("hour").add("30", "minutes");
        }
    }
    return newStartTime;
}


export const roundEndTime = (originalEndTime, cycle) => {
    let newEndTime = moment(originalEndTime * 1000);
    if (cycle === 3600) {
        if (newEndTime.get("minutes") === 0) {
            newEndTime = newEndTime.startOf("hour");
        } else {
            newEndTime = newEndTime.startOf("hour").add(1, "hours");
        }
    } else if (cycle === 1800) {
        if (newEndTime.get("minutes") === 0) {
            newEndTime = newEndTime.startOf("hour");
        } else if (newEndTime.get("minutes") <= 30) {
            newEndTime = newEndTime.startOf("hour").add("30", "minutes");
        } else {
            newEndTime = newEndTime.startOf("hour").add(1, "hours");
        }
    }
    return newEndTime;
}