import { useState } from "react"
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table } from "react-bootstrap"

import ConditionalContainer from '../ConditionalContainer'
import LoadingContainer from '../LoadingContainer/LoadingContainer'

import './AdminMultidayReservationTable.css'
import moment, { invalid, max } from "moment";
import { fetchReservationAvaliable } from "../../redux/reservation/reservationThunk";
import { roundEndTime, roundStartTime } from "../../utils/utils";



export default function AdminMultidayReservationTable({ selectedRoom, dateRange, reservationList, onClick }) {
    const dispatch = useDispatch();

    const user = useSelector(state => state.user);
    const room = useSelector(state => state.room);
    const reservation = useSelector(state => state.reservation);


    const [startDate, setStartDate] = useState(moment(dateRange[0]).startOf('day'));
    const [endDate, setEndDate] = useState(moment(dateRange[1]).startOf('day'));
    const [dateList, setDateList] = useState([]);

    const [isLoading, setIsLoading] = useState(true);

    const [datesAvaliableTimes, setDatesAvaliableTimes] = useState({});
    const [filteredReservationList, setFilteredReservationList] = useState([]);
    const [colSpan, setColSpan] = useState(1);
    const [reservationTable, setReservationTable] = useState({});



    const [hourRange, setHourRange] = useState([0, 24]);


    useEffect(async () => {
        if (!dateList) {
            return;
        }

        let newDatesAvaliableTimes = {}

        for (let idx = 0; idx < dateList.length; idx++) {
            const date = dateList[idx];
            const result = await dispatch(fetchReservationAvaliable({ token: user.token, options: { date: date.unix() } }));
            if (result.meta.requestStatus === "fulfilled") {
                newDatesAvaliableTimes[date.unix()] = result.payload;
            }
        }
        setDatesAvaliableTimes(newDatesAvaliableTimes);

    }, [dateList, reservationList]);



    useEffect(() => {
        setStartDate(moment(dateRange[0]).startOf('day'));
        setEndDate(moment(dateRange[1]).startOf('day'));
        setIsLoading(true);
    }, [dateRange]);


    useEffect(() => {
        if (dateList.length !== 0) {
            if (startDate.unix() === dateList[0].unix() && endDate.unix() === dateList[dateList.length - 1].unix()) {
                return;
            }
        }
        let newDateList = [];
        for (let d = startDate.unix(); d <= endDate.unix(); d += 86400) {
            newDateList.push(moment(d * 1000));
        }
        setDateList(newDateList);
    }, [startDate, endDate]);


    // Filter ReservationList
    useEffect(() => {
        if (!selectedRoom) {
            return;
        }
        if (room.rooms.length === 0) {
            return;
        }

        const findedRoomType = room.roomTypes.find(element => {
            return element.id === selectedRoom.type_id;
        });
        if (!findedRoomType) {
            return;
        }

        const filteredList = reservationList.filter(element => {
            return selectedRoom.name === element.room_name && startDate.unix() <= element.date && element.date <= endDate.unix();
        });

        setFilteredReservationList(filteredList);
        setColSpan(3600 / findedRoomType.units);
        setIsLoading(true);

    }, [reservationList, selectedRoom, room]);



    // Calculate hourspan
    useEffect(() => {

        // let minHour = 24;
        // let maxHour = 0;

        // console.log(datesAvaliableTimes)


        // Object.keys(datesAvaliableTimes).forEach((date) => {
        //     if (!datesAvaliableTimes[date]) {
        //         return;
        //     }
        //     if (!datesAvaliableTimes[date][selectedRoom.name]) {
        //         return;
        //     }
        //     datesAvaliableTimes[date][selectedRoom.name].forEach((timeInterval, idx) => {
        //         if (idx === 0) {
        //             return;
        //         }
        //         let startHour = moment(timeInterval[0] * 1000).startOf("hour").get("hour");
        //         let endHour = moment(timeInterval[1] * 1000).startOf("hour").get("hour");
        //         endHour = (endHour === 0) ? 24 : endHour;
        //         endHour += 1;
        //         endHour = (endHour > 24) ? 24 : endHour;


        //         if (startHour < minHour) {
        //             minHour = startHour;
        //         }
        //         if (endHour > maxHour) {
        //             maxHour = endHour;
        //         }

        //     });
        // });

        // filteredReservationList.forEach((reservationItem, idx) => {
        //     const reservationStartTime = roundStartTime(reservationItem.start_time, 3600 / colSpan);
        //     const reservationEndTime = roundEndTime(reservationItem.end_time, 3600 / colSpan);
        //     const reservationStartHour = reservationStartTime.get("hours") + reservationStartTime.get("minutes") / 60;
        //     let reservationEndHour = reservationEndTime.get("hours") + reservationEndTime.get("minutes") / 60;

        //     if (reservationEndHour === 0) {
        //         reservationEndHour = 24;
        //     }

        //     if (reservationStartHour < minHour) {
        //         minHour = reservationStartHour;
        //     }
        //     if (reservationEndHour > maxHour) {
        //         maxHour = reservationEndHour;
        //     }


        // });

        // minHour -= 1;
        // maxHour += 1;

        // minHour = (minHour < 0) ? 0 : minHour;
        // maxHour = (maxHour > 24) ? 24 : maxHour;

        // if (minHour >= maxHour) {
        //     minHour = 8;
        //     maxHour = 20;
        // }

        // setHourRange([minHour, maxHour]);




        let minHour = 24;
        let maxHour = 0;

        dateList.forEach(date => {
            const dayType = moment(date).isoWeekday() < 6 ? "weekday" : "holiday";
            const roomReserveTypeName = selectedRoom.reserve_type[dayType].type;
            const allowReserve = selectedRoom.reserve_type[dayType].allow_reserve;
            const reserveType = reservation.reservationTypes.find(reservationType => reservationType.name === roomReserveTypeName);
            if (!reserveType) {
                return;
            }
            if (reserveType.cycle !== 86400) {
                return;
            }
            const reservationTimeLimits = reserveType.allow_reserve;
            console.log(allowReserve)
            for (let i = 0; i < allowReserve.length; i++) {
                const roomTimeLimit = Array.from(allowReserve[i], x => parseInt(x));
                const reservationTimeLimit = Array.from(reservationTimeLimits[i], x => parseInt(x));
                if (roomTimeLimit[0] / 3600 < minHour) {
                    minHour = Math.floor(roomTimeLimit[0] / 3600);
                }
                if (roomTimeLimit[1] / 3600 > maxHour) {
                    maxHour = Math.ceil(roomTimeLimit[1] / 3600);
                }
            }

        })


        if (minHour < maxHour) {
            minHour = Math.max(minHour, 0);
            maxHour = Math.min(24, maxHour);
            setHourRange([minHour, maxHour]);
        } else {
            setHourRange([0, 24]);
        }

    }, [filteredReservationList, datesAvaliableTimes, dateList]);


    useEffect(() => {
        if (!selectedRoom) {
            return;
        }
        if (dateList.length === 0) {
            return;
        }

        let newReservationTable = {};

        // Create empty span
        dateList.forEach((date, idx) => {
            newReservationTable[date.unix()] =
                [...Array((hourRange[1] - hourRange[0]) * colSpan).keys()].map(
                    i => (i / colSpan) + hourRange[0]).map(
                        (hour, i) => {
                            const startHour = hour;
                            const endHour = hour + 1 / colSpan;
                            let invalid = true;

                            if (!datesAvaliableTimes[date.unix()] || !datesAvaliableTimes[date.unix()][selectedRoom.name]) {
                                invalid = true;
                            } else {
                                datesAvaliableTimes[date.unix()][selectedRoom.name].forEach((timeInterval) => {
                                    const timeIntervalStartMoment = moment(timeInterval[0] * 1000);
                                    const timeIntervalEndMoment = moment(timeInterval[1] * 1000);
                                    const timeIntervalStartHour = timeIntervalStartMoment.get("hour") + timeIntervalStartMoment.get("minute") / 60;
                                    let timeIntervalEndHour = timeIntervalEndMoment.get("hour") + timeIntervalEndMoment.get("minute") / 60;
                                    timeIntervalEndHour = timeIntervalEndHour === 0 ? 24 : timeIntervalEndHour;
                                    if (timeIntervalStartHour <= startHour && endHour <= timeIntervalEndHour) {
                                        invalid = false;
                                    }
                                });
                            }

                            const passed = moment(date).startOf("day").add(endHour * 60, "minute").unix() < moment().unix();

                            return {
                                invalid: invalid,
                                passed: passed,
                                room: selectedRoom,
                                date: date,
                                reservation: null,
                                span: [startHour, endHour],
                            }
                        }
                    );
        });
        console.log(newReservationTable)


        // Put reservation into span
        filteredReservationList.reverse().forEach((reservationItem, idx) => {
            const reservationRoom = room.rooms.find(element => element.name === reservationItem.room_name);
            const date = moment(reservationItem.start_time * 1000).startOf("day");
            const reservationStartTime = roundStartTime(reservationItem.start_time, 3600 / colSpan);
            const reservationEndTime = roundEndTime(reservationItem.end_time, 3600 / colSpan);
            const reservationStartHour = reservationStartTime.get("hour") + reservationStartTime.get("minute") / 60;
            let reservationEndHour = reservationEndTime.get("hour") + reservationEndTime.get("minute") / 60;

            if (reservationEndHour === 0) {
                reservationEndHour = 24;
            }
            if (moment(reservationItem.end_time * 1000).unix() < reservationEndTime.unix() && moment().unix() < reservationEndTime.unix()) {
                return;
            }

            let roomRow = newReservationTable[date.unix()];
            const passed = reservationEndTime.unix() < moment().unix();


            let reservationCol = {
                invalid: false,
                passed: passed,
                room: reservationRoom,
                date: moment(date).startOf("day"),
                reservation: reservationItem,
                span: [reservationStartHour, reservationEndHour],
            }

            let startColIdx = roomRow.findIndex(element => element.span[0] === reservationStartHour);
            let endColIdx = roomRow.findIndex(element => element.span[1] === reservationEndHour);

            for (let i = startColIdx; i <= endColIdx; i++) {
                const roomCol = roomRow[i];
                if (roomCol && roomCol.reservation != null) {
                    return;
                }
            }

            roomRow.splice(startColIdx, endColIdx - startColIdx + 1, reservationCol);


            newReservationTable[date.unix()] = roomRow;

        })
        console.log(newReservationTable);
        setReservationTable(newReservationTable);

        if (Object.keys(datesAvaliableTimes).length !== 0) {
            setIsLoading(false);
        }


    }, [hourRange]);


    const handleClick = (col) => {
        if (onClick) {
            onClick(col);
        }
    }

    return (
        <LoadingContainer isLoading={isLoading}>
            <Table bordered responsive>
                <thead>
                    <tr>
                        <th colSpan={1} key={-1}><div style={{ width: "60px" }}>{selectedRoom ? selectedRoom.name : ""}</div></th>
                        {[...Array((hourRange[1] - hourRange[0]) * colSpan).keys()].map(i => (i / colSpan) + hourRange[0]).map(
                            (hour, i) =>
                                <th colSpan={1} key={i}>
                                    <div className="reservation-table-cell-header" style={{ width: `${2 / colSpan * 25}px` }}>
                                        {`${Math.floor(hour).toString().padStart(2, "0")}:${(hour % 1 * 60).toString().padStart(2, "0")}-
                                ${(Math.floor(hour + 1 / colSpan)).toString().padStart(2, "0")}:${((hour + 1 / colSpan) % 1 * 60).toString().padStart(2, "0")}`}
                                    </div>
                                </th>
                        )}
                    </tr>
                </thead>
                <tbody>
                    {dateList.map(
                        (date, i) =>
                            <tr key={i}>
                                <td colSpan={1} key={-1} style={{ width: "60px" }}>{date.format("YYYY/MM/DD")}</td>
                                {reservationTable[date.unix()] ? reservationTable[date.unix()].map(
                                    (col, j) =>
                                        <td className={
                                            `cell 
                                        ${(col.invalid && !col.passed) ? "invalid-not-passed-cell" : " "} 
                                        ${(col.invalid && col.passed) ? "invalid-passed-cell" : " "} 
                                        ${(col.reservation && !col.passed) ? "reservation-not-passed-cell" : " "} 
                                        ${(col.reservation && col.passed) ? "reservation-passed-cell" : " "} 
                                        ${(!col.invalid && !col.reservation && !col.passed) ? "empty-not-passed-cell" : " "}
                                        ${(!col.invalid && !col.reservation && col.passed) ? "empty-passed-cell" : " "}
                                        `}
                                            onClick={() => handleClick(col)}
                                            colSpan={colSpan * (col.span[1] - col.span[0])}
                                            key={j}>
                                            {col.reservation ?
                                                <p className="reservation-table-cell-p">{`${col.reservation.name}\n${col.reservation.id_teaching}`}</p>
                                                : <></>
                                            }

                                        </td>

                                ) : <></>}
                            </tr>

                    )}
                </tbody>
            </Table>
        </LoadingContainer>
    )
}