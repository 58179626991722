import React, { useEffect, useState } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useRouteMatch,
    Redirect
} from "react-router-dom";
import { useSelector } from 'react-redux';
import { Row, Col, Container, Button } from "react-bootstrap";
import { LinkContainer } from 'react-router-bootstrap';
import EditAnnouncementDialog from './dialogs/EditAnnouncementDialog';
import EditFooterDialog from './dialogs/EditFooterDialog';


export default function SystemSettingPage() {

    const { path, url } = useRouteMatch();

    const user = useSelector(state => state.user);

    const [showEditAnnouncementDialog, setShowEditAnnouncementDialog] = useState(false);
    const [showEditFooterDialog, setShowEditFooterDialog] = useState(false);



    return (
        <div className="pageContainer">
            <Container>
                <Row>
                    <Col>
                        <h2>系統設定</h2>
                    </Col>
                </Row>
                <Row className="justify-content-md-center">
                    <Col md='6'>
                        <Row>
                            <Col>
                                <LinkContainer to={`roomtype`}><Button size='lg'>　　琴房種類　　</Button></LinkContainer>
                            </Col>
                        </Row>
                        {user.profile.identity !== 'admin_s' ?
                            <Row className="justify-content-md-center">
                                <Col>
                                    <LinkContainer to={`teachingtype`}><Button size='lg'>　　授課項目　　</Button></LinkContainer>
                                </Col>
                            </Row> :
                            <></>
                        }
                        {user.profile.identity !== 'admin_s' ?
                        <Row className="justify-content-md-center">
                            <Col>
                                <LinkContainer to={`reservationtype`}><Button size='lg'>　　預約設置　　</Button></LinkContainer>
                            </Col>
                        </Row>:
                            <></>
                        }
                        <Row className="justify-content-md-center">
                            <Col>
                                <Button size='lg' onClick={() => setShowEditAnnouncementDialog(true)}>　　公告編輯　　</Button>
                            </Col>
                        </Row>
                        {user.profile.identity !== 'admin_s' ?
                            <Row className="justify-content-md-center">
                                <Col>
                                    <LinkContainer to={`classroomregulation`}><Button size='lg'>琴房管理辦法編輯</Button></LinkContainer>
                                </Col>
                            </Row>:
                            <></>
                        }
                        <Row className="justify-content-md-center">
                            <Col>
                                <Button size='lg' onClick={() => setShowEditFooterDialog(true)}>　編輯網頁資訊　</Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>

            <EditAnnouncementDialog show={showEditAnnouncementDialog} onConfirm={() => { }} onClose={() => setShowEditAnnouncementDialog(false)} />
            <EditFooterDialog show={showEditFooterDialog} onConfirm={() => { }} onClose={() => setShowEditFooterDialog(false)} />
        </div>
    );
}

