import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import jsonwebtoken from 'jsonwebtoken';


export const fetchAllTypes = createAsyncThunk(
    'type/fetchAllTypes',
    async ({ token }, thunkAPI) => {

        if (!token) {
            return thunkAPI.rejectWithValue({ status: 0, data: { 'message': 'invalid token!' } });
        }
        try {
            const tokenData = jsonwebtoken.decode(token);
            const response = await axios.get(`/types`,
                {
                    headers: { "x-access-token": `token ${token}` }
                }
            );
            return response.data;

        } catch (error) {
            console.log("error", error.response)
            return thunkAPI.rejectWithValue({ status: error.response.status, data: error.response.data });
        }

    }
);


export const fetchSubtypes = createAsyncThunk(
    'type/fetchSubtypes',
    async ({ token, typeName }, thunkAPI) => {

        if (!token) {
            return thunkAPI.rejectWithValue({ status: 0, data: { 'message': 'invalid token!' } });
        }
        try {
            const tokenData = jsonwebtoken.decode(token);
            const response = await axios.get(`/types/type?name=${typeName}`,
                {
                    headers: { "x-access-token": `token ${token}` }
                }
            );
            return { typeName: typeName, subtypes: response.data };

        } catch (error) {
            console.log("error", error.response)
            return thunkAPI.rejectWithValue({ status: error.response.status, data: error.response.data });
        }

    }
);

export const fetchUserSubtypes = createAsyncThunk(
    'type/fetchUserSubtypes',
    async ({ token }, thunkAPI) => {

        if (!token) {
            return thunkAPI.rejectWithValue({ status: 0, data: { 'message': 'invalid token!' } });
        }
        try {
            const tokenData = jsonwebtoken.decode(token);
            console.log(tokenData)
            const response = await axios.get(`/types/subtype?user_id=${tokenData.data.id}`,
                {
                    headers: { "x-access-token": `token ${token}` }
                }
            );
            return response.data;

        } catch (error) {
            console.log("error", error.response)
            return thunkAPI.rejectWithValue({ status: error.response.status, data: error.response.data });
        }

    }
);



export const addNewType = createAsyncThunk(
    'type/addNewType',
    async ({ token, typeName }, thunkAPI) => {

        if (!token) {
            return thunkAPI.rejectWithValue({ status: 0, data: { 'message': 'invalid token!' } });
        }
        const form = new FormData();
        form.append('name', typeName);
        try {
            const response = await axios.post('/types/type', form,
                {
                    headers: { "x-access-token": `token ${token}` }
                }
            );
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue({ status: error.response.status, data: error.response.data });
        }

    }
);

export const deleteType = createAsyncThunk(
    'type/deleteType',
    async ({ token, type_id }, thunkAPI) => {

        if (!token) {
            return thunkAPI.rejectWithValue({ status: 0, data: { 'message': 'invalid token!' } });
        }

        try {
            const response = await axios.delete(`/types/type`,
                {
                    headers: { "x-access-token": `token ${token}` },
                    data: { type_id: type_id },
                }
            );
            return { id: type_id };
        } catch (error) {
            return thunkAPI.rejectWithValue({ status: error.response.status, data: error.response.data });
        }

    }
);


export const addNewSubtype = createAsyncThunk(
    'type/addNewSubtype',
    async ({ token, subtypeName, rootName }, thunkAPI) => {

        if (!token) {
            return thunkAPI.rejectWithValue({ status: 0, data: { 'message': 'invalid token!' } });
        }
        const form = new FormData();
        form.append('root', rootName);
        form.append('name', subtypeName);
        try {
            const response = await axios.post('/types/subtype', form,
                {
                    headers: { "x-access-token": `token ${token}` }
                }
            );
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue({ status: error.response.status, data: error.response.data });
        }

    }
);

export const deleteSubtype = createAsyncThunk(
    'type/deleteSubtype',
    async ({ token, subtype_id, name, root }, thunkAPI) => {

        if (!token) {
            return thunkAPI.rejectWithValue({ status: 0, data: { 'message': 'invalid token!' } });
        }

        try {
            const response = await axios.delete(`/types/subtype`,
                {
                    headers: { "x-access-token": `token ${token}` },
                    data: {subtype_id: subtype_id},
                }
            );
            return { id: subtype_id, name: name, root: root };
        } catch (error) {
            return thunkAPI.rejectWithValue({ status: error.response.status, data: error.response.data });
        }

    }
);




export const moveUserSubtype = createAsyncThunk(
    'type/moveUserSubtype',
    async ({ token, user_id, typeName, fromSubtypeName, toSubtypeName }, thunkAPI) => {

        if (!token) {
            return thunkAPI.rejectWithValue({ status: 0, data: { 'message': 'invalid token!' } });
        }

        const form = new FormData();
        form.append('from_root', typeName);
        form.append('from_subtype', fromSubtypeName);
        form.append('to_root', typeName);
        form.append('to_subtype', toSubtypeName);
        form.append('userid_list', JSON.stringify([user_id]).replace("\'","\""));

        try {
            const response = await axios.put(`/types/type`,
                form,
                {
                    headers: { "x-access-token": `token ${token}` }
                }
            );
            return {
                user_id: user_id,
                typeName: typeName,
                oldSubtypeName: fromSubtypeName, 
                newSubtype: response.data
            };
        } catch (error) {
            return thunkAPI.rejectWithValue({ status: error.response.status, data: error.response.data });
        }

    }
);