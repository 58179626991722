import React, { useEffect, useState } from 'react';
import { Row, Col, Container, Form, Button, Nav } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { checkUser, login, sendCaptcha, updateUserPassword, verifyCaptcha } from '../../redux/user/userThunk'
import { clearState } from '../../redux/user/userSlice'


import 'bootstrap/dist/css/bootstrap.min.css';


import ConditionalContainer from '../../components/ConditionalContainer';


export default function ForgotPasswordPage() {

    const dispatch = useDispatch();
    const history = useHistory();
    const { register, errors, handleSubmit } = useForm();

    const { isFetching, isSuccess, isError, userErrorMessage } = useSelector(
        state => state.user
    );


    const identityType = {
        member: "member",
        admin: "admin",
        notUser: "notUser",
    }

    const captchaStatuses = {
        notSent: "notSent",
        sent: "sent",
        verified: "verified",
    }

    const [identity, setIdentity] = useState(identityType.notUser);
    const [captchaStatus, setCaptchaStatus] = useState(captchaStatuses.notSent);

    const [ranStrEncode, setRanStrEncode] = useState("");
    const [token, setToken] = useState("");


    const [password, setPassword] = useState("");
    const [verifyPassword, setVerifyPassword] = useState("");
    const [passwordValidated, setPasswordValidated] = useState(false);




    const [errorMessage, setErrorMessage] = useState("");


    if (localStorage.getItem('token')) {
        console.log(localStorage.getItem('token'))
        history.push('/');
    }




    const onSubmitAccount = async (data) => {
        const result = await dispatch(checkUser(data));

        if (result.meta.requestStatus !== "fulfilled") {
            const resultMessage = result.payload.data.message;
            console.log(resultMessage);
            if (resultMessage.includes("Member by account does not exist.")) {
                setErrorMessage("無法找到該使用者");
            }
            return;
        }

        const identityMessage = result.payload.message;

        if (identityMessage.includes("Identity is Member.")) {
            setIdentity(identityType.member)
        } else if (identityMessage.includes("Identity is Admin.")) {
            setIdentity(identityType.admin)
        } else {
            setIdentity(identityType.notUser);
        }
    }


    const onSubmitEmail = async (data) => {
        const result = await dispatch(sendCaptcha(data));

        if (result.meta.requestStatus !== "fulfilled") {
            const resultMessage = result.payload.data.message;
            console.log(resultMessage);
            if (resultMessage.includes("Email is not in a vaild form.")) {
                setErrorMessage("電子郵件格式不正確");
            } else if (resultMessage.includes("Email does not belong to any Admin account.")) {
                setErrorMessage("電子郵件不屬於任何管理員");
            } else if (resultMessage.includes("More than one admin account has the same email address.")) {
                setErrorMessage("多於兩個管理員使用相同救援帳戶");
            }
            return;
        } else {
            setRanStrEncode(result.payload.ran_str_encode);
            setCaptchaStatus(captchaStatuses.sent);
        }

    }


    const onSubmitCaptcha = async (data) => {
        data.ran_str_encode = ranStrEncode;
        const result = await dispatch(verifyCaptcha(data));
        console.log(result);
        if (result.meta.requestStatus !== "fulfilled") {
            const resultMessage = result.payload.data.message;
            console.log(resultMessage);
            if (resultMessage.includes("Wrong captcha.")) {
                setErrorMessage("驗證碼錯誤");
            }

            return;
        } else {
            setToken(result.payload.token);
            setCaptchaStatus(captchaStatuses.verified);
        }

    }




    const isPasswordValid = () => {
        return isPasswordFormatValid() && isPasswordLengthValid();
    }

    const isVerifyPasswordValid = () => {
        return verifyPassword === password;
    }

    const isPasswordLengthValid = () => {
        return password.length <= 20 && password.length >= 6;
    }

    const isPasswordFormatValid = () => {
        let regex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]/;
        return regex.test(password);
    }

    const determinePasswordErrorMessage = () => {
        if (!isPasswordLengthValid()) {
            setErrorMessage("密碼長度必須為6-20!");
        } else if (!isPasswordFormatValid()) {
            setErrorMessage("密碼必須包含英文字母與數字!");
        } else if (!isVerifyPasswordValid()) {
            setErrorMessage("密碼與密碼確認不符合!");
        }
    }

    const onSubmitPassword = async (event) => {
        const form = event.currentTarget;
        event.preventDefault();

        determinePasswordErrorMessage();

        if (form.checkValidity() === false || !isPasswordValid() || !isVerifyPasswordValid()) {
            setPasswordValidated(true);
            event.stopPropagation();
            return;
        }

        const result = await dispatch(updateUserPassword({ token: token, password: password }));
        console.log(result);
        if (result.meta.requestStatus === "fulfilled") {
            history.replace('/login');
            return;
        }

        setPasswordValidated(true);

    }


    return (
        <div className="pageContainer">
            <Container>
                <Row className="justify-content-md-center">
                    <Col>
                        <h3>無法登入</h3>
                    </Col>
                </Row>
                <ConditionalContainer condition={identity === identityType.notUser}>
                    <Row className="justify-content-md-center">
                        <Col md={6}>
                            <Form onSubmit={handleSubmit(onSubmitAccount)}>
                                <Form.Group className="mb-3" as={Row} md={3} controlId="formBasicEmail">
                                    <Form.Label column>請輸入帳號</Form.Label>
                                    <Col md={8}>
                                        <Form.Control column type="text" placeholder="請輸入帳號" {...register('username')} />
                                    </Col>
                                </Form.Group>
                                <p className="error-message">{errorMessage}</p>
                                <Button variant="primary" type="submit">
                                    送出
                                </Button>
                            </Form>
                        </Col>
                    </Row>
                </ConditionalContainer>

                <ConditionalContainer condition={identity === identityType.member}>
                    <br />
                    <Row className="justify-content-md-center">
                        <Col>
                            <h3>親愛的使用者，您好!</h3>
                        </Col>
                    </Row>
                    <Row className="justify-content-md-center">
                        <Col>
                            <h5>很抱歉，為了保護您的帳戶安全，請直接聯絡管理員將您的密碼重置，登入之後立即更改密碼，以免遭人盜用。</h5>
                        </Col>
                    </Row>
                    <Row className="justify-content-md-center">
                        <Col>
                            <Button onClick={() => { history.replace('/login'); }}>回到登入畫面</Button>
                        </Col>
                    </Row>
                </ConditionalContainer>

                <ConditionalContainer condition={identity === identityType.admin && captchaStatus === captchaStatuses.notSent}>
                    <Row className="justify-content-md-center">
                        <Col md={6}>
                            <Form onSubmit={handleSubmit(onSubmitEmail)}>

                                <Form.Group className="mb-3" as={Row} md={3} controlId="formBasicEmail">
                                    <Form.Label column>請輸入救援電子郵件</Form.Label>
                                    <Col md={8}>
                                        <Form.Control column type="text" placeholder="請輸入救援電子郵件" {...register('email')} />
                                    </Col>
                                </Form.Group>
                                <p className="error-message">{errorMessage}</p>
                                <Button variant="primary" type="submit">
                                    送出
                                </Button>

                            </Form>
                        </Col>
                    </Row>
                </ConditionalContainer>

                <ConditionalContainer condition={identity === identityType.admin && captchaStatus === captchaStatuses.sent}>
                    <Row className="justify-content-md-center">
                        <Col md={6}>
                            <Form onSubmit={handleSubmit(onSubmitCaptcha)}>

                                <Form.Group className="mb-3" as={Row} md={3} controlId="formBasicEmail">
                                    <Form.Label column>請輸入驗證碼</Form.Label>
                                    <Col md={8}>
                                        <Form.Control column type="text" placeholder="請輸入驗證碼" {...register('captcha')} />
                                    </Col>
                                </Form.Group>
                                <p className="error-message">{errorMessage}</p>
                                <Button variant="primary" type="submit">
                                    送出
                                </Button>

                            </Form>
                        </Col>
                    </Row>
                </ConditionalContainer>

                <ConditionalContainer condition={identity === identityType.admin && captchaStatus === captchaStatuses.verified}>
                    <Row className="justify-content-md-center">
                        <Col md={6}>
                            <Form noValidate onSubmit={onSubmitPassword}>
                                <Form.Control
                                    column
                                    as="input"
                                    type="password"
                                    placeholder="輸入6-20位密碼，包含英文字母與數字"
                                    isValid={isPasswordValid() && passwordValidated}
                                    isInvalid={!isPasswordValid() && passwordValidated}
                                    onChange={e => { setPassword(e.target.value); determinePasswordErrorMessage(); }} />
                                <br />
                                <Form.Control
                                    column
                                    as="input"
                                    type="password"
                                    placeholder="請再輸入一次密碼"
                                    isValid={isVerifyPasswordValid() && passwordValidated}
                                    isInvalid={!isVerifyPasswordValid() && passwordValidated}
                                    onChange={e => { setVerifyPassword(e.target.value); determinePasswordErrorMessage(); }} />
                                <Form.Control.Feedback type="invalid">{errorMessage}</Form.Control.Feedback>
                                <Button variant="primary" type="submit">
                                    更新密碼
                                </Button>

                            </Form>
                        </Col>
                    </Row>
                </ConditionalContainer>

            </Container>
        </div>
    );
}


