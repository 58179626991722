import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { Modal, Button, Form, Row, Col } from "react-bootstrap"
import { roomTypeRestricts } from '../../../redux/room/roomSlice';
import { addNewRoomType } from '../../../redux/room/roomThunk';


export default function AddRoomTypeDialog({ show, onClose, onConfirm }) {
    const dispatch = useDispatch();

    const user = useSelector(state => state.user);
    const room = useSelector(state => state.room);

    const unitOptions = [
        { name: "一小時", value: 3600 },
        { name: "半小時", value: 1800 }
    ]
    const restrictsOptions = [
        { name: "限管理員", value: roomTypeRestricts.adminOnly },
        { name: "管理員與使用者-老師", value: roomTypeRestricts.adminAndTeacher },
        { name: "管理員與使用者-學生", value: roomTypeRestricts.adminAndStudent },
        { name: "所有人", value: roomTypeRestricts.everyone }
    ]

    const restrictsOptionsAdminS = [
        { name: "管理員與使用者-學生", value: roomTypeRestricts.adminAndStudent },
        { name: "所有人", value: roomTypeRestricts.everyone }
    ]

    useEffect(() => {
        setShow(show);
    }, [show]);

    const [showState, setShow] = useState(show);
    const [validated, setValidated] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const [roomTypeName, setRoomType] = useState("");
    const [restricts, setRestricts] = useState(restrictsOptions[2]);
    const [units, setUnits] = useState(unitOptions[0]);



    const handleClose = () => {
        setShow(false);
        setValidated(false);
        setRoomType("");
        setRestricts(restrictsOptions[2]);
        setUnits(unitOptions[0]);
        setErrorMessage("");
        onClose();
    }

    const handleSubmit = async (event) => {
        const form = event.currentTarget;
        event.preventDefault();

        determineErrorMessage();


        if (form.checkValidity() === false || !isRoomTypeNameValid()) {
            event.stopPropagation();
        } else {
            await dispatch(addNewRoomType({ token: user.token, name: roomTypeName, restricts: restricts.value, units: units.value }));
            onConfirm();
            handleClose();
        }

        setValidated(true);

    };

    const isRoomTypeNameLengthValid = () => {
        return roomTypeName.length <= 10 && roomTypeName.length > 0;
    }

    const isRoomTypeNameNotRepeatValid = () => {
        return !room.roomTypes.find(roomType => roomType.name === roomTypeName);
    }


    const isRoomTypeNameValid = () => {
        return isRoomTypeNameLengthValid() && isRoomTypeNameNotRepeatValid();
    }

    const determineErrorMessage = () => {
        if (!isRoomTypeNameLengthValid()) {
            setErrorMessage("琴房種類名稱必須在1~10字之間!");
        } else if (!isRoomTypeNameNotRepeatValid()) {
            setErrorMessage("琴房種類名稱不能重複!");
        }

    }


    return (
        <Modal show={showState} >
            <Modal.Header>
                <Modal.Title>新增琴房種類</Modal.Title>
            </Modal.Header>
            <Form noValidate onSubmit={handleSubmit}>
                <Modal.Body>
                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="4">
                            琴房種類名稱:
                        </Form.Label>
                        <Col sm="8">
                            <Form.Control
                                column
                                as="input"
                                type="text"
                                placeholder="輸入琴房種類名稱(十字以內)"
                                isValid={isRoomTypeNameValid() && validated}
                                isInvalid={!isRoomTypeNameValid() && validated}
                                onChange={e => { setRoomType(e.target.value); determineErrorMessage(); }} />
                            <Form.Control.Feedback type="invalid">{errorMessage}</Form.Control.Feedback>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="4">
                            可預約身分:
                        </Form.Label>
                        <Col sm="8">
                            {user.profile.identity === 'admin_s' ?
                                <Form.Select aria-label="Default select example"
                                    onChange={e => setRestricts(restrictsOptionsAdminS[e.target.options.selectedIndex])}>
                                    {restrictsOptionsAdminS.map(
                                        (option, i) =>
                                            <option
                                                value={option.value}
                                                onChange={() => setRestricts(option)}>
                                                {option.name}
                                            </option>
                                    )}
                                </Form.Select> :
                                <Form.Select aria-label="Default select example"
                                    onChange={e => setRestricts(restrictsOptions[e.target.options.selectedIndex])}>
                                    {restrictsOptions.map(
                                        (option, i) =>
                                            <option
                                                value={option.value}
                                                onChange={() => setRestricts(option)}>
                                                {option.name}
                                            </option>
                                    )}
                                </Form.Select>}
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="4">
                            預約單位:
                        </Form.Label>
                        <Col sm="8">
                            <div key={"inline-radio"} className="mb-3">
                                {unitOptions.map(
                                    (option, i) =>
                                        <Form.Check
                                            inline
                                            defaultChecked={units.value === option.value}
                                            label={option.name}
                                            name="units-radio"
                                            type="radio"
                                            id={`inline-radio-${i}`}
                                            onChange={() => setUnits(option)}
                                        />
                                )}
                            </div>
                        </Col>
                    </Form.Group>


                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" type="submit">
                        新增
                    </Button>
                    <Button variant="secondary" onClick={handleClose}>
                        取消
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>);
}