import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { Modal, Button, Form } from "react-bootstrap"
import { addNewType } from '../../../redux/type/typeThunk';

import './EditAnnouncementDialog.css'
import { updateAnnouncement } from '../../../redux/system/systemThunk';


export default function EditAnnouncementDialog({ show, onClose, onConfirm }) {
    const dispatch = useDispatch();

    const user = useSelector(state => state.user);
    const system = useSelector(state => state.system);



    const [showState, setShow] = useState(show);
    const [validated, setValidated] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const [announcement, setAnnouncement] = useState(system.info.announcement);


    useEffect(() => {
        setShow(show);
    }, [show]);

    useEffect(() => {
        setAnnouncement(system.info.announcement);
    }, [system.info.announcement]);


    const handleClose = () => {
        setShow(false);
        setValidated(false);
        onClose();
    }

    const handleSubmit = async (event) => {

        const form = event.currentTarget;
        event.preventDefault();

        determineErrorMessage();

        if (form.checkValidity() === false || !isInputValid()) {
            event.stopPropagation();
        } else {
            dispatch(updateAnnouncement({ token: user.token, announcement: announcement }));
            onConfirm();
            handleClose();
        }

        setValidated(true);

    }


    const isInputValid = () => {
        return isLengthValid();
    }

    const determineErrorMessage = () => {
        if (!isLengthValid()) {
            setErrorMessage("請輸入字元在200字以內的內容!");
        }

    }


    const isLengthValid = () => {
        return announcement.length <= 200;
    }

    return (
        <Modal show={showState} dialogClassName="modal-70w">
            <Modal.Header>
                <Modal.Title>公告編輯</Modal.Title>
            </Modal.Header>
            <Form noValidate onSubmit={handleSubmit}>
                <Modal.Body>
                    <Form.Control
                        column
                        value={announcement}
                        as="input"
                        placeholder=""
                        isValid={isInputValid() && validated}
                        isInvalid={!isInputValid() && validated}
                        onChange={e => setAnnouncement(e.target.value)} />
                    <Form.Control.Feedback type="invalid">{errorMessage}</Form.Control.Feedback>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" type="submit">
                        確認更改
                    </Button>
                    <Button variant="primary" onClick={handleClose}>
                        取消
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>);
}