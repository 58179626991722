import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useRouteMatch,
    Redirect,
    useHistory
} from "react-router-dom";
import { Row, Col, Container, Button, Form } from "react-bootstrap"
import { LinkContainer } from 'react-router-bootstrap'
import ConditionalContainer from '../../components/ConditionalContainer'

import useQuery from '../../utils/useQuery'
import { addNewReserveType, fetchAllReservationTypes, updateReserveType } from '../../redux/reservation/reservationThunk';
import { getDayFromSecond, getHourFromSecond, getMinuteFromSecond, getMonthFromSecond, getWeekFromSecond } from '../../utils/utils';
import { fetchAllTypes, fetchSubtypes } from '../../redux/type/typeThunk';
import { addNewRoom, fetchAllRooms, fetchAllRoomTypes, updateRoom } from '../../redux/room/roomThunk';
import { roomTypeRestricts } from '../../redux/room/roomSlice';
import { userIdentity } from '../../redux/user/userSlice';
import { fetchAllTeachingTypes } from '../../redux/teacher/teacherThunk';
import { registerUser } from '../../redux/userList/userListThunk';

export default function AddUserFormPage() {
    //TODO: form validation
    const dispatch = useDispatch();
    const history = useHistory();

    const { path, url } = useRouteMatch();

    const user = useSelector(state => state.user);
    const type = useSelector(state => state.type);
    const teacher = useSelector(state => state.teacher);



    const identityOptions = [
        { name: "學生", value: userIdentity.student },
        { name: "老師", value: userIdentity.teacher },
    ]


    const [userName, setUserName] = useState("");
    const [accountName, setAccountName] = useState("");
    const [teachingTypeName, setTeachingTypeName] = useState("");
    const [identity, setIdentity] = useState(identityOptions[0]);
    const [password, setPassword] = useState("");
    const [verifyPassword, setVerifyPassword] = useState("");
    const [subtypes, setSubtypes] = useState({});

    const [backendErrorMessage, setBackendErrorMessage] = useState("");
    const [userNameErrorMessage, setUserNameErrorMessage] = useState("");
    const [accountNameErrorMessage, setAccountNameErrorMessage] = useState("");



    useEffect(async () => {
        dispatch(fetchAllTypes({ token: user.token }));
        dispatch(fetchAllTeachingTypes({ token: user.token }));
    }, []);


    useEffect(async () => {
        if (!teacher.teachingTypes) {
            return;
        }
        if (!teacher.teachingTypes[0]) {
            return;
        }
        setTeachingTypeName(teacher.teachingTypes[0].name);
    }, [teacher]);


    useEffect(async () => {
        if (!type.types) {
            return;
        }

        let newSubtypes = {}
        type.types.forEach(
            (typeItem) => {
                newSubtypes[typeItem.name] = typeItem.subtype[0];
            }
        );

        setSubtypes(newSubtypes);
    }, [type]);




    const handleSubmit = async (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            event.preventDefault();

            // console.log(subtypes)
            const result = await dispatch(registerUser(
                {
                    token: user.token,
                    account: accountName,
                    password: accountName,
                    name: userName,
                    identity: identity.value,
                    student_id: accountName,
                    teaching: teachingTypeName,
                    types: subtypes,
                }
            ));

            if (result.meta.requestStatus === "fulfilled") {
                history.replace('/user/usermanage');
                return;
            }


            console.log(result);
            if (result.payload.status === 500) {
                setBackendErrorMessage("伺服器錯誤");
            } else {
                const errorMessage = result.payload.data.message;
                console.log(errorMessage);
                if (errorMessage.includes("The account already exist")) {
                    setBackendErrorMessage("帳號已存在");
                } else {
                    setBackendErrorMessage(errorMessage);
                }
            }


        }

        // setValidated(true);

    };

    // const isFormValid = () => {
    //     return isRoomNameValid();
    // }

    // const isRoomNameValid = () => {
    //     return isRoomNameLengthValid() && isRoomNameNotRepeatValid();
    // }

    // const isRoomNameLengthValid = () => {
    //     return roomName.length > 0 && roomName.length <= 10;
    // }

    // const isRoomNameNotRepeatValid = () => {
    //     return !room.rooms.find(element => (element.name === roomName && room_id !== element.id));
    // }

    // const determineErrorMessage = () => {
    //     if (!isRoomNameLengthValid()) {
    //         setRoomNameErrorMessage("請輸入1~10字的新增琴房名稱!");
    //     } else if (!isRoomNameNotRepeatValid()) {
    //         setRoomNameErrorMessage("預約琴房名稱已經存在!");
    //     }
    // }


    return (
        <div className="pageContainer">
            <Container>
                <Row>
                    <Col>
                        <h2>新增使用者</h2>
                    </Col>
                </Row>
                <Row className="justify-content-md-center">
                    <Col md={8}>
                        <Form onSubmit={handleSubmit}>

                            <Form.Group as={Row} className="justify-content-center">
                                <Form.Label className="text-align-left" column sm="2">
                                    身份:
                                </Form.Label>
                                <Col sm="4">
                                    {(user.profile.identity === 'admin_s') ?
                                        <div key={"identity-radio"} className="mb-3">
                                            {identityOptions.map(
                                                (option, i) =>
                                                    <Form.Check
                                                        disabled
                                                        key={i}
                                                        inline
                                                        checked={identity.value === option.value}
                                                        label={option.name}
                                                        name="identity-radio"
                                                        type="radio"
                                                        id={`identity-radio-${i}`}
                                                        onChange={() => setIdentity(option)}
                                                    />
                                            )}
                                        </div> :
                                        <div key={"identity-radio"} className="mb-3">
                                            {identityOptions.map(
                                                (option, i) =>
                                                    <Form.Check
                                                        key={i}
                                                        inline
                                                        checked={identity.value === option.value}
                                                        label={option.name}
                                                        name="identity-radio"
                                                        type="radio"
                                                        id={`identity-radio-${i}`}
                                                        onChange={() => setIdentity(option)}
                                                    />
                                            )}
                                        </div>
                                    }
                                </Col>
                            </Form.Group>

                            <ConditionalContainer condition={identity.value === userIdentity.teacher}>
                                <Form.Group as={Row} className="justify-content-center">
                                    <Form.Label className="text-align-left" column sm="2">
                                        授課項目:
                                    </Form.Label>
                                    <Col sm="4">
                                        <Form.Select
                                            value={teachingTypeName}
                                            onChange={e => setTeachingTypeName(e.target.value)}>
                                            {teacher.teachingTypes.map(
                                                (teachingtype, i) =>
                                                    <option key={i}>{teachingtype.name}</option>
                                            )}
                                        </Form.Select>
                                    </Col>
                                </Form.Group>
                            </ConditionalContainer>
                            <br />

                            <Form.Group as={Row} className="justify-content-center">
                                <Form.Label className="text-align-left" column sm="2">使用者姓名:</Form.Label>
                                <Col sm="4">
                                    <Form.Control
                                        required
                                        column
                                        as="input"
                                        value={userName}
                                        onChange={e => {
                                            setUserName(e.target.value);
                                            // determineErrorMessage();
                                        }}
                                    // isValid={isRoomNameValid() && validated}
                                    // isInvalid={!isRoomNameValid() && validated}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {userNameErrorMessage}
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <br />

                            <Form.Group as={Row} className="justify-content-center">
                                <Form.Label className="text-align-left" column sm="2">帳號:</Form.Label>
                                <Col sm="4">
                                    <Form.Control
                                        required
                                        column
                                        as="input"
                                        value={accountName}
                                        onChange={e => {
                                            setAccountName(e.target.value);
                                            // determineErrorMessage();
                                        }}
                                    // isValid={isRoomNameValid() && validated}
                                    // isInvalid={!isRoomNameValid() && validated}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {accountNameErrorMessage}
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <br />

                            <Form.Group as={Row} className="justify-content-center">
                                <Form.Label className="text-align-left" column sm="2">類組:</Form.Label>
                                <Col md="4"></Col>
                            </Form.Group>
                            {type.types.map(
                                (typeItem, idx) =>
                                    <>
                                        <Form.Group as={Row} className="justify-content-center">
                                            <Form.Label className="text-align-left" column md={{ span: 2, offset: 1 }}>{typeItem.name}:</Form.Label>
                                            <Col sm="4">
                                                <Form.Select
                                                    value={subtypes[typeItem.name]}
                                                    onChange={e => setSubtypes({ ...subtypes, [typeItem.name]: e.target.value })}>
                                                    {typeItem.subtype.map(
                                                        (subtype, i) =>
                                                            <option key={i}>{subtype}</option>
                                                    )}
                                                </Form.Select>
                                            </Col>
                                        </Form.Group>
                                        <br />
                                    </>
                            )}
                            <h5 className="error-message">{backendErrorMessage}</h5>
                            <Row>
                                <Col>
                                    <Button size='lg' type="submit">新增</Button>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

