import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { Modal, Button, Form } from "react-bootstrap"
import { addNewType } from '../../../redux/type/typeThunk';
import { updateUserPassword } from '../../../redux/user/userThunk';


export default function UpdatePasswordDialog({ show, onClose, onConfirm }) {
    const dispatch = useDispatch();

    const user = useSelector(state => state.user);


    useEffect(() => {
        setShow(show);
    }, [show]);

    const [showState, setShow] = useState(show);
    const [validated, setValidated] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [password, setPassword] = useState("");
    const [verifyPassword, setVerifyPassword] = useState("");



    const handleClose = () => {
        setShow(false);
        setValidated(false);
        setPassword("");
        setVerifyPassword("");
        setErrorMessage("");
        onClose();
    }

    const handleSubmit = async (event) => {
        const form = event.currentTarget;
        event.preventDefault();

        determineErrorMessage();

        // console.log('length', isPasswordLengthValid());
        // console.log('format', isPasswordFormatValid());
        // console.log('verify', isVerifyPasswordValid());


        if (form.checkValidity() === false || !isPasswordValid() || !isVerifyPasswordValid()) {
            event.stopPropagation();
        } else {
            await dispatch(updateUserPassword({ token: user.token, password: password }));
            onConfirm();
            handleClose();
        }

        setValidated(true);

    };

    const isPasswordValid = () => {
        return isPasswordFormatValid() && isPasswordLengthValid();
    }

    const isVerifyPasswordValid = () => {
        return verifyPassword === password;
    }

    const determineErrorMessage = () => {
        if(!isPasswordLengthValid()){
            setErrorMessage("密碼長度必須為6-20!");
        }else if(!isPasswordFormatValid()){
            setErrorMessage("密碼必須包含英文字母與數字!");
        }else if(!isVerifyPasswordValid()){
            setErrorMessage("密碼與密碼確認不符合!");
        }

    }


    const isPasswordLengthValid = () => {
        return password.length <= 20 && password.length >= 6;
    }

    const isPasswordFormatValid = () => {
        let regex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]/;
        return regex.test(password);
    }


    return (
        <Modal show={showState} >
            <Modal.Header>
                <Modal.Title>修改密碼</Modal.Title>
            </Modal.Header>
            <Form noValidate onSubmit={handleSubmit}>
                <Modal.Body>
                    <Form.Control
                        column
                        as="input"
                        type="password"
                        placeholder="輸入6-20位密碼，包含英文字母與數字"
                        isValid={isPasswordValid() && validated}
                        isInvalid={!isPasswordValid() && validated}
                        onChange={e => { setPassword(e.target.value); determineErrorMessage(); }} />
                    <br />
                    <Form.Control
                        column
                        as="input"
                        type="password"
                        placeholder="請再輸入一次密碼"
                        isValid={isVerifyPasswordValid() && validated}
                        isInvalid={!isVerifyPasswordValid() && validated}
                        onChange={e => { setVerifyPassword(e.target.value); determineErrorMessage(); }} />
                    <Form.Control.Feedback type="invalid">{errorMessage}</Form.Control.Feedback>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" type="submit">
                        更新密碼
                    </Button>
                    <Button variant="secondary" onClick={handleClose}>
                        取消
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>);
}