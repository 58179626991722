import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import jsonwebtoken from 'jsonwebtoken';


export const fetchAllEvents = createAsyncThunk(
    'event/fetchAllEvents',
    async ({ token }, thunkAPI) => {

        if (!token) {
            return thunkAPI.rejectWithValue({ status: 0, data: { 'message': 'invalid token!' } });
        }
        try {
            const tokenData = jsonwebtoken.decode(token);
            const response = await axios.get(`/event/all`,
                {
                    headers: { "x-access-token": `token ${token}` }
                }
            );
            let eventList = response.data;
            for (let i = 0; i < eventList.length; i++) {
                eventList[i].song_list = [];
            }
            return eventList;

        } catch (error) {
            console.log("error", error.response)
            return thunkAPI.rejectWithValue({ status: error.response.status, data: error.response.data });
        }

    }
);



export const fetchEventDetailById = createAsyncThunk(
    'event/fetchEventDetailById',
    async ({ token, event_id }, thunkAPI) => {

        if (!token) {
            return thunkAPI.rejectWithValue({ status: 0, data: { 'message': 'invalid token!' } });
        }
        try {
            const tokenData = jsonwebtoken.decode(token);
            const response = await axios.get(`/event?id=${event_id}`,
                {
                    headers: { "x-access-token": `token ${token}` }
                }
            );
            return response.data;

        } catch (error) {
            console.log("error", error.response)
            return thunkAPI.rejectWithValue({ status: error.response.status, data: error.response.data });
        }

    }
);


export const addNewEvent = createAsyncThunk(
    'event/addNewEvent',
    async ({ token, eventName }, thunkAPI) => {

        if (!token) {
            return thunkAPI.rejectWithValue({ status: 0, data: { 'message': 'invalid token!' } });
        }
        const form = new FormData();
        form.append('name', eventName);
        try {
            const response = await axios.post('/event/', form,
                {
                    headers: { "x-access-token": `token ${token}` }
                }
            );
            let newEvent = response.data;
            newEvent.song_list = [];
            return newEvent;
        } catch (error) {
            return thunkAPI.rejectWithValue({ status: error.response.status, data: error.response.data });
        }

    }
);

export const deleteEvent = createAsyncThunk(
    'event/deleteEvent',
    async ({ token, event_id }, thunkAPI) => {

        if (!token) {
            return thunkAPI.rejectWithValue({ status: 0, data: { 'message': 'invalid token!' } });
        }

        try {
            const response = await axios.delete(`/event/`,
                {
                    headers: { "x-access-token": `token ${token}` },
                    data: { id: event_id },
                }
            );
            return { id: event_id };
        } catch (error) {
            return thunkAPI.rejectWithValue({ status: error.response.status, data: error.response.data });
        }

    }
);


export const deleteSongFromEvent = createAsyncThunk(
    'event/deleteSongFromEvent',
    async ({ token, song_id, event_id }, thunkAPI) => {

        if (!token) {
            return thunkAPI.rejectWithValue({ status: 0, data: { 'message': 'invalid token!' } });
        }


        try {
            const response = await axios.delete(`/song/`,
                {
                    headers: { "x-access-token": `token ${token}` },
                    data: {id: song_id},
                }
            );
            return { event_id: event_id, song_id: song_id };
        } catch (error) {
            return thunkAPI.rejectWithValue({ status: error.response.status, data: error.response.data });
        }

    }
);
